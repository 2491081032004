import { Box } from "@mui/material";

import colorCalculator from "../mapUtils/colorCalculator";

const Legend = ({ countries }) => {
  // Set to keep track of unique country names
  const uniqueNames = new Set();

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      {countries?.map((country) => {
        // Check if the country name is already processed
        if (!uniqueNames.has(country.key)) {
          // Add the country name to the set
          uniqueNames.add(country.key);

          return (
            <Box
              key={country.key}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #EBF1F8",
                marginBottom: "0.5em",
              }}
            >
              {/* Country Name */}
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#15314E",
                  marginRight: "3em",
                  textWrap: "nowrap",
                }}
              >
                {country.key}
              </p>

              {/* Interest Bar and % */}
              <div
                style={{
                  width: "20em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {/* Bar container */}
                <div
                  style={{
                    width: "200px",
                    height: "8px",
                    backgroundColor: "#F6F6F6",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginRight: "1em",
                  }}
                >
                  {/* Bar progress */}
                  <div
                    style={{
                      backgroundColor: colorCalculator(country.doc_count),
                      width: `${country.doc_count}%`,
                      height: "100%",
                      borderRadius: "8px",
                    }}
                  />
                </div>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#15314E",
                  }}
                >
                  {country.doc_count}%
                </p>
              </div>
            </Box>
          );
        }

        return null; // Return null for duplicate country names
      })}
    </Box>
  );
};

export default Legend;
