import React from "react";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

export default function HelperButton({ setIsDrawerOpen }) {
  const useStyles = makeStyles(() => ({
    Fab: {
      position: "fixed",
      right: 30,
      bottom: 30,
      backgroundColor: "#000000",
      color: "#ffffff",
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Fab
        name="stepHelpPopup"
        size="small"
        className={classes.Fab}
        data-tut="reactour__que"
        onClick={() => setIsDrawerOpen(true)}
      >
        <Typography>?</Typography>
      </Fab>
    </div>
  );
}
