import { Grid } from "@mui/material";
import OnboardingProcess from "./onboardingProcess/OnboardingProcess";
import UserGuide from "./userGuide/UserGuide";

const section = [<OnboardingProcess />, <UserGuide />];

function GetStartedRight({ selectedIndex }) {
  return (
    <Grid item container md={10}>
      {section[selectedIndex - 1]}
    </Grid>
  );
}

export default GetStartedRight;
