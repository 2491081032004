import React from "react";

import { Grid } from "@mui/material";

function MainLayoutSection({
  left,
  right,
  leftHidden,
  rightHidden,
  leftWidth = "48%",
  rightWidth = "48%",
  height = "68vh",
}) {
  return (
    <Grid container style={{ height }} justifyContent="space-between">
      {!leftHidden && (
        <Grid item style={{ width: leftWidth, height: "100%" }}>
          {left}
        </Grid>
      )}
      {!rightHidden && (
        <Grid item style={{ width: rightWidth, height: "100%" }}>
          {right}
        </Grid>
      )}
    </Grid>
  );
}
export default MainLayoutSection;
