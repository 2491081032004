import { Box, TextField } from "@mui/material";

import React from "react";
import Heading from "../heading/Heading";

function InputField({
  heading,
  label = "Label Name",
  rows = 1,
  marginBottom = "20px",
  marginTop = "10px",
  value,
  handleChange,
  error,
  name,
}) {
  return (
    <Box
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        width: "100%",
      }}
    >
      <Heading text={heading} size="13px" marginBottom="10px" color="#999" />
      <TextField
        name={name}
        label={label}
        variant="outlined"
        value={value}
        onChange={handleChange}
        fullWidth
        size="small"
        error={Boolean(error)}
        helperText={error}
        multiline
        minRows={rows}
        InputLabelProps={{
          style: {
            fontSize: "14px",
          },
        }}
      />
    </Box>
  );
}

export default InputField;
