import SustainablilityTop from "components/widgets/sustainabilityTop/sustainablilityTop";
import ReportPdf from "./ReportPdf";
import { Box } from "@mui/material";

function ReportLeftMain() {
  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ height: "10%" }}>
        <SustainablilityTop />
      </Box>
      <Box sx={{ height: "90%" }}>
        <ReportPdf />
      </Box>
    </Box>
  );
}

export default ReportLeftMain;
