import React from "react";
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DynamicTable from "components/UI/phase-3/dynamicTable/DynamicTable";
import { useNavigate } from "react-router-dom";

const surveys = [
  {
    surveyName: "ESG Benchmarking Survey",
    lastModified: "25 Jul",
    status: "Active",
    createdBy: "Pradeep",
  },
  {
    surveyName: "ESG Materiality Analysis",
    lastModified: "22 Apr",
    status: "Active",
    createdBy: "Pradeep",
  },
  {
    surveyName: "Greenhouse Gas Emissions Inventory",
    lastModified: "02 Jun",
    status: "Active",
    createdBy: "Pradeep",
  },
  {
    surveyName: "Supplier ESG Performance Evaluation",
    lastModified: "21 Nov",
    status: "Active",
    createdBy: "Pradeep",
  },
  {
    surveyName: "ESG Stakeholder Engagement Survey",
    lastModified: "29 Mar",
    status: "Active",
    createdBy: "Pradeep",
  },
  {
    surveyName: "ESG Master KPI Collection Survey",
    lastModified: "27 Mar",
    status: "Active",
    createdBy: "Pradeep",
  },
  {
    surveyName: "ESG Policies & Certifications",
    lastModified: "21 Jan",
    status: "Active",
    createdBy: "Pradeep",
  },
];

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

function SurveyList() {
  const classes = useStyle();

  const navigate = useNavigate();
  return (
    <Box sx={{ margin: "10px 0px" }}>
      <DynamicTable
        headings={[
          "Survey Name",
          "Last Modified",
          "Status",
          "Created By",
          "Action",
        ]}
      >
        {surveys?.map((data, index) => (
          <TableRow
            key={data?._id}
            // className={classes.tableRow}
            hover
            onClick={() =>
              navigate(`/survey/${data.surveyName?.replaceAll(" ", "_")}`)
            }
          >
            <TableCell align="left">{data.surveyName}</TableCell>
            <TableCell>{data.lastModified}</TableCell>
            <TableCell>
              <Chip
                sx={(theme) => ({
                  backgroundColor:
                    data.status !== "Active"
                      ? theme.palette.primary.lighter
                      : theme.palette.secondary.lighter,
                  padding: "6px 10px",
                  fontSize: "12px",
                  color:
                    data.status !== "Active"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark,
                  borderRadius: "80px",
                })}
                label={data.status}
              />
            </TableCell>
            <TableCell>{data.createdBy}</TableCell>

            <TableCell>
              <MoreVertOutlinedIcon />
            </TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </Box>
  );
}

export default SurveyList;
