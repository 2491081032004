import {
  SET_ENVIRONMENT_SURVEY,
  SET_INTERNAL_MATURITY_MATRIX,
  SET_MATURITY_MATRIX_ASSIGNED_MEMBER,
  SET_TASK_ANSWERS,
  SET_EXTERNAL_MATURITY_MATRIX,
  SET_MATURITY_MATRIX_TASK_QUESTIONS,
  SET_OVERALL_SURVEY,
  SET_SURVEY_QUESTIONS,
  RESET_MATURITY_MATRIX,
  SET_MATURITY_MATRIX_NOT_ASSIGNED_USERS,
} from "../../constants/maturityMetricsConst/maturityMatrixConstants";

const initial_state = {
  questions: [],
  taskQuestions: [],
  taskAnswers: [],
  maturityMatrixReviewer: [],
  externalData: [],
  notAssignedUsers: [],
  internalData: {
    calculated_matrix_obj: {},
    category_matrix_value: {
      "Business Model & Innovation": 0,
      Environment: 0,
      "Human Capital": 0,
      "Leadership & Governance": 0,
      "Social Capital": 0,
    },
    total_no_of_users: 1,
    total_value: 0,
  },
  data1: [],
  data2: {},
};

export default function maturityMatrixReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_OVERALL_SURVEY:
      return { ...state, data1: action.payload };
    case SET_ENVIRONMENT_SURVEY:
      return { ...state, data2: action.payload };
    case SET_SURVEY_QUESTIONS:
      return { ...state, questions: action.payload };
    case SET_TASK_ANSWERS:
      return { ...state, taskAnswers: action.payload };
    case SET_MATURITY_MATRIX_TASK_QUESTIONS:
      return { ...state, taskQuestions: action.payload };
    case SET_MATURITY_MATRIX_ASSIGNED_MEMBER:
      return { ...state, maturityMatrixReviewer: action.payload };
    case SET_INTERNAL_MATURITY_MATRIX:
      return { ...state, internalData: action.payload };
    case SET_EXTERNAL_MATURITY_MATRIX:
      return { ...state, externalData: action.payload };
    case SET_MATURITY_MATRIX_NOT_ASSIGNED_USERS:
      return { ...state, notAssignedUsers: action.payload };
    case RESET_MATURITY_MATRIX:
      return { ...initial_state };
    default:
      return state;
  }
}
