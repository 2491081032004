import React, { useState } from "react";

import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import DashboardPage from "./pages/DashboardPage";
import ExploreQueryBuilder from "./component/exploreQBuilder/ExploreQueryBuilder";
import {
  Button,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
const API_URL = "https://cube2.imgrows.app";

const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzExMDY1NDMsImV4cCI6MTY3MTE5Mjk0M30.-9_171MOu0Vs8rfl2f_siqdys9SYph6xfZDvFL81ilI";

const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: `${API_URL}/cubejs-api/v1`,
});

function AppDashBoard(props) {
  const { charts } = props.dataHub;
  const { token, selectedOrganization } = props.login;
  const { selectedKpiType } = useSelector((state) => state?.dataHub);

  const cubejsApi = React.useMemo(
    () =>
      cubejs({
        apiUrl: `${API_URL}/cubejs-api/v1`,
        headers: {
          Authorization: ` ${selectedKpiType}/${props?.dataStream_id}` || "",
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedKpiType, props?.dataStream_id]
  );

  const BACKGROUND_COLOR = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(210, 79, 31, 0.2)",
    "rgba(154, 6, 203, 0.22)",
  ];

  const BORDER_COLOR = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(210, 79, 31, 0.2)",
    "rgba(154, 6, 203, 0.22)",
  ];

  const [backgroundColor, setBackGroundColor] =
    React.useState(BACKGROUND_COLOR);

  const [borderColor, setBorderColor] = React.useState(BORDER_COLOR);

  const [chartTitle, setChartTitle] = React.useState("New Chart");

  const [limit, setLimit] = React.useState(5000);

  React.useEffect(() => {
    props?.getDataHubChart(token, selectedOrganization._id);
  }, []);

  // const dashboardItems = [
  //   {
  //     id: 0,
  //     name: "Total waste Recycled(in metric tonne) ",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Recycling.Recyled_value"],
  //         order: {
  //           "Recycling.Recyled_value": "desc"
  //         },
  //         dimensions: ["Recycling.Recycled_quantity"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 16,
  //       w: 6,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 1,
  //     name: "Percentage of Female Board of Directors",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Percentage_Female_BOD.femaleBOD_value"],
  //         order: {
  //           "Percentage_Female_BOD.femaleBOD_value": "desc"
  //         },
  //         dimensions: ["Percentage_Female_BOD.percentageoffemaleBOD"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 8,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 2,
  //     name: "Waste generated",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Waste.total_waste_generated"],
  //         order: {
  //           "Total_Waste.total_waste_generated": "desc"
  //         },
  //         dimensions: ["Total_Waste.total_waste"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 0,
  //       w: 12,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 3,
  //     name: "Total Energy Generated",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Energy.Total_Energy"],
  //         order: {
  //           "Total_Energy.Total_Energy": "desc"
  //         },
  //         dimensions: ["Total_Energy.Energy"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 8,
  //       y: 8,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 4,
  //     name: "Emission Intensity",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Emission.Total_Emission"],
  //         order: {
  //           "Total_Emission.Total_Emission": "desc"
  //         },
  //         dimensions: ["Total_Emission.Emission"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 6,
  //       y: 16,
  //       w: 6,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 5,
  //     name: "Total Energy Consumed (in joules or multipls)",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Total_Energy_Consumption.Total_Energy_Consumption"],
  //         order: {
  //           "Total_Energy_Consumption.Total_Energy_Consumption": "desc"
  //         },
  //         dimensions: ["Total_Energy_Consumption.Energy_Consumption"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 4,
  //       y: 8,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 6,
  //     name: "Rehabilitation and Resettlement",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Rehabilitated.Total_Rehabilitated"],
  //         order: {
  //           "Rehabilitated.Total_Rehabilitated": "desc"
  //         },
  //         dimensions: ["Rehabilitated.Rehabilitated"],
  //         timeDimensions: []
  //       },
  //       chartType: "pie"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 24,
  //       w: 4,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 7,
  //     name: "Minimum Wages Paid",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: ["Average_Wages.Total_Wages"],
  //         order: {
  //           "Average_Wages.Total_Wages": "desc"
  //         },
  //         dimensions: ["Average_Wages.Wages"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 4,
  //       y: 24,
  //       w: 8,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   },
  //   {
  //     id: 8,
  //     name: "Total Waste Genrated",
  //     vizState: {
  //       query: {
  //         limit: 5000,
  //         measures: [
  //           "Total_Waste_Generated_for_recycling.Total_Waste_Generated"
  //         ],
  //         order: {
  //           "Total_Waste_Generated_for_recycling.Total_Waste_Generated": "desc"
  //         },
  //         dimensions: ["Total_Waste_Generated_for_recycling.Waste_Generated"],
  //         timeDimensions: []
  //       },
  //       chartType: "bar"
  //     },
  //     layout: {
  //       x: 0,
  //       y: 32,
  //       w: 12,
  //       h: 8,
  //       minW: 4,
  //       minH: 8
  //     }
  //   }
  // ];

  // const [charts, setChart] = useState(charts ? charts : []);

  const [title, setTitle] = useState(null);

  const finalTitle = title != null ? title : "New charts";

  const [vizState, setVizState] = useState(null);

  const finalVizState = vizState || {};

  const [open, setOpen] = React.useState(false);

  const [addChart, setAddChart] = React.useState(true);

  const [pivotConfig, setPivotConfiq] = React.useState({});

  const [barChartCustomize, setBarChartCustomize] = React.useState({
    borderWidth: 10,
    barThickness: 100,
    borderRadius: 20,
  });

  const [lineChartCustomize, setLineChartCustomize] = React.useState({
    pointRadius: 15,
    tension: 0.3,
    pointHoverRadius: 10,
    borderWidth: 6,
    pointStyle: "rectRounded",
  });

  const [pieChartCustomize, setPieChartCustomize] = React.useState({
    cutout: 0,
    rotation: 0,
    circumference: 360,
  });

  const [chartX_AxisCustomize, setChartX_AxisCustomize] = React.useState({
    display: true,
    text: "Your Title",
    align: "center",
    color: "red",
    font: {
      size: 15,
    },
  });

  const [chartY_AxisCustomize, setChartY_AxisCustomize] = React.useState({
    display: true,
    text: "Your Title",
    align: "center",
    color: "red",
    font: {
      size: 15,
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    setOpen(true);
  };

  const handleAddChart = () => {
    const newChartObj = {
      // id: charts.length,
      name: chartTitle,
      vizState: {
        query: {
          limit,
          measures: vizState.query.measures,

          dimensions: vizState.query.dimensions,
          order: vizState.query.order,
          filters: vizState.query.filters,
          timeDimensions: [],
        },
        chartType: vizState.chartType,
      },
      layout: {
        x: 0,
        y: 0,
        w: 4,
        h: 8,
        minW: 4,
        minH: 8,
      },
      pivotConfig: vizState.pivotConfig,

      chartColors: {
        backgroundColors: backgroundColor,
        borderColors: borderColor,
        chartX_AxisCustomize,
        chartY_AxisCustomize,
      },
    };

    props.addDataHubChart(
      token,
      selectedOrganization._id,
      newChartObj,
      selectedKpiType
    );

    // setChart((preCharts) => [...preCharts, newChartObj]);

    setOpen(false);
  };

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      {!props?.dataStream_id && (
        <div
          style={{
            width: "90%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button color="primary" variant="contained" onClick={handleDrawer}>
            Add charts
          </Button>
        </div>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>Add charts</Typography>
            {/* <TextField
              value={chartTitle}
              name="chartTitle"
              onChange={(e) => setChartTitle(e.target.value)}
            ></TextField> */}
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
            {/* <Button
              color="primary"
              variant="contained"
              onClick={handleAddChart}
              disabled={!addChart}
            >
              Add charts
            </Button> */}
          </div>
        </DialogTitle>
        <ExploreQueryBuilder
          cubejsApi={cubejsApi}
          vizState={finalVizState}
          setVizState={setVizState}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          setBackGroundColor={setBackGroundColor}
          setBorderColor={setBorderColor}
          setPivotConfiq={setPivotConfiq}
          setLimit={setLimit}
          barChartCustomize={barChartCustomize}
          lineChartCustomize={lineChartCustomize}
          chartX_AxisCustomize={chartX_AxisCustomize}
          chartY_AxisCustomize={chartY_AxisCustomize}
          pieChartCustomize={pieChartCustomize}
          setBarChartCustomize={setBarChartCustomize}
          setChartX_AxisCustomize={setChartX_AxisCustomize}
          setChartY_AxisCustomize={setChartY_AxisCustomize}
          setLineChartCustomize={setLineChartCustomize}
          setPieChartCustomize={setPieChartCustomize}
          setAddChart={setAddChart}
          chartTitle={chartTitle}
          setChartTitle={setChartTitle}
        />
        <div style={{ width: "99%" }}>
          <Button
            style={{ margin: "10px 8px", float: "right" }}
            color="primary"
            variant="contained"
            onClick={handleAddChart}
            disabled={!addChart}
          >
            Add charts
          </Button>
        </div>
      </Dialog>

      <div style={{ height: "100%" }}>
        <DashboardPage
          // charts={charts}
          dataStream_id={props?.dataStream_id}
          frameWork={props?.frameWork}
          dashboardCharts={charts}
          updateDashboardLayout={props.updateDashboardLayout}
          token={token}
          selectedOrganization={selectedOrganization._id}
          open={open}
          deleteDataHubChart={props?.deleteDataHubChart}
        />
      </div>
    </CubeProvider>
  );
}
export default AppDashBoard;
