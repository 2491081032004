import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import ListSubheader from "@mui/material/ListSubheader";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../../../../customHooks/useDebouncec";
import {
  Circle,
  hexToHsva,
  hsvaToRgbaString,
  hsvaToHexa,
  rgbaStringToHsva,
  Colorful,
} from "@uiw/react-color";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function ChartColorsTransferList({
  setBackGroundColors,
  setBorderColors,
}) {
  const ALL_COLORS = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(210, 79, 31, 0.2)",
    "rgba(154, 6, 203, 0.22)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(210, 79, 31, 1)",
    "rgba(154, 6, 203, 1)",
  ];

  const colorArrHexa = ALL_COLORS.map((i) => hsvaToHexa(rgbaStringToHsva(i)));

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(colorArrHexa);
  const [backgroundColor, setBackgroundColor] = React.useState([]);

  const [borderColor, setBorderColor] = React.useState([]);
  const [hex, setHex] = React.useState("#59c09a");
  const [addColor, setAddColor] = React.useState(false);

  const leftChecked = intersection(checked, left);
  const backgroundColorChecked = intersection(checked, backgroundColor);
  const borderColorChecked = intersection(checked, borderColor);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  //   React.useEffect(() => {
  //     setBorderColors(borderColor.map((c) => hsvaToRgbaString(hexToHsva(c))));
  //     setBackGroundColors(
  //       backgroundColor.map((c) => hsvaToRgbaString(hexToHsva(c)))
  //     );
  //   }, [backgroundColor, borderColor]);

  const handleBorderColor = () => {
    setBorderColor(borderColor.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setBorderColors(
      borderColor.concat(leftChecked).map((c) => hsvaToRgbaString(hexToHsva(c)))
    );
  };

  const handleBackGroundColor = () => {
    setBackgroundColor(backgroundColor.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    console.log(
      "bgcolr",
      backgroundColor
        .concat(leftChecked)
        .map((c) => hsvaToRgbaString(hexToHsva(c)))
    );
    setBackGroundColors(
      backgroundColor
        .concat(leftChecked)
        .map((c) => hsvaToRgbaString(hexToHsva(c)))
    );
  };

  const handleCheckedBackgroundLeft = () => {
    setLeft(left.concat(backgroundColorChecked));
    setBackgroundColor(not(backgroundColor, backgroundColorChecked));
    setChecked(not(checked, backgroundColorChecked));
    setBackGroundColors(
      not(backgroundColor, backgroundColorChecked).map((c) =>
        hsvaToRgbaString(hexToHsva(c))
      )
    );
  };

  const handleCheckedBorderLeft = () => {
    setLeft(left.concat(borderColorChecked));
    setBorderColor(not(borderColor, borderColorChecked));
    setChecked(not(checked, borderColorChecked));
    setBorderColors(
      not(borderColor, borderColorChecked).map((c) =>
        hsvaToRgbaString(hexToHsva(c))
      )
    );
  };

  useDebounce(
    () => {
      setLeft((preValue) => [hex, ...preValue]);
    },
    2000,
    [hex]
  );

  const customList = (items, header) => (
    <Paper className={classes.paper}>
      <List
        subheader={
          <ListSubheader style={{ backgroundColor: "#f2f2f2" }}>
            {header}
          </ListSubheader>
        }
        dense
        component="div"
        role="list"
      >
        {header === "All Colors" && (
          <ListItem
            style={{ cursor: "pointer" }}
            onClick={() => setAddColor(true)}
          >
            add more color <AddIcon />{" "}
          </ListItem>
        )}
        {items.map((value, id) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={<Circle colors={[value]} color={value} />}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList(left, "All Colors")}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleBackGroundColor}
            disabled={leftChecked.length === 0}
            aria-label="move selected backgroundColor"
          >
            BackGround Color &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedBackgroundLeft}
            disabled={backgroundColorChecked.length === 0}
            aria-label="move selected left"
          >
            Remove &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(backgroundColor, "BackGround Colors")}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleBorderColor}
            disabled={leftChecked.length === 0}
            aria-label="move selected backgroundColor"
          >
            Border Color &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedBorderLeft}
            disabled={borderColorChecked.length === 0}
            aria-label="move selected left"
          >
            Remove &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(borderColor, "Border Colors")}</Grid>
      <Grid item md={10} justifyContent="center">
        {addColor && (
          <Colorful
            color={hex}
            onChange={(color) => {
              setHex(color.hexa);
            }}
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        )}
      </Grid>
    </Grid>
  );
}
