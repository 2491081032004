import makeStyles from "@mui/styles/makeStyles";

const profileDetailsFormStyles = makeStyles((theme) => ({
  image: {
    width: 200,
    height: 200,
    borderRadius: "50%",
    overflow: "hidden",
  },
  fileUpload: {
    cursor: "pointer",
  },
  Avatar: { width: 200, height: 200 },
  Card: { border: "none" },
  Card1: { border: "none", height: "10vh" },
  CardContent: { textAlign: "right", paddingRight: 100 },
  Typography: { fontWeight: "600", fontSize: 26 },
  Typography1: { fontWeight: "600" },
  Grid: { marginTop: 15, marginBottom: 15 },
  CustomButton: { paddingRight: 30, paddingLeft: 30 },
}));

export default profileDetailsFormStyles;
