import { getSuppliersList } from "actions/phase-3/orgDetails/orgDetailsAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSuppliersAPIs = (keyword = "", limit = 5, page = 1) => {
  const { suppliers_list } = useSelector((state) => state.orgDetails);
  const { parent_id } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const fetchSuppliers = () => {
    dispatch(getSuppliersList(parent_id, keyword, limit, (page - 1) * limit));
  };

  useEffect(() => {
    fetchSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  return {
    // states & data
    suppliers_list,
    // funcs
    fetchSuppliers,
  };
};
