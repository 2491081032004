import {
  SET_ONBOARD_DATA,
  SET_USER_DETAILS,
  SET_LOGOUT_USER,
} from "constants/phase-3/auth/userConst";

const initial_state = {
  user_id: "",
  name: "",
  email: "",
  onBoarding: false,
  user_type: null,
  profile_img: null,
  signup_process: 0,
  help_flow: [],
  paid: false,
  isLoggedin: false,
  onBoardData: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_USER_DETAILS:
      return (state = {
        ...state,
        ...action.payload,
      });

    case SET_ONBOARD_DATA:
      return (state = {
        ...state,
        onBoardData: action.payload,
      });

    case SET_LOGOUT_USER:
      return (state = {
        ...initial_state,
      });

    default:
      return state;
  }
}

// ------------------- Important Key Points -------------------

/*
1. signup_process no. usages:
  a. signup_process = 0 -> Signup/Login Page redirection
  b. signup_process = 1 -> Verify OTP Page redirection
  c. signup_process = 2 -> OTP Verified and Basic Info Part 1 Page redirection
  d. signup_process = 3 -> Basic Info Part 2 Page redirection
  e. signup_process = 4 -> All Info complete and Main App redirection

*/
