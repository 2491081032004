import React from "react";

import { Paper } from "@mui/material";

function LeftSectionLayout({ hasShadow = true, padding = "10px", children }) {
  return (
    <Paper
      style={{
        borderRadius: "8px",
        width: "100%",
        padding,
        height: "100%",
        boxShadow: hasShadow && "0px 0px 8px 0px rgba(51, 116, 185, 0.25)",
      }}
    >
      {children}
    </Paper>
  );
}

export default LeftSectionLayout;
