import makeStyles from "@mui/styles/makeStyles";

const menuBarUseStyles = makeStyles((theme) => ({
  hideShow: {
    visibility: "hidden",
    height: "0",
    [theme.breakpoints.down("md")]: {
      visibility: "visible",
      height: "auto",
    },
  },
  link: { textDecoration: "none" },
  divider: { marginTop: "auto" },
  mainGrid: { height: "70px" },
  icon: { color: "#0873d0" },
  img: { float: "left", marginRight: "0px", width: "50%" },
}));

export default menuBarUseStyles;
