import { useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import AuthLeftBlue from "components/screen/phase-3/AuthLeftRight/AuthLeftBlue";
import AuthRightWrap from "components/screen/phase-3/AuthLeftRight/AuthRightWrap";
import OnBoardRightHead from "components/widgets/phase-3/OnBoardRight/OnBoardRightHead";
import {
  CompanyInfoForm,
  ESGRoleForm,
} from "components/widgets/phase-3/OnBoardRight/Forms";
import { useDispatch, useSelector } from "react-redux";
import useFormValidation from "customHooks/useFormValidation";
import {
  createParentEntity,
  get_user_details,
  userUserData,
} from "actions/phase-3/auth/authAction";
import MainGoalsForm from "components/widgets/phase-3/OnBoardRight/Forms/MainGoalsForm";
import FoundUsForm from "components/widgets/phase-3/OnBoardRight/Forms/FoundUsForm";
import GreenCheckMark from "images/phase-3/AuthLeftRight/onboarding/GreenCheckMark.svg";
import { useNavigate } from "react-router-dom";

const stepLabels = {
  1: {
    label: "Company Information",
    head: "Your company Name, Industry, and Sector",
    desc: "Please provide your full name along with the industry and sector your company operates in. This helps us tailor the experience to your specific field.",
  },
  2: {
    label: "Your ESG Role",
    head: "Your Role in ESG",
    desc: "Tell us about your role in your company’s ESG efforts. Understanding your responsibilities allows us to better support your goals.",
  },
  3: {
    label: "Primary Objectives",
    head: "Your Main Goals",
    desc: "What do you hope to achieve by using our ESG platform? Sharing your objectives allows us to customize your experience and support your success.",
  },
  4: {
    label: "Discovery Source",
    head: "How Did You Find Us?",
    desc: "Let us know how you came across our application. This information helps us understand how users discover our platform and improve our outreach efforts.",
  },
  5: {
    head: "Congratulation! your registration has been complete",
    desc: "Proceed on to your onboarding screen to view",
  },
};

const VerifyContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const total_step = 5;
  const [step, setStep] = useState(1);
  const [formError, setFormError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const { signup_process, entity_id } = useSelector((state) => state.user);
  const { parent_entity } = useSelector((state) => state.orgDetails);

  const initialState = {
    company_name: parent_entity?.entity_name || "",
    sector_id: parent_entity?.sector?._id || null,
    industry_id: parent_entity?.industry?._id || null,
    isin: parent_entity?.isin || "",
    work: "",
    category: "",
    goals: [],
    how_you_found_us: "",
  };

  const validationRules = {
    company_name: { minCharacters: 3, maxCharacters: 30, required: true },
    sector_id: { required: true },
    industry_id: { required: true },
    isin: { required: true, message: "ISIN is required" },
    work: { required: true },
    category: { required: true },
    goals: { required: true },
    how_you_found_us: { required: true },
  };

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(initialState, validationRules);
  const validationData = {
    formData,
    errors,
    handleChange,
    setFormData,
    hasErrors,
  };

  const disablePrev = step === 1;
  const disableNext = step === 5 || formError;

  const handelPrev = () => {
    if (!disablePrev) {
      setStep((prev) => prev - 1);
    }
  };

  const handelNext = () => {
    if (!disableNext) {
      if (step === 1 && hasChanges) {
        dispatch(
          createParentEntity(
            entity_id,
            formData?.company_name, // entity_name
            formData?.sector_id, // sector_id
            formData?.industry_id, // industry_id
            formData?.isin, // isin
            setStep,
            setHasChanges
          )
        );
      } else if (step === 4) {
        dispatch(
          userUserData(
            formData?.work,
            formData?.category,
            formData?.goals,
            formData?.how_you_found_us,
            entity_id,
            setStep,
            setHasChanges
          )
        );
      } else {
        setStep((prev) => prev + 1);
        setHasChanges(false);
      }
    }
  };

  const handleFormError = (hasError) => {
    setFormError(hasError);
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <CompanyInfoForm
            setHasChanges={setHasChanges}
            validationData={validationData}
            handleFormError={handleFormError}
          />
        );
      case 2:
        return (
          <ESGRoleForm
            setHasChanges={setHasChanges}
            validationData={validationData}
            handleFormError={handleFormError}
          />
        );
      case 3:
        return (
          <MainGoalsForm
            setHasChanges={setHasChanges}
            validationData={validationData}
            handleFormError={handleFormError}
          />
        );
      case 4:
        return (
          <FoundUsForm
            setHasChanges={setHasChanges}
            validationData={validationData}
            handleFormError={handleFormError}
          />
        );
      case 5:
        return (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <Box
              component={"img"}
              src={GreenCheckMark}
              alt={"SurveyCompleted"}
              width={"20em"}
              height={"20em"}
            />
          </Stack>
        );

      default:
        break;
    }
  };

  const handleOnBoard = () => {
    dispatch(get_user_details(false));
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  useEffect(() => {
    if (signup_process === 3) {
      setStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width={"100%"} height={"100vh"} display={"flex"}>
      <AuthLeftBlue sx={{ display: { xs: "none", md: "block" } }} />
      <AuthRightWrap>
        <Stack
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ p: { xs: 5, md: 15 } }}
        >
          {/* Main Box */}
          <Stack spacing={4} width={"100%"}>
            {/* Form Section */}
            <Stack spacing={2}>
              {/* Head */}
              <OnBoardRightHead
                step={step}
                total_steps={total_step}
                label={stepLabels[step].label}
                head={stepLabels[step].head}
                desc={stepLabels[step].desc}
                hideStep={step === 5}
              />
            </Stack>

            {/* Form */}
            {renderForm()}

            {/* Actions */}
            {step !== 5 ? (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="text"
                  size="large"
                  sx={{ width: { xs: "auto", md: "15em" } }}
                  onClick={handelPrev}
                  disabled={disablePrev}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: { xs: "auto", md: "15em" } }}
                  onClick={handelNext}
                  disabled={disableNext}
                >
                  Next
                </Button>
              </Box>
            ) : (
              <Button
                variant="contained"
                size="large"
                onClick={handleOnBoard}
                fullWidth
              >
                Proceed On To Onboarding
              </Button>
            )}
          </Stack>
        </Stack>
      </AuthRightWrap>
    </Box>
  );
};
export default VerifyContainer;
