import React from "react";
import debounce from "lodash.debounce";

// mui
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material";

import { CgSearch } from "react-icons/cg";

// components
import Textfield from "./textfield";

function SearchTextField({ searchClick, ...props }) {
  const theme = useTheme();

  const [searchChange, setSearchChange] = React.useState("");

  // Debounce the searchClick function
  const debouncedSearchClick = React.useMemo(
    () => debounce((query) => searchClick(query), 800), // Debounce delay set to 500 milliseconds
    [searchClick]
  );

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchChange(newValue);
    debouncedSearchClick(newValue);
  };

  return (
    <Textfield
      variant={"outlined"}
      color={"primary"}
      label={"Search"}
      type={"text"}
      value={searchChange}
      size={"small"}
      onChange={handleSearchChange}
      InputProps={{
        sx: {
          borderRadius: 2,
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!searchChange}
              onClick={() => searchClick(searchChange)}
              size="small"
              sx={{ cursor: "pointer", zIndex: 100 }}
            >
              <CgSearch size={24} color={theme.palette.primary.main} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default React.memo(SearchTextField);
