import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: 440,
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#76A2D0",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#3374B9",
    },
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: "rgb(233, 233, 233)",
    zIndex: 1,
    // whiteSpace: "nowrap",
  },
}));

const SelectedSurveyTable = ({ surveyDetails }) => {
  const classes = useStyles();

  if (!surveyDetails || surveyDetails.length === 0) {
    return <div>No survey details available</div>;
  }

  // Extract keys from the first object to use as headers
  const headers = Object.keys(surveyDetails[0]).filter(
    (key) => key !== "_id" && key !== "SurveyID" && key !== "SyncTaskId"
  );

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header} className={classes.stickyHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyDetails.map((detail, index) => (
            <TableRow key={index}>
              {headers.map((header) => (
                <TableCell key={header}>{detail[header]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectedSurveyTable;
