import {
  assignMaturitySurvey,
  getStakeholders,
} from "actions/phase-3/esgMaturity/esgMaturityAction";
import { assignMaterialitySurvey } from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSurveyDrawerAPIs = (
  open,
  toggleDrawer,
  fetchAssignedStakeholders,
  keyword,
  source
) => {
  const dispatch = useDispatch();
  const { parent_id, entity_id, unit_id } = useSelector((state) => state.user);
  const { stakeholders_list, survey_id } = useSelector(
    (state) => state.esgMaturity
  );

  const { materialityAssessmentDetails } = useSelector(
    (state) => state?.materialityAssessment
  );

  const [selectedStakeholders, setSelectedStakeholders] = useState([]);

  const handleHeadCheckboxClick = () => {
    if (selectedStakeholders.length === stakeholders_list.length) {
      // Deselect all if everything is already selected
      setSelectedStakeholders([]);
    } else {
      // Select all stakeholders
      const allStakeholderIds = stakeholders_list.map(
        (stakeholder) => stakeholder._id
      );
      setSelectedStakeholders(allStakeholderIds);
    }
  };

  const handleCheckboxClick = (stakeholderId) => {
    setSelectedStakeholders((prevSelected) => {
      if (prevSelected.includes(stakeholderId)) {
        // Deselect the stakeholder if it's already selected
        return prevSelected.filter((id) => id !== stakeholderId);
      } else {
        // Select the stakeholder
        return [...prevSelected, stakeholderId];
      }
    });
  };

  const handleSubmit = () => {
    if (source === "materiality_mapping") {
      dispatch(
        assignMaterialitySurvey(
          entity_id,
          materialityAssessmentDetails?._id,
          selectedStakeholders,

          toggleDrawer
        )
      );
    } else if (source === "source2") {
      // dispatch(
      //   assignSource2Survey(
      //     selectedStakeholders,
      //     survey_id?.survey_id,
      //     parent_id,
      //     entity_id,
      //     fetchAssignedStakeholders,
      //     toggleDrawer
      //   )
      // );
    } else {
      dispatch(
        assignMaturitySurvey(
          selectedStakeholders, // stakeholder_ids
          survey_id?.survey_id, // survey_id
          parent_id, // parent_id
          entity_id, // entity_id
          fetchAssignedStakeholders,
          toggleDrawer
        )
      );
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(getStakeholders(parent_id, entity_id, unit_id, keyword));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, keyword, survey_id?.survey_id]);

  return {
    // states & data
    stakeholders_list,
    selectedStakeholders,
    // funcs
    handleSubmit,
    handleCheckboxClick,
    handleHeadCheckboxClick,
  };
};
