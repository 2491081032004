import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  IconButton,
  Button,
  adaptV4Theme,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import DownloadWhiteSVG from "../../../images/downloadWhite.svg";
import {
  getDashboardSurvey,
  setSelectedDashboardSurvey,
} from "../../../actions/datahubActions/datahubActions";
import NoData from "../DatahubNoData/NoData";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#3374b9",
      },
      secondary: {
        main: "#47BB76",
      },
    },
  })
);

const columns = [
  { id: "name", label: "Survey Name" },
  { id: "lastUpdated", label: "Last Updated" },
  { id: "action", label: "Explore" },
];

function DataHubSheets() {
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { token, selectedOrganization } = useSelector((state) => state.login);
  const { dashboardSurvey } = useSelector((state) => state.dataHub);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (row) => {
    dispatch(setSelectedDashboardSurvey(row));
    navigate("/data_hub/survey/selected_survey");
  };

  useEffect(() => {
    dispatch(getDashboardSurvey(token, selectedOrganization?._id));
  }, []);

  if (dashboardSurvey?.length === 0) {
    return <NoData message={"No survey data found"} />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box padding={"20px"}>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "#EBF1F8" }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardSurvey
                  ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        onClick={() => handleRowClick(row)}
                        style={{ cursor: "pointer" }}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox color="primary" />
                        </TableCell> */}
                        <TableCell>{row.survey_name}</TableCell>
                        <TableCell>
                          {moment(row.last_modified_on).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            size={"small"}
                            // onClick={(e) => e.stopPropagation()}
                          >
                            <ChevronRightRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="flex-end" mt={5} mr={2}>
            <Pagination
              count={Math.ceil(dashboardSurvey.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default React.memo(DataHubSheets);
