import { createEsgStrategyPlanningAssessment, setEsgStrategyPlanningAssessmentDetails, setOriginalEsgStrategyPlanningDetails, updateEsgStrategyPlanningAssessment } from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import {
  createMaterialityAssessment,
  getMaterialityTopicYearBase,
  setMaterialityAssessmentDetails,
  setOriginalMaterialityDetails,
  updateMaterialityAssessment,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEsgStrategyPlanningBasicDetail = () => {
  const { esgStrategyPlanningAssessmentDetail, originalEsgStrategyPlanningAssessmentDetail } = useSelector(
    (state) => state?.esgStrategyPlanning
  );
  const { materialityAssessmentYearList } =
    useSelector((state) => state?.materialityAssessment);
  const dispatch = useDispatch();

  function areAllFieldsNotEmpty(obj) {
    // Check if the object has no properties
    if (Object.keys(obj).length === 0) {
      return false;
    }

    // Check if all properties are not null, undefined, or empty string
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  }

  let disableButton = areAllFieldsNotEmpty(esgStrategyPlanningAssessmentDetail);

  const hasChanges = (original, updated) => {
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  const handleBasicDetailsAPi = async () => {
    let response;

    if (esgStrategyPlanningAssessmentDetail._id) {
      // Check if there are changes before calling update API
      if (
        hasChanges(
          originalEsgStrategyPlanningAssessmentDetail,
          esgStrategyPlanningAssessmentDetail
        )
      ) {
        dispatch(
          setEsgStrategyPlanningAssessmentDetails(esgStrategyPlanningAssessmentDetail)
        );
        dispatch(
          setOriginalEsgStrategyPlanningDetails(esgStrategyPlanningAssessmentDetail)
        );

        response = await dispatch(
          updateEsgStrategyPlanningAssessment(esgStrategyPlanningAssessmentDetail)
        );
      } else {
        response = { status: true };
      }
    } else {
      // Call create API if _id is not present
      response = await dispatch(
        createEsgStrategyPlanningAssessment(esgStrategyPlanningAssessmentDetail)
      );
    }

    return response;
  };

  const getMaterialityTopicWithYear = (year_of_assessment) => {
    dispatch(
      getMaterialityTopicYearBase(
        "66c5c99aafeb50a530d72b28",
        year_of_assessment
      )
    );
  };

  useEffect(() => {
    esgStrategyPlanningAssessmentDetail?.year_of_assessment &&
      getMaterialityTopicWithYear(
        esgStrategyPlanningAssessmentDetail?.year_of_assessment
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esgStrategyPlanningAssessmentDetail?.year_of_assessment]);

  // handleDisableCondition && handleBasicDetails(value);

  const handleEsgStrategyPlanningStep = (step) => {
    return step === 0 && handleBasicDetailsAPi();
  };


  return {
    handleEsgStrategyPlanningStep,
    disableButton,
    esgStrategyPlanningAssessmentDetail,
    getMaterialityTopicWithYear,
    materialityAssessmentYearList
  };
};
