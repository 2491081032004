import { useState } from "react";

export default function useTabChanges(tabs) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSubTab, setSelectedSubTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedSubTab(0);
  };

  const handleSubTabChange = (newValue) => {
    setSelectedSubTab(newValue);
  };

  const handleTabChanges = () => {
    let tabNewValue = selectedTab;
    setSelectedSubTab((preValue) => {
      const hasLastTab =
        tabs[selectedTab].leftComponent.length === preValue + 1;

      if (hasLastTab) tabNewValue += 1;

      return hasLastTab ? 0 : preValue + 1;
    });

    setSelectedTab(tabNewValue);
  };

  const handlePreviousTabChanges = () => {
    let tabNewValue = selectedTab;
    setSelectedSubTab((preValue) => {
      if (preValue === 0) {
        tabNewValue -= 1;
        return tabNewValue >= 0
          ? tabs[tabNewValue].leftComponent.length - 1
          : 0;
      }
      return preValue - 1;
    });

    if (tabNewValue >= 0) {
      setSelectedTab(tabNewValue);
    }
  };

  return {
    selectedTab,
    selectedSubTab,
    handleChange,
    handleSubTabChange,
    handlePreviousTabChanges,
    handleTabChanges,
  };
}
