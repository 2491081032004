import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import MainLayoutStepper from "components/layout/stepperLayout/mainLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import EsgMaturityDisclosure from "components/widgets/phase-3/esgMaturity/esgMaturityDisclosure/EsgMaturityDisclosure";
import MaturityQuestionMain from "components/widgets/phase-3/esgMaturity/maturityQuestion/MaturityQuestionMain";
import ShareSurveyMain from "components/widgets/phase-3/esgMaturity/shareSurvey/ShareSurveyMain";

const dummyQuestions = [
  {
    question:
      "How often does your company perform audits to ensure compliance with environmental regulations?",
    weightage: "4 weightage",
    option: [
      { option: "Never", value: "1" },
      { option: "Rarely", value: "2" },
      { option: "Occasionally", value: "3" },
      { option: "Regularly", value: "4" },
    ],
  },
  {
    question:
      "What is your company’s approach to reducing greenhouse gas emissions?",
    weightage: "5 weightage",
    option: [
      { option: "No approach in place", value: "1" },
      { option: "Considering plans", value: "2" },
      { option: "Initiated reduction measures", value: "3" },
      { option: "Implemented comprehensive reduction strategy", value: "4" },
    ],
  },
  {
    question: "To what extent does your company use renewable energy sources?",
    weightage: "4 weightage",
    option: [
      { option: "Not at all", value: "1" },
      { option: "Less than 25%", value: "2" },
      { option: "25% to 50%", value: "3" },
      { option: "More than 50%", value: "4" },
    ],
  },
  {
    question: "How does your company manage waste generated from operations?",
    weightage: "3 weightage",
    option: [
      { option: "No management plan", value: "1" },
      { option: "Basic waste segregation", value: "2" },
      { option: "Advanced recycling program", value: "3" },
      { option: "Zero waste initiative", value: "4" },
    ],
  },
  {
    question:
      "What measures has your company implemented to promote employee health and safety?",
    weightage: "5 weightage",
    option: [
      { option: "No measures in place", value: "1" },
      { option: "Basic health and safety guidelines", value: "2" },
      { option: "Regular health and safety training", value: "3" },
      { option: "Comprehensive health and safety program", value: "4" },
    ],
  },
  {
    question:
      "How does your company ensure fair labor practices within its supply chain?",
    weightage: "4 weightage",
    option: [
      { option: "No measures in place", value: "1" },
      { option: "Code of conduct for suppliers", value: "2" },
      { option: "Regular supplier audits", value: "3" },
      { option: "Partnerships to improve labor practices", value: "4" },
    ],
  },
  {
    question: "To what extent does your company engage with local communities?",
    weightage: "3 weightage",
    option: [
      { option: "No engagement", value: "1" },
      { option: "Occasional community initiatives", value: "2" },
      { option: "Regular community programs", value: "3" },
      { option: "Strategic community partnerships", value: "4" },
    ],
  },
  {
    question:
      "How does your company address diversity and inclusion in the workplace?",
    weightage: "5 weightage",
    option: [
      { option: "No policy in place", value: "1" },
      { option: "Basic diversity policy", value: "2" },
      { option: "Active diversity and inclusion programs", value: "3" },
      { option: "Comprehensive diversity and inclusion strategy", value: "4" },
    ],
  },
  {
    question:
      "What is the level of transparency in your company’s environmental and social reporting?",
    weightage: "4 weightage",
    option: [
      { option: "No reporting", value: "1" },
      { option: "Limited reporting", value: "2" },
      { option: "Detailed but infrequent reporting", value: "3" },
      { option: "Regular and comprehensive reporting", value: "4" },
    ],
  },
  {
    question:
      "How does your company promote sustainable sourcing of raw materials?",
    weightage: "3 weightage",
    option: [
      { option: "No policy in place", value: "1" },
      { option: "Basic sustainable sourcing guidelines", value: "2" },
      { option: "Active sustainable sourcing program", value: "3" },
      { option: "Comprehensive sustainable sourcing strategy", value: "4" },
    ],
  },
  {
    question:
      "To what extent has your company integrated circular economy principles into its operations?",
    weightage: "4 weightage",
    option: [
      { option: "Not at all", value: "1" },
      { option: "Planning to integrate", value: "2" },
      { option: "Partially integrated", value: "3" },
      { option: "Fully integrated", value: "4" },
    ],
  },
  {
    question:
      "What is your company’s approach to water management and conservation?",
    weightage: "3 weightage",
    option: [
      { option: "No approach in place", value: "1" },
      { option: "Basic water conservation measures", value: "2" },
      { option: "Advanced water management practices", value: "3" },
      { option: "Comprehensive water conservation strategy", value: "4" },
    ],
  },
  {
    question:
      "How does your company support innovation in environmental and social practices?",
    weightage: "5 weightage",
    option: [
      { option: "No support for innovation", value: "1" },
      { option: "Occasional support for innovative projects", value: "2" },
      { option: "Regular support for innovation", value: "3" },
      { option: "Dedicated innovation programs", value: "4" },
    ],
  },
  {
    question:
      "To what extent does your company consider environmental and social criteria in its investment decisions?",
    weightage: "4 weightage",
    option: [
      { option: "Not at all", value: "1" },
      { option: "Occasionally", value: "2" },
      { option: "Frequently", value: "3" },
      { option: "Always", value: "4" },
    ],
  },
  {
    question: "How does your company ensure responsible marketing practices?",
    weightage: "3 weightage",
    option: [
      { option: "No measures in place", value: "1" },
      { option: "Basic responsible marketing guidelines", value: "2" },
      { option: "Regular review of marketing practices", value: "3" },
      { option: "Comprehensive responsible marketing strategy", value: "4" },
    ],
  },
  {
    question:
      "What steps has your company taken to reduce its carbon footprint?",
    weightage: "5 weightage",
    option: [
      { option: "No steps taken", value: "1" },
      { option: "Basic carbon reduction measures", value: "2" },
      { option: "Significant carbon reduction initiatives", value: "3" },
      { option: "Comprehensive carbon management strategy", value: "4" },
    ],
  },
  {
    question:
      "To what extent does your company engage in sustainable product design?",
    weightage: "4 weightage",
    option: [
      { option: "Not at all", value: "1" },
      { option: "Occasionally", value: "2" },
      { option: "Frequently", value: "3" },
      { option: "Always", value: "4" },
    ],
  },
  {
    question:
      "How does your company manage the social impact of its operations?",
    weightage: "3 weightage",
    option: [
      { option: "No management plan", value: "1" },
      { option: "Basic social impact assessment", value: "2" },
      { option: "Regular social impact assessments", value: "3" },
      { option: "Comprehensive social impact management strategy", value: "4" },
    ],
  },
  {
    question:
      "What is your company’s approach to employee engagement on sustainability issues?",
    weightage: "5 weightage",
    option: [
      { option: "No approach in place", value: "1" },
      { option: "Occasional engagement activities", value: "2" },
      { option: "Regular engagement programs", value: "3" },
      { option: "Comprehensive employee engagement strategy", value: "4" },
    ],
  },
  {
    question:
      "To what extent does your company consider biodiversity in its operations?",
    weightage: "4 weightage",
    option: [
      { option: "Not at all", value: "1" },
      { option: "Occasionally", value: "2" },
      { option: "Frequently", value: "3" },
      { option: "Always", value: "4" },
    ],
  },
];

export default function EsgMaturity() {
  const navigate = useNavigate();

  const stepContentMapping = useMemo(
    () => ({
      0: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <MaturityQuestionMain
                heading="ESG Maturity survey preview"
                source="maturity"
                questions={dummyQuestions}
              />
            </LeftSectionLayout>
          }
          right={
            <RightSectionLayout>
              <ShareSurveyMain />
            </RightSectionLayout>
          }
        />
      ),
      1: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <EsgMaturityDisclosure />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
    }),
    []
  );

  return (
    <MainLayoutStepper
      steps={["Survey Assign", "Analysis"]}
      mainCrumb={"ESG Maturity"}
      stepContentMapping={stepContentMapping}
      handleFinalStep={() => navigate("/materiality_mapping")}
      // handleStepChangeApi={handleMaterialityStep}
      handleDisableButton={true}
      skipProceedAPI={true}
    />
  );
}
