import {
  getMaterialityAssessmentTaskList,
  setMaterialitySelectedTask,
} from "actions/phase-3/task/materiality/materialityTaskAction.js/materialityTaskAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useMaterialityAPIs = (keyword = "", limit = 5, page = 1) => {
  const { entity_id } = useSelector((state) => state.user);
  const { materialityAssessmentTaskList } = useSelector(
    (state) => state.materialityTask
  );

  const dispatch = useDispatch();

  const fetchAssets = () => {
    dispatch(getMaterialityAssessmentTaskList(entity_id));
  };

  const handleSelectTask = async (task) => {
    await dispatch(setMaterialitySelectedTask(task));
  };

  useEffect(() => {
    fetchAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  return {
    // // states & data
    materialityAssessmentTaskList,
    // // funcs
    handleSelectTask,
  };
};
