// import { StarIcon } from "./CustomIcons";
import { TiStarFullOutline } from "react-icons/ti";
import { TiStarOutline } from "react-icons/ti";

// ----------------------------------------------------------------------

const ICON_SMALL = { width: 20, height: 20 };
const ICON_LARGE = { width: 28, height: 28 };

export default function Rating(theme) {
  return {
    MuiRating: {
      defaultProps: {
        emptyIcon: <TiStarOutline />,
        icon: <TiStarFullOutline />,
      },

      styleOverrides: {
        root: {
          "& .MuiRating-iconFilled": {
            color: theme.palette.primary.main,
          },
          "& .MuiRating-iconHover": {
            color: theme.palette.primary.main,
          },
          "&.Mui-disabled": {
            opacity: 0.48,
          },
        },
        iconEmpty: { color: theme.palette.primary.light },
        sizeSmall: { "& svg": { ...ICON_SMALL } },
        sizeLarge: { "& svg": { ...ICON_LARGE } },
      },
    },
  };
}
