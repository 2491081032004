import {
  SET_FRAMEWORK_NAME,
  SET_IMAGE,
  RESET_FRAMEWORK,
  SET_FILTERED_FRAMEWORKS,
} from "../../constants/frameworks/frameworkConstants";
import UNIVERSAL from "../../config/config";
import encrypt from "../shared/sharedActions";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";

export function setFrameworkName(payload) {
  return {
    type: SET_FRAMEWORK_NAME,
    payload: payload,
  };
}

export function setImage(payload) {
  return {
    type: SET_IMAGE,
    payload: payload,
  };
}
export function resetFramework() {
  return {
    type: RESET_FRAMEWORK,
  };
}

export function addFramework(token) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "/add_framework", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "user-token": token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewFrameworks(token) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/view_frameworks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "user-token": token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const setFilteredFrameworks = (payload) => ({
  type: SET_FILTERED_FRAMEWORKS,
  payload,
});

export const viewFilteredFrameworks = (token, country_id, organisation_id) => {
  const data = encrypt({
    "user-token": token,
    country_id,
    organisation_id,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/framework/view_filtered_frameworks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setFilteredFrameworks(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((err) => console.log(err));
  };
};
