import { TableCell, TableRow } from "@mui/material";
import DynamicTable, { textStyle } from "./DynamicTable";

// Helper function to access nested properties with fallback options
const getNestedValue = (obj, path) => {
  const options = path.split("||").map((opt) => opt.trim());
  for (let option of options) {
    const value = option
      .split(".")
      .reduce((acc, part) => acc && acc[part], obj);
    if (value) return value; // Return the first non-null, non-undefined value
  }
  return null; // Return null if none of the options have a value
};

const DynamicTableFull = ({
  tableHead,
  tableData,
  tableRowOrder,
  rowClicked,
  renderAction,
}) => {
  const hasRowClick = Boolean(rowClicked);

  const handleRowClick = (row) => {
    if (hasRowClick) {
      rowClicked(row);
    }
  };

  return (
    <DynamicTable headings={tableHead}>
      {tableData?.map((row, index) => (
        <TableRow
          key={row?._id || index}
          onClick={() => handleRowClick(row)}
          sx={{
            borderBottom: "1px solid #EBF1F8",
            cursor: hasRowClick ? "pointer" : "auto",
            "&:hover": {
              bgcolor: "primary.lighter",
            },
          }}
        >
          {tableRowOrder.map((column) => (
            <TableCell key={column} sx={textStyle("main")}>
              {getNestedValue(row, column)}
            </TableCell>
          ))}
          <TableCell>{renderAction && renderAction(row)}</TableCell>
        </TableRow>
      ))}
    </DynamicTable>
  );
};

export default DynamicTableFull;
