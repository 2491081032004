import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

/*
options example =>
[["Value", Weightage]]
[
  ["Very Poorly", 1],
  ["Poorly", 2],
  ["Somewhat Poorly", 3],
  ["Somewhat Well", 4],
  ["Very Well", 5]
]
*/

export default function RadioButtonsGroup2({
  option = [],
  label = "",
  optChange,
  value, // Add value prop to handle selected option
}) {
  const handleOptChange = (event) => {
    const selectedOption = option.find((o) => o[0] === event.target.value);
    if (Boolean(optChange) && selectedOption) {
      optChange(selectedOption[1]); // Send the value (weight) to the handler
    }
  };

  return (
    <FormControl>
      {label && (
        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      )}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={handleOptChange} // Attach the change handler here
        value={option.find((o) => o[1] === value)?.[0] || ""} // Set the selected value here
      >
        {option.map((o, index) => (
          <FormControlLabel
            key={index}
            value={o[0]}
            control={<Radio />}
            label={o[0]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
