import React from "react";
import HightChartsReact from "highcharts-react-official";
import HighCharts from "highcharts/highcharts";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import graph from "../../../../../images/Zones.png";
import Heading from "components/UI/phase-3/heading/Heading";

import {
  Box,
  Button,
  Chip,
  Typography,
  TableCell,
  TableRow,
} from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { RelavanceBar } from "components/widgets/materialityAssessmentLeft/materialityAssessmentLeft";

const dummyData = [
  {
    holding_position: "2",
    topic_name: "Environmental Sustainability",
    vote_data: { average: 4 },
  },
  {
    holding_position: "3",
    topic_name: "Social Responsibility",
    vote_data: { average: 3 },
  },
  {
    holding_position: "1",
    topic_name: "Corporate Governance",
    vote_data: { average: 5 },
  },
  {
    holding_position: "4",
    topic_name: "Economic Performance",
    vote_data: { average: 2 },
  },
  {
    holding_position: "3",
    topic_name: "Innovation and Technology",
    vote_data: { average: 3 },
  },
  {
    holding_position: "2",
    topic_name: "Environmental Sustainability",
    vote_data: { average: 4 },
  },
  {
    holding_position: "3",
    topic_name: "Social Responsibility",
    vote_data: { average: 3 },
  },
  {
    holding_position: "1",
    topic_name: "Corporate Governance",
    vote_data: { average: 5 },
  },
  {
    holding_position: "4",
    topic_name: "Economic Performance",
    vote_data: { average: 2 },
  },
  {
    holding_position: "3",
    topic_name: "Innovation and Technology",
    vote_data: { average: 3 },
  },
];
const scatterData = [
  { x: 4, y: 4, topic: "Environmental Sustainability" },
  { x: 3, y: 3, topic: "Social Responsibility" },
  { x: 5, y: 5, topic: "Corporate Governance" },
  { x: 2, y: 2, topic: "Economic Performance" },
  { x: 3, y: 3, topic: "Innovation and Technology" },
];

const options = {
  chart: {
    type: "scatter",
    zoomType: "xy",

    plotBackgroundImage: graph,
    style: {
      fontFamily: "Poppins",

      backgroundColor: "#ddd",
    },
    width: null, // Increase the width of the chart
    height: "60%",
  },
  title: {
    // text: "Importance to company Vs Topic's impact on  Environment/ Social/ Governance",
    text: "",
  },
  credits: {
    enabled: false,
  },

  xAxis: {
    title: {
      text: "Importance to company",
    },
    min: 0.5,
    max: 5,
  },
  yAxis: {
    title: {
      text: "Topic's impact on  Environment/ Social/ Governance",
    },
    min: 0.5,
    max: 5,
    gridLineWidth: 0,
  },
  plotOptions: {
    scatter: {
      showInLegend: false,
      opacity: 0.8,
      jitter: {
        x: 0.04,
        y: 0.05,
      },
      marker: {
        radius: 8,
        symbol: "circle",
        fillColor: "#3374b9",
      },
      tooltip: {
        pointFormat: "{point.x}, {point.y}, {point.topic}",
        headerFormat: "",
      },
    },
  },
  series: [
    {
      data: scatterData,
    },
  ],
};

const tableData = [
  {
    slNo: 1,
    materialTopicName: "Climate Change",
    categoryOfESG: "Environment",
    riskOpportunity: "Risk",
    relevance: 5,
    riskOpportunityOverview:
      "Potential regulatory changes, increased operational costs, and reputational damage.",
    approach:
      "Implementing carbon reduction strategies, enhancing energy efficiency, and adopting renewable energy sources.",
    mitigationActivities:
      "1. Installing solar panels\n2. Conducting energy audits\n3. Transitioning to electric vehicles",
  },
  {
    slNo: 2,
    materialTopicName: "Employee Health & Safety",
    categoryOfESG: "Social",
    riskOpportunity: "Opportunity",
    relevance: 4,
    riskOpportunityOverview:
      "Improving workplace safety, enhancing employee well-being, and reducing accident rates.",
    approach:
      "Regular safety training, updating safety protocols, and enhancing workplace ergonomics.",
    mitigationActivities:
      "1. Conducting regular safety drills\n2. Providing ergonomic equipment\n3. Offering health and wellness programs",
  },
  {
    slNo: 3,
    materialTopicName: "Data Privacy",
    categoryOfESG: "Governance",
    riskOpportunity: "Risk",
    relevance: 4,
    riskOpportunityOverview:
      "Data breaches, loss of customer trust, and legal repercussions.",
    approach:
      "Strengthening cybersecurity measures, implementing data protection policies, and ensuring regulatory compliance.",
    mitigationActivities:
      "1. Installing advanced firewalls\n2. Conducting employee cybersecurity training\n3. Performing regular data audits",
  },
  {
    slNo: 4,
    materialTopicName: "Diversity & Inclusion",
    categoryOfESG: "Social",
    riskOpportunity: "Opportunity",
    relevance: 5,
    riskOpportunityOverview:
      "Enhanced company culture, improved employee satisfaction, and better innovation.",
    approach:
      "Promoting inclusive policies, establishing diversity goals, and fostering an inclusive workplace environment.",
    mitigationActivities:
      "1. Conducting diversity training sessions\n2. Creating employee resource groups\n3. Regularly assessing diversity metrics",
  },
  {
    slNo: 5,
    materialTopicName: "Water Management",
    categoryOfESG: "Environment",
    riskOpportunity: "Risk",
    relevance: 3,
    riskOpportunityOverview:
      "Water scarcity, increased costs, and potential production disruptions.",
    approach:
      "Efficient water use strategies, implementing water-saving technologies, and recycling water.",
    mitigationActivities:
      "1. Installing low-flow fixtures\n2. Using water-efficient irrigation\n3. Recycling wastewater",
  },
  {
    slNo: 6,
    materialTopicName: "Human Rights",
    categoryOfESG: "Social",
    riskOpportunity: "Risk",
    relevance: 4,
    riskOpportunityOverview:
      "Supply chain issues, negative public perception, and potential legal challenges.",
    approach:
      "Adopting human rights policies, ensuring supplier compliance, and conducting regular audits.",
    mitigationActivities:
      "1. Implementing a supplier code of conduct\n2. Conducting on-site supplier audits\n3. Engaging with stakeholders on human rights issues",
  },
  {
    slNo: 7,
    materialTopicName: "Energy Efficiency",
    categoryOfESG: "Environment",
    riskOpportunity: "Opportunity",
    relevance: 5,
    riskOpportunityOverview:
      "Cost savings, reduced environmental impact, and enhanced reputation.",
    approach:
      "Implementing energy-saving technologies, optimizing operations, and conducting energy audits.",
    mitigationActivities:
      "1. Upgrading to energy-efficient lighting\n2. Improving insulation\n3. Using energy management systems",
  },
  {
    slNo: 8,
    materialTopicName: "Corporate Governance",
    categoryOfESG: "Governance",
    riskOpportunity: "Risk",
    relevance: 4,
    riskOpportunityOverview:
      "Regulatory compliance, investor confidence, and organizational integrity.",
    approach:
      "Enhancing board oversight, implementing robust governance frameworks, and ensuring transparency.",
    mitigationActivities:
      "1. Conducting regular governance reviews\n2. Implementing whistleblower policies\n3. Ensuring transparent reporting",
  },
  {
    slNo: 9,
    materialTopicName: "Supply Chain Sustainability",
    categoryOfESG: "Environment",
    riskOpportunity: "Opportunity",
    relevance: 4,
    riskOpportunityOverview:
      "Sustainable sourcing, reduced environmental impact, and improved supplier relations.",
    approach:
      "Engaging with suppliers, setting sustainability standards, and promoting responsible sourcing.",
    mitigationActivities:
      "1. Conducting supplier sustainability assessments\n2. Implementing green procurement policies\n3. Collaborating with suppliers on sustainability initiatives",
  },
  {
    slNo: 10,
    materialTopicName: "Community Engagement",
    categoryOfESG: "Social",
    riskOpportunity: "Opportunity",
    relevance: 3,
    riskOpportunityOverview:
      "Building community relations, enhancing corporate reputation, and fostering local support.",
    approach:
      "Supporting local initiatives, engaging with community stakeholders, and promoting volunteerism.",
    mitigationActivities:
      "1. Participating in community projects\n2. Providing grants to local organizations\n3. Encouraging employee volunteerism",
  },
];

function MaterialityAnalysisMain() {
  return (
    <Box sx={{ height: "100%" }}>
      <Heading size="16px" text="Materiality Assessment">
        <Button
          variant="contained"
          size="medium"
          startIcon={<ShareOutlinedIcon />}
        >
          Export Report
        </Button>
      </Heading>
      <Typography variant="body2" color={"#676767"} marginTop={"5px"}>
        Build your sustainability foundation. Here, you'll view the map to your
        company's groups. This crucial step ensures data flows smoothly,
        enabling accurate measurement and informed decision-making.
      </Typography>
      <Box
        sx={{
          overflow: "scroll",
          height: "85%",
        }}
      >
        <Box
          sx={{
            height: "95%",
            display: "flex",
          }}
        >
          <Box sx={{ width: "50%", height: "100%", overflow: "scroll" }}>
            {dummyData.map((item) => (
              <RelavanceBar item={item} />
            ))}
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "50%",
              padding: "10px",
              position: "relative",
            }}
          >
            {/* <Box
            sx={{
              width: "100%",
              height: "90%",
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
            }}
          > */}
            <HightChartsReact highcharts={HighCharts} options={options} />
            {/* </Box> */}
          </Box>
        </Box>
        <Box
          sx={{
            height: "95%",
            display: "flex",
            marginTop: "35px",
          }}
        >
          <DynamicTable
            headings={[
              "Sl.No.",
              "Material Topic Name",
              "Category of ESG",
              "Risk/Opportunity",
              "Relavance",
              "Risk/opportunity overview",
              "Approach",
              "Mitigation/Activities",
            ]}
          >
            {/* name: "tom",
      email: "tom@example.com",
      role: "dev",
      id: 12,
      isCheck: false, */}
            {tableData?.map((rowData, rowIndex) => (
              <TableRow
                key={rowIndex}
                //   className={classes.tableRowHover}
                onClick={() => {
                  // dispatch(setSelectedCorporate(rowData));
                  // history.push(
                  //   `/supplier_task/${rowData.company_name.replace(/ /g, "_")}`
                  // );
                }}
                sx={{ borderBottom: "1px solid #EBF1F8" }}
              >
                <TableCell style={textStyle("main")}>{rowData.slNo}</TableCell>
                <TableCell style={textStyle("main")}>
                  <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                    {rowData.materialTopicName}
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>
                  {rowData.categoryOfESG}
                </TableCell>
                <TableCell style={textStyle()}>
                  <Chip
                    sx={(theme) => ({
                      backgroundColor:
                        rowData.riskOpportunity === "Risk"
                          ? theme.palette.error.lighter
                          : theme.palette.secondary.lighter,
                      padding: "6px 10px",
                      fontSize: "12px",
                      color:
                        rowData.riskOpportunity === "Risk"
                          ? theme.palette.error.dark
                          : theme.palette.secondary.dark,
                      borderRadius: "80px",
                    })}
                    label={rowData.riskOpportunity}
                  />
                </TableCell>
                <TableCell style={textStyle()}>
                  <Chip
                    sx={(theme) => ({
                      backgroundColor: theme.palette.primary.lighter,
                      padding: "6px 10px",
                      fontSize: "12px",
                      color: theme.palette.primary.dark,
                      borderRadius: "80px",
                    })}
                    label={
                      rowData.relevance > 3 ? "Very Important" : "Important"
                    }
                  />
                </TableCell>

                <TableCell style={textStyle()}>
                  {rowData.riskOpportunityOverview}
                </TableCell>
                <TableCell style={textStyle()}>{rowData.approach}</TableCell>
                <TableCell style={textStyle()}>
                  {rowData.mitigationActivities}
                </TableCell>
              </TableRow>
            ))}
          </DynamicTable>
        </Box>
      </Box>
    </Box>
  );
}

export default MaterialityAnalysisMain;
