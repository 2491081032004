import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useTheme } from "@mui/styles";
import { Stack } from "@mui/material";

function DefineAgainstLists({ list, handleUpdate, openDrawer, handleDelete }) {
  const theme = useTheme();
  return (
    <List>
      {list.map((item, index) => (
        <ListItem
          secondaryAction={
            <Stack direction={"row"} gap={3}>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  handleUpdate(item);
                  openDrawer();
                }}
              >
                <MdOutlineModeEditOutline color={theme.palette.primary.main} />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  handleDelete(item);
                }}
              >
                <RiDeleteBin5Line color={theme.palette.error.main} />
              </IconButton>
            </Stack>
          }
        >
          <ListItemAvatar>
            <Avatar
              sx={{
                boxShadow: "0px 0px 8px 0px rgba(51, 116, 185, 0.25)",
                backgroundColor: "inherit",
              }}
            >
              {index + 1}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item?.title} />
        </ListItem>
      ))}
    </List>
  );
}

export default DefineAgainstLists;
