import { Box, Tabs, Tab, Stack, Button } from "@mui/material";
import { FiUser } from "react-icons/fi";
import ActivityTable from "./ActivityTable";
import KpiTable from "./KpiTable";
import useTabChanges from "customHooks/useTabChanges";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import AssignDrawer from "./assignDrawer/AssignDrawer";

const tabs = [
  { label: "Activities", component: <ActivityTable /> },
  { label: "KPI's", component: <KpiTable /> },
];

function PlanMonitoring() {
  const { selectedTab, handleChange } = useTabChanges();
  const { openDrawer, toggleDrawer } = usePaginationSearch();

  return (
    <Box sx={{ height: "100%" }}>
      {/*  tabs */}
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="plan monitor tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      {/* buttons */}
      <Stack alignItems={"flex-end"}>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<FiUser />}
          onClick={toggleDrawer}
        >
          Assign User
        </Button>
      </Stack>
      {/* table */}
      <Box sx={{ overflow: "scroll", height: "85%" }} mt={2}>
        {tabs[selectedTab].component}
      </Box>

      {/* assign Drawer */}
      <AssignDrawer open={openDrawer} onClose={toggleDrawer} />
    </Box>
  );
}

export default PlanMonitoring;
