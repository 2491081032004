import React, { useState } from "react";

import { Box } from "@mui/material";
import ReportSkeleton from "components/widgets/skeletons/reportSkeleton";
import { LinearProgressWithLabel } from "components/widgets/sustainabilityReport/sustainabilityReportPDF";

function ReportPdf() {
  const [hideLoader, setHideLoader] = useState(true);
  return (
    <Box mt={2} sx={{ height: "100%" }}>
      {!hideLoader && (
        <Box sx={{ height: "10%" }}>
          <LinearProgressWithLabel value={24} />
        </Box>
      )}
      {!hideLoader ? (
        <Box sx={{ height: "90%" }}>
          <ReportSkeleton />
        </Box>
      ) : (
        <Box sx={{ height: "98%", borderRadius: "8px" }}>
          <iframe
            //   src={blob}
            src={
              "https://imgrows.s3.us-east-2.amazonaws.com/step_6_647876e92abd009f7f2bd1d1.pdf"
            }
            title="pdf-test"
            width="100%"
            height="100%"
            name={Date.now()}
            style={{ borderRadius: "8px" }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ReportPdf;
