import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import GeneralSettingMenu from "../../widgets/generalSettingMenu/generalSettingMenu";
import GeneralSettingForm from "../../widgets/generalSettingForm/generalSettingForm";
import ProfileDetailsForm from "../../widgets/generalSettingForm/profileDetailsForm";
import CompanyDetailsForm from "../../widgets/generalSettingForm/companyDetailsForm";
import UserSettings from "../../widgets/generalSettingForm/userSettings";
import PlansPage from "../../widgets/generalSettingForm/plansPage";
import "../../../styles/settingScroll.css";
import LeftDots from "../../../images/left-dots.svg";
import RightDots from "../../../images/right-dots.svg";
import "./generalSettings.css";
function GeneralSettings(props) {
  const { generalSettings, login, setSelectedMenu, getOrganizations } = props;
  useEffect(() => {
    //  getOrganizations(login.token, window.location.hostname);
    localStorage.getItem("status") !== "EXPIRED"
      ? setSelectedMenu("profile-details")
      : setSelectedMenu("plans");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // console.log(selectedMenu)
  return (
    <>
      <Grid container className="generalSettingsContainer">
        <Grid
          item
          container
          xs={3}
          justifyContent="center"
          alignItems="center"
          className="generalSettingsItem"
        >
          <img
            src={LeftDots}
            alt="leftDots"
            className="generalSettingsImage1"
          />
          <Grid item xs={8}>
            <GeneralSettingMenu
              generalSettings={generalSettings}
              setSelectedMenu={setSelectedMenu}
              login={login}
            />
          </Grid>
          <img
            src={RightDots}
            alt="rightDots"
            className="generalSettingsImage2"
          />
        </Grid>
        <Grid item xs={9}>
          <Card className="generalSettingsCard">
            <CardContent
              className="settingScroll"
              style={{
                height:
                  generalSettings.selectedMenu === "plans" ? "90vh" : "90vh",
                padding: 0,
              }}
            >
              {generalSettings.selectedMenu === "profile-details" && (
                <ProfileDetailsForm {...props} />
              )}
              {generalSettings.selectedMenu === "company-details" && (
                <CompanyDetailsForm {...props} />
              )}
              {generalSettings.selectedMenu === "plans" && (
                <PlansPage {...props} />
              )}
              {generalSettings.selectedMenu === "general-settings" && (
                <GeneralSettingForm {...props} />
              )}
              {generalSettings.selectedMenu === "user-settings" && (
                <UserSettings {...props} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
export default React.memo(GeneralSettings);
