import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import {
  AssetsTab,
  DataTab,
  DetailsTab,
  StakeholdersTab,
  UnitsTab,
} from "./TabComps";
import { useNodeDrawerHook } from "./APIs/NodeDrawerAPIs";

// Array of tab details
const tabs = [
  { label: "Details", component: <DetailsTab /> },
  { label: "Units", component: <UnitsTab /> },
  { label: "Stakeholders", component: <StakeholdersTab /> },
  { label: "Assets", component: <AssetsTab /> },
  { label: "Data", component: <DataTab /> },
];

const NodeDrawer = ({ open, onClose }) => {
  const { selectedTab, handleChange } = useNodeDrawerHook();

  return (
    <DynamicDrawer
      heading={"Aerofusion Dynamics Global Headquarters"}
      hasDrawerOpen={open}
      handleClose={onClose}
      sx={{ width: "80vw" }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="org details tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Box mt={2}>{tabs[selectedTab].component}</Box>
    </DynamicDrawer>
  );
};
export default NodeDrawer;
