import React from "react";
import { useRoutes } from "react-router-dom";
import LoginCon from "../../../containers/login/login-cont";
import BasicDetailsCont from "../../../containers/basicDetails/basicDetailsCont";
import SignupContainer from "../../../containers/signup/signupContainer";
import Drawer from "../../widgets/policies/drawer";
import OnboardingTour from "../../screen/onboardinTour/onboardinTour";
import SurveyUp from "../../UI/maturityMatrix/SurveyUp";
import OnboardContainer from "containers/phase-3/Onboard/OnboardContainer";
// import Controller_con from "../../../containers/router/controllerCont";

const SignupProcessRoutes = () => {
  const routes = [
    { path: "/onboard_details", element: <OnboardContainer /> },
    { path: "/auto_login", element: <LoginCon /> },
    { path: "/basicDetails", element: <BasicDetailsCont /> },
    // { path: "/", element: <Controller_con /> }, // Uncomment and update if needed
    { path: "/", element: <SignupContainer /> },
    { path: "/drawer", element: <Drawer /> },
    { path: "/privacy_policy", element: <Drawer /> },
    { path: "/faq", element: <Drawer /> },
    { path: "/tnc", element: <Drawer /> },
    { path: "/onboarding_tour", element: <OnboardingTour /> },
    { path: "/maturity_matrix", element: <SurveyUp /> },
    // { path: "*", element: <NotFound /> } // Optional: Handle unknown routes
  ];

  const element = useRoutes(routes);

  return element;
};

export default SignupProcessRoutes;
