import {
  Box,
  Tabs,
  Tab,
  Stack,
  Button,
  Paper,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Icon,
  IconButton,
} from "@mui/material";
import useTabChanges from "customHooks/useTabChanges";
import SelectAndOption from "components/UI/phase-3/selectAndOption/SelectAndOption";
import { AddCircleOutlineSharp } from "@mui/icons-material";
import { FiUserPlus, FiUserCheck, FiUserMinus, FiUserX } from "react-icons/fi";

// Array of tab details
const tabs = [
  {
    label: "Quarter 1",
  },
  {
    label: "Quarter 2",
  },
  {
    label: "Quarter 3",
  },
  { label: "Quarter 4" },
];

const detailCard = [
  {
    title: "Total Assigned",
    points: 24,
    icon: (
      <FiUserPlus
        size={30}
        style={{
          backgroundColor: "#EBF1F8",
          borderRadius: "30px",
          padding: "4px",
          color: "#3374B9",
        }}
      />
    ),
  },
  {
    title: "Total Submitted",
    points: 24,
    icon: (
      <FiUserCheck
        size={30}
        style={{
          backgroundColor: "#EBF1F8",
          borderRadius: "30px",
          padding: "4px",
          color: "#3374B9",
        }}
      />
    ),
  },
  {
    title: "Total Pending",
    points: 24,
    icon: (
      <FiUserMinus
        size={30}
        style={{
          backgroundColor: "#F9EEED",
          borderRadius: "30px",
          padding: "4px",
          color: "#C45446",
        }}
      />
    ),
  },
  {
    title: "Total Approved",
    points: 24,
    icon: (
      <FiUserCheck
        size={30}
        style={{
          backgroundColor: "#EBF6EF",
          borderRadius: "30px",
          padding: "4px",
          color: "#32A05F",
        }}
      />
    ),
  },
  {
    title: "Total Rejected",
    points: 24,
    icon: (
      <FiUserCheck
        size={30}
        style={{
          backgroundColor: "#BBBBBB",
          borderRadius: "30px",
          padding: "4px",
          color: "#242424",
        }}
      />
    ),
  },
];

export const DetailCard = ({ title, points, icon }) => (
  <Card variant="outlined" sx={{ flex: 1, height: "98%", padding: "3px" }}>
    {icon && <Box sx={{ position: "absolute", right: 10, top: 5 }}>{icon}</Box>}
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      gap={2}
      sx={{ height: "100%" }}
    >
      <Typography variant="h4" color={"primary"}>
        {title}
      </Typography>

      <Typography variant="h1" color={"primary"}>
        {points || "-"}
      </Typography>
    </Stack>
  </Card>
);

function ComplianceHeader() {
  const { selectedTab, handleChange } = useTabChanges();
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="supplier tabs"
          sx={{ marginBottom: "15px" }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
        <SelectAndOption
          heading={""}
          label="Select Year"
          options={["2022", "2023", "2024"]}
          fullWidth={true}
          width="10%"
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
        sx={{ height: "100px" }}
      >
        {detailCard.map((data) => (
          <DetailCard
            title={data?.title}
            points={data?.points}
            icon={data?.icon}
          />
        ))}
      </Stack>
    </>
  );
}

export default ComplianceHeader;
