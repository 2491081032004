import { Box } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import EsgScore from "./EsgScore";
import EsgBreakdown from "./EsgBreakdown";
import Stakeholders from "./Stakeholders";
import Suggestions from "./Suggestions";

function EsgMaturityDisclosure() {
  return (
    <Box>
      <Heading
        text={"ESG Maturity Disclosure"}
        size={"15px"}
        marginBottom={"8px"}
        bg
      />
      <Box className="scroll">
        {/* ESG Score */}
        <Heading
          text={"ESG Score"}
          marginTop={"8px"}
          size={"13px"}
          horizontalLine
        />
        <EsgScore />

        {/* ESG Breakdown */}
        <Heading
          text={"ESG Breakdown"}
          marginTop={"30px"}
          size={"13px"}
          horizontalLine
        />
        <EsgBreakdown />

        {/* Stakeholders */}
        <Heading
          text={"Stakeholders"}
          size={"13px"}
          marginTop={"30px"}
          horizontalLine
        />
        <Stakeholders />

        {/* Suggestions */}
        <Heading
          text={"Suggestions"}
          size={"13px"}
          marginTop={"30px"}
          horizontalLine
        />
        <Suggestions />
      </Box>
    </Box>
  );
}

export default EsgMaturityDisclosure;
