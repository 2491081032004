import { useState } from "react";
import { Box, Stack, TextField, Typography, useTheme } from "@mui/material";

import { RiBookLine } from "react-icons/ri";

const ProfileContactDetails = ({ contact_details }) => {
  const theme = useTheme();

  const [contactDetails, setContactDetails] = useState({
    apartment_name: contact_details?.apartment_name,
    street_name: contact_details?.street_name,
    city_state: contact_details?.city_state,
    pin_code: contact_details?.pin_code,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactDetails({
      ...contactDetails,
      [name]: value,
    });
  };

  return (
    <Box my={2}>
      {/* Heading */}
      <Stack
        spacing={1}
        direction={"row"}
        alignItems={"center"}
        borderBottom={"1px solid"}
        borderColor={"primary.main"}
        p={1}
      >
        <RiBookLine size={20} color={theme.palette.primary.main} />
        <Typography variant="body2">Contact Details</Typography>
      </Stack>

      {/* Apartment and Street */}
      <Box display={"flex"} gap={2} my={2}>
        <Box flex={1}>
          <Typography variant="subtitle2" color={"text.faded"} gutterBottom>
            Apartment Name/ Tower name/
          </Typography>
          <TextField
            name="apartment_name"
            value={contactDetails?.apartment_name}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Box>

        <Box flex={1}>
          <Typography variant="subtitle2" color={"text.faded"} gutterBottom>
            Street Name
          </Typography>
          <TextField
            name="apartment_name"
            value={contactDetails?.street_name}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Box>
      </Box>

      {/* City and Pincode */}
      <Box display={"flex"} gap={2} my={2}>
        <Box flex={1}>
          <Typography variant="subtitle2" color={"text.faded"} gutterBottom>
            City/State
          </Typography>
          <TextField
            name="city_state"
            value={contactDetails?.city_state}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Box>

        <Box flex={1}>
          <Typography variant="subtitle2" color={"text.faded"} gutterBottom>
            Pincode
          </Typography>
          <TextField
            name="pin_code"
            type="number"
            value={contactDetails?.pin_code}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ProfileContactDetails;
