import { useMemo } from "react";

import { Box, Button, Grid } from "@mui/material";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import NoListItem from "components/widgets/phase-3/MaterialityMapping/selectToppic/left/component/NoListItem";

import { usePaginationSearch } from "customHooks/usePaginationSearch";
import useFormValidation from "customHooks/useFormValidation";
import { useEsgStrategyPlanningTopic } from "../../APIs/HandleTopicApi";
import DefineAgainstLists from "./DefineAgainstLists";

function DatRisk() {
  const { openDrawer, toggleDrawer } = usePaginationSearch();

  const initialState = useMemo(
    () => ({
      title: "",
      risk_type: "",
      risk_overview: "",
    }),
    []
  );

  const validationRules = useMemo(
    () => ({
      title: { required: true },
      risk_type: { required: false },
      risk_overview: { required: true },
    }),
    []
  );

  const { formData, errors, handleChange, validationErrors, setFormData } =
    useFormValidation(initialState, validationRules);

  const {
    handleUpdateEsgActivity,
    handleAddEsgActivity,
    esgRisks,
    handleDeleteEsgActivity,
  } = useEsgStrategyPlanningTopic(false, null, "RISK");

  const handleSubmit = async () => {
    const response = formData?.risk_id
      ? await handleUpdateEsgActivity(formData)
      : await handleAddEsgActivity(formData);
    if (response?.status || false) {
      toggleDrawer();
      setFormData(initialState);
    }
  };
  return (
    <>
      {/* Drawer */}
      <DynamicDrawer
        hasDrawerOpen={openDrawer}
        handleClose={() => {
          toggleDrawer();
          setFormData(initialState);
        }}
        heading={formData?.risk_id ? "Update Risk" : "Add Risk"}
        buttonText={formData?.risk_id ? "Update Risk" : "Add Risk"}
        disableSubmit={Object.keys(validationErrors).length > 0}
        submitClick={handleSubmit}
      >
        <InputField
          value={formData?.title}
          error={errors.title}
          name={"title"}
          handleChange={handleChange}
          heading={"Name of the Risk"}
          label={"Enter here"}
          marginBottom={"6px"}
          marginTop={5}
        />

        <InputField
          value={formData?.risk_overview}
          error={errors.risk_overview}
          name={"risk_overview"}
          handleChange={handleChange}
          heading={"Overview of the Risk"}
          label={"Enter here"}
          marginBottom={"6px"}
          rows={6}
        />
      </DynamicDrawer>
      {/* Heading */}
      <Heading text="Risks" marginBottom="0">
        <Button
          variant={"outlined"}
          color={"secondary"}
          startIcon={<AddOutlinedIcon />}
          size={"small"}
          onClick={toggleDrawer}
        >
          Add Risk
        </Button>
      </Heading>

      {/* main content */}
      <>
        <DefineAgainstLists
          list={esgRisks}
          handleUpdate={setFormData}
          openDrawer={toggleDrawer}
          handleDelete={handleDeleteEsgActivity}
        />

        {/* no data compoenent */}

        {esgRisks?.length === 0 && <NoListItem message="No Policy Added" />}
      </>
    </>
  );
}

export default DatRisk;
