import { Typography } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
function CreateTabs({
  selectedTab,
  setSelectedTab,
  chartTitle,
  measures,
  dimensions,
  chartType,
  orderMembers,
  pivotConfig,
}) {
  const d = [
    { tab: 1, text: "Basic Details", done: chartTitle && selectedTab > 1 },
    {
      tab: 2,
      text: "Measure Value",
      done: measures.length > 0 && selectedTab > 2,
    },
    {
      tab: 3,
      text: "Dimention Value",
      done: dimensions.length > 0 && selectedTab > 3,
    },
    { tab: 4, text: "Type of charts", done: chartType && selectedTab > 4 },
    { tab: 5, text: "Colors", done: chartType && selectedTab > 5 },
    { tab: 6, text: "Chart Orders", done: chartType && selectedTab > 6 },
    { tab: 7, text: "Set Limit", done: selectedTab > 7 },
    { tab: 8, text: "Pivot Config", done: selectedTab > 8 },
    { tab: 9, text: "Labels", done: selectedTab === 9 },
  ];

  const disabled = (tab) => {
    // if (chartTitle && tab === 1) return true;
    if (!chartTitle && tab === 2) return true;
    else if (!measures.length > 0 && tab === 3) return true;
    else if (!dimensions.length > 0 || (!chartType && tab === 4)) return true;
    else if (
      !measures.length > 0 &&
      !dimensions.length > 0 &&
      (tab === 5 || tab === 6 || tab === 7 || tab === 8)
    )
      return true;
    return false;
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        marginTop: 8,
        paddingRight: 8,
      }}
    >
      {d.map((tab) => (
        <div
          role="button"
          onClick={() => !disabled(tab.tab) && setSelectedTab(tab.tab)}
          style={{
            display: "flex",
            width: "180px",
            padding: 8,
            borderRadius: 8,
            backgroundColor: selectedTab === tab.tab ? "#EBF1F8" : "inherit",
            cursor: "pointer",
          }}
        >
          {tab?.done ? (
            <Typography
              style={{
                fontSize: 12,
                borderRadius: "50%",
                backgroundColor: "#32A05F",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "18px",
                height: "18px",
              }}
            >
              <DoneIcon style={{ fontSize: 12 }} />
            </Typography>
          ) : (
            <Typography
              style={{
                fontSize: 12,
                borderRadius: "50%",
                backgroundColor:
                  selectedTab === tab.tab ? "#3374B9" : "#F6F6F6",
                color: selectedTab === tab.tab ? "white" : "#6C6C6C",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "18px",
                height: "18px",
              }}
            >
              {tab?.tab}
            </Typography>
          )}
          <Typography
            style={{
              fontSize: 13,
              paddingLeft: 8,
              fontWeight: 500,
              color: selectedTab === tab.tab ? "inherit" : "#6C6C6C",
            }}
          >
            {tab?.text}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default CreateTabs;
