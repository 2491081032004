import React from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
// import { Link } from "react-router-dom";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      secondary: {
        main: "#47BB76",
      },
    },
  })
);

function DataHubTabs(props) {
  const { value, tabs = [] } = props;

  const tab = tabs.map((t, i) => {
    return (
      <Tab
        key={i}
        style={{
          backgroundColor: value === t.value ? "#FFFFFF" : "transparent",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          borderRadius: "5px 5px 0px 0px",
          textTransform: "capitalize",
        }}
        label={<span>{t.tab}</span>}
        name={t.tab}
      />
    );
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppBar
          position="static"
          color="inherit"
          style={{
            boxShadow: "none",
            backgroundColor: "transparent",
          }}
        >
          <Tabs
            value={value}
            onChange={props.handleTabs}
            aria-label="simple tabs example"
          >
            {tab}
          </Tabs>
        </AppBar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default React.memo(DataHubTabs);
