import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  grid: { paddingTop: 20 },
  TableCell: { minWidth: 100 },
  CustomTextfield: { width: "100%" },
  grid1: { marginTop: 20 },
  input: { display: "none" },
  Box1: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box2: { borderBottom: "2px solid #3374B9" },
  pointer: { cursor: "pointer" },
});
const typeTwoData = [
  {
    row: "Row 1",
    columns: [
      { column: "Column A", data: "Data 1-A" },
      { column: "Column B", data: "Data 1-B" },
      { column: "Column C", data: "Data 1-C" },
    ],
  },
  {
    row: "Row 2",
    columns: [
      { column: "Column A", data: "Data 2-A" },
      { column: "Column B", data: "Data 2-B" },
      { column: "Column C", data: "Data 2-C" },
    ],
  },
  {
    row: "Row 3",
    columns: [
      { column: "Column A", data: "Data 3-A" },
      { column: "Column B", data: "Data 3-B" },
      { column: "Column C", data: "Data 3-C" },
    ],
  },
];

function DisclosureTypeTwo() {
  const classes = useStyles();

  return (
    <>
      {typeTwoData.length > 0 && (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                {typeTwoData.length > 0 &&
                  typeTwoData[0].columns &&
                  typeTwoData[0].columns.map((col, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      className={classes.TableCell}
                    >
                      {col.column}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {typeTwoData.length > 0 &&
                typeTwoData.map((row, rowIndex) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    <TableCell align="center">
                      <Typography>{row.row}</Typography>
                    </TableCell>
                    {row.columns &&
                      row.columns.map((column, colIndex) => (
                        <TableCell key={colIndex} align="center">
                          <TextField
                            name={`Text_type2${colIndex}`}
                            size="small"
                            variant="outlined"
                            className={classes.CustomTextfield}
                            value={typeTwoData[rowIndex].columns[colIndex].data}
                            disabled
                          />
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default DisclosureTypeTwo;
