import { HiOutlineCalendar } from "react-icons/hi";

// ----------------------------------------------------------------------

export default function DatePicker(theme) {
  return {
    MuiDatePicker: {
      defaultProps: {
        slots: {
          openPickerIcon: HiOutlineCalendar, // override default icon
        },
        slotProps: {
          layout: {
            sx: {
              "& .MuiDayCalendar-weekDayLabel": {
                color: theme.palette.action.active,
              },
            },
          },
        },
      },
      styleOverrides: {
        root: {
          // Add your other style overrides here
        },
      },
    },
  };
}
