import { createTheme, adaptV4Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      secondary: {
        main: "#47BB76",
      },
    },
  })
);

const reportDataLeftStyles = makeStyles({
  arrowVisible: {
    visibility: "visible",
  },
  arrowHidden: {
    visibility: "hidden",
  },

  cardContainer: {
    backgroundColor: "#FFFFFF",
    marginBottom: "15px",
    height: "100%",
    borderRadius: 5,
    marginLeft: "12px",
  },
  tab: {
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "none",
    fontFamily: "poppins",
  },
  search: {
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  search2: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  icon: {
    color: "transparent",
  },
  label: {
    color: "rgba(51, 116, 185, 1)",
  },
  mainTopicContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  mainTopicText: {
    fontWeight: "600",
    fontSize: 18,
    fontFamily: "poppins",
  },
  environmentBorder: {
    backgroundColor: "#DAF1E4",
    height: 4,
    width: "100%",
  },
  socialBorder: {
    backgroundColor: "#EAD6F1",
    height: 4,
    width: "100%",
  },
  governanceBorder: {
    backgroundColor: "#D6E3F1",
    height: 4,
    width: "100%",
  },
  businessBorder: {
    backgroundColor: "#F1D6D6",
    height: 4,
    width: "100%",
  },
  humanBorder: {
    backgroundColor: "#F6F3DA",
    height: 4,
    width: "100%",
  },
  generalDisclosureBorder: {
    backgroundColor: "#DAF1EE",
    height: 4,
    width: "100%",
  },
  topicContainer: {
    padding: 15,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D6E3F173",
    },
  },
  topicText: {
    fontWeight: "600",
    marginLeft: 10,
  },
  freezeDataCard: {
    fontSize: "16px",
    lineHeight: "21px",
    marginTop: "30px",
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "50px",
    borderRadius: "8px",
  },
  freezeListText: {
    paddingBottom: "10px",
    fontWeight: "500",
    fontSize: "16px",
    textTransform: "capitalize",
    fontFamily: "poppins",
  },
  freezeDataBtn: {
    display: "flex",
    justifyContent: "center",
  },
  metricCardHeader: {
    padding: 5,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  metricCardHeader1: {
    padding: 5,
    width: "100%",
    alignItems: "center",
  },
  metricCardHeaderSocial: {
    backgroundColor: "#EAD6F1",
  },
  metricCardHeaderBusiness: {
    backgroundColor: "#F1D6D6",
  },
  metricCardHeaderHuman: {
    backgroundColor: "#F6F3DA",
  },
  metricCardHeaderEnvironmental: {
    backgroundColor: "#DAF1E4",
  },
  metricCardHeaderGovernance: {
    backgroundColor: "#D6E3F1",
  },
  metricIcon: {
    visibility: "hidden",
    fontSize: "16px",
    color: "#3374B9",
    float: "right",
  },
  metricCardBar: {
    padding: 5,
    display: "flex",
    width: "100%",
    borderRadius: "5px",
    cursor: "pointer",
    minHeight: 52,
    margin: "8px 0px",
    "&:hover": {
      backgroundColor: "#EBF1F8",
      "& $metricIcon": {
        visibility: "visible",
      },
    },
  },
  selectedMetricCardBar: {
    display: "flex",
    padding: 5,
    width: "100%",
    cursor: "pointer",
    borderRadius: "5px",
    minHeight: 52,
    margin: "8px 0px",
    backgroundColor: "#EBF1F8",
    "& $metricIcon": {
      visibility: "visible",
    },
  },
  metricCardBarBorderSocial: {
    borderLeft: "5px solid #EAD6F1",
    borderTop: "1px solid #DADBE6",
    borderRight: "1px solid #DADBE6",
    borderBottom: "1px solid #DADBE6",
  },
  metricCardBarBorderBusiness: {
    borderLeft: "5px solid #F1D6D6",
    borderTop: "1px solid #DADBE6",
    borderRight: "1px solid #DADBE6",
    borderBottom: "1px solid #DADBE6",
  },
  metricCardBarBorderHuman: {
    borderLeft: "5px solid #F6F3DA",
    borderTop: "1px solid #DADBE6",
    borderRight: "1px solid #DADBE6",
    borderBottom: "1px solid #DADBE6",
  },
  metricCardBarBorderEnvironmental: {
    borderLeft: "5px solid #DAF1E4",
    borderTop: "1px solid #DADBE6",
    borderRight: "1px solid #DADBE6",
    borderBottom: "1px solid #DADBE6",
  },
  metricCardBarBorderGovernance: {
    borderLeft: "5px solid #D6E3F1",
    borderTop: "1px solid #DADBE6",
    borderRight: "1px solid #DADBE6",
    borderBottom: "1px solid #DADBE6",
  },
  metricCardBarBorderGeneralDisclosure: {
    borderLeft: "5px solid #DAF1EE",
    borderTop: "1px solid #DADBE6",
    borderRight: "1px solid #DADBE6",
    borderBottom: "1px solid #DADBE6",
  },

  Box: { padding: "10px 0px" },
  BoxBaground: { backgroundColor: "#EAD6F1" },
  BoxBaground1: { backgroundColor: "#F6F3DA" },
  BoxBaground2: { backgroundColor: "#DAF1E4" },
  BoxBaground3: { backgroundColor: "#F1D6D6" },
  BoxBaground4: { backgroundColor: "#D6E3F1" },
  BoxBaground5: { backgroundColor: "#DAF1EE" },
  fontSize: { fontSize: 42 },
  Typography: {
    paddingLeft: "8px",
    fontFamily: "poppins",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "600",
  },
  Typography1: {
    fontFamily: "poppins",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "400",
    paddingTop: "16px",
  },
  div: { display: "flex", alignItems: "center" },
  Card: { margin: 10 },
  CardLoader: { marginTop: 50, color: "#3374b9" },
  size: { fontSize: 46 },
  CardContent: { padding: 0, fontFamily: "poppins" },
  loader: {
    marginTop: 50,
    color: "#3374b9",
  },
  CardContentLoader: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "poppins",
  },
  Card1: {
    backgroundColor: "#F0F6FD",
    // cursor: 'pointer',
    height: 50,
    borderRadius: 10,
    margin: "0px 10px",
    fontFamily: "poppins",
  },
  CardContent2: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    padding: 20,
    position: "relative",
    fontFamily: "poppins",
  },
  Avatar: {
    marginRight: 20,
    width: 35,
    height: 35,
  },
  Typography2: { marginTop: 6, fontFamily: "poppins" },
  div1: { position: "absolute", right: 10 },
  Box1: { marginTop: 25 },
  downloadButton: {
    textTransform: "none",
    float: "right",
    marginRight: "10px",
    color: "#3374B9",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  shareButton: {
    textTransform: "none",
    float: "right",
    marginRight: "10px",
    color: "#3374B9",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "1px 1px 3px #c8c0c0a1",
    },
  },
  headerIcon: {
    paddingLeft: "5px",
  },
  mainGrid2: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    fontFamily: "poppins",
  },
  grid8: { width: "100%" },
  grid7: { marginTop: 20 },
  grid3: { display: "flex", justifyContent: "flex-end" },
});
export default reportDataLeftStyles;
