import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Stack } from "@mui/material";

export default function UserAvatar() {
  return (
    <Stack alignItems={"flex-start"}>
      <AvatarGroup total={24}>
        <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/300" />
        <Avatar alt="Travis Howard" src="https://i.pravatar.cc/301" />
        <Avatar alt="Agnes Walker" src="https://i.pravatar.cc/302" />
      </AvatarGroup>
    </Stack>
  );
}
