import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  Grid,
  InputAdornment,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { brmRightStyles } from "../companySetup/brmRightStyle";
import noUser from "images/NoUser.png";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import briefcase from "images/briefcase.png";

const dummyData = {
  members: [
    {
      name: "saad",
      email: "saad@appstone.in",
      role: "dev",
      id: 0,
      isCheck: false,
    },
    {
      name: "vaibhaw",
      email: "vaibhaw@appstone.in",
      role: "dev",
      id: 1,
      isCheck: false,
    },
    {
      name: "bhaibhav",
      email: "bhaibhav@appstone.in",
      role: "dev",
      id: 2,
      isCheck: false,
    },
    {
      name: "deepak",
      email: "deepak@appstone.in",
      role: "dev",
      id: 3,
      isCheck: false,
    },
    {
      name: "john",
      email: "john@example.com",
      role: "manager",
      id: 4,
      isCheck: false,
    },
    {
      name: "alice",
      email: "alice@example.com",
      role: "designer",
      id: 5,
      isCheck: false,
    },
    {
      name: "bob",
      email: "bob@example.com",
      role: "dev",
      id: 6,
      isCheck: false,
    },
    {
      name: "emma",
      email: "emma@example.com",
      role: "manager",
      id: 7,
      isCheck: false,
    },
    {
      name: "michael",
      email: "michael@example.com",
      role: "dev",
      id: 8,
      isCheck: false,
    },
    {
      name: "sara",
      email: "sara@example.com",
      role: "designer",
      id: 9,
      isCheck: false,
    },
    {
      name: "peter",
      email: "peter@example.com",
      role: "dev",
      id: 10,
      isCheck: false,
    },
    {
      name: "linda",
      email: "linda@example.com",
      role: "manager",
      id: 11,
      isCheck: false,
    },
    {
      name: "tom",
      email: "tom@example.com",
      role: "dev",
      id: 12,
      isCheck: false,
    },
    {
      name: "jane",
      email: "jane@example.com",
      role: "designer",
      id: 13,
      isCheck: false,
    },
    {
      name: "mark",
      email: "mark@example.com",
      role: "dev",
      id: 14,
      isCheck: false,
    },
  ],
};

export const UserCard = ({
  member,
  onClick,
  isChecked = false,
  hideCheckbox = false,
}) => {
  const { name, email, role, designation, surveySentStatus } = member;
  const [checked, setChecked] = useState(isChecked);

  const hadleClick = (value) => {
    if (!value) {
      setChecked(!checked);
      onClick();
    }
  };
  return (
    <Grid
      item
      container
      onClick={() =>
        surveySentStatus
          ? hadleClick(surveySentStatus)
          : hadleClick(surveySentStatus)
      }
      md={12}
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: 8,
        margin: "10px 0px",
        width: "100%",
        padding: 4,
        cursor: surveySentStatus ? "default " : "pointer",
        backgroundColor: checked ? "#EBF1F8" : "",
      }}
      role="button"
    >
      <Grid item container md={12} style={{ width: "100%" }}>
        <Grid item md={10} container alignItems="center">
          {!hideCheckbox && (
            <Checkbox
              color="secondary"
              checked={checked}
              disabled={surveySentStatus}
            />
          )}
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 600,
              padding: hideCheckbox ? "3px 10px" : "3px 0px",
            }}
          >
            {name}
          </Typography>
        </Grid>
        {/* <Grid md={2} container alignItems="center" justifyContent="center">
            <img src={id} alt="brief" style={{ color: "#505050", width: 22 }} />
            <Typography
              style={{ fontSize: 12, fontWeight: 400, padding: "3px 8px" }}
            >
              INF241
            </Typography>
          </Grid> */}
      </Grid>
      <Grid
        container
        item
        md={12}
        style={{
          width: "100%",
          padding: "3px 0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          md={10}
          container
          alignItems="center"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <MailOutlineIcon style={{ color: "#505050", marginLeft: "8px" }} />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {email}
          </Typography>
        </Grid>
        <Grid item md={2} container alignItems="center">
          <img
            src={briefcase}
            alt="brief"
            style={{ color: "#505050", width: 22 }}
          />
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 400,
              padding: "3px 0px",
              color: "#505050",
              marginLeft: "8px",
            }}
          >
            {role || designation}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
// import { viewSupplierStakeHolder } from "../../../actions/brm/BrmActions";
function StakeholderList({
  brmData = dummyData,
  setAddNewUserDialogOpen,
  handleEditUser,
  login,
}) {
  const classes = brmRightStyles();
  const [filtered, setFiltered] = useState([]);
  const text = useRef("");
  const handleSearch = (e) => {
    const searchText = e.target.value.trim().toLowerCase();
    text.current = searchText;
    if (brmData.members && brmData.members.length > 0 && searchText) {
      console.log(searchText);

      const filter = brmData.members.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchText) ||
          item.email.toLowerCase().includes(searchText)
        );
      });
      setFiltered(filter);
    } else if (brmData.members && brmData.members.length > 0 && !searchText) {
      setFiltered(brmData.members);
    } else {
      setFiltered([]);
    }
  };

  useEffect(() => {
    // dispatch(
    //   viewSupplierStakeHolder(
    //     login?.token,
    //     brmData?.supplierOrganizationDetails?._id,
    //     login?.current_organisation
    //   )
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{}} className={classes.mainDiv}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Stakeholders List
          </Typography>
        </div>
        <div>
          <Button
            // variant="contained"
            size="small"
            // startIcon={<Add />}
            color="primary"
            style={{ textTransform: "capitalize" }}
            onClick={() => setAddNewUserDialogOpen(true)}
          >
            Add Users
          </Button>
        </div>
      </div>
      <div>
        {/* <Grid
          container
          md={12}
          sm={12}
          xs={12}
          style={{
            marginTop: "10px",
            backgroundColor: "#EBF1F8",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          <Grid item md={5} xs={5}>
            <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
              Name / E-mail
            </Typography>
          </Grid>
          <Grid item md={3} xs={3}>
            <Typography style={{ fontSize: "13px" }}>Category</Typography>
          </Grid>
          <Grid item md={3} xs={3} align="center">
            <Typography style={{ fontSize: "13px" }}>Role</Typography>
          </Grid>
          <Grid item md={1} xs={1}>
            <Typography style={{ fontSize: "13px", alignSelf: "right" }}>
              Edit
            </Typography>
          </Grid>
        </Grid> */}
        <div className="scroll" style={{ height: "59vh" }}>
          {brmData?.members && brmData?.members.length > 0 && (
            <TextField
              name="Search"
              variant="outlined"
              fullWidth
              size="small"
              type="text"
              label="Search"
              onChange={handleSearch}
              style={{ margin: "8px 0px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: "#3374B9" }} />
                  </InputAdornment>
                ),
              }}
              // value={searchCompanyText}
            />
          )}

          {text.current ? (
            filtered.length > 0 ? (
              filtered.map((member) => (
                <UserCard
                  hideCheckbox
                  key={member.name}
                  member={member}
                  onClick={() => {}}
                />
              ))
            ) : (
              <Typography>No Users Found</Typography>
            )
          ) : (
            brmData?.members?.map((member) => (
              <UserCard
                hideCheckbox
                key={member?.name}
                member={member}
                onClick={() => {}}
              />
            ))
          )}
          {/* {text.current && filtered.length > 0 ? (
            filtered.map((member) => (
              <UserCard
                hideCheckbox
                key={member.name}
                member={member}
                onClick={() => {}}
              />
            ))
          ) : filtered.length === 0 ? (
            <Typography>No Users Found</Typography>
          ) : (
            brmData?.members?.map((member) => (
              <UserCard
                hideCheckbox
                key={member.name}
                member={member}
                onClick={() => {}}
              />
            ))
          )} */}
          {brmData?.members && brmData?.members.length === 0 && (
            <div className={classes.emptyCont}>
              <img className={classes.emptyImg} src={noUser} alt="analytics" />
              <Typography className={classes.text}>
                Currently no User has been uploaded to showcase
              </Typography>
              <Typography className={classes.subText}>
                Upload file type (.xls, .pdf, .doc) Max size of 5 Mb
              </Typography>
              <Grid container justifyContent="center">
                <Button
                  className={classes.uploadBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAddNewUserDialogOpen(true);
                  }}
                >
                  Add User
                </Button>
              </Grid>
            </div>
          )}
        </div>
        {/* {brmData.members &&
          brmData.members.length > 0 &&
          brmData.members.map((member) => (
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                marginTop: "10px",
                padding: "5px",
                borderBottom: "1px solid grey",
                borderRadius: "2px",
                alignItems: "center",
              }}
            >
              <Grid item md={5} xs={5}>
                <Typography style={{ fontSize: "13px", marginLeft: "5px" }}>
                  {member.name}
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    marginLeft: "5px",
                    color: "grey",
                  }}
                >
                  {member.email}
                </Typography>
              </Grid>
              <Grid item md={3} xs={3}>
                <Typography style={{ fontSize: "13px" }}>
                  {member.category}
                </Typography>
              </Grid>
              <Grid item md={3} xs={3} align="center">
                <Typography style={{ fontSize: "13px" }}>
                  {member.role}
                </Typography>
              </Grid>
              <Grid item md={1} xs={1}>
                <Icon
                  onClick={() => handleEditUser(member)}
                  style={{ alignSelf: "right", cursor: "pointer" }}
                >
                  more_vert
                </Icon>
              </Grid>
            </Grid>
          ))} */}
      </div>
    </div>
  );
}

export default StakeholderList;
