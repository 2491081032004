import React from "react";
import apiIntegration from "../../../images/apiIntegration.png";
import Grid from "@mui/material/Grid";
import DataHubIntegrationDrawer from "./DataHubIntegrationDrawer";

function DatahubApi(props) {
  return (
    <div>
      <DataHubIntegrationDrawer {...props} />
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          sm={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <img
            src={apiIntegration}
            alt=""
            style={{
              marginTop: "6%",
              width: "560.96px",
              height: "360px",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default React.memo(DatahubApi);
