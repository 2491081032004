import React from "react";
import Icon from "@mui/material/Icon";
import repeatWhite from "../../../images/repeatWhite.png";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../UI/button/button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
import moment from "moment";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      secondary: {
        main: "#47BB76",
      },
    },
  })
);

function DataHubSurveyButtons(props) {
  const { date, handleMainTabChange, selectedMainTab } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "165px",
        paddingBottom: "32px",
      }}
    >
      <div>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppBar
              position="static"
              color="inherit"
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            >
              <Tabs
                value={selectedMainTab}
                onChange={handleMainTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  style={{
                    backgroundColor:
                      selectedMainTab === 0 ? "#FFFFFF" : "transparent",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    borderRadius: "5px 5px 0px 0px",
                    textTransform: "capitalize",
                  }}
                  label={<span>Raw Data</span>}
                  name="Raw Data"
                />

                {/* <Tab
                  style={{
                    backgroundColor:
                      selectedMainTab === 1 ? "#FFFFFF" : "transparent",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    borderRadius: "5px 5px 0px 0px",
                    textTransform: "capitalize",
                  }}
                  label={<span>Calculated Data</span>}
                  name="Calculated Data"
                  // disabled
                /> */}
                <Tab
                  style={{
                    backgroundColor:
                      selectedMainTab === 1 ? "#FFFFFF" : "transparent",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    borderRadius: "5px 5px 0px 0px",
                    textTransform: "capitalize",
                  }}
                  label={<span>Cube DashBoard</span>}
                  name="Cube DashBoard"
                  // disabled
                />
              </Tabs>
            </AppBar>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: "100px",
        }}
      >
        <Typography
          style={{
            color: "#6C6C6C",
            fontWeight: 400,
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Last Updated on {moment(date).format("Do MMMM YYYY")}
        </Typography>

        <CustomButton
          startIcon={<Icon component={"img"} src={repeatWhite} />}
          // onClick={props.handleButton}2
          color="secondary"
          variant="contained"
          style={{
            borderRadius: "8px",
            textTransform: "capitalize",
            padding: "8px 16px",
            marginRight: "10px",
            marginLeft: "10px",
            flex: "1 0 auto",
          }}
          component="label"
        >
          Refresh
        </CustomButton>

        <hr
          style={{
            height: "100%",
            borderWidth: 1,
            color: "gray",
            backgroundColor: "gray",
          }}
        />

        <IconButton size="large">
          <AddIcon style={{ color: "#3374B9" }}></AddIcon>
        </IconButton>
        <IconButton size="large">
          <ShareOutlinedIcon style={{ color: "#3374B9" }}></ShareOutlinedIcon>
        </IconButton>
        <IconButton size="large">
          <TrendingUpIcon style={{ color: "#3374B9" }}></TrendingUpIcon>
        </IconButton>
      </div>
    </div>
  );
}

export default React.memo(DataHubSurveyButtons);
