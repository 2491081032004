import { getAssetsList } from "actions/phase-3/orgDetails/orgDetailsAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useAssetsAPIs = (keyword = "", limit = 5, page = 1) => {
  const { assets_list } = useSelector((state) => state.orgDetails);
  const { parent_id } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const fetchAssets = () => {
    dispatch(getAssetsList(parent_id, keyword, limit, (page - 1) * limit));
  };

  useEffect(() => {
    fetchAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  return {
    // states & data
    assets_list,
    // funcs
    fetchAssets,
  };
};
