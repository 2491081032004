import { useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Collapse,
  Checkbox,
  useTheme,
} from "@mui/material";
import { FiChevronsDown, FiSearch, FiChevronRight } from "react-icons/fi";

const StructureSearch = ({ orgData }) => {
  const theme = useTheme();
  const [expandedNodes, setExpandedNodes] = useState({ root: false });

  const handleExpandClick = (index) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderNode = (node, index) => (
    <Box key={index} mb={1}>
      <Box display="flex" alignItems="center">
        <Box width={24} display="flex" justifyContent="center">
          {node.children && (
            <IconButton
              size="small"
              onClick={() => handleExpandClick(index)}
              sx={{
                transform: expandedNodes[index]
                  ? "rotate(90deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s",
                p: 0,
              }}
            >
              <FiChevronRight size={16} />
            </IconButton>
          )}
        </Box>
        <Checkbox size="small" />
        <Typography variant="body2" ml={1}>
          {node.name}
        </Typography>
      </Box>
      {node.children && (
        <Collapse in={expandedNodes[index]}>
          <Box ml={4}>
            {node.children.map((child, childIndex) =>
              renderNode(child, `${index}-${childIndex}`)
            )}
          </Box>
        </Collapse>
      )}
    </Box>
  );

  const handleRootExpandClick = () => {
    const expandAll = !expandedNodes.root;
    const newExpandedNodes = { root: expandAll };

    const recursivelyExpand = (node, parentIndex) => {
      node.children?.forEach((child, index) => {
        const childIndex = `${parentIndex}-${index}`;
        newExpandedNodes[childIndex] = expandAll;
        recursivelyExpand(child, childIndex);
      });
    };

    recursivelyExpand(orgData, "root");
    setExpandedNodes(newExpandedNodes);
  };

  return (
    <Box
      position={"absolute"}
      top={0}
      left={0}
      p={2}
      width={"30em"}
      bgcolor={"background.paper"}
      boxShadow={"0px 4px 8px -2px #15314E1A"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={1}
      >
        <Typography variant="body1" fontWeight={600}>
          Structure
        </Typography>

        <IconButton
          sx={{
            color: "primary.main",
            transform: expandedNodes.root ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
          onClick={handleRootExpandClick}
        >
          <FiChevronsDown size={20} />
        </IconButton>
      </Box>

      <TextField
        id="structure-search"
        placeholder="Search"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <FiSearch size={18} color={theme.palette.primary.main} />
          ),
        }}
      />

      <Collapse in={expandedNodes.root}>
        <Box mt={2}>{renderNode(orgData, "root")}</Box>
      </Collapse>
    </Box>
  );
};

export default StructureSearch;
