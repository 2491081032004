import React, { useMemo } from "react";
import { Box } from "@mui/material";
import YearPicker from "components/UI/phase-3/datePicker/YearPicker";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import SelectAndOption from "components/UI/phase-3/selectAndOption/SelectAndOption";
import useFormValidation from "customHooks/useFormValidation";

import { setMaterialityAssessmentDetails } from "actions/phase-3/materialityAssessment/materialityAssessmentAction";
import { useMaterialityBasicDetail } from "../APIs/HandleBasicDetailApi";

const BasicDetails = React.memo(() => {
  const { materialityAssessmentDetails, disableButton } =
    useMaterialityBasicDetail();

  const initialAssessmentState = useMemo(
    () => ({
      assessment_name: "",
      framework_id: "645b9d92c908386433355b8b",
      year_of_assessment: "",
      stakeholder_group_engaged: "",
      stakeholder_identity_process_description: "",
      type_of_materiality: "",
      methodology_of_assessment: "",
      entity_id: "66c5c99aafeb50a530d72b28",
    }),
    []
  );

  const validationRules = useMemo(
    () => ({
      assessment_name: { maxCharacters: 30, required: true },
      framework_id: { required: true },
      year_of_assessment: { required: true },
      stakeholder_group_engaged: { maxCharacters: 300, required: true },
      stakeholder_identity_process_description: {
        maxCharacters: 200,
        required: true,
      },
      type_of_materiality: { required: true },
      methodology_of_assessment: { maxCharacters: 300, required: true },
    }),
    []
  );

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(
      disableButton ? materialityAssessmentDetails : initialAssessmentState,
      validationRules,
      setMaterialityAssessmentDetails
    );

  return (
    <Box>
      <Heading text={"Basic Details"} size={"15px"} marginBottom={"8px"} bg />
      <Box className="scroll">
        <InputField
          value={formData?.assessment_name}
          error={errors.assessment_name}
          name={"assessment_name"}
          handleChange={handleChange}
          heading={"Assessment name"}
          label={"Enter name here"}
        />
        <YearPicker
          heading={"Pick the year of assessment"}
          label="Select to open year picker"
          value={formData?.year_of_assessment}
          error={errors.year_of_assessment}
          name={"year_of_assessment"}
          handleChange={setFormData}
          actionCreator={setMaterialityAssessmentDetails}
        />
        <InputField
          heading={"Stakeholder Groups Engaged"}
          label={"Enter here"}
          rows={3}
          value={formData?.stakeholder_group_engaged}
          error={errors.stakeholder_group_engaged}
          name={"stakeholder_group_engaged"}
          handleChange={handleChange}
        />
        <InputField
          heading={
            "Describe the process of identifying key stakeholder groups of entity"
          }
          label={"Enter here"}
          rows={3}
          value={formData?.stakeholder_identity_process_description}
          error={errors.stakeholder_identity_process_description}
          name={"stakeholder_identity_process_description"}
          handleChange={handleChange}
        />
        <SelectAndOption
          heading={"Type of materiality"}
          options={["Single Materiality", "Other Materiality"]}
          value={formData?.type_of_materiality}
          error={errors.type_of_materiality}
          name={"type_of_materiality"}
          handleChange={handleChange}
        />
        <InputField
          heading={"Methodology of assessment"}
          label={"Enter here"}
          rows={5}
          value={formData?.methodology_of_assessment}
          error={errors.methodology_of_assessment}
          name={"methodology_of_assessment"}
          handleChange={handleChange}
        />
      </Box>
    </Box>
  );
});

export default BasicDetails;
