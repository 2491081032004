import { Box, Stack, Typography } from "@mui/material";
import AuthBlueLeftBG from "images/phase-3/AuthLeftRight/AuthBlueLeftBG.svg";
import GroupChartsData from "images/phase-3/AuthLeftRight/GroupChartsData.jpg";

const AuthLeftBlue = ({
  bgImg,
  heading,
  desc,
  baseImg,
  baseImgWidth = "100%",
  baseImgHeight = "25em",
  sx,
}) => {
  return (
    <Box
      height={"100%"}
      width={"100%"}
      maxWidth={"30em"}
      bgcolor={"primary.main"}
      sx={{
        position: "relative",
        overflow: "hidden",
        ...sx,
      }}
    >
      <Box
        height={"100%"}
        width={"100%"}
        sx={{
          backgroundImage: `url(${bgImg || AuthBlueLeftBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "center",
          opacity: 0.1,
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          height: "100%",
          width: "100%",
        }}
      >
        {/* Might make dynamic later */}
        <Stack
          height={"100%"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          color={"#ffffff"}
          p={2}
          gap={4}
        >
          <Stack gap={2}>
            <Typography variant="h1" fontWeight={500}>
              {heading || "Join the Path to Sustainable Success"}
            </Typography>
            <Typography variant="subtitle2" fontWeight={400}>
              {desc ||
                "Unlock powerful tools to manage and improve your company’s ESG performance. Our platform helps you meet compliance, track progress, and achieve your sustainability goals with ease"}
            </Typography>
          </Stack>

          <img
            src={baseImg || GroupChartsData}
            alt="baseImg"
            style={{ width: baseImgWidth, height: baseImgHeight }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default AuthLeftBlue;
