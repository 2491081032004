import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import OfficeLogo from "../../../images/Office.svg";

import { Link } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CustomButton from "../../UI/button/button";
import profileCardUseStyles from "./profileCardStyles";

function ProfileCard({
  organisationDetails,
  surveyListing,
  viewAssessments,
  setCurrentOrganzation,
  setCurrentOrganisationUserType,
  loginDetails,
  setIsNewCompany,
  setSelectedOraganization,
  anchorEl,
  onClose,
  setSignupProcessNo,
  setIsSubscriptionActive,
}) {
  const classes = profileCardUseStyles();

  const [active, setActive] = React.useState(
    localStorage.getItem("current_organisation")
  );
  const [siAddCompanyDialogOpen, setIsAddCompanyDialogOpen] = useState(false);

  useEffect(() => {
    setSelectedOraganization(
      organisationDetails.find(
        (ele) => ele._id === localStorage.getItem("current_organisation")
      )
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addCompanyDialog = () => {
    return (
      <Dialog open={siAddCompanyDialogOpen} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Grid container justifyContent="center">
            <Grid item xs={6}>
              <Typography className={classes.typo}>Add New Company</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mx={1}>
                <Typography className={classes.typo1}>
                  Are you sure you want to add new company.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid item xs={3} className={classes.grid1}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => setIsAddCompanyDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5}>
              <Link to="/basicDetails" className={classes.link}>
                <CustomButton
                  color="primary"
                  variant="contained"
                  className={classes.CustomButton}
                  onClick={() => {
                    setIsNewCompany(true);
                    setSignupProcessNo(2);
                    setIsAddCompanyDialogOpen(false);
                  }}
                >
                  Add
                </CustomButton>
              </Link>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Menu
      id="card-profile-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={() => onClose()}
      className={classes.Menu}
      getContentAnchorEl={null}
    >
      {siAddCompanyDialogOpen && addCompanyDialog()}
      <Box className={classes.boxstyle}>
        <Box>
          <Typography variant="subtitle1" className={classes.typo2}>
            {loginDetails.selectedOrganization &&
              loginDetails.selectedOrganization.company_name}
          </Typography>
        </Box>
        <Avatar
          src={
            loginDetails.selectedOrganization &&
            loginDetails.selectedOrganization.logo
              ? loginDetails.selectedOrganization.logo.url
              : OfficeLogo
          }
        />
      </Box>
      <div classname={classes.mainDiv}></div>
      {organisationDetails.map((s, i) => (
        <ListItem
          name="companyName"
          key={s._id}
          style={{ backgroundColor: s._id === active && "#D2E4FF33" }}
        >
          <ListItemAvatar>
            <Avatar src={s.logo ? s.logo.url : OfficeLogo} />
          </ListItemAvatar>
          <ListItemText
            name={s.company_name.substring(0, 4)}
            className={classes.companyText}
            onClick={() => {
              setActive(s._id);
              setCurrentOrganzation(s._id);
              setSelectedOraganization(s);
              localStorage.setItem("status", s.payment_status);
              localStorage.setItem("status_message", s.payment_message);
              setIsSubscriptionActive(
                s?.payment_status === "EXPIRED" ? false : true
              );

              s?.payment_status === "EXPIRED" || localStorage.getItem("TRIAL")
                ? localStorage.setItem("location", "/general_settings")
                : localStorage.getItem("current_organisation_user_type") ===
                    "SA"
                  ? localStorage.setItem("location", "/survey_listing")
                  : localStorage.setItem("location", "");

              localStorage.setItem("next_payment_date", s?.next_payment_date);

              localStorage.setItem("current_organisation", s._id);
              localStorage.setItem(
                "current_organisation_user_type",
                s.user_type
              );
              //commenting out this because it leading to duplicate API calls
              // if (loginDetails.parent_organisation.includes(s._id)) {
              //   viewAssessments(loginDetails.token, surveyListing, s._id, 5);
              // }
              setCurrentOrganisationUserType(s.user_type);
              onClose();
            }}
            primary={s.company_name}
          />
          {s._id === active && (
            <ListItemSecondaryAction>
              <CheckCircleRoundedIcon
                className={classes.tick}
                color="primary"
              />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}

      <div className={classes.dividerDiv}></div>
      <div
        className={classes.subDiv}
        onClick={() => {
          setIsAddCompanyDialogOpen(true);
        }}
        name="addNewComp"
      >
        <Typography className={classes.typo}>Add New Company</Typography>
      </div>

      <Box className={classes.boxstyle}>
        <Link to="/privacy_policy" target="_blank">
          <Typography className={classes.boxTypo}>Privacy Policy</Typography>
        </Link>
        <Link to="/tnc" target="_blank">
          <Typography className={classes.boxTypo}>Terms of Service</Typography>
        </Link>
      </Box>
    </Menu>
  );
}

export default ProfileCard;
