import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function MoreActionDrawer({
  isDrawerOpen,
  handleCloseDrawer,
  handleDeleteDialog,
  fileName,
  isPublished,
}) {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      PaperProps={{ style: { background: "#F6F6F6" } }}
    >
      <Card
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#FFFFFF",
          boxShadow: "none",
          borderRadius: "5px",
          padding: "16px",
          height: "72px",
          backgroundColor: "#EBF1F8",
          width: "25vw",
        }}
      >
        <CardContent style={{ marginRight: "12px" }}>
          <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
            {fileName}
          </Typography>
        </CardContent>
        <CardActions onClick={handleCloseDrawer}>
          <CloseIcon style={{ color: "#3374B9", cursor: "pointer" }} />
        </CardActions>
      </Card>
      <div style={{ padding: "16px" }}>
        <List
          dense={true}
          style={{
            backgroundColor: "rgba(71, 187, 118, 0.10)",
            borderRadius: "5px",
          }}
        >
          <ListItem>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
                  {isPublished ? "Unpublish" : "Delete"}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              {isPublished ? (
                <VisibilityOffOutlinedIcon
                  onClick={handleDeleteDialog}
                  style={{ color: "#C45446", cursor: "pointer" }}
                />
              ) : (
                <DeleteOutlineOutlinedIcon
                  onClick={handleDeleteDialog}
                  style={{ color: "#C45446", cursor: "pointer" }}
                />
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}
