import React, { useState } from "react";
import { BlueBackgroundCard } from "../apiConnection/ApiConnection";
import { Icon, Tab, Typography } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";

import NewsArticle from "./NewsArticles";
import ControversyProfile from "./ControversyProfile";
import Insights from "./Insights";

function Controversies(props) {
  const { esgScore, controversiesDetails, articles, ...esgInsights } = {};

  // console.log(esgScore, esgInsights);
  const [controversyTabNo, setControversyTabNo] = useState(0);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Controversies
          </Typography>
        </div>
        <div>
          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          /> */}
          <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
            more_vert
          </Icon>
        </div>
      </div>
      <TabContext value={controversyTabNo}>
        <TabList
          onChange={(e, newVal) => {
            setControversyTabNo(newVal);
          }}
          // onChange={handleTabChange}
        >
          <Tab value={0} disableRipple label={<span>Controversy Score</span>} />
          <Tab value={1} disableRipple label={<span>Articles </span>} />
          <Tab value={2} disableRipple label={<span>Insights</span>} />
        </TabList>
        <TabPanel value={0}>
          <div
            className="scroll"
            style={{ height: "55vh", marginTop: "10px", paddingBottom: 20 }}
          >
            <ControversyProfile />
          </div>
        </TabPanel>
        <TabPanel value={1}>
          <div
            className="scroll"
            style={{ height: "55vh", marginTop: "10px", paddingBottom: 20 }}
          >
            <BlueBackgroundCard heading={"News Articles"} />
            <NewsArticle articles={articles} {...props} />
          </div>
        </TabPanel>
        <TabPanel value={2}>
          <Insights />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Controversies;
