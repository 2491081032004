import { Box, Button } from "@mui/material";
import { useState } from "react";

// components
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import LayoutStepper from "components/layout/stepperLayout/LayoutStepper";
import {
  AssignStakeholders,
  SelectActivity,
  SelectFrequency,
} from "./DrawerSteps";
import { useSelectActivity } from "./APIs/SelectActivityApi";

const AssignDrawer = ({ open, onClose }) => {
  const steps = ["Select Activity", "Assign Stakeholders", "Select Frequency"];

  const [activeStep, setActiveStep] = useState(0);

  // hooks APIs
  const {
    activities,
    selectedActivities,
    disableComplete,
    handleCheckboxChange,
    resetActivites,
    selectAllActivities,
  } = useSelectActivity(); // Step 1 API

  // Function to handle the "Complete List" button logic
  const handleCompleteList = () => {
    if (activeStep === steps.length - 1) {
      setActiveStep(0); // Reset to first step if last step is reached
    } else {
      setActiveStep((prevStep) => prevStep + 1); // Move to next step
    }
  };

  // Function to handle going back to the previous step
  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <SelectActivity
            activities={activities}
            selectedActivities={selectedActivities}
            handleCheckboxChange={handleCheckboxChange}
          />
        );
      case 1:
        return <AssignStakeholders />;
      case 2:
        return <SelectFrequency />;
      default:
        return null;
    }
  };

  // Render action buttons based on the active step
  const renderActionButtons = () => {
    if (activeStep === 0) {
      return (
        <>
          <Button onClick={() => resetActivites()}>Clear Selection</Button>
          <Button onClick={() => selectAllActivities()}>Select All</Button>
          <Button
            variant="contained"
            onClick={handleCompleteList}
            disabled={disableComplete}
          >
            Complete List
          </Button>
        </>
      );
    } else if (activeStep === 1) {
      return (
        <>
          <Button onClick={handleBack}>Back</Button>
          <Button
            variant="contained"
            onClick={handleCompleteList}
            disabled={disableComplete}
          >
            Assign to Stakeholder
          </Button>
        </>
      );
    } else if (activeStep === 2) {
      return (
        <>
          <Button onClick={handleBack}>Back</Button>
          <Button
            variant="contained"
            onClick={handleCompleteList}
            disabled={disableComplete}
          >
            Set Frequency
          </Button>
        </>
      );
    }
    return null;
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Assign Data"}
      centerHead={
        <Box width={"50%"}>
          <LayoutStepper steps={steps} activeStep={activeStep} />
        </Box>
      }
      hasCustomActionButton={
        <Box width={"100%"}>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            {renderActionButtons()}
          </Box>
        </Box>
      }
      hasDefaultActionButton={false}
      sx={{ px: 0 }}
    >
      <Box sx={{ width: { xs: "90vw", sm: "80vw", md: "70vw" } }}>
        {/* Main Render */}
        {renderStepContent(activeStep)}
      </Box>
    </DynamicDrawer>
  );
};

export default AssignDrawer;
