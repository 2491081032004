import { Padding } from "@mui/icons-material";
import { Box } from "@mui/material";
import CountryInput from "components/UI/phase-3/countryInput/CountryInput";
import YearPicker from "components/UI/phase-3/datePicker/YearPicker";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import SelectAndOption from "components/UI/phase-3/selectAndOption/SelectAndOption";

function EsgComplianceBasicDetails() {
  return (
    <Box>
      <Heading
        text={"Basic Assessment Details"}
        size={"15px"}
        marginBottom={"8px"}
        bg
      />
      <Box className="scroll">
        <InputField heading={"Assessment name"} label={"Enter name here"} />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
            Padding: 0,
          }}
        >
          <YearPicker
            heading={"Pick the year of assessment"}
            label="Select to open year picker"
            marginBottom="10px"
            marginTop="0"
          />
          <SelectAndOption
            heading={"Scope of reporting"}
            marginBottom="10px"
            marginTop="0"
            options={["Consolidated", "Standalone"]}
          />
        </Box>

        <CountryInput heading={"Country"} label={"Select from dropdown"} />

        <InputField
          heading={"Sustainability Team Message"}
          label={"Enter here"}
          rows={3}
        />
        <InputField
          heading={"ESG Goals Overall"}
          label={"Enter here"}
          rows={3}
        />
        <InputField heading={"About Report"} label={"Enter here"} rows={3} />
      </Box>
    </Box>
  );
}

export default EsgComplianceBasicDetails;
