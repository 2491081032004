import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialityMainTopic,
  getMaterialityTopic,
  selectMaterialityTopic,
  selectAllMaterialityTopic,
  getSelectedMaterialityTopic,
  removeMaterialityTopic,
  removeAllMaterialityTopic,
  setTopicLimit,
} from "actions/phase-3/materialityAssessment/materialityAssessmentAction";

export const useMaterialityTopic = (source = false) => {
  const dispatch = useDispatch();

  // Memoize state selection to prevent unnecessary renders
  const {
    materialityAssessmentDetails,
    materialityMainTopics,
    materialityTopics,
    selectedMaterialityTopics,
    topicCount,
    topicLimit,
  } = useSelector(useCallback((state) => state?.materialityAssessment, []));

  const filter = useMemo(() => "All", []);

  const handleLoadMore = () => {
    dispatch(setTopicLimit(topicLimit + 10));
  };
  const handleMainTopic = useCallback(() => {
    if (materialityAssessmentDetails?.framework_type) {
      dispatch(
        getMaterialityMainTopic(materialityAssessmentDetails.framework_type)
      );
    }
  }, [dispatch, materialityAssessmentDetails?.framework_type]);

  const handleTopics = useCallback(() => {
    if (
      materialityAssessmentDetails?._id &&
      materialityAssessmentDetails?.framework_id
    ) {
      dispatch(
        getMaterialityTopic(
          materialityAssessmentDetails._id,
          materialityAssessmentDetails.framework_id,
          filter,
          0,
          topicLimit
        )
      );
    }
  }, [
    dispatch,
    materialityAssessmentDetails?._id,
    materialityAssessmentDetails?.framework_id,
    filter,
    topicLimit,
  ]);

  const handleSelectTopic = useCallback(
    (topic_id) => {
      if (
        materialityAssessmentDetails?._id &&
        materialityAssessmentDetails?.framework_id
      ) {
        dispatch(
          selectMaterialityTopic(
            materialityAssessmentDetails._id,
            topic_id,
            materialityAssessmentDetails.framework_id
          )
        );
        refreshTopics();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      materialityAssessmentDetails?._id,
      materialityAssessmentDetails?.framework_id,
    ]
  );

  const handleSelectAllTopics = useCallback(() => {
    if (
      materialityAssessmentDetails?._id &&
      materialityAssessmentDetails?.framework_id
    ) {
      dispatch(
        selectAllMaterialityTopic(
          materialityAssessmentDetails._id,
          materialityAssessmentDetails.framework_id
        )
      );
      refreshTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    materialityAssessmentDetails?._id,
    materialityAssessmentDetails?.framework_id,
  ]);

  const handleSelectedTopics = useCallback(() => {
    if (
      materialityAssessmentDetails?._id &&
      materialityAssessmentDetails?.framework_id
    ) {
      dispatch(
        getSelectedMaterialityTopic(
          materialityAssessmentDetails._id,
          materialityAssessmentDetails.framework_id
        )
      );
    }
  }, [
    dispatch,
    materialityAssessmentDetails?._id,
    materialityAssessmentDetails?.framework_id,
  ]);

  const handleRemoveTopic = useCallback(
    async (topic_id) => {
      dispatch(removeMaterialityTopic(topic_id));
      refreshTopics();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const handleRemoveAllTopics = useCallback(() => {
    if (materialityAssessmentDetails?._id) {
      dispatch(removeAllMaterialityTopic(materialityAssessmentDetails._id));
      dispatch(setTopicLimit(10));

      refreshTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, materialityAssessmentDetails?._id]);

  const refreshTopics = useCallback(() => {
    setTimeout(() => {
      handleTopics();
      //   handleSelectedTopics();
    }, 300); // Delay of 300 milliseconds
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTopics, handleSelectedTopics]);

  useEffect(() => {
    if (!materialityMainTopics?.length && source) {
      handleMainTopic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMainTopic, materialityMainTopics?.length]);

  useEffect(() => {
    // handleSelectedTopics();
    source && handleTopics();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicLimit]);

  useEffect(() => {
    // handleTopics();
    return () => {
      dispatch(setTopicLimit(10));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    materialityMainTopics,
    materialityTopics,
    handleSelectTopic,
    handleSelectAllTopics,
    selectedMaterialityTopics,
    handleRemoveTopic,
    handleRemoveAllTopics,
    handleLoadMore,
    topicCount,
    topicLimit,
  };
};
