const handleKpiDataWithOrganisation = (CURRENT_ORGANIZATION) => {
  const KPI_DATA = {
    Environmental: [
      {
        id: 16,
        name: "Total Energy Non-Renewable",
        width: "32%",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartY_AxisCustomize: "MegaJoules",
        layout: {
          x: 0,
          y: 0,
          w: 5,
          h: 8,
        },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_total_energy_nr_loc.total_energy"],
            order: {
              "Env_total_energy_nr_loc.date": "asc",
            },
            dimensions: ["Env_total_energy_nr_loc.location"],
            filters: [
              {
                member: "Env_total_energy_nr_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_total_energy_nr_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "line",
        },

        pivotConfig: {
          x: ["Env_total_energy_nr_loc.date.month"],
          y: ["Env_total_energy_nr_loc.location", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },

        label: ["Maharashtra", "Karnataka", "Gujarat", "Kerala"],
      },
      {
        id: 17,
        name: "Total Energy Renewable (MegaJoules)",
        width: "33%",
        layout: {
          x: 9,
          y: 0,
          w: 7,
          h: 8,
        },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_total_energy_r_loc.total_energy"],
            order: {
              "Env_total_energy_r_loc.total_energy": "desc",
            },
            dimensions: ["Env_total_energy_r_loc.location"],
            filters: [
              {
                member: "Env_total_energy_r_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_total_energy_r_loc.date",
              },
            ],
          },
          chartType: "pie",
        },

        pivotConfig: {
          x: ["Env_total_energy_r_loc.location"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        label: ["Maharashtra", "Karnataka", "Gujarat", "Kerala"],
      },
      {
        id: 12,
        name: "Total Plastic Waste Recycling",

        baseline: "#32A05F",
        targetline: "#FFCC66",
        width: "33%",
        chartColors: {
          backgroundColors: ["#005A87"],
          borderColor: ["#005A87"],
        },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_Total_plastic_waste_recycling.total_p"],
            order: {
              "Env_Total_plastic_waste_recycling.date": "asc",
            },
            dimensions: ["Env_Total_plastic_waste_recycling.total_w"],
            filters: [
              {
                member: "Env_Total_plastic_waste_recycling.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_Total_plastic_waste_recycling.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_Total_plastic_waste_recycling.total_w"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Metric Tons",
      },

      {
        id: 13,
        name: "Total E-Waste Recycling",
        width: "32%",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartColors: {
          backgroundColors: ["#FF6A00"],
          borderColor: ["#FF6A00"],
        },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_Total_e_waste_recycling.total_e"],
            order: {
              "Env_Total_e_waste_recycling.date": "asc",
            },
            dimensions: ["Env_Total_e_waste_recycling.total_e_waste"],
            filters: [
              {
                member: "Env_Total_e_waste_recycling.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_Total_e_waste_recycling.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_Total_e_waste_recycling.total_e_waste"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Metric Tons",
      },
      {
        id: 14,
        name: "Total Hazardous Waste Recycling",
        width: "33%",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartColors: {
          backgroundColors: ["#FFB43D"],
          borderColor: ["#FFB43D"],
        },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_Total_hazardous_waste_recycling.total_h"],
            order: {
              "Env_Total_hazardous_waste_recycling.date": "asc",
            },
            dimensions: ["Env_Total_hazardous_waste_recycling.total_h_waste"],
            filters: [
              {
                member: "Env_Total_hazardous_waste_recycling.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_Total_hazardous_waste_recycling.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_Total_hazardous_waste_recycling.total_h_waste"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Metric Tons",
      },
      {
        id: 15,
        name: "Total Other Hazardous Waste Recycling",
        width: "33%",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartColors: {
          backgroundColors: ["#8246AF"],
          borderColor: ["#8246AF"],
        },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_Total_others_waste_recycling.total_other"],
            order: {
              "Env_Total_others_waste_recycling.date": "asc",
            },
            dimensions: ["Env_Total_others_waste_recycling.total_other_waste"],
            filters: [
              {
                member: "Env_Total_others_waste_recycling.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_Total_others_waste_recycling.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_Total_others_waste_recycling.total_other_waste"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Metric Tons",
      },

      {
        id: 18,
        name: "Total Electricity Consumption Renewable",
        width: "32%",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        // chartColors: {
        //   backgroundColors: ["#F04187"],
        //   borderColor: ["#F04187"],
        // },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_total_elec_cons_r_loc.total_elec"],
            order: {
              "Env_total_elec_cons_r_loc.date": "asc",
            },
            dimensions: ["Env_total_elec_cons_r_loc.total_elec_cons_r_loc"],
            filters: [
              {
                member: "Env_total_elec_cons_r_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_total_elec_cons_r_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "area",
        },

        pivotConfig: {
          x: ["Env_total_elec_cons_r_loc.total_elec_cons_r_loc"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },

        chartY_AxisCustomize: "Kilowatt-hours (kWh)",
      },
      {
        id: 19,
        name: "Total Fuel Consumption Renewable",
        width: "33%",
        baseline: "#32A05F",
        targetline: "#FFCC66",

        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_total_fuel_cons_from_r_loc.total_fuel"],
            order: {
              "Env_total_fuel_cons_from_r_loc.date": "asc",
            },
            dimensions: ["Env_total_fuel_cons_from_r_loc.location"],
            filters: [
              {
                member: "Env_total_fuel_cons_from_r_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_total_fuel_cons_from_r_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_total_fuel_cons_from_r_loc.date.month"],
          y: ["Env_total_fuel_cons_from_r_loc.location", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Gallons (gal)",
      },
      {
        id: 20,
        name: "Total Other Energy Consumption Renewable",
        width: "33%",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartColors: {
          backgroundColors: ["#38B6FF"],
          borderColor: ["#38B6FF"],
        },
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_total_energy_con_r_other_loc.total_energy"],
            order: {
              "Env_total_energy_con_r_other_loc.date": "asc",
            },
            dimensions: [
              "Env_total_energy_con_r_other_loc.total_energy_r_other_loc",
            ],
            filters: [
              {
                member: "Env_total_energy_con_r_other_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_total_energy_con_r_other_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_total_energy_con_r_other_loc.total_energy_r_other_loc"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Terajoules (TJ)",
      },
      {
        id: 21,
        name: "Total S1 Emission",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_total_s1_emissions_loc.total_s1"],
            order: {
              "Env_total_s1_emissions_loc.date": "asc",
            },
            dimensions: ["Env_total_s1_emissions_loc.location"],
            filters: [
              {
                member: "Env_total_s1_emissions_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_total_s1_emissions_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_total_s1_emissions_loc.date.month"],
          y: ["Env_total_s1_emissions_loc.location", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Metric Tons of CO2 equivalent",
      },
      {
        id: 22,
        name: "Total S2 Emission",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_total_s2_emissions_loc.total_s2"],
            order: {
              "Env_total_s2_emissions_loc.date": "asc",
            },
            dimensions: ["Env_total_s2_emissions_loc.location"],
            filters: [
              {
                member: "Env_total_s2_emissions_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_total_s2_emissions_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_total_s2_emissions_loc.date.month"],
          y: ["Env_total_s2_emissions_loc.location", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Metric Tons of CO2 equivalent",
      },
      {
        id: 23,
        name: "Total Volume of Water Consumption",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_Total_volume_of_water_consumption_loc.total_water"],
            order: {
              "Env_Total_volume_of_water_consumption_loc.date": "asc",
            },
            dimensions: ["Env_Total_volume_of_water_consumption_loc.location"],
            filters: [
              {
                member:
                  "Env_Total_volume_of_water_consumption_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_Total_volume_of_water_consumption_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_Total_volume_of_water_consumption_loc.date.month"],
          y: ["Env_Total_volume_of_water_consumption_loc.location", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Cubic meters",
      },
      {
        id: 24,
        name: "Total Volume of Water Withdrawal",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        vizState: {
          query: {
            limit: 5000,
            measures: ["Env_Total_volume_of_water_withdrawal_loc.total_water"],
            order: {
              "Env_Total_volume_of_water_withdrawal_loc.date": "asc",
            },
            dimensions: ["Env_Total_volume_of_water_withdrawal_loc.location"],
            filters: [
              {
                member:
                  "Env_Total_volume_of_water_withdrawal_loc.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "Env_Total_volume_of_water_withdrawal_loc.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["Env_Total_volume_of_water_withdrawal_loc.date.month"],
          y: ["Env_Total_volume_of_water_withdrawal_loc.location", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Cubic meters",
      },
      // {
      //   id: 25,
      //   name: "Total volume of water withdrawal from Groundwater sources",
      //   chartY_AxisCustomize:"Cubic meters",
      //   vizState: {
      //     query: {
      //       limit: 5000,
      //       measures: ["Env_water_wtd_from_gwater_src.total_water"],
      //       order: {
      //         "Env_water_wtd_from_gwater_src.total_ewaste": "desc",
      //       },
      //       dimensions: [
      //         "Env_water_wtd_from_gwater_src.total_gwater_wtd",
      //       ],
      //       filters: [
      //         {
      //           member: "Env_water_wtd_from_gwater_src.organisationid",
      //           operator: "equals",
      //           values: [CURRENT_ORGANIZATION],
      //         },
      //       ],
      //       timeDimensions: [
      //         {
      //           dimension: "Env_water_wtd_from_gwater_src.date",
      //           granularity: "month",
      //         },
      //       ],
      //     },
      //     chartType: "area",
      //   },

      //   pivotConfig: {
      //     x: ["Env_water_wtd_from_gwater_src.total_gwater_wtd"],
      //     y: ["measures"],
      //     fillMissingDates: true,
      //     joinDateRange: false,
      //   },
      // },
      // {
      //   id: 26,
      //   name: "Total volume of water withdrawal from Seawater Sources",
      //   chartY_AxisCustomize:"Cubic meters",
      //   vizState: {
      //     query: {
      //       limit: 5000,
      //       measures: ["Env_water_wtd_from_seawater.total_water"],
      //       order: {
      //         "Env_water_wtd_from_seawater.total_ewaste": "desc",
      //       },
      //       dimensions: [
      //         "Env_water_wtd_from_seawater.total_sea_water",
      //       ],
      //       filters: [
      //         {
      //           member: "Env_water_wtd_from_seawater.organisationid",
      //           operator: "equals",
      //           values: [CURRENT_ORGANIZATION],
      //         },
      //       ],
      //       timeDimensions: [
      //         {
      //           dimension: "Env_water_wtd_from_seawater.date",
      //           granularity: "month",
      //         },
      //       ],
      //     },
      //     chartType: "area",
      //   },

      //   pivotConfig: {
      //     x: ["Env_water_wtd_from_seawater.total_sea_water"],
      //     y: ["measures"],
      //     fillMissingDates: true,
      //     joinDateRange: false,
      //   },
      // },
      // {
      //   id: 27,
      //   name: "Total volume of water withdrawal from Surface water Sources",
      //   chartY_AxisCustomize:"Cubic meters",
      //   vizState: {
      //     query: {
      //       limit: 5000,
      //       measures: ["Env_water_wtd_from_surface_water_src.total_water"],
      //       order: {
      //         "Env_water_wtd_from_surface_water_src.total_ewaste": "desc",
      //       },
      //       dimensions: [
      //         "Env_water_wtd_from_surface_water_src.total_surface_water",
      //       ],
      //       filters: [
      //         {
      //           member: "Env_water_wtd_from_surface_water_src.organisationid",
      //           operator: "equals",
      //           values: [CURRENT_ORGANIZATION],
      //         },
      //       ],
      //       timeDimensions: [
      //         {
      //           dimension: "Env_water_wtd_from_surface_water_src.date",
      //           granularity: "month",
      //         },
      //       ],
      //     },
      //     chartType: "area",
      //   },

      //   pivotConfig: {
      //     x: ["Env_water_wtd_from_surface_water_src.total_surface_water"],
      //     y: ["measures"],
      //     fillMissingDates: true,
      //     joinDateRange: false,
      //   },
      // },
      // {
      //   id: 28,
      //   name: "Total volume of water withdrawal from Third Party Water Source",
      //   chartY_AxisCustomize:"Cubic meters",
      //   vizState: {
      //     query: {
      //       limit: 5000,
      //       measures: ["Env_water_wtd_from_tpwater_src.total_water"],
      //       order: {
      //         "Env_water_wtd_from_tpwater_src.total_ewaste": "desc",
      //       },
      //       dimensions: [
      //         "Env_water_wtd_from_tpwater_src.total_tpwater",
      //       ],
      //       filters: [
      //         {
      //           member: "Env_water_wtd_from_tpwater_src.organisationid",
      //           operator: "equals",
      //           values: [CURRENT_ORGANIZATION],
      //         },
      //       ],
      //       timeDimensions: [
      //         {
      //           dimension: "Env_water_wtd_from_tpwater_src.date",
      //           granularity: "month",
      //         },
      //       ],
      //     },
      //     chartType: "area",
      //   },

      //   pivotConfig: {
      //     x: ["Env_water_wtd_from_tpwater_src.total_tpwater"],
      //     y: ["measures"],
      //     fillMissingDates: true,
      //     joinDateRange: false,
      //   },
      // },
      // {
      //   id: 29,
      //   name: "Total volume of water withdrawal from Other Sources",
      //   chartY_AxisCustomize:"Cubic meters",
      //   vizState: {
      //     query: {
      //       limit: 5000,
      //       measures: ["Env_water_wtd_from_other_src.total_water"],
      //       order: {
      //         "Env_water_wtd_from_other_src.total_ewaste": "desc",
      //       },
      //       dimensions: [
      //         "Env_water_wtd_from_other_src.total_other_water",
      //       ],
      //       filters: [
      //         {
      //           member: "Env_water_wtd_from_other_src.organisationid",
      //           operator: "equals",
      //           values: [CURRENT_ORGANIZATION],
      //         },
      //       ],
      //       timeDimensions: [
      //         {
      //           dimension: "Env_water_wtd_from_other_src.date",
      //           granularity: "month",
      //         },
      //       ],
      //     },
      //     chartType: "area",
      //   },

      //   pivotConfig: {
      //     x: ["Env_water_wtd_from_other_src.total_other_water"],
      //     y: ["measures"],
      //     fillMissingDates: true,
      //     joinDateRange: false,
      //   },
      // },
    ],

    "Human Capital": [
      {
        id: 7,
        name: "No of Affected Employees/Workers rehabilitated Safety Incident (Count)",
        width: "40%",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_no_afc_emp_wor_rhd_sft_inct.total_emp_workers"],
            order: {
              "HC_no_afc_emp_wor_rhd_sft_inct.date": "asc",
            },
            dimensions: [
              "HC_no_afc_emp_wor_rhd_sft_inct.total_no_aff_emp_workers",
            ],
            filters: [
              {
                member: "HC_no_afc_emp_wor_rhd_sft_inct.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_no_afc_emp_wor_rhd_sft_inct.date",
                granularity: "month",
              },
            ],
          },
          chartType: "pie",
        },

        pivotConfig: {
          x: ["HC_no_afc_emp_wor_rhd_sft_inct.date.month"],
          y: [
            "HC_no_afc_emp_wor_rhd_sft_inct.total_no_aff_emp_workers",
            "measures",
          ],
          fillMissingDates: true,
          joinDateRange: false,
        },
        label: [
          "BOD Employees",
          "Wages Workers Employee",
          "KMP Employeess",
          "Other Employees",
        ],
      },
      {
        id: 6,
        name: "No of Affected Employees/Workers Placed in Suitable Employment Safety Incident",
        width: "59%",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_no_afc_emp_wor_p_in_s_empt_sft_i.total_emp"],
            order: {
              "HC_no_afc_emp_wor_p_in_s_empt_sft_i.date": "asc",
            },
            dimensions: [
              "HC_no_afc_emp_wor_p_in_s_empt_sft_i.total_no_aff_emp",
            ],
            filters: [
              {
                member: "HC_no_afc_emp_wor_p_in_s_empt_sft_i.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_no_afc_emp_wor_p_in_s_empt_sft_i.date",
                granularity: "month",
              },
            ],
          },
          chartType: "line",
        },

        pivotConfig: {
          x: ["HC_no_afc_emp_wor_p_in_s_empt_sft_i.date.month"],
          y: [
            "HC_no_afc_emp_wor_p_in_s_empt_sft_i.total_no_aff_emp",
            "measures",
          ],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartY_AxisCustomize: "Count",
      },
      {
        id: 0,
        name: "Total Other Board of Directors Employees",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_other_bod_employees.total_other_bod"],
            order: {
              "HC_total_other_bod_employees.date": "asc",
            },
            dimensions: ["HC_total_other_bod_employees.total_other_bod_emp"],
            filters: [
              {
                member: "HC_total_other_bod_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_other_bod_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "area",
        },

        pivotConfig: {
          x: ["HC_total_other_bod_employees.total_other_bod_emp"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      },
      {
        id: 1,
        name: "Total Wages Board of Directors Employees",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        width: "32%",
        chartY_AxisCustomize: "Currency (USD)",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_wages_bod_employees.total_wages_bod"],
            order: {
              "HC_total_wages_bod_employees.date": "asc",
            },
            dimensions: ["HC_total_wages_bod_employees.total_wages_bod_emp"],
            filters: [
              {
                member: "HC_total_wages_bod_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_wages_bod_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["HC_total_wages_bod_employees.total_wages_bod_emp"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartColors: {
          backgroundColors: ["#005A87"],
          borderColor: ["#005A87"],
        },
      },
      {
        id: 2,
        name: "Total Wages Key Management Personel Employees",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_wages_kmp_employees.total_wages_kmp"],
            order: {
              "HC_total_wages_kmp_employees.date": "asc",
            },
            dimensions: ["HC_total_wages_kmp_employees.total_wages_kmp_emp"],
            filters: [
              {
                member: "HC_total_wages_kmp_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_wages_kmp_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["HC_total_wages_kmp_employees.total_wages_kmp_emp"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },

        chartColors: {
          backgroundColors: ["#FF6A00"],
          borderColor: ["#FF6A00"],
        },
      },
      {
        id: 3,
        name: "Total Wages Other Employees",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        width: "33%",
        chartY_AxisCustomize: "Currency (USD)",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_wages_other_employees.total_wages_other"],
            order: {
              "HC_total_wages_other_employees.date": "asc",
            },
            dimensions: [
              "HC_total_wages_other_employees.total_wages_other_emp",
            ],
            filters: [
              {
                member: "HC_total_wages_other_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_wages_other_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["HC_total_wages_other_employees.total_wages_other_emp"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartColors: {
          backgroundColors: ["#FFB43D"],
          borderColor: ["#FFB43D"],
        },
      },
      {
        id: 4,
        name: "Total Wages Workers",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartY_AxisCustomize: "Currency (USD)",
        width: "33%",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_wages_workers_employees.total_wages_worker"],
            order: {
              "HC_total_wages_workers_employees.date": "asc",
            },
            dimensions: [
              "HC_total_wages_workers_employees.total_wages_other_worker",
            ],
            filters: [
              {
                member: "HC_total_wages_workers_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_wages_workers_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["HC_total_wages_workers_employees.total_wages_other_worker"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
        chartColors: {
          backgroundColors: ["#8246AF"],
          borderColor: ["#8246AF"],
        },
      },
      {
        id: 5,
        name: "Total Wages Employees",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartY_AxisCustomize: "Currency (USD)",
        width: "32%",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_wages_employees.total_wages"],
            order: {
              "HC_total_wages_employees.date": "asc",
            },
            dimensions: ["HC_total_wages_employees.total_wages_emp"],
            filters: [
              {
                member: "HC_total_wages_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_wages_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "bar",
        },

        pivotConfig: {
          x: ["HC_total_wages_employees.total_wages_emp"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },

        chartColors: {
          backgroundColors: ["#F04187"],
          borderColor: ["#F04187"],
        },
      },
      {
        id: 8,
        name: "Total Board of Directors Employees",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        width: "33%",
        chartY_AxisCustomize: "Count",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_bod_employees.total_bod"],
            order: {
              "HC_total_bod_employees.date": "asc",
            },
            dimensions: ["HC_total_bod_employees.total_bod_emp"],
            filters: [
              {
                member: "HC_total_bod_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_bod_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "area",
        },

        pivotConfig: {
          x: ["HC_total_bod_employees.total_bod_emp"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      },
      {
        id: 9,
        name: "Total Female Board of Directors Employees",
        baseline: "#32A05F",
        targetline: "#FFCC66",
        chartY_AxisCustomize: "Count",
        width: "33%",
        vizState: {
          query: {
            limit: 5000,
            measures: ["HC_total_female_bod_employees.total_female_bod"],
            order: {
              "HC_total_female_bod_employees.date": "asc",
            },
            dimensions: ["HC_total_female_bod_employees.total_female_bod_emp"],
            filters: [
              {
                member: "HC_total_female_bod_employees.organisationid",
                operator: "equals",
                values: [CURRENT_ORGANIZATION],
              },
            ],
            timeDimensions: [
              {
                dimension: "HC_total_female_bod_employees.date",
                granularity: "month",
              },
            ],
          },
          chartType: "area",
        },

        pivotConfig: {
          x: ["HC_total_female_bod_employees.total_female_bod_emp"],
          y: ["measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      },
    ],
  };

  return KPI_DATA;
};

export default handleKpiDataWithOrganisation;
