import { Box } from "@mui/material";
import GetStarted from "components/screen/phase-3/getStarted/GetStarted";
import Heading from "components/UI/phase-3/heading/Heading";
import React from "react";

function GetStartedCont() {
  return (
    <Box width={"100%"} p={2}>
      {/* Heading */}
      <Heading text={"Onboarding"} size={"16px"} marginBottom={"30px"} />

      {/* Main Content */}
      <GetStarted />
    </Box>
  );
}

export default GetStartedCont;
