import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Social,
  Human,
  Business,
  Environmental,
  Governance,
  RatingFive,
  RatingFour,
  RatingThree,
  RatingTwo,
  RatingOne,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { TableAccordian } from "./TableAccordian";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    borderRadius: "0px",
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function checkVote(vote) {
  switch (vote) {
    case 1:
      return <RatingOne />;
    case 2:
      return <RatingTwo />;
    case 3:
      return <RatingThree />;
    case 4:
      return <RatingFour />;
    case 5:
      return <RatingFive />;
    default:
      return;
  }
}

export default function DashboardTables({
  head,
  body,
  hasAccordian = false,
  // apis,
  token,
  type,
}) {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      style={{ borderRadius: "0px" }}
      elevation={0}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ backgroundColor: "#EBF1F8" }}>
          <TableRow>
            {head.map((h, i, a) => (
              <TableCell>{h}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!hasAccordian && (
          <TableBody>
            {Object.keys(body).length > 0 &&
              body.map((row) => (
                <TableRow
                  key={row.first}
                  style={{ backgroundColor: "#FBFBFB" }}
                >
                  {Object.keys(row).length > 0 &&
                    !Object.keys(row).some((t) => t === "vote_data") &&
                    Object.keys(row).map((h, i, a) => {
                      return <TableCell>{row[h]}</TableCell>;
                    })}
                  {/* <TableCell component="th" scope="row">
                {row.first}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
              ))}
            {Object.keys(body).length > 0 &&
              body.map((row) => (
                <TableRow
                  key={row.first}
                  style={{ backgroundColor: "#FBFBFB" }}
                >
                  {Object.keys(row).length > 0 &&
                    Object.keys(row).some((t) => t === "vote_data") && (
                      <>
                        <TableCell>{row["holding_position"]}</TableCell>
                        <TableCell>{row["topic_name"]}</TableCell>
                        <TableCell>
                          {row["vote_data"]?.average > 0 && (
                            <IconButton
                              className={classes.iconColor}
                              size="large"
                            >
                              {checkVote(row["vote_data"]?.average)}
                            </IconButton>
                          )}
                        </TableCell>
                      </>
                    )}
                  {/* <TableCell component="th" scope="row">
                {row.first}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
              ))}
            {Object.keys(body).length === 0 && (
              <>
                <TableCell align="right"></TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      padding: "20px",
                      // border: "solid",
                    }}
                  >
                    No Data
                  </Typography>
                </TableCell>
                <TableCell align="left"></TableCell>
              </>
            )}
          </TableBody>
        )}
        {hasAccordian && type !== "Task" && (
          <TableBody>
            {Object.keys(body).length > 0 &&
              body.map((row) => <TableAccordian key={row._id} row={row} />)}
            {Object.keys(body).length === 0 && (
              <>
                <TableCell align="right"></TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      padding: "20px",
                      // border: "solid",
                    }}
                  >
                    No Report Assign
                  </Typography>
                </TableCell>
                <TableCell align="left"></TableCell>
              </>
            )}
          </TableBody>
        )}
        {hasAccordian && type === "Task" && (
          <TableBody>
            {Object.keys(body).length > 0 &&
              body.map((row, i, arr) => (
                <TableAccordian
                  key={row._id}
                  row={row}
                  // apis={apis}
                  type={type}
                  bodyArr={arr}
                />
              ))}
            {Object.keys(body).length === 0 && (
              <>
                <TableCell align="right"></TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      padding: "20px",
                      // border: "solid",
                    }}
                  >
                    No Task Assign
                  </Typography>
                </TableCell>
                <TableCell align="left"></TableCell>
              </>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
