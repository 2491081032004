import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Avatar, Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const dummyData = [
  {
    topic: "Section A",
    subTopic: [{ topic: "name of company" }, { topic: "year of assessment" }],
  },
  {
    topic: "Section B",
    subTopic: [{ topic: "name of company" }, { topic: "year of assessment" }],
  },
  {
    topic: "Principal 1",
    subTopic: [{ topic: "Essential indicator" }, { topic: "LeaderShip" }],
  },
  {
    topic: "Section A",
    subTopic: [{ topic: "name of company" }, { topic: "year of assessment" }],
  },
  {
    topic: "Section B",
    subTopic: [{ topic: "name of company" }, { topic: "year of assessment" }],
  },
  {
    topic: "Principal 1",
    subTopic: [{ topic: "Essential indicator" }, { topic: "LeaderShip" }],
  },
];
function ComplianceLeft() {
  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflow: "scroll",
          height: "100%",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {dummyData.map((sectionId, index) => (
          <li key={`section-${sectionId?.topic}`}>
            <ul>
              <ListSubheader
                sx={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <ListItemIcon>
                  <Avatar sx={{ bgcolor: "#F6F6F6" }}>{index}</Avatar>
                </ListItemIcon>
                <ListItemText primary={sectionId?.topic} />
              </ListSubheader>
              {sectionId?.subTopic.map((item, subIndex) => (
                <ListItemButton sx={{ pl: 6 }} selected={1 === subIndex}>
                  <ListItemIcon>
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ color: "#6C6C6C" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item?.topic} />
                </ListItemButton>
              ))}
            </ul>
          </li>
        ))}
      </List>
    </>
  );
}

export default ComplianceLeft;
