import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { DetailCard } from "../ComplianceHeader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Heading from "components/UI/phase-3/heading/Heading";

const dummyWidget = [
  {
    title: "Total Data Points",
    points: 24,
  },
  {
    title: "Total Points Assigned",
    points: 0,
  },
  {
    title: "Total Points Submitted",
    points: 0,
  },
  {
    title: "Total Points Approved",
    points: 0,
  },
];

const dummyData = [
  {
    topic: "Section A",
    status: "pending",
  },
  {
    topic: "Section B",
    subTopic: [
      { topic: "name of company", status: "pending" },
      { topic: "year of assessment", status: "pending" },
    ],
  },
  {
    topic: "Principal 1",
    subTopic: [
      { topic: "Essential indicator", status: "pending" },
      { topic: "LeaderShip", status: "pending" },
    ],
  },
];

export const CustomNumber = ({ index, hasBGColor }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "42px",
        height: "30px",
        padding: "5px",
        border: "none",
        backgroundColor: hasBGColor && "#EBF1F8",
        color: hasBGColor && "#3374B9",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          border: hasBGColor && "1px solid #3374B9",
          backgroundColor: hasBGColor && "#3374B9",
          margin: "2px",
          borderRadius: "5px",
          width: "3px",
          height: "18px",
          fontWeight: "bold",
        }}
      ></div>
      <div>
        <Typography style={{ padding: "2px 5px 2px 2px" }}>
          {index + 1}
        </Typography>
      </div>
    </div>
  );
};

const ComplianceRightDisclosureRow = ({
  data,
  index,
  hasBGColor = true,
  handleExplore,
}) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={5} mt={3}>
      <CustomNumber index={index} hasBGColor={hasBGColor} />

      <Typography variant="subtitle1">{data?.topic}</Typography>
      {data?.status && (
        <Chip
          sx={(theme) => ({
            backgroundColor:
              data.status !== "Active"
                ? theme.palette.primary.lighter
                : theme.palette.secondary.lighter,
            padding: "6px 10px",
            fontSize: "12px",
            color:
              data.status !== "Active"
                ? theme.palette.primary.dark
                : theme.palette.secondary.dark,
            borderRadius: "80px",
            marginLeft: "auto",
          })}
          label={data.status}
        />
      )}
      {data?.status && (
        <IconButton onClick={handleExplore}>
          <KeyboardArrowRightIcon color="primary" size="small" />
        </IconButton>
      )}
    </Stack>
  );
};

export default function DisclosureListing({ toggleExplore }) {
  return (
    <>
      {/* heading */}
      <Heading text="Essential Indicator" />
      <Stack direction={"row"} gap={2} mt={1}>
        {dummyWidget.map(({ title, points }) => (
          <DetailCard title={title} points={points} />
        ))}
      </Stack>
      {/* main content */}
      <Stack>
        {/* Header */}
        <Stack direction={"row"} alignItems={"center"} gap={7} mt={3}>
          <Typography variant="body2">Disclosure</Typography>
          <Typography variant="body2" ml={"auto"}>
            Status
          </Typography>
          <Typography variant="body2">Explore</Typography>
        </Stack>
        {/* Body */}
        {dummyData.map((data, index) => {
          return (
            <>
              {/* parent topic */}
              <ComplianceRightDisclosureRow
                data={data}
                index={index}
                handleExplore={toggleExplore}
              />

              {/* child topic */}
              {data?.subTopic &&
                data?.subTopic.map((subTopic, subIndex) => {
                  return (
                    <ComplianceRightDisclosureRow
                      data={subTopic}
                      index={subIndex}
                      hasBGColor={false}
                      handleExplore={toggleExplore}
                    />
                  );
                })}
            </>
          );
        })}
      </Stack>
    </>
  );
}
