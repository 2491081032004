import React from "react";
import dataSurvey from "../../../images/dataSurvey.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DataSetLeft from "./dataSetLeft/DataSetLeft";
import DataSetRight from "./dataSetRight/DataSetRight";

function DataSetsMain() {
  return (
    <>
      {false && (
        <div>
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "15px",
              }}
            >
              <img
                src={dataSurvey}
                alt=""
                style={{
                  marginTop: "6%",
                  width: "560.96px",
                  height: "360px",
                }}
              />
            </Grid>
          </Grid>

          <Typography
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "32px",
              textAlign: "center",
              textTransform: "capitalize",
              color: "#242424",
              padding: "1px 15px",
            }}
          >
            Import sheet files to procees the data.
          </Typography>
        </div>
      )}
      <Grid container justifyContent="space-evenly" style={{ height: "70vh" }}>
        <Grid item md={4} style={{ padding: "20px", height: "100%" }}>
          <DataSetLeft />
        </Grid>
        <Grid
          item
          md={8}
          style={{ padding: "20px 20px 20px 0px", height: "100%" }}
        >
          <DataSetRight />
        </Grid>
      </Grid>
    </>
  );
}

export default DataSetsMain;
