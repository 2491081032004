import { useNavigate } from "react-router-dom";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { Box, Icon, TableCell, TableRow, Typography } from "@mui/material";
import esgMaturity from "images/navDrawerIcon/esgMaturity.png";
import esgStrategy from "images/navDrawerIcon/esgStrategy.png";
import esgCompliance from "images/navDrawerIcon/esgCompliance.png";
import materialityMapping from "images/navDrawerIcon/materialityMapping.png";
// import organisationalStructure from "images/navDrawerIcon/organisationStructure.png";
// import survey from "images/navDrawerIcon/survey.png";
// import supplier from "images/navDrawerIcon/supplier.png";
// import task from "images/navDrawerIcon/task.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTasksList } from "actions/phase-3/task/maturity/maturityAction";

const taskTypeMap = {
  1: { text: "esg maturity", icon: esgMaturity },
  2: { text: "materiality mapping", icon: materialityMapping },
  3: { text: "esg strategy", icon: esgStrategy },
  4: { text: "esg compliance", icon: esgCompliance },
};

function TaskListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { entity_id } = useSelector((state) => state.user);
  const { tasks_list } = useSelector((state) => state.maturityTask);

  useEffect(() => {
    dispatch(getTasksList(entity_id));
  }, [dispatch, entity_id]);

  return (
    <DynamicTable headings={["Task Type", "Sent On", "Requested By"]}>
      {tasks_list.map((task, rowIndex) => {
        const taskDetails = taskTypeMap[task.task_type] || {};
        return (
          <TableRow
            key={rowIndex}
            onClick={() => {
              navigate(`/task/${taskDetails.text?.replaceAll(" ", "_")}`);
            }}
            sx={{
              borderBottom: "1px solid #EBF1F8",
              cursor: "pointer",
            }}
            hover
          >
            <TableCell style={textStyle("main")}>
              <Box display={"flex"} alignItems={"center"} sx={{ gap: 2 }}>
                <Icon
                  component={"img"}
                  fontSize="small"
                  src={taskDetails.icon}
                  color="primary"
                />
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  {taskDetails.text}
                </Typography>
              </Box>
            </TableCell>
            <TableCell style={textStyle()}>{task?.sent}</TableCell>
            <TableCell style={textStyle()}>{task?.requestedBy}</TableCell>
          </TableRow>
        );
      })}
    </DynamicTable>
  );
}

export default TaskListing;
