import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import dataSurvey from "../../../images/dataSurvey.png";
import DataHubSurveySheetTable from "./dataHubSurveySheetTable";
import DataHubSurveyDrawer from "./dataHubSurveyDrawer";
import NoData from "../DatahubNoData/NoData";

function DataHubSurvey(props) {
  const {
    isDrawerOpen,
    handleDrawer,
    login,
    addTags,
    dataHub,
    uploadImportFile,
    getImportFileList,
    deleteTags,
    getImportFileData,
    getMatatikaJobStatus,
    restartMatatikaJob,
  } = props;
  const [file, setFile] = React.useState([]);
  const TAB_NAME = "survey_tab";
  React.useEffect(() => {
    getImportFileList(login.token, login.current_organisation, TAB_NAME);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFile = (e) => {
    const uploadFile = e.target.files[0];
    uploadFile !== undefined && setFile([uploadFile]);
  };
  const handleFileRemove = (value) => {
    setFile(value);
  };
  return (
    <>
      <DataHubSurveyDrawer
        login={login}
        addTags={addTags}
        dataHub={dataHub}
        file={file}
        isDrawerOpen={isDrawerOpen}
        handleDrawer={handleDrawer}
        uploadHandler={handleFile}
        handleFileRemove={handleFileRemove}
        uploadImportFile={uploadImportFile}
        deleteTags={deleteTags}
      />
      {dataHub.importFileList.length === 0 && <NoData />}
      {dataHub.importFileList.length > 0 && (
        <DataHubSurveySheetTable
          file={dataHub.importFileList}
          status={dataHub.dataProcessingStatus}
          progress={dataHub.fileProcessingProgress}
          getImportFileData={getImportFileData}
          token={login.token}
          getMatatikaJobStatus={getMatatikaJobStatus}
          restartMatatikaJob={restartMatatikaJob}
          currentOrganisation={login.current_organisation}
        />
      )}
    </>
  );
}

export default React.memo(DataHubSurvey);
