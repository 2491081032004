import {
  Box,
  IconButton,
  Button,
  Stack,
  TableCell,
  TableRow,
  Pagination,
} from "@mui/material";
import { getStakeholdersList } from "actions/phase-3/orgDetails/orgDetailsAction";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import DynamicTableFull from "components/UI/phase-3/dynamicTable/DynamicTableFull";
import SearchTextField from "components/UI/textfield/searchTextField";
import StakeholderDrawer from "components/widgets/phase-3/OrgDetailsProfile/StakeholderComps/StakeholderDrawer";

import { FiUpload, FiDownload, FiPlus } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useStakeholdersAPIs } from "./APIs/StakeholdersApis";
import { usePaginationSearch } from "customHooks/usePaginationSearch";

const stakeholdersListHead = [
  { name: "Stakeholder Name" },
  { name: "Unique Id" },
  { name: "Role" },
  { name: "Entity/Unit ID" },
  { name: "Entity/Unit Name" },
  { name: "View" },
];

const tableRowOrder = [
  "name",
  "stakeholder_id",
  "category",
  "entity.entity_id || unit.unit_id",
  "entity.entity_name || unit.unit_name",
];

const customAction = (row) => (
  <IconButton
    aria-label="CustomAction"
    size="small"
    sx={{ color: "primary.main" }}
  >
    <IoIosArrowForward />
  </IconButton>
);

const Stakeholders = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const {
    // states & data
    stakeholders_list,
    fetchStakeholders,
  } = useStakeholdersAPIs(keyword, limit, page);

  return (
    <Box position={"relative"} height={"65vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={2} mb={2} justifyContent="flex-end">
        <SearchTextField searchClick={handleSearch} sx={{ minWidth: "30em" }} />
        <Button variant="text" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button variant="text" startIcon={<FiUpload />}>
          Upload Sheet
        </Button>
        <Button
          variant="contained"
          startIcon={<FiPlus />}
          size="small"
          onClick={toggleDrawer}
        >
          Add Stakeholders
        </Button>
      </Stack>

      {/* Entry List Table */}
      <DynamicTableFull
        tableHead={stakeholdersListHead}
        tableData={stakeholders_list?.stakeholder_list}
        tableRowOrder={tableRowOrder}
        renderAction={customAction}
      />

      <Box position={"absolute"} right={2} bottom={0}>
        <Pagination
          count={Math.ceil(stakeholders_list?.total_count / limit)}
          variant="outlined"
          page={page}
          onChange={handlePageChange}
        />
      </Box>

      <StakeholderDrawer
        open={openDrawer}
        onClose={toggleDrawer}
        fetchStakeholders={fetchStakeholders}
      />
    </Box>
  );
};

export default Stakeholders;
