import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Chip,
  Select,
  MenuItem,
  InputAdornment,
  Autocomplete, // Import Autocomplete
} from "@mui/material";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import ImageUplaod from "components/UI/phase-3/ImageUpload/ImageUplaod";

import { GrLocation } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { createUnits } from "actions/phase-3/orgDetails/orgDetailsAction";

// hooks
import useFormValidation from "customHooks/useFormValidation";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEntityListAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/EntryListApis";
import requiredField from "components/UI/phase-3/inputField/requiredShow";

const UnitsDrawer = ({ open, onClose, fetchUnits }) => {
  const dispatch = useDispatch();
  const [entityID, setEntityID] = useState("");
  const [unitImage, setUnitImage] = useState(null);

  const { parent_id } = useSelector((state) => state.user);

  const initialState = {
    name: "",
    type: "",
    unit_id: "",
    category: "",
    street: "",
    city_state: "",
    coordinates: "",
  };

  const validationRules = {
    name: { maxCharacters: 30, required: true },
    type: { required: true },
    unit_id: { maxCharacters: 21, required: true },
    category: { required: true },
    street: { required: true },
    city_state: { required: true },
    coordinates: { required: true },
  };

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(initialState, validationRules);

  // Handle search keyword and fetch entity list
  const { keyword, handleSearch } = usePaginationSearch(
    true /*searchDebounce*/
  );
  const { entity_list } = useEntityListAPIs(
    keyword,
    50, // limit
    1, // page
    true, // hideLoader
    true // hideMsg
  );

  const handleTypeClick = (type) => {
    setFormData((prev) => ({ ...prev, type }));
  };

  const handleCategoryClick = (category) => {
    setFormData((prev) => ({ ...prev, category }));
  };

  const disableSubmit =
    !formData.name ||
    !formData.type ||
    !formData.unit_id ||
    !formData.category ||
    !formData.street ||
    !formData.city_state ||
    !formData.coordinates ||
    !entityID ||
    !unitImage ||
    hasErrors;

  const handleSubmit = async () => {
    if (!disableSubmit) {
      await dispatch(
        createUnits(
          parent_id,
          formData.name,
          formData.type,
          formData.category,
          formData.unit_id,
          { street_name: formData.street, city_state: formData.city_state },
          formData.coordinates,
          entityID,
          { file: unitImage[0] },
          fetchUnits
        )
      );

      setFormData(initialState);
      setEntityID("");
      setUnitImage(null);
      onClose();
    }
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Unit"}
      buttonText={"Add Unit"}
      submitClick={handleSubmit}
      disableSubmit={disableSubmit}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        {/* Unit Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Unit Name {requiredField(Boolean(errors.name) || !formData.name)}
          </Typography>
          <TextField
            name="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
            fullWidth
          />
        </Box>

        {/* Unit Type */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Unit Type {requiredField(Boolean(errors.type) || !formData.type)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="Plant"
              onClick={() => handleTypeClick("Plant")}
              color={formData.type === "Plant" ? "primary" : "default"}
            />
            <Chip
              label="Office"
              onClick={() => handleTypeClick("Office")}
              color={formData.type === "Office" ? "primary" : "default"}
            />
          </Box>
        </Box>

        {/* Unit ID */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Unit ID{" "}
            {requiredField(Boolean(errors.unit_id) || !formData.unit_id)}
          </Typography>
          <TextField
            name="unit_id"
            value={formData.unit_id}
            onChange={handleChange}
            error={Boolean(errors.unit_id)}
            helperText={errors.unit_id}
            required
            fullWidth
          />
        </Box>

        {/* Unit Category */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Unit Category{" "}
            {requiredField(Boolean(errors.category) || !formData.category)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="Internal"
              onClick={() => handleCategoryClick("Internal")}
              color={formData.category === "Internal" ? "primary" : "default"}
            />
            <Chip
              label="External"
              onClick={() => handleCategoryClick("External")}
              color={formData.category === "External" ? "primary" : "default"}
            />
          </Box>
        </Box>

        {/* Location */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Location{" "}
            {requiredField(
              Boolean(errors.street) ||
                !formData.street ||
                Boolean(errors.city_state) ||
                !formData.city_state
            )}
          </Typography>
          <TextField
            name="street"
            placeholder="Street name, building name"
            value={formData.street}
            onChange={handleChange}
            error={Boolean(errors.street)}
            helperText={errors.street}
            sx={{ mb: 2 }}
            required
            fullWidth
          />
          <TextField
            name="city_state"
            placeholder="City, state"
            value={formData.city_state}
            onChange={handleChange}
            error={Boolean(errors.city_state)}
            helperText={errors.city_state}
            required
            fullWidth
          />
        </Box>

        {/* GPS Coordinates */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            GPS Coordinates{" "}
            {requiredField(
              Boolean(errors.coordinates) || !formData.coordinates
            )}
          </Typography>
          <TextField
            name="coordinates"
            value={formData.coordinates}
            onChange={handleChange}
            error={Boolean(errors.coordinates)}
            helperText={errors.coordinates}
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: "primary.main" }}>
                  <GrLocation size={24} />
                </InputAdornment>
              ),
            }}
            required
            fullWidth
          />
        </Box>

        {/* Entity Name with Autocomplete */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Entity Name {requiredField(!entityID)}
          </Typography>
          <Autocomplete
            options={entity_list?.entity_list || []} // Ensure options is an array
            getOptionLabel={(option) => option.entity_name}
            onInputChange={(event, value) => handleSearch(value)} // Update search keyword
            onChange={(event, newValue) =>
              setEntityID(newValue ? newValue?._id : "")
            } // Store selected entity's id
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search and select entity name"
                fullWidth
              />
            )}
          />
        </Box>

        <Typography variant="body2" color={"text.faded"}>
          The unit in creation will be placed under this parent
        </Typography>

        {/* Image */}
        <ImageUplaod image={setUnitImage} setImage={setUnitImage} />
      </Box>
    </DynamicDrawer>
  );
};

export default UnitsDrawer;
