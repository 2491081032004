import {
  SET_TOPIC_SELECTED,
  RESET_ESG_STRATEGY,
  SET_TOPIC_STATE,
  SET_CURRENT_TOPIC,
  SET_IS_EDIT_ACTIVITY,
  SET_IS_EDIT_KPI,
  SET_TOPIC_DETAILS,
  SET_ASSIGNED_TOPICS,
  SET_ASSIGNED_MEMBERS,
  SET_UNITS,
} from "../../constants/esgStategy/esgStrategyConst";

const initial_state = {
  selectedTopics: [],
  topicState: 0,
  currentTopic: "",
  topicDetails: "",
  isEditActivity: false,
  isEditKPI: false,
  assignedTopics: [],
  assignedMembers: [],
  units: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_TOPIC_SELECTED:
      return { ...state, selectedTopics: action.payload };
    case SET_TOPIC_STATE:
      return { ...state, topicState: action.payload };
    case SET_CURRENT_TOPIC:
      return { ...state, currentTopic: action.payload };
    case SET_TOPIC_DETAILS:
      return { ...state, topicDetails: action.payload };
    case SET_IS_EDIT_ACTIVITY:
      return { ...state, isEditActivity: action.payload };
    case SET_IS_EDIT_KPI:
      return { ...state, isEditKPI: action.payload };
    case SET_ASSIGNED_TOPICS:
      return { ...state, assignedTopics: action.payload };
    case SET_ASSIGNED_MEMBERS:
      return { ...state, assignedMembers: action.payload };
    case SET_UNITS:
      return { ...state, units: action.payload };
    case RESET_ESG_STRATEGY:
      return (state = {
        ...state,
        selectedTopics: [],
      });
    default:
      return state;
  }
}
