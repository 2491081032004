import { Box, Divider, Stack, Typography, TextField } from "@mui/material";
import RadioButtonsGroup2 from "components/UI/phase-3/RadioButtonGroup/RadioButtonGroup2";

const SingleQuestions = ({
  quesNo = 1,
  question = "Your Question Here?",
  opts = [],
  submittedAns,
  optChange = () => {},
  showComment,
  comment = "",
  commentChange,
}) => {
  const handleCommentChange = (e) => {
    const comment = e.target.value;
    if (Boolean(commentChange)) {
      commentChange(comment);
    }
  };

  return (
    <Box>
      <Box
        display={"flex"}
        gap={4}
        p={2}
        border={"1px solid"}
        borderColor={"action.disabledBackground"}
        borderRadius={1}
      >
        {/* Left side */}
        <Typography variant="body1">Q{quesNo}.</Typography>

        {/* Right side */}
        <Stack spacing={1}>
          <Typography variant="body1">{question}</Typography>
          <Divider />
          <RadioButtonsGroup2
            option={opts}
            optChange={optChange}
            value={submittedAns} // Pass the selected value here
          />
        </Stack>
      </Box>

      {/* Comment */}
      {showComment && (
        <Box mt={2}>
          <Typography variant="h3" fontWeight={500} gutterBottom>
            Add a comment
          </Typography>

          <TextField
            id="CommentBox"
            placeholder="Write your comment here..."
            value={comment}
            onChange={handleCommentChange}
            minRows={2}
            multiline
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};
export default SingleQuestions;
