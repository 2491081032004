import React from "react";
import CompanyProfile from "./CompanyProfile";
import DimensionChart from "./DimensionChart";
import { BlueBackgroundCard } from "../apiConnection/ApiConnection";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
const usestyles = makeStyles(() => ({
  topicHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 10px",
    position: "relative",
  },
  signleTopicTypeContainer: {
    display: "flex",
    alignItems: "center",
  },
  topicTypeCount: {
    padding: "0px 8px",
    color: "#9FA1AF",
    fontWeight: "600",
  },
  size: { fontSize: 46 },
  Typography: {
    overflowWrap: "anywhere",
    fontWeight: "500",
  },
  typonumb: {
    fintSize: 24,
    fontWeight: 600,
    marginLeft: "auto",
  },
  divider: {
    width: 2,
    height: 22,
    backgroundColor: "#9FA1AF",
    borderRadius: 1,
    margin: "0px 5px",
  },
}));
function SelfAssessmentSurvey(props) {
  const { self_assessment } = props?.selfAssessmentScore || {};

  let classes = usestyles();
  return (
    <div className="scroll" style={{ height: "55vh" }}>
      <div style={{ display: "flex", gap: 10 }}>
        <div style={{ width: "40%" }}>
          <BlueBackgroundCard heading={"Assessment Score"} />
          <CompanyProfile scores={self_assessment?.final_score || 0} />
        </div>
        <div style={{ width: "60%" }}>
          <BlueBackgroundCard heading={"Dimentions"} />
          <DimensionChart
            isProfile
            {...props}
            scores={self_assessment?.final_category_scores || {}}
          />
        </div>
      </div>
      <div style={{ padding: "15px 0px" }}>
        <Grid item md={12} container wrap="wrap" spacing={2}>
          {[
            "Environment",
            "Social Capital",
            "Leadership & Governance",
            "Business Model & Innovation",
            "Human Capital",
          ].map((topic, idx) => {
            return (
              <React.Fragment key={idx}>
                {topic === "Environment" && (
                  <Grid item xs={6} md={4}>
                    <div className={classes.signleTopicTypeContainer}>
                      <Environmental className={classes.size} />
                      <div className={classes.divider}></div>

                      <Typography className={classes.Typography}>
                        Environmental
                      </Typography>
                      <Typography className={classes.typonumb}>
                        {self_assessment?.final_category_scores[topic] || 0}
                      </Typography>
                    </div>
                  </Grid>
                )}
                {topic === "Social Capital" && (
                  <Grid item xs={6} sm={3} md={4}>
                    <div className={classes.signleTopicTypeContainer}>
                      <Social className={classes.size} />
                      <div className={classes.divider}></div>
                      <Typography className={classes.Typography}>
                        Social
                      </Typography>
                      <Typography className={classes.typonumb}>
                        {self_assessment?.final_category_scores[topic] || 0}
                      </Typography>
                    </div>
                  </Grid>
                )}
                {topic === "Leadership & Governance" && (
                  <Grid item xs={6} sm={3} md={4}>
                    <div className={classes.signleTopicTypeContainer}>
                      <Governance className={classes.size} />
                      <div className={classes.divider}></div>
                      <Typography className={classes.Typography}>
                        Governance
                      </Typography>
                      <Typography className={classes.typonumb}>
                        {self_assessment?.final_category_scores[topic] || 0}
                      </Typography>
                    </div>
                  </Grid>
                )}
                {topic === "Business Model & Innovation" && (
                  <Grid item xs={6} sm={3} md={4}>
                    <div className={classes.signleTopicTypeContainer}>
                      <Business className={classes.size} />
                      <div className={classes.divider}></div>
                      <Typography className={classes.Typography}>
                        Business
                      </Typography>
                      <Typography className={classes.typonumb}>
                        {self_assessment?.final_category_scores[topic] || 0}
                      </Typography>
                    </div>
                  </Grid>
                )}
                {topic === "Human Capital" && (
                  <Grid item xs={6} md={4} lg={4}>
                    <div className={classes.signleTopicTypeContainer}>
                      <Human className={classes.size} />
                      <div className={classes.divider}></div>
                      <Typography className={classes.Typography}>
                        Human capital
                      </Typography>
                      <Typography className={classes.typonumb}>
                        {self_assessment?.final_category_scores[topic] || 0}
                      </Typography>
                    </div>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}

export default SelfAssessmentSurvey;
