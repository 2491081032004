import { useState, useMemo } from "react";
import debounce from "lodash.debounce";

export const usePaginationSearch = (searchDebounce) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);

  const limit = 5;

  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  // Use useMemo to create a debounced version of handleSearch if searchDebounce is true
  const debouncedHandleSearch = useMemo(() => {
    return searchDebounce
      ? debounce((keyword) => setKeyword(keyword), 500)
      : (keyword) => setKeyword(keyword);
  }, [searchDebounce]);

  const handleSearch = (keyword) => {
    debouncedHandleSearch(keyword);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  };
};
