// ----------------------------------------------------------------------

export default function Radio(theme) {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          color: theme.palette.text.primary, // Default color when not selected
          // "&:hover": {
          //   backgroundColor: "transparent", // Prevents the default hover background color
          // },
          svg: {
            fontSize: 24,
            "&[font-size=small]": {
              fontSize: 20,
            },
          },
        },
      },
    },
  };
}
