import React from "react";

import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import noData from "images/NoData.png";
const brmRightStyles = makeStyles(() => ({
  cardContainerDefault: {
    width: "100%",
    height: "8.4rem",
    // height: "125px" || '8.4rem',
    background: "#FDFDFD",
    border: "1px solid #C0D4E9",
    borderRadius: "8px",
    marginBottom: "16px",
  },

  mainHeadingDefault: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#242424",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    marginBottom: 8,
  },

  subHeadingDefault: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#BBBBBB",
    letterSpacing: "0.0025em",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: "14px",
    // cursor: "pointer",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    // marginBottom: '15px',
    borderRadius: 5,
    height: "100%",
  },
}));

export function NoDataComponent({
  imgSrc = noData,
  mainTitle,
  subTitle,
  buttonTex,
  handleButtonClick,
}) {
  const classes = brmRightStyles();
  return (
    <div
      className="scroll"
      style={{
        height: "59vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "70%",
        }}
      >
        <img
          className={classes.emptyImg}
          style={{ width: "40%" }}
          src={imgSrc}
          alt="No dataImage"
        />
        <Typography
          style={{ fontSize: "16px", fontWeight: 500, textAlign: "center" }}
          className={classes.text}
          variant="body1"
        >
          {mainTitle}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "#878787" }}
          className={classes.subText}
        >
          {subTitle}
        </Typography>
        {buttonTex && (
          <Grid container justifyContent="center">
            <Button
              className={classes.uploadBtn}
              variant="contained"
              color="primary"
              onClick={() => {
                handleButtonClick(true);
              }}
              size="small"
            >
              {buttonTex}
            </Button>
          </Grid>
        )}
      </div>
    </div>
  );
}
