import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import makeStyles from "@mui/styles/makeStyles";
import "../../../styles/hidescroll.css";
import Typography from "@mui/material/Typography";
import framework from "../../../images/framework.png";
import { id } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  hover_card: {
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "&:hover": {
      backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
      padding: 2,
    },
  },
  listItem: {
    background: "white",
    height: "85px",
    cursor: "pointer",
  },
  ListItemSelected: {
    backgroundImage: "linear-gradient(to right, #47BB76, #3374B9)",
    padding: 2,
    height: "85px",
    cursor: "pointer",
  },
  avatar: {
    width: 70,
    height: 70,
    marginRight: 15,
  },
  typography: {
    fontSize: 12,
    fontWeight: "600",
  },
}));

export default function FrameworkContent(props) {
  const classes = useStyles();
  const { setFrameworkId, frameworkId } = props;
  const handleListItemClick = (value, defaultFilter, index) => {
    setFrameworkId && setFrameworkId(value);
    props?.onClose && props?.onClose(value, defaultFilter, index);
  };

  return (
    <>
      <Grid container style={{ marginTop: "12px 0px" }}>
        <Grid container item xs={12} spacing={2}>
          {props.framework.length > 0 ? (
            props.framework.map((content, id) => (
              <Grid item xs={12} sm={6} md={6} key={id}>
                <Card className={classes.hover_card} variant="outlined">
                  <CardActionArea>
                    <List>
                      <ListItem
                        key={id}
                        className={
                          frameworkId && frameworkId === content._id
                            ? classes.ListItemSelected
                            : classes.ListItems
                        }
                        name={
                          content.framework_name !== ""
                            ? content.framework_name
                            : "sustainability Accounting Standards Board"
                        }
                        onClick={() =>
                          handleListItemClick(content._id, content.default, id)
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt="frameworkpic"
                            className={classes.avatar}
                            src={
                              content.image && Array.isArray(content?.image)
                                ? content?.image[0]
                                : content?.image || ""
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            content.framework_name !== "" ? (
                              <Typography className={classes.typography}>
                                {" "}
                                {content.framework_name}{" "}
                              </Typography>
                            ) : (
                              <Typography>
                                Sustainability Accounting Standards Board
                              </Typography>
                            )
                          }
                        />
                      </ListItem>
                    </List>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sm={6}>
              <Card className={classes.hover_card} variant="outlined">
                <Typography style={{ textAlign: "center" }}>
                  No framework
                </Typography>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
