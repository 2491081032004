import { Box, Typography } from "@mui/material";
import React from "react";
import HorizontalLine from "../horizontalLine/HorizontalLine";

function Heading({
  text = "Type your heading here, add a text attribute to your heading component",
  horizontalLine,
  size = "14px",
  color = "#222",
  marginBottom = "14px",
  marginTop = 0,
  weight = 600,
  bg,
  icon = false,
  children,
}) {
  return (
    <Box
      style={{
        marginBottom: marginBottom,
        marginTop: marginTop,
        backgroundColor: bg ? "#fff" : "",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontWeight: weight,
            fontSize: size,
            color: color,
            marginBottom: horizontalLine ? "5px" : 0,
          }}
        >
          {icon && icon}
          {text}
        </Typography>
        {children}
      </Box>

      {horizontalLine && <HorizontalLine />}
    </Box>
  );
}

export default Heading;
