import { IconButton } from "@mui/material";

import DynamicTableFull from "components/UI/phase-3/dynamicTable/DynamicTableFull";
import { IoIosArrowForward } from "react-icons/io";

const tableHead = [
  { name: "Asset Name" },
  { name: "Asset ID" },
  { name: "Entity/Unit Type" },
  { name: "Entity/Unit Name" },
  { name: "Handled By" },
  { name: "View" },
];

const tableData = [
  {
    _id: "66ac5f35c695763e87bb8d62",
    asset_name: "Assembly Line Machinery",
    asset_id: "A-3820",
    entity_unit_id: "O-001",
    entity_unit_name: "Bengaluru Corporate Office",
    handled_by: "Vikram Kumar",
  },
];

const tableRowOrder = [
  "asset_name",
  "asset_id",
  "entity_unit_id",
  "entity_unit_name",
  "handled_by",
];

const customAction = (row) => (
  <IconButton
    aria-label="CustomAction"
    size="small"
    sx={{ color: "primary.main" }}
  >
    <IoIosArrowForward />
  </IconButton>
);

const AssetsTab = () => {
  return (
    <DynamicTableFull
      tableHead={tableHead}
      tableData={tableData}
      tableRowOrder={tableRowOrder}
      renderAction={customAction}
    />
  );
};
export default AssetsTab;
