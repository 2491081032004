import { Box, Chip, Typography } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import RadioButtonsGroup2 from "components/UI/phase-3/RadioButtonGroup/RadioButtonGroup2";
import { NoDataComponent } from "./shareSurvey/NoData";

const MaturitySurveyListing = ({ maturity_survey }) => {
  return (
    <Box
      sx={{
        overflow: "scroll",
        height: "85%",
      }}
    >
      {maturity_survey?.length !== 0 ? (
        maturity_survey?.map((data, index) => {
          const hasMainTopic = data.hasOwnProperty("topic");
          return (
            <>
              {hasMainTopic && (
                <Heading
                  text={`${index + 1}.  ${data?.topic}`}
                  size="14px"
                  weight={600}
                  horizontalLine={true}
                />
              )}
              {hasMainTopic ? (
                data?.questions.map((data) => (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "space-between",
                        alignItems: "center",
                        pointerEvents: "none",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          border: "1px solid #E9E9E9",
                          borderRadius: "8px",
                          padding: "16px",
                        }}
                      >
                        {data?.question}
                      </Typography>
                      <Chip
                        sx={{
                          backgroundColor: "#EBF1F8",
                          padding: "6px 10px",
                          fontSize: "12px",
                          color: "#3374B9",
                          borderRadius: "80px",
                        }}
                        label={data?.weightage}
                      />
                    </Box>
                    <Box sx={{ padding: "10px" }}>
                      <RadioButtonsGroup2 option={data?.option} />
                    </Box>
                  </>
                ))
              ) : (
                <div key={data?._id}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "space-between",
                      alignItems: "center",
                      pointerEvents: "none",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        border: "1px solid #E9E9E9",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      {data?.name}
                    </Typography>
                    {data?.weightage && (
                      <Chip
                        sx={{
                          backgroundColor: "#EBF1F8",
                          padding: "6px 10px",
                          fontSize: "12px",
                          color: "#3374B9",
                          borderRadius: "80px",
                        }}
                        label={data?.weightage}
                      />
                    )}
                  </Box>
                  <Box sx={{ padding: "10px" }}>
                    <RadioButtonsGroup2 option={data?.ans} />
                  </Box>
                </div>
              )}
              {hasMainTopic && (
                <Box sx={{ padding: "10px" }}>
                  <InputField
                    heading={"Risk to this topic"}
                    label={"Enter here"}
                    rows={3}
                  />
                  <InputField
                    heading={"Opportunities to this topic"}
                    label={"Enter here"}
                    rows={3}
                  />
                  <InputField
                    heading={"Type of Approach"}
                    label={"Enter here"}
                    rows={3}
                  />
                  <InputField
                    heading={"Type of Mitigation"}
                    label={"Enter here"}
                    rows={3}
                  />
                </Box>
              )}
            </>
          );
        })
      ) : (
        <NoDataComponent mainTitle={"Currently there are no surveys found"} />
      )}
    </Box>
  );
};
export default MaturitySurveyListing;
