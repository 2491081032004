import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import useDebounce from "../../../../../customHooks/useDebouncec";
import { CustomDataInput } from "./SelectChartType";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

export default function LimitComponent({
  setLimit,
  updatePivotConfig,
  setPivotConfiq,
  availableMembers,
}) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (e) => {
    setValue(Number(e.target.value));
  };

  useDebounce(
    () => {
      value >= 0 && setLimit(value);
      setPivotConfiq({ ...availableMembers, limit: value });

      updatePivotConfig.update({ ...availableMembers, limit: value });
    },
    2000,
    [value]
  );

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <CustomDataInput
        type="number"
        id="outlined-basic"
        // label="Set Limits for Charts Rows"
        variant="outlined"
        name="value"
        value={value}
        onChange={handleChange}
      />
    </form>
  );
}
