import React from "react";
import { connect } from "react-redux";
import DataHubSurveyScreen from "../../components/screen/dataHubSurvey/dataHubSurveyScreen";
import {
  setImportFileData,
  getDataHubChart,
  updateDashboardLayout,
} from "../../actions/datahubActions/datahubActions";

export class DataHubSurveyCont extends React.PureComponent {
  render() {
    console.log("survey const");
    return <DataHubSurveyScreen {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    dataHub: store.dataHub,
    login: store.login,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setImportFileData: (payload) => {
      dispatch(setImportFileData(payload));
    },
    getDataHubChart: (token, organisation_id) => {
      dispatch(getDataHubChart(token, organisation_id));
    },
    updateDashboardLayout: (token, organisation_id, new_layout) => {
      dispatch(updateDashboardLayout(token, organisation_id, new_layout));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataHubSurveyCont);
