import { Button, Typography, Breadcrumbs } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const DataHubSurveyCrumb = ({ crumbTabs, back }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "2% 2% 1% 5%",
        alignItems: "center",
      }}
    >
      {crumbTabs?.length > 0 && (
        <>
          <Button
            variant="outlined"
            style={{
              background: "#F0F6FD",
              border: "1px solid #3374B9",
              borderRadius: "5px",
              textAlign: "center",
              height: "40px",
              width: "40px", // Adjusted width to better fit the icon
              marginRight: "20px",
            }}
            onClick={back}
          >
            <ArrowBackIcon fontSize="large" style={{ padding: "5px" }} />
          </Button>

          <Breadcrumbs
            separator={
              <NavigateNextIcon
                style={{ color: "#76A2D0" }}
                fontSize="medium"
              />
            }
            aria-label="breadcrumb"
          >
            {crumbTabs.map((tab, index) => (
              <Typography
                key={index}
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "22px",
                  lineHeight: "28px",
                  color: "#15314E",
                }}
                color="textPrimary"
              >
                {tab}
              </Typography>
            ))}
          </Breadcrumbs>
        </>
      )}
    </div>
  );
};

export default DataHubSurveyCrumb;
