import { Divider } from "@mui/material";

function HorizontalLine({ color = "#A1BFDF" }) {
  return (
    <div>
      <Divider sx={{ borderColor: color }} />
    </div>
  );
}

export default HorizontalLine;
