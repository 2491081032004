import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import InfoIcon from "@mui/icons-material/Info";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import clsx from "clsx";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import materialityAssessmentLeftStyles from "./materialityTopicStyles";
import {
  Social,
  Human,
  Business,
  Environmental,
  Governance,
} from "utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { useMaterialityTopic } from "../../../APIs/HandleTopicApi";

export const TopicBar = (props) => {
  const classes = materialityAssessmentLeftStyles();

  const [currentIndex, setCurrentIndex] = useState("");
  const [isInfo, setIsInfo] = useState(false);
  const { topic, source, index, handleAddTopic, materialityMainTopics } = props;
  let typeOfTopic = materialityMainTopics?.filter(
    (main) => main._id + "" === topic.parent_id + ""
  );
  return (
    <>
      <div
        className={classes.topicBarContainer}
        onMouseLeave={() => {
          setIsInfo(false);
        }}
        onMouseEnter={() => {
          setCurrentIndex(index);
        }}
        style={{
          backgroundColor: source === "right" && "#EBF1F8",
          transition: "all 0.5s ease",
        }}
      >
        <div className={classes.topicBarContents}>
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Social Capital" && (
              <Social style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Environment" && (
              <Environmental style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Leadership & Governance" && (
              <Governance style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Human Capital" && (
              <Human style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Business Model & Innovation" && (
              <Business style={{ fontSize: 46 }} />
            )}
          <div
            className={clsx(!isInfo && classes.topicContentDivider, {
              [classes.topicContentDividerInfo]: isInfo,
            })}
          ></div>
          <div style={{ maxWidth: 500 }}>
            <Typography className={classes.topicname}>
              {topic?.text || topic?.topic_name}
            </Typography>
            {index === currentIndex && isInfo && (
              <>
                {topic?.topic_info !== "" ? (
                  <Typography className={classes.selectTopicText}>
                    Note: {topic?.topic_info}
                  </Typography>
                ) : (
                  <Typography className={classes.selectTopicText}>
                    Topic info not available
                  </Typography>
                )}
              </>
            )}
          </div>
        </div>
        <div className={classes.mainDiv}>
          {source !== "right" && (
            <>
              <div>
                <IconButton
                  name="expandTopic"
                  className={classes.iconContainer}
                  onClick={() => {
                    setIsInfo(!isInfo);
                  }}
                  size="large"
                >
                  <InfoIcon className={classes.iconColor} />
                </IconButton>
              </div>
              <div>
                <IconButton
                  name="addTopic"
                  className={clsx(classes.iconContainer, classes.iconColor)}
                  onClick={() => handleAddTopic(topic?._id)}
                  size="large"
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
            </>
          )}
          {source === "right" && (
            <div>
              <IconButton
                name="addTopic"
                className={clsx(classes.iconContainer, classes.iconColor)}
                onClick={() => handleAddTopic(topic?.materiality_topic_id)}
                size="large"
              >
                <RemoveCircleRoundedIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
