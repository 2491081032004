import React, { useState } from "react";

import { Box, TextField } from "@mui/material";
import Heading from "../heading/Heading";

const Datepicker = ({
  heading,
  label = "Label name",
  marginBottom = "20px",
  marginTop = "10px",
  value,
  handleChange,
  error,
  name,
}) => {
  function formatDate(dateString) {
    if (dateString?.length === 10) {
      // The date is already in "YYYY-MM-DD" format
      return dateString;
    } else if (dateString?.length > 10) {
      // The date is in "YYYY-MM-DDTHH:mm:ss.sssZ" format
      return dateString.slice(0, 10);
    }

    return "";
    // Optional: Handle unexpected formats or throw an error
  }

  return (
    <Box
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        width: "100%",
      }}
    >
      <Heading text={heading} size="13px" marginBottom="10px" color="#999" />
      <TextField
        name={name}
        label={label}
        type="date"
        value={formatDate(value)}
        error={Boolean(error)}
        helperText={error}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        size="small"
      />
    </Box>
  );
};

export default Datepicker;
