import { useState } from "react";

export const useSelectActivity = () => {
  const activities = [
    "Total other non hazardous waste generated locations",
    "Total female bod employees",
    "Total s1 emissions locations",
    "Total bod employees",
    "Total other hazardous waste generated locations",
    "Principle 4 - Leadership Indicators",
    "Total quantity reused",
    "Total other bod employees",
  ];

  const [selectedActivities, setSelectedActivities] = useState([]);

  const handleCheckboxChange = (activity) => {
    if (selectedActivities.includes(activity)) {
      setSelectedActivities((prev) => prev.filter((item) => item !== activity));
    } else {
      setSelectedActivities((prev) => [...prev, activity]);
    }
  };

  const selectAllActivities = () => {
    setSelectedActivities(activities);
  };

  const resetActivites = () => {
    setSelectedActivities([]);
  };

  const disableComplete = selectedActivities?.length === 0;

  return {
    // states
    activities,
    selectedActivities,
    disableComplete,
    // functions
    handleCheckboxChange,
    selectAllActivities,
    resetActivites,
  };
};
