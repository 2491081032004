const { useState } = require("react");

export const useNodeDrawerHook = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return {
    selectedTab,
    handleChange,
  };
};
