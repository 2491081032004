import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import TextField from "../../UI/textfield/textfield";
import SustainabilityTopStyles from "./sustainablityTopStyles";

function SustainabilityTop(props) {
  const ref = useRef();
  const classes = SustainabilityTopStyles();
  const [access, setAccess] = useState(false);
  const [assessment_name, setAssessment] = useState(
    props?.sustainabilityReport?.reportName || "new report"
  );

  console.log(assessment_name);

  useEffect(() => {
    if (props?.sustainabilityReport?.reportName !== "") {
      setAssessment(props?.sustainabilityReport?.reportName || "new report");
    } else {
      setAssessment("Untitled");
    }
  }, [props?.sustainabilityReport?.reportName]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (access && ref?.current && !ref.current.contains(e.target)) {
        handelUpdate();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [access]);

  const handelUpdate = () => {
    // props.updateReportName(
    // 	props.login.token,
    // 	props.assessment_id,
    // 	6,
    // 	assessment_name,
    // 	props.sustainabilityReport.jwt_has
    // );
    setAccess(false);
  };

  return (
    <Grid container>
      <Grid item md={12} className={classes.mainGrid}>
        <Box className={classes.subGrid}>
          <div className={classes.listDiv}>
            <Icon>list_alt</Icon>
          </div>
          {access === true ? (
            <>
              {assessment_name === props?.sustainabilityReport?.reportName ? (
                <Box
                  display="flex"
                  alignItems="center"
                  ref={ref}
                  className={classes.boxWidth}
                >
                  <TextField
                    placeholder="Assessment"
                    value={assessment_name}
                    size="small"
                    variant="outlined"
                    onChange={(event) => setAssessment(event.target.value)}
                    className={classes.boxWidth}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  onBlur={() => handelUpdate()}
                  className={classes.boxWidth}
                >
                  <TextField
                    name="pdfName"
                    placeholder="Assessment"
                    value={assessment_name}
                    size="small"
                    variant="outlined"
                    onChange={(event) => setAssessment(event.target.value)}
                    className={classes.boxWidth}
                  />
                </Box>
              )}
            </>
          ) : (
            <Typography variant="subtitle1" onClick={() => setAccess(true)}>
              {assessment_name}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default React.memo(SustainabilityTop);
