import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DataHubBreadCrumbs from "./dataHubBreadCrumbs";
import DataHubTabs from "./dataHubTabs";
import DataHubButtons from "./dataHubButtons";
import DataHubSurvey from "../../widgets/dataHubSurvey/dataHubSurvey";
import DatahubApi from "../../widgets/dataHubApi/datahub_API";
import DataHubSheets from "../../widgets/dataHubSheets/dataHubSheets";
import DataSetsMain from "../../widgets/dataHubDataSets/DataSetsMain";

const TABS_ARR = [
  { value: 0, tab: "Sheets Import" },
  { value: 1, tab: "API Integration" },
  { value: 2, tab: "Survey" },
  { value: 3, tab: "Data Sets" },
];

function DataHubScreen(props) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    // match,
    // history,
    login,
    addTags,
    dataHub,
    getTags,
    uploadImportFile,
    getImportFileList,
    deleteTags,
    getImportFileData,
    getMatatikaJobStatus,
    restartMatatikaJob,
  } = props;

  // console.log(props)

  const pathNames = location.pathname.split("/").filter((x) => x);

  // const { params } = match || {};
  const { page } = params || {};
  const { token, selectedOrganization } = login;
  const tabNameToIndex = {
    0: "sheets_import",
    1: "api_integration",
    2: "survey",
    3: "data_sets",
  };
  const indexToTabName = {
    sheets_import: 0,
    api_integration: 1,
    survey: 2,
    data_sets: 3,
  };

  const [selectedTab, setSelectedTab] = React.useState(
    indexToTabName[page] || 0
  );
  const [tabName, setTabName] = React.useState("Sheets Import");
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isIntegrationDrawerOpen, setIsIntegrationDrawerOpen] = useState(false);

  const handleChange = (event, newValue) => {
    navigate(`/data_hub/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
    setTabName(event.target.innerText);
  };

  const uploadHandler = (e) => {
    // const file = e.target.files[0];
    // file !== undefined && setUploadFile((preValue) => [...preValue, file]);
    if (selectedTab === 0) {
      setIsDrawerOpen((preValue) => !preValue);
      !isDrawerOpen && getTags(token, selectedOrganization._id);
    } else if (selectedTab === 1) {
      setIsIntegrationDrawerOpen((preValue) => !preValue);
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Typography
        component="div"
        style={{
          backgroundColor: "#F3F4F4",
          minHeight: "91vh",
          margin: "-10px",
        }}
      >
        <DataHubBreadCrumbs tabName={tabName} pathNames={pathNames} />

        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            style={{ marginLeft: "9%", marginTop: "1%" }}
          >
            <DataHubTabs
              handleTabs={handleChange}
              value={selectedTab}
              tabs={TABS_ARR}
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "25px",
            }}
          >
            <DataHubButtons uploadHandler={uploadHandler} value={selectedTab} />
          </Grid>
        </Grid>

        {selectedTab === 0 && (
          <DataHubSurvey
            login={login}
            addTags={addTags}
            dataHub={dataHub}
            isDrawerOpen={isDrawerOpen}
            handleDrawer={uploadHandler}
            uploadImportFile={uploadImportFile}
            getImportFileList={getImportFileList}
            deleteTags={deleteTags}
            getImportFileData={getImportFileData}
            getMatatikaJobStatus={getMatatikaJobStatus}
            restartMatatikaJob={restartMatatikaJob}
          />
        )}
        {selectedTab === 1 && (
          <DatahubApi
            handleDrawer={uploadHandler}
            isIntegrationDrawerOpen={isIntegrationDrawerOpen}
            setIsIntegrationDrawerOpen={setIsIntegrationDrawerOpen}
          />
        )}
        {/* Datahub Survey */}
        {selectedTab === 2 && <DataHubSheets />}
        {selectedTab === 3 && <DataSetsMain />}
      </Typography>
    </Container>
  );
}

export default React.memo(DataHubScreen);
