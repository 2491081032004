import React from "react";
import { useSelector } from "react-redux";
import { withStyles, makeStyles } from "@mui/styles";

import { Grid, Typography, TableCell } from "@mui/material";
import { EnvironmentalKpiChart } from "./KpiChart";

import { dummyesgScores } from "./riskUtils";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";
// import {
//   BlueBackgroundCard,
//   KpiPresentation,
// } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
// import { HeadingCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompany/EsgGptCompanyInfo";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EBF1F8",
    // color: theme.palette.common.black,
    fontWeight: 500,
    color: "#15314E",
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    color: "#15314E",
  },
}))(TableCell);

function createData(srNo, name, calories, fat, carbs, protein) {
  return { srNo, name, calories, fat, carbs, protein };
}

const rows = [
  createData("1. ", "Our People"),
  createData(
    "",
    "People with a Substantive Life Improvement (cumulative rounded)5",
    "3,588,000",
    "4,598,000",
    "5,878,000"
  ),
  createData("1.1 ", "Contribution to region"),
  createData("", "North America", "$15,973", "$18,925", "$42,239"),
  createData("", "Europe", "15,973", "18,925", "42,239"),
  createData("", "Cross-Region", "15,973", "18,925", "42,239"),
  createData("", "Growth Markets", "15,973", "18,925", "42,239"),
  createData("", "Total  Contributions", "15,973", "18,925", "42,239"),
  createData("1.2 ", "Contribution to region"),
  createData("", "Cash", "$15,973", "$18,925", "$42,239"),
  createData(
    "",
    "In-Kind (Accenture Development Partnerships and pro bono consulting)",
    "15,973",
    "18,925",
    "42,239"
  ),
  createData(
    "",
    "Cross-Time (Paid volunteering)",
    "15,973",
    "18,925",
    "42,239"
  ),
  createData("2. ", "community impact"),
  createData(
    "",
    "Global Headcount at Fiscal Year End (rounded)",
    "3,588,000",
    "4,598,000",
    "5,878,000"
  ),
  createData("2.1 ", "Employee Workforce Percentage by Gender2"),
  createData("", "North America", "$15,973", "$18,925", "$42,239"),
  createData("", "Total  Contributions", "15,973", "18,925", "42,239"),
  createData("2.2 ", "Contribution to region"),
  createData("", "Cash", "$15,973", "$18,925", "$42,239"),
  createData(
    "",
    "Cross-Time (Paid volunteering)",
    "15,973",
    "18,925",
    "42,239"
  ),
  createData("2.3 ", "Employee Workforce Percentage by Gender2"),
  createData("", "North America", "$15,973", "$18,925", "$42,239"),
  createData("", "Total  Contributions", "15,973", "18,925", "42,239"),
  createData("2.4 ", "Contribution to region"),
  createData("", "Cash", "$15,973", "$18,925", "$42,239"),
  createData(
    "",
    "Cross-Time (Paid volunteering)",
    "15,973",
    "18,925",
    "42,239"
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    marginTop: "10px",
    width: "100%",
  },
  tableCell: {
    padding: "5px",
  },
  titleRow: {
    backgroundColor: "#F6F6F6",
  },
});

export default function EsgKpi({ scores, riskEvaluator }) {
  const classes = useStyles();
  //   const { organizationDetails } = useSelector((state) => state.brmReducer);
  //   const { esgScore } = useSelector((state) => state?.esgDiagnosis);

  const { energy_kpis, water_kpis } = dummyesgScores || {};
  return (
    <>
      <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
        ESG KPI's
      </Typography>
      {Object.keys(dummyesgScores || {}).length > 0 ? (
        <div className="scroll" style={{ height: "59.5vh" }}>
          <Grid md={12} sm={12}>
            <EnvironmentalKpiChart
              db={true}
              energy_kpis={energy_kpis}
              water_kpis={water_kpis}
              isin={"123456"}
            />
          </Grid>
        </div>
      ) : (
        <div className="scroll" style={{ height: "59.5vh" }}>
          <NoDataComponent mainTitle={`No KPI's Found`} />
        </div>
      )}
    </>
  );
}
