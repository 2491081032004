import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomTextfield from "../../../components/UI/textfield/textfield";
import { useLocation, useNavigate } from "react-router-dom";
import { passwordValidation } from "../../../actions/regexValidation/regexValidation";
import makeStyles from "@mui/styles/makeStyles";

export const resetPassword = makeStyles((theme) => ({
  mainGrid: {
    height: "100vh",
    backgroundColor: "#21134A",
  },
  card: {
    borderRadius: 10,
  },
  cardTypography: {
    margin: "10px 0px 20px 0px",
  },
  cardTextField: {
    width: "100%",
  },
  button: {
    margin: "40px 0px 20px 0px",
  },
}));

function ResetPassword(props) {
  const classes = resetPassword();
  const { resetForgetPassword } = props;

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let passcode = useQuery().get("resetcode");
  let navigate = useNavigate();

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.mainGrid}
      >
        <Grid item xs={8} md={5} lg={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.cardTypography}>
                RESET PASSWORD
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Please enter the password below and click on reset to change the
                password
              </Typography>
              <CustomTextfield
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                margin="dense"
                variant="outlined"
                size="small"
                label="Password"
                className={classes.cardTextField}
                value={password}
                error={password !== "" && !passwordValidation(password)}
                helperText="At least 8 character, 1 uppercase, 1 lowercase, 1 number and 1 symboll."
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityIcon fontSize="small" color="disabled" />
                      ) : (
                        <VisibilityOffIcon fontSize="small" color="disabled" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <CustomTextfield
                id="Confirm_password"
                name="Confirm_password"
                type={showPassword ? "text" : "password"}
                margin="dense"
                variant="outlined"
                size="small"
                label="Confirm Password"
                className={classes.cardTextField}
                value={confirmPassword}
                error={confirmPassword !== "" && confirmPassword !== password}
                helperText={
                  confirmPassword !== "" &&
                  confirmPassword !== password &&
                  "Password and confirm password doesn't match."
                }
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={
                  password !== confirmPassword ||
                  (password === "" && confirmPassword === "") ||
                  !passwordValidation(password)
                    ? true
                    : false
                }
                onClick={() => {
                  resetForgetPassword(password, passcode);
                  navigate("/login");
                }}
              >
                Reset Password
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
export default React.memo(ResetPassword);
