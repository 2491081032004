import { useMemo } from "react";

import { Box, Button, Grid } from "@mui/material";
import Datepicker from "components/UI/phase-3/datePicker/Datepicker";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import NoListItem from "components/widgets/phase-3/MaterialityMapping/selectToppic/left/component/NoListItem";

import { usePaginationSearch } from "customHooks/usePaginationSearch";
import useFormValidation from "customHooks/useFormValidation";
import { useEsgStrategyPlanningTopic } from "../../APIs/HandleTopicApi";
import DefineAgainstLists from "./DefineAgainstLists";

function DatKpis() {
  const { openDrawer, toggleDrawer } = usePaginationSearch();

  const initialState = useMemo(
    () => ({
      title: "",
      baseline: "",
      target: "",
      unit: "",
      start_date: "",
      end_date: "",
      currency: "",
      budget: "",
      comment: "",
    }),
    []
  );

  const validationRules = useMemo(
    () => ({
      title: { required: true },
      baseline: { required: true },
      target: { required: true },
      unit: { required: true },
      start_date: { required: true },
      end_date: { required: true },
      currency: { required: true },
      budget: {
        required: true,
      },
      comment: {
        required: false,
      },
    }),
    []
  );

  const { formData, errors, handleChange, validationErrors, setFormData } =
    useFormValidation(initialState, validationRules);

  const {
    handleUpdateEsgActivity,
    handleAddEsgActivity,
    esgKpi,
    handleDeleteEsgActivity,
  } = useEsgStrategyPlanningTopic(false, null, "KPI");

  const handleSubmit = async () => {
    const response = formData?.kpi_id
      ? await handleUpdateEsgActivity(formData)
      : await handleAddEsgActivity(formData);
    if (response?.status || false) {
      toggleDrawer();
      setFormData(initialState);
    }
  };

  return (
    <>
      {/* Drawer */}
      <DynamicDrawer
        hasDrawerOpen={openDrawer}
        handleClose={() => {
          toggleDrawer();
          setFormData(initialState);
        }}
        heading={formData?.kpi_id ? "Update KPI" : "Add KPI"}
        buttonText={formData?.kpi_id ? "Update KPI" : "Add KPI"}
        disableSubmit={Object.keys(validationErrors).length > 0}
        submitClick={handleSubmit}
      >
        <InputField
          value={formData?.title}
          error={errors.title}
          name={"title"}
          handleChange={handleChange}
          heading={"Name of the KPI"}
          label={"Enter here"}
          marginBottom={"6px"}
          marginTop={5}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <InputField
            value={formData?.baseline}
            error={errors.baseline}
            name={"baseline"}
            handleChange={handleChange}
            heading={"Baseline"}
            label={"Enter here"}
            marginBottom={"6px"}
          />
          <InputField
            value={formData?.target}
            error={errors.target}
            name={"target"}
            handleChange={handleChange}
            heading={"Target"}
            label={"Enter here"}
            marginBottom={"6px"}
          />
          <InputField
            value={formData?.unit}
            error={errors.unit}
            name={"unit"}
            handleChange={handleChange}
            heading={"Add Units"}
            label={"Select here"}
            marginBottom={"6px"}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <Datepicker
            value={formData?.start_date}
            error={errors.start_date}
            name={"start_date"}
            handleChange={handleChange}
            heading={"Start Date"}
            label={"Enter here"}
            marginBottom={"6px"}
          />
          <Datepicker
            value={formData?.end_date}
            error={errors.end_date}
            name={"end_date"}
            handleChange={handleChange}
            heading={"End Date(Expected)"}
            label={"Enter here"}
            marginBottom={"6px"}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <InputField
            value={formData?.currency}
            error={errors.currency}
            name={"currency"}
            handleChange={handleChange}
            heading={"Currency"}
            label={"Select the type of currency"}
            marginBottom={"6px"}
          />
          <InputField
            value={formData?.budget}
            error={errors.budget}
            name={"budget"}
            handleChange={handleChange}
            heading={"Enter Budget Amount"}
            label={"Enter here"}
            marginBottom={"6px"}
          />
        </Box>
        <InputField
          value={formData?.comment}
          error={errors.comment}
          name={"comment"}
          handleChange={handleChange}
          heading={"Additional Comment"}
          label={"Enter here"}
          marginBottom={"6px"}
          rows={6}
        />
      </DynamicDrawer>
      {/* Heading */}
      <Heading text="Key Performance Indicator" marginBottom="0">
        <Button
          variant={"outlined"}
          color={"secondary"}
          startIcon={<AddOutlinedIcon />}
          size={"small"}
          onClick={toggleDrawer}
        >
          Add Kpi
        </Button>
      </Heading>

      {/* main content */}
      <>
        <DefineAgainstLists
          list={esgKpi}
          handleUpdate={setFormData}
          openDrawer={toggleDrawer}
          handleDelete={handleDeleteEsgActivity}
        />
      </>

      {/* no data compoenent */}

      {esgKpi?.length === 0 && <NoListItem message="No Kpi Added" />}
    </>
  );
}

export default DatKpis;
