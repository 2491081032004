import { Box } from "@mui/material";
import Messages from "./chat/Messages";

function ChatMain() {
  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
      gap={2}
      //   p={2}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        {[...Array(2)].map((_, i) => (
          <Messages key={i} />
        ))}
      </Box>
    </Box>
  );
}

export default ChatMain;
