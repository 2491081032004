import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import EsgFoundationProcesses from "./EsgFoundationProcesses";
import EsgDataConsolidation from "./EsgDataConsolidation";
import ImplementationProcess from "./ImplementationProcess";
import EsgWiseAssistant from "./EsgWiseAssistant";

// components

// Array of tab details
const tabs = [
  { label: "ESG Foundation Processes", component: <EsgFoundationProcesses /> },
  { label: "ESG Data Consolidation", component: <EsgDataConsolidation /> },
  { label: "Implementation Process", component: <ImplementationProcess /> },
  { label: "ESG Wise Assistant", component: <EsgWiseAssistant /> },
];

const OnboardingProcess = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="onboarding process tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Box mt={2}>{tabs[selectedTab].component}</Box>
    </Box>
  );
};

export default OnboardingProcess;
