// import {

//   } from '../../constants/generalSettings/generalSettings';

const initial_state = {
  layoutActiveStep: 0,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case "SET_LAYOUT_ACTIVE_STEP":
      return (state = { ...state, layoutActiveStep: action.payload });

    default:
      return state;
  }
}
