import { Box } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import React, { useState } from "react";
import SurveyQuestionsLeft from "./SurveyQuestionsLeft";
import SurveyQuestionsRight from "./SurveyQuestionsRight";

function SurveyQuestions() {
  const [selectedSection, setSelectedSection] = useState(null);

  return (
    <Box>
      <Box>
        <Heading text={"Questions"} size={"15px"} marginBottom={"8px"} bg />
      </Box>
      <Box style={{ display: "flex" }}>
        <SurveyQuestionsLeft
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
        <SurveyQuestionsRight selectedSection={selectedSection} />
      </Box>
    </Box>
  );
}

export default SurveyQuestions;
