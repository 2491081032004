import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function RadioButtonsGroup({
  option = [],
  label = "",
  value = "",
  handleChange,
  name = "radio-buttons-group",
}) {
  return (
    <FormControl>
      {label && (
        <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
      )}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        // defaultValue="female"
        name={name}
        value={value}
        onChange={handleChange}
      >
        {option.map((o) => (
          <FormControlLabel
            value={o?.value || o}
            control={<Radio />}
            label={o?.option || o}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
