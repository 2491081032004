import React from "react";
import { Box } from "@mui/material";

import LeftCard from "./LeftCard";

function SupplierLeft({ component, selectedSubTab, handleSubTabChange }) {
  return (
    <Box sx={{ overflow: "scroll", height: "95%" }}>
      <LeftCard
        toMap={component}
        subTabNo={selectedSubTab}
        handleTabChange={handleSubTabChange}
      />
    </Box>
  );
}

export default SupplierLeft;
