import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ChartRenderer from "./ChartRenderedDashboard";

import DashboardItem from "../../UI/dataHubCalculatedCharts/component/DashboardItem";

import Dashboard from "../../UI/dataHubCalculatedCharts/component/Dashboard";

import {
  getDashboardCharts,
  setKpiDrawerState,
} from "../../../actions/datahubActions/datahubActions";

const defaultLayout = (i) => ({
  x: i?.layout?.x || 0,
  y: i?.layout?.y || 0,

  w: i?.layout?.w || 4,
  h: i?.layout?.h || 8,
  minW: 4,
  minH: 8,
});

function ChartsCompenents({
  isEdit,
  selectedTabChartsData,
  tabName,
  setIsStaticChartKpiDrawerOpen,
  setSelectedhartData,
}) {
  const dispatch = useDispatch();
  const { current_organisation, token } = useSelector((state) => state.login);

  // framework type
  const { selectedKpiType } = useSelector((state) => state.dataHub);

  const TABS_TYPE = {
    Environmental: "environmental",
    "Social Capital": "social_capital",
    Governance: "governance",
    "Human Capital": "human_capital",
    "Business Model & Innovation": "business_model_and_innovation",
  };

  React.useEffect(() => {
    tabName !== "General" &&
      dispatch(
        getDashboardCharts(
          token,
          current_organisation,
          TABS_TYPE[tabName],
          true,
          selectedKpiType
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName, selectedKpiType]);

  return (
    <Dashboard isEdit={isEdit} token={token}>
      {selectedTabChartsData.map((item) => {
        return (
          <div
            key={item._id}
            style={{ height: "300px", width: "49%" }}
            data-grid={defaultLayout(item)}
          >
            <DashboardItem
              isEdit={isEdit}
              chartId={item._id}
              tabType={TABS_TYPE[tabName]}
              //   setSelectedhartData={setSelectedhartData}
              onMoreClick={() => {
                setIsStaticChartKpiDrawerOpen(true);
                dispatch(setKpiDrawerState(true));
                setSelectedhartData(item);
              }}
              title={item.name}
            >
              <ChartRenderer
                vizState={{
                  query: {
                    ...item?.vizState?.query,
                    timeDimensions: item?.vizState?.query.hasOwnProperty(
                      "timeDimensions"
                    )
                      ? item?.vizState?.query?.timeDimensions.length > 0
                        ? [
                            {
                              dimension: `${
                                item?.vizState?.query?.measures[0].split(".")[0]
                              }.date`,
                              granularity: "quarter",
                            },
                          ]
                        : []
                      : [],
                    filters: [
                      {
                        member: `${
                          item?.vizState?.query?.measures[0].split(".")[0]
                        }.organisationid`,
                        operator: "equals",
                        values: [current_organisation],
                      },
                    ],
                  },
                  chartType: item?.vizState?.chartType,
                }}
                pivotConfig={item?.pivotConfig}
                backgroundColor={item?.chartColors?.backgroundColors}
                borderColor={item?.chartColors?.borderColor}
                pieChartCustomize={item?.label}
                // chartY_AxisCustomize={item?.chartY_AxisCustomize}
                baseline={item?.baseline}
                targetline={item?.targetline}
                axisLabels={item?.axisLabels}
              />
            </DashboardItem>
          </div>
        );
      })}
    </Dashboard>
  );
}

export default ChartsCompenents;
