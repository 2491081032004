import { Box } from "@mui/material";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import ComplianceHeader from "components/widgets/phase-3/Task/compliance/ComplianceHeader";
import ComplianceLeft from "components/widgets/phase-3/Task/compliance/left/ComplianceLeft";
import ComplianceRight from "components/widgets/phase-3/Task/compliance/right/ComplinaceRight";

function ComplianceMain() {
  return (
    <Box sx={{ overflow: "hidden" }}>
      {/* Header section */}
      <ComplianceHeader />
      {/* Main content */}
      <Box
        sx={{ height: "63vh", overflow: "scroll", padding: "20px 5px 0px 5px" }}
      >
        <MainLayoutSection
          height="98%"
          left={
            <LeftSectionLayout hasShadow={false}>
              <ComplianceLeft />
            </LeftSectionLayout>
          }
          leftWidth="25%"
          right={
            <RightSectionLayout>
              <ComplianceRight />
            </RightSectionLayout>
          }
          rightWidth="73%"
        />
      </Box>
    </Box>
  );
}

export default ComplianceMain;
