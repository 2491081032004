import Box from "@mui/material/Box";

import { controversyChartData, defaultColors } from "./chartUtils/chartData";

import BarChart from "./chartComponents/BarChart";

const ControversyChart = () => {
  return (
    <Box sx={{ height: "300px", width: "100%" }}>
      <BarChart data={controversyChartData} />
    </Box>
  );
};
export default ControversyChart;
