import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  selectedTabText: {
    color: "#EBF6EF",
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 500,
    padding: "16px",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  selectedTab: {
    borderRadius: "8px",
    backgroundColor: "#32A05F",
    maxWidth: "289px",
    marginBottom: "16px",
  },
  unselectedTabText: {
    color: "#154328",
    padding: "16px",
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 500,
    textTransform: "capitalize",
    cursor: "pointer",
  },
  unselectedTab: {
    backgroundColor: "none",
    marginBottom: "16px",
    "&:hover": {
      borderRadius: "8px",
      backgroundColor: "#BFE2CD",
    },
  },
}));

const DataHubTableTabs = (props) => {
  const classes = useStyles();
  const { value, noOfSheets, handleChange } = props;

  const [tabNames, setTabNames] = useState([]);
  useEffect(() => {
    for (let index = 0; index < noOfSheets.length; index++) {
      setTabNames((prev) => [...prev, Object.keys(noOfSheets[index])[0]]);
    }
  }, [noOfSheets]);

  useEffect(() => {
    return () => {
      setTabNames([]);
    };
  }, []);

  return (
    <div
      style={{
        paddingLeft: "20px",
        overflow: "scroll",
        maxHeight: "500px",
        position: "relative",
      }}
    >
      {tabNames.map((tab, index) => (
        <Box
          className={
            value === index ? classes.selectedTab : classes.unselectedTab
          }
          key={index}
        >
          <Typography
            className={
              value === index
                ? classes.selectedTabText
                : classes.unselectedTabText
            }
            onClick={() => handleChange(index, tab)}
          >
            {tab}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default DataHubTableTabs;
