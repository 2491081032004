import { useState } from "react";

import {
  Box,
  Button,
  Icon,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import chatBot from "images/chatbot.png";
import DisclosureTypeOne from "./disclosureType/DisclosureTypeOne";
import DisclosureTypeThree from "./disclosureType/DisclosureTypeThree";
import DisclosureTypeTwo from "./disclosureType/DisclosureTypeTwo";
import ChatThreadMain from "./chatThread/ChatThreadMain";

export default function ExploreDisclosure({ toggleExplore }) {
  const [chatThreadOpen, setChatThreadOpen] = useState(false);
  return (
    <>
      {/* heading */}
      <Heading
        text="name of company"
        icon={
          <IconButton sx={{ marginRight: "3px" }} onClick={toggleExplore}>
            <KeyboardArrowLeft color="primary" size="small" />
          </IconButton>
        }
      >
        <Stack direction={"row"} gap={2}>
          <Button
            variant="text"
            color="primary"
            onClick={() => setChatThreadOpen(true)}
          >
            View Chat Thread
          </Button>
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </Stack>
      </Heading>
      {/* Bot suggestion heading */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Box
          sx={{
            backgroundColor: "#EBF1F8",
            borderRadius: "8px",
            width: "85%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 3,
            padding: "10px",
          }}
        >
          <img src={chatBot} alt="chatbot" />
          <Typography variant="body2">
            Impact grows bot is handy suggestion tool based on the raw data from
            exchange hub it can give you it give you data to enter in specific
            field. This data can also be edited before submitting.
          </Typography>
        </Box>
        <Button variant="text">View Suggestion</Button>
      </Stack>
      {/* disclosure question */}
      <Typography variant="subtitle1">
        a : Descriibe the actual and potential, negative and positve impacts on
        the economy, environment and people including impacts on their human
        rights:
      </Typography>

      {/* Disclosure Body */}

      <DisclosureTypeOne />
      <DisclosureTypeThree />
      <DisclosureTypeTwo />

      {/* Drawers */}

      {/* chat thread drawer */}
      <ChatThreadMain
        hasDrawerOpen={chatThreadOpen}
        handleClose={() => setChatThreadOpen(false)}
      />
    </>
  );
}
