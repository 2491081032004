import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Grid,
  Icon,
  IconButton,
  Typography,
  Autocomplete,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import countryList from "utils/countries/countryList";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { TurnoverYearPickerDialog } from "./TurnoverPickerDialog";

import SectorIndustry from "./SectorIndustry";

import { brmRightStyles } from "./brmRightStyle";
import OptimizeInitialRender from "./OptimizeInitialRender";
// import { updateSupplierOrganization } from "../../../../actions/brm/BrmActions";
import VendorSector from "./SectorMain";

export const RevenueData = ({
  revenueData = [],
  setRevenueData,
  handleTurnover,
  disableInputs,
  dataType,
}) => {
  const classes = brmRightStyles();
  const handleName = dataType === "Revenue" ? "revenue" : "turnover";

  return (
    <>
      <Box
        my={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.headerText}>
          {`${dataType} Data`}
          {revenueData?.length === 0 && (
            <span style={{ color: "red", padding: "0px 3px" }}>*</span>
          )}
        </Typography>
        <Button
          name="addBranch"
          size="medium"
          onClick={() => {
            const initialPeer =
              dataType === "Revenue"
                ? {
                    revenue: "",
                    year: "",
                    currency: "",
                    figures: "",
                  }
                : {
                    turnover: "",
                    year: "",
                    currency: "",
                    figures: "",
                  };
            const copy = [...revenueData];
            copy.push(initialPeer);
            setRevenueData((prev) => ({ ...prev, [handleName]: copy }));
            window.scrollTo({
              top: 50,
              behavior: "smooth",
            });
            // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
          }}
          variant="text"
          color="primary"
          style={{ textTransform: "capitalize" }}
          disabled={disableInputs}
        >
          {`Add ${dataType}`}
        </Button>
      </Box>
      {revenueData?.map((revenue, i) => {
        return (
          <Grid key={i} container spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
              <TextField
                name="turnovers"
                variant="outlined"
                fullWidth
                size="small"
                type="number"
                label={`Add ${dataType}`}
                value={
                  dataType === "Revenue"
                    ? revenue.revenue
                    : revenue.turnover || ""
                }
                onChange={(e) => {
                  if (dataType === "Revenue") {
                    const copy = [...revenueData];
                    copy[i].revenue = e.target.value;
                    setRevenueData((prev) => ({ ...prev, [handleName]: copy }));
                  } else {
                    const copy = [...revenueData];
                    copy[i].turnover = e.target.value;
                    setRevenueData((prev) => ({ ...prev, [handleName]: copy }));
                  }
                }}
                disabled={disableInputs}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              role="button"
              onClick={() =>
                handleTurnover(i, dataType === "Revenue" ? true : false)
              }
            >
              <TextField
                name="NumOfEmployee"
                variant="outlined"
                fullWidth
                size="small"
                type="text"
                label="Year"
                onChange={(e) => {
                  const copy = [...revenueData];
                  copy[i].year = e.target.value;
                  setRevenueData((prev) => ({ ...prev, [handleName]: copy }));
                }}
                value={revenue.year || ""}
                disabled={disableInputs}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Icon
                style={{ cursor: disableInputs ? "not-allowed" : "pointer" }}
                onClick={() => {
                  if (!disableInputs) {
                    const copy = [...revenueData];
                    copy.splice(i, 1);
                    setRevenueData((prev) => ({ ...prev, [handleName]: copy }));
                  }
                }}
              >
                remove
              </Icon>
            </Grid>
            <Grid item xs={5} md={5}>
              <Autocomplete
                id="country-select-demo"
                size="small"
                value={revenue.currency || ""}
                options={["USD", "AED", "INR"]}
                classes={{
                  endAdornment: classes.icon,
                }}
                // inputProps={{
                //   classes: {
                //     icon: classes.icon,
                //     root:{}
                //   },
                // }}
                autoHighlight
                getOptionLabel={(option) => `${option}`}
                renderOption={(option) => (
                  <React.Fragment>{option}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Currency"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const copy = [...revenueData];
                    copy[i].currency = newValue;
                    setRevenueData((prev) => ({ ...prev, [handleName]: copy }));
                  }
                }}
                disabled={disableInputs}
              />
            </Grid>
            <Grid item xs={5} md={6}>
              <Autocomplete
                id="country-select-demo"
                size="small"
                value={revenue.figures || ""}
                options={["Million", "Billion", "Crore"]}
                classes={{
                  endAdornment: classes.icon,
                  // root: {},
                }}
                // inputProps={{
                //   classes: {
                //     icon: classes.icon,
                //     root:{}
                //   },
                // }}
                autoHighlight
                getOptionLabel={(option) => `${option}`}
                renderOption={(option) => (
                  <React.Fragment>{option}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Figures"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const copy = [...revenueData];
                    copy[i].figures = newValue;
                    setRevenueData((prev) => ({ ...prev, [handleName]: copy }));
                    // setBranchCountry(newValue);
                  }
                }}
                disabled={disableInputs}
              />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

// const TurnOverData = () => {
//   return  <>
//   <Box
//     my={2}
//     display="flex"
//     alignItems="center"
//     justifyContent="space-between"
//   >
//     <Typography className={classes.headerText}>
//       Turnover Data
//     </Typography>
//     <Button
//       name="addBranch"
//       size="medium"
//       onClick={() => {
//         const initialPeer = {
//           turnover: "",
//           year: "",
//           currency: "",
//           figures: "",
//         };
//         const copy = [...turnoverData];
//         copy.push(initialPeer);
//         setTurnoverData(copy);
//         window.scrollTo({
//           top: 50,
//           behavior: "smooth",
//         });
//         // document.getElementById('compCont').scrollIntoView({ behavior: "smooth" });
//       }}
//       variant="outlined"
//       color="primary"
//     >
//       Add Turnover
//     </Button>
//   </Box>
//   {turnoverData.map((turnover, i) => {
//     return (
//       <Grid container spacing={2} alignItems="center">
//         <Grid item xs={12} md={5}>
//           <TextField
//             name="turnovers"
//             variant="outlined"
//             fullWidth
//             size="small"
//             type="text"
//             label="Add Turnover"
//             value={turnover.turnover}
//             onChange={(e) => {
//               const copy = [...turnoverData];
//               copy[i].turnover = parseInt(e.target.value);
//               setTurnoverData(copy);
//             }}
//           />
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           role="button"
//           onClick={() => handleTurnover(i, false)}
//         >
//           <TextField
//             name="NumOfEmployee"
//             variant="outlined"
//             fullWidth
//             size="small"
//             type="text"
//             label="Year"
//             onChange={(e) => {
//               const copy = [...turnoverData];
//               copy[i].year = e.target.value;
//               setTurnoverData(copy);
//             }}
//             value={turnover.year}
//           />
//         </Grid>
//         <Grid item xs={12} md={1}>
//           <Icon
//             style={{ cursor: "pointer" }}
//             onClick={() => {
//               const copy = [...turnoverData];
//               copy.splice(i, 1);
//               setTurnoverData(copy);
//             }}
//           >
//             remove
//           </Icon>
//         </Grid>
//         <Grid item xs={5} md={5}>
//           <Autocomplete
//             id="country-select-demo"
//             size="small"
//             value={turnover.currency}
//             options={["USD", "AED", "INR"]}
//             classes={{
//               endAdornment: classes.icon,
//               root: {},
//             }}
//             // inputProps={{
//             //   classes: {
//             //     icon: classes.icon,
//             //     root:{}
//             //   },
//             // }}
//             autoHighlight
//             getOptionLabel={(option) => `${option}`}
//             renderOption={(option) => (
//               <React.Fragment>{option}</React.Fragment>
//             )}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Currency"
//                 variant="outlined"
//                 inputProps={{
//                   ...params.inputProps,
//                   autoComplete: "new-password", // disable autocomplete and autofill
//                 }}
//               />
//             )}
//             onChange={(event, newValue) => {
//               if (newValue) {
//                 const copy = [...turnoverData];
//                 copy[i].currency = newValue;
//                 setTurnoverData(copy);
//                 // setBranchCountry(newValue);
//               }
//             }}
//           />
//         </Grid>
//         <Grid item xs={5} md={6}>
//           <Autocomplete
//             id="country-select-demo"
//             size="small"
//             value={turnover.figures}
//             options={["Million", "Billion", "Crore"]}
//             classes={{
//               endAdornment: classes.icon,
//               root: {},
//             }}
//             // inputProps={{
//             //   classes: {
//             //     icon: classes.icon,
//             //     root:{}
//             //   },
//             // }}
//             autoHighlight
//             getOptionLabel={(option) => `${option}`}
//             renderOption={(option) => (
//               <React.Fragment>{option}</React.Fragment>
//             )}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Figures"
//                 variant="outlined"
//                 inputProps={{
//                   ...params.inputProps,
//                   autoComplete: "new-password", // disable autocomplete and autofill
//                 }}
//               />
//             )}
//             onChange={(event, newValue) => {
//               if (newValue) {
//                 const copy = [...turnoverData];
//                 copy[i].figures = newValue;
//                 setTurnoverData(copy);
//                 // setBranchCountry(newValue);
//               }
//             }}
//           />
//         </Grid>
//       </Grid>
//     );
//   })}
// </>
// }

function CompanyDetails(props) {
  const classes = brmRightStyles();

  const { brmData } = props;

  const [sectors, setSectors] = useState([]);
  const [isSectorDialogOpen, setIsSectorDialogOpen] = useState(false);
  const [companyLogoURI, setCompanyLogoURI] = useState("");
  const [turnoverPicker, setTurnoverPicker] = useState(false);
  const [tstartYear, setTStartYear] = useState("");
  const [tendYear, setTEndYear] = useState("");
  const currentIndex = useRef({
    i: 0,
    revenue: true,
  });
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [organizaitonDetailsObject, setOrganizaitonDetailsObject] = useState({
    name: "",
    operation: "",
    no_of_employees: "",
    isin: "",
    year_of_assessment: "",
    country_name: "",
    country_code: "",
    company_address: [
      { landMark: "", state: "", pinCode: "", companyAddress: "", country: "" },
    ],
    revenue: [],
    turnover: [],
    sector_id: "",
    industry_id: "",
  });

  useEffect(() => {
    setOrganizaitonDetailsObject(brmData?.supplierOrganizationDetails);
    setCompanyLogoURI(brmData?.supplierOrganizationDetails?.logo?.url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brmData?.supplierOrganizationDetails]);

  useEffect(() => {
    if (
      brmData?.supplierOrganizationDetails &&
      brmData?.supplierOrganizationDetails.sector_id
    ) {
      let masterSectors = [...brmData?.sectorIndustries];
      masterSectors.map((masterSec, masterSecIdx) => {
        return (
          brmData?.supplierOrganizationDetails.sector_id === masterSec._id &&
          masterSec.industry_data.map((masterInd, masterIndIdx) =>
            masterInd._id === brmData?.supplierOrganizationDetails.industry_id
              ? (masterSectors[masterSecIdx].industry_data[
                  masterIndIdx
                ].isSelected = true)
              : (masterSectors[masterSecIdx].industry_data[
                  masterIndIdx
                ].isSelected = false)
          )
        );
      });

      setSectors(masterSectors);
    } else {
      setSectors(brmData?.sectorIndustries);
    }
  }, [brmData?.sectorIndustries, brmData?.supplierOrganizationDetails]);

  const handleIndustryChange = (industryIndex, sectorIndex) => {
    let sectorL = JSON.parse(JSON.stringify(sectors));

    sectorL = sectorL.map((sect, i) => {
      sect.industry_data.map((ind, j) => {
        if (sectorIndex === i && industryIndex === j) {
          ind.isSelected = true;
        } else {
          ind.isSelected = false;
        }
        return ind;
      });

      return sect;
    });

    setSectors(sectorL);
  };

  const handleClearCompanyLogo = () => {
    setCompanyLogoURI("");
  };
  const handleUploadCompanyLogo = (e) => {
    const reader = new FileReader();

    const file = e.target.files[0];
    reader.onloadend = () => {
      setCompanyLogoURI(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  useEffect(() => {
    // console.log("new mount", organizaitonDetailsObject?.year_of_assessment);
    organizaitonDetailsObject?.year_of_assessment &&
      setStartYear(
        organizaitonDetailsObject?.year_of_assessment?.split("-")[0]
      );
    organizaitonDetailsObject?.year_of_assessment &&
      setEndYear(organizaitonDetailsObject?.year_of_assessment?.split("-")[1]);
  }, [organizaitonDetailsObject?.year_of_assessment]);

  useEffect(() => {
    const data = [];
    if (!organizaitonDetailsObject?.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (organizaitonDetailsObject?.year_of_assessment) {
      for (let i = 0; i < 9; i++) {
        data.push(
          organizaitonDetailsObject?.year_of_assessment.split("-")[0] - 3 + i
        );
      }
      setyearRange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatePickerDialogOpen]);

  useEffect(() => {
    const data = [];
    if (true || "!companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (false && "companyDetails.yearOfAssessment") {
      for (let i = 0; i < 9; i++) {
        // data.push(companyDetails.yearOfAssessment.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turnoverPicker]);
  const trange = (year) => {
    if (tstartYear === year) return true;
    if (tendYear === year) return true;
    if (year <= tendYear && year >= tstartYear) return true;
    else return false;
  };
  const handleTurnoverYearClick = (year) => {
    if (tstartYear && tendYear) {
      setTStartYear(year);
      setTEndYear("");
    } else if (!tstartYear) {
      setTStartYear(year);
    } else if (year < tstartYear) {
      setTStartYear(year);
    } else if (year !== tstartYear && year === tstartYear + 1) {
      setTEndYear(year);
    } else if (year !== tstartYear + 1) {
      setTStartYear(year);
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  const handleTurnover = (i, revenue) => {
    setTurnoverPicker(true);
    currentIndex.current = { i, revenue: revenue };
  };
  const handleSetYear = () => {
    const handleYear = (prev) => {
      let copy = [...prev];
      copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
      return copy;
    };
    !currentIndex.current.revenue &&
      setOrganizaitonDetailsObject((prev) => {
        return { ...prev, turnover: handleYear(prev?.turnover) };

        // let copy = [...prev.turnoverData];
        // copy[currentIndex.current.i].year = `${tstartYear}-${tendYear}`;
        // return copy;
      });
    currentIndex.current.revenue &&
      setOrganizaitonDetailsObject((prev) => {
        return { ...prev, revenue: handleYear(prev?.revenue) };
      });
    // setCompanyDetails((prev) => ({
    //   ...prev,
    //   yearOfAssessment: `${startYear}-${endYear}`,
    // }));
    setTurnoverPicker(false);
    setTStartYear("");
    setTEndYear("");
  };

  const handleCancelYear = () => {
    setTStartYear("");
    setTEndYear("");
    setTurnoverPicker(false);
  };

  const gridItemsArray = [
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <TextField
            name="compName"
            variant="outlined"
            fullWidth
            size="small"
            label="Company Name"
            value={organizaitonDetailsObject?.name || ""}
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                name: e.target.value,
              }));
            }}
          />
        </Box>
      ),
    },
    {
      xs: 1,
      md: 1,
      content: (
        <>
          {!organizaitonDetailsObject?.name && (
            <span
              style={{
                color: "red",
                padding: "0px 3px",
                display: "inline",
              }}
            >
              *
            </span>
          )}
        </>
      ),
    },
    {
      xs: 12,
      md: 8,
      content: (
        <>
          <Box my={2}>
            <Typography className={classes.headerText}>Company Logo</Typography>
          </Box>
          <Box my={2}>
            {!companyLogoURI ? (
              <>
                <input
                  id="company-logo"
                  type="file"
                  onChange={(event) => handleUploadCompanyLogo(event)}
                  style={{ display: "none" }}
                  cursor="pointer"
                />
                <label htmlFor="company-logo">
                  <div className={classes.logoSelecter}>
                    <AddCircleOutlineIcon
                      name="addLogoIcon"
                      fontSize="large"
                      className={classes.addIcon}
                    />
                  </div>
                </label>
              </>
            ) : (
              <div className={classes.companyLogo}>
                <img
                  alt="company-logo"
                  accept="image/png, image/gif, image/jpeg"
                  src={companyLogoURI}
                />
                <IconButton
                  onClick={handleClearCompanyLogo}
                  className={classes.ClearImage}
                  size="large"
                >
                  <CancelIcon style={{ color: "gray" }} fontSize="small" />
                </IconButton>
              </div>
            )}
          </Box>
        </>
      ),
    },
    // {
    //   xs: 12,
    //   md: 9,
    //   content: (
    //     <Box my={2}>
    //       <Typography className={classes.headerText}>
    //         Sector & Industry
    //         {sectors?.length === 0 && (
    //           <span
    //             style={{
    //               color: "red",
    //               padding: "0px 3px",
    //               display: "inline",
    //             }}
    //           >
    //             *
    //           </span>
    //         )}
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   xs: 12,
    //   md: 3,
    //   content: (
    //     <Button
    //       onClick={() => {
    //         setIsSectorDialogOpen(true);
    //       }}
    //       variant="text"
    //       color="primary"
    //     >
    //       Add Industry & Sector
    //     </Button>
    //   ),
    // },
    // {
    //   xs: 12,
    //   md: 8,
    //   content: (
    //     <Box my={2}>
    //       {sectors.length !== 0 ? (
    //         sectors.map((sector) => (
    //           <div style={{ marginBottom: 8 }}>
    //             {sector.industry_data.some((inds) => inds.isSelected) && (
    //               <>
    //                 <Typography className={classes.selectedSectorTitle}>
    //                   {sector.Sector}
    //                 </Typography>
    //                 {sector.industry_data.map(
    //                   (industry) =>
    //                     industry.isSelected && (
    //                       <Chip
    //                         className={classes.sectorChip}
    //                         label={industry.Industry}
    //                       />
    //                     )
    //                 )}
    //               </>
    //             )}
    //           </div>
    //         ))
    //       ) : (
    //         <Chip className={classes.sectorChip} label="Non Selected" />
    //       )}
    //     </Box>
    //   ),
    // },
    {
      xs: 12,
      md: 12,
      content: (
        <VendorSector
          sector={sectors}
          setCompanyDetails={setOrganizaitonDetailsObject}
        />
      ),
    },
    {
      xs: 12,
      md: 12,
      content: (
        <RevenueData
          dataType={"Revenue"}
          revenueData={organizaitonDetailsObject?.revenue}
          setRevenueData={setOrganizaitonDetailsObject}
          handleTurnover={handleTurnover}
        />
      ),
    },
    {
      xs: 12,
      md: 12,
      content: (
        <RevenueData
          dataType={"TurnOver"}
          revenueData={organizaitonDetailsObject?.turnover}
          setRevenueData={setOrganizaitonDetailsObject}
          handleTurnover={handleTurnover}
        />
      ),
    },
    {
      xs: 12,
      md: 11,
      content: <></>,
    },
    {
      xs: 1,
      md: 1,
      content: (
        <>
          {!organizaitonDetailsObject?.country_name && (
            <span
              style={{
                color: "red",
                padding: "0px 3px",
                display: "inline",
              }}
            >
              *
            </span>
          )}
        </>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <TextField
            name="NumOfEmployee"
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            label="ISIN Number"
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                isin: e.target.value,
              }));
            }}
            value={organizaitonDetailsObject?.isin || ""}
          />
        </Box>
      ),
    },
    // {
    //   xs: 1,
    //   md: 1,
    //   content: (
    //     <>
    //       {!organizaitonDetailsObject?.isin && (
    //         <span
    //           style={{
    //             color: "red",
    //             padding: "0px 3px",
    //             display: "inline",
    //           }}
    //         >
    //           *
    //         </span>
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   xs: 12,
    //   md: 11,
    //   content: (
    //     <Box
    //       my={2}
    //       onClick={() => setIsDatePickerDialogOpen(true)}
    //       style={{
    //         border: "1px solid #00000050",
    //         padding: 12,
    //         borderRadius: 5,
    //         cursor: "pointer",
    //         minHeight: 45,
    //       }}
    //     >
    //       {organizaitonDetailsObject?.year_of_assessment ? (
    //         <Typography variant="body1" name="yearOfAssesment">
    //           {`${
    //             organizaitonDetailsObject?.year_of_assessment.split("-")[0]
    //           } - ${
    //             organizaitonDetailsObject?.year_of_assessment.split("-")[1]
    //           }`}
    //         </Typography>
    //       ) : (
    //         <Typography style={{ color: "#878282e6", fontFamily: "Roboto" }}>
    //           Year Of Assessment
    //         </Typography>
    //       )}
    //     </Box>
    //   ),
    // },
    {
      xs: 1,
      md: 1,
      content: (
        <>
          {
            <span
              style={{
                color: "red",
                padding: "0px 3px",
                display: "inline",
              }}
            >
              *
            </span>
          }
        </>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <TextField
            name="compOperation"
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            size="small"
            label="Company Operation"
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                operation: e.target.value,
              }));
            }}
            value={organizaitonDetailsObject?.operation || ""}
          />
        </Box>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <Box my={2}>
          <TextField
            name="NumOfEmployee"
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            label="Number of Employees"
            onChange={(e) => {
              setOrganizaitonDetailsObject((prev) => ({
                ...prev,
                no_of_employees: e.target.value,
              }));
            }}
            value={organizaitonDetailsObject?.no_of_employees || ""}
          />
        </Box>
      ),
    },
    {
      xs: 12,
      md: 11,
      content: (
        <>
          <Box my={2}>
            <Typography className={classes.headerText}>
              Company Address
            </Typography>
          </Box>
          <Box my={2}>
            <TextField
              name="compAddress"
              variant="outlined"
              fullWidth
              size="small"
              label="Street/Location"
              value={
                (organizaitonDetailsObject?.company_address &&
                  organizaitonDetailsObject?.company_address[0]
                    ?.companyAddress) ||
                ""
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  company_address: [
                    {
                      ...prev?.company_address[0],
                      companyAddress: e.target.value,
                    },
                  ],
                }));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RoomOutlinedIcon fontSize="small" color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box my={2}>
            <TextField
              variant="outlined"
              name="compLandmark"
              fullWidth
              size="small"
              label="Landmark"
              value={
                (organizaitonDetailsObject?.company_address &&
                  organizaitonDetailsObject?.company_address[0]?.landMark) ||
                ""
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  company_address: [
                    {
                      ...prev?.company_address[0],
                      landMark: e.target.value,
                    },
                  ],
                }));
              }}
            />
          </Box>
          <Box my={2}>
            <TextField
              name="compPincode"
              variant="outlined"
              fullWidth
              size="small"
              label="Pincode"
              type="number"
              value={
                (organizaitonDetailsObject?.company_address &&
                  organizaitonDetailsObject?.company_address[0]?.pinCode) ||
                ""
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  company_address: [
                    {
                      ...prev?.company_address[0],
                      pinCode: e.target.value,
                    },
                  ],
                }));
              }}
            />
          </Box>
          <Box my={2}>
            <TextField
              name="compState"
              variant="outlined"
              fullWidth
              size="small"
              label="State"
              value={
                (organizaitonDetailsObject?.company_address &&
                  organizaitonDetailsObject?.company_address[0]?.state) ||
                ""
              }
              onChange={(e) => {
                setOrganizaitonDetailsObject((prev) => ({
                  ...prev,
                  company_address: [
                    {
                      ...prev?.company_address[0],
                      state: e.target.value,
                    },
                  ],
                }));
              }}
            />
          </Box>
          <Box my={2}>
            <Autocomplete
              name="compCountry"
              id="countryCode"
              options={countryList || []}
              getOptionLabel={(option) => option}
              renderOption={(option) => option}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Country" variant="outlined" />
              )}
              value={
                (organizaitonDetailsObject?.company_address &&
                organizaitonDetailsObject?.company_address[0]?.country
                  ? organizaitonDetailsObject?.company_address[0]?.country
                  : "") || ""
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  setOrganizaitonDetailsObject((prev) => ({
                    ...prev,
                    company_address: [
                      {
                        ...prev?.company_address[0],
                        country: newValue,
                      },
                    ],
                  }));
                }
              }}
            />
          </Box>
        </>
      ),
    },
    {
      xs: 12,
      md: 12,
      content: (
        <>
          <Button
            style={{
              float: "right",
              textTransform: "none",
              fontSize: "13px",
              color: "#3374B9",
            }}
            onClick={() => {
              //   dispatch(
              //     updateSupplierOrganization(
              //       login.token,
              //       brmData?.supplierOrganizationDetails?._id,
              //       organizaitonDetailsObject?.isin,
              //       organizaitonDetailsObject?.name,
              //       organizaitonDetailsObject?.sector_id,
              //       organizaitonDetailsObject?.industry_id,
              //       organizaitonDetailsObject?.country,
              //       organizaitonDetailsObject?.website,
              //       organizaitonDetailsObject?.operation,
              //       organizaitonDetailsObject?.no_of_employees,
              //       organizaitonDetailsObject?.company_address,
              //       organizaitonDetailsObject?.revenue.map((r) => ({
              //         ...r,
              //         revenue: parseFloat(r.revenue),
              //       })),
              //       organizaitonDetailsObject?.turnover.map((r) => ({
              //         ...r,
              //         turnover: parseFloat(r.turnover),
              //       })),
              //       companyLogo
              //       // organizaitonDetailsObject?.year_of_assessment,
              //       // companyLogo,
              //       // sectors,
              //       // brmData?.sectorIndustries,
              //       // revenueData.filter(
              //       //   (d) =>
              //       //     d.revenue !== "" &&
              //       //     d.year !== "" &&
              //       //     d.currency !== "" &&
              //       //     d.figures !== ""
              //       // ),
              //       // turnoverData.filter(
              //       //   (d) =>
              //       //     d.turnover !== "" &&
              //       //     d.year !== "" &&
              //       //     d.currency !== "" &&
              //       //     d.figures !== ""
              //       // ),
              //       // organizaitonDetailsObject?.country
              //     )
              //   );
            }}
          >
            Update Portfolio
          </Button>
          <Button
            onClick={() =>
              brmData?.supplierOrganizationDetails &&
              setOrganizaitonDetailsObject(brmData?.supplierOrganizationDetails)
            }
            style={{
              float: "right",
              textTransform: "none",
              fontSize: "13px",
              color: "#3374B9",
            }}
          >
            Cancel
          </Button>
        </>
      ),
    },
    // ... Add more objects for other Grid items
  ];

  return (
    <>
      {turnoverPicker && (
        <TurnoverYearPickerDialog
          isOpen={turnoverPicker}
          onCancel={handleCancelYear}
          onSet={handleSetYear}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={tstartYear}
          end={tendYear}
          yearRange={yearRange}
          onYearClick={handleTurnoverYearClick}
          range={trange}
          onClear={() => {
            setTEndYear("");
            setTStartYear("");
          }}
        />
      )}
      {isDatePickerDialogOpen && (
        <TurnoverYearPickerDialog
          isOpen={isDatePickerDialogOpen}
          onCancel={() => {
            setOrganizaitonDetailsObject((prev) => ({
              ...prev,
              year_of_assessment: "",
            }));
            organizaitonDetailsObject?.year_of_assessment &&
              setStartYear(
                organizaitonDetailsObject?.year_of_assessment.split("-")[0]
              );
            organizaitonDetailsObject?.year_of_assessment &&
              setEndYear(
                organizaitonDetailsObject?.year_of_assessment.split("-")[1]
              );
            setIsDatePickerDialogOpen(false);
          }}
          onSet={() => {
            setOrganizaitonDetailsObject((prev) => ({
              ...prev,
              year_of_assessment: `${startYear}-${endYear}`,
            }));
            setIsDatePickerDialogOpen(false);
          }}
          onYearBack={handleYearBack}
          onYearForward={handleYearForward}
          start={startYear}
          end={endYear}
          yearRange={yearRange}
          onYearClick={handleYearClick}
          range={range}
          onClear={() => {
            setStartYear("");
            setEndYear("");
          }}
        />
      )}
      {isSectorDialogOpen && (
        <SectorIndustry
          isSectorDialogOpen={isSectorDialogOpen}
          sectors={sectors}
          handleIndustryChange={handleIndustryChange}
          handleCloseSector={() => setIsSectorDialogOpen(false)}
        />
      )}

      <div style={{ height: "63vh" }} className={classes.mainDiv}>
        <Typography className={classes.cardTypography}>
          My Company details
        </Typography>

        <div className="scroll" style={{ height: "61vh" }}>
          <Grid container>
            <OptimizeInitialRender chunkSize={1}>
              {gridItemsArray.map((item, index) => (
                <Grid item key={index} xs={item.xs} md={item.md}>
                  {item.content}
                </Grid>
              ))}
            </OptimizeInitialRender>

            <Grid item xs={7}></Grid>
            <Grid item xs={9}></Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
