import { Stack, Typography } from "@mui/material";

const OnBoardRightHead = ({
  step,
  total_steps,
  hideStep,
  label,
  head,
  desc,
}) => {
  return (
    <Stack spacing={2}>
      {!hideStep && (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={12}
          bgcolor={"primary.lighter"}
          width={"max-content"}
          p={1}
        >
          <Typography variant="body2" color={"primary.darker"}>
            {step}/{total_steps} {label}
          </Typography>
        </Stack>
      )}

      <Typography variant="h1" fontWeight={"600"}>
        {head}
      </Typography>
      <Typography variant="body1" color={"action.active"}>
        {desc}
      </Typography>
    </Stack>
  );
};
export default OnBoardRightHead;
