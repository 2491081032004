import { Box, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DynamicTable from "components/UI/phase-3/dynamicTable/DynamicTable";
import Heading from "components/UI/phase-3/heading/Heading";
import React from "react";

const organizations = [
  {
    organizationName: "NetHorizon",
    noOfStakeholders: 20,
    responseCount: 14,
    organizationDated: "Feb 1, 2020",
  },
  {
    organizationName: "QuantumForge",
    noOfStakeholders: 20,
    responseCount: 8,
    organizationDated: "Feb 1, 2020",
  },
  {
    organizationName: "NovoTechz",
    noOfStakeholders: 20,
    responseCount: 19,
    organizationDated: "Feb 1, 2020",
  },
  {
    organizationName: "EliteInnovations",
    noOfStakeholders: 20,
    responseCount: 20,
    organizationDated: "Feb 1, 2020",
  },
];

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

function SurveyResponses() {
  const classes = useStyle();

  return (
    <Box>
      <Heading text={"Responses"} size={"15px"} marginBottom={"20px"} bg />
      <DynamicTable
        headings={[
          "Organisation Name",
          "",
          "",
          "No. of Stakeholders",
          "Response Count",
          "Organisation Dated",
        ]}
      >
        {organizations?.map((data, index) => (
          <TableRow
            key={data?._id}
            className={classes.tableRow}
            // onClick={() =>
            //   navigate(`/survey/${data.surveyName?.replaceAll(" ", "_")}`)
            // }
          >
            <TableCell align="left">{data.organizationName}</TableCell>
            <TableCell align="left" width={300}></TableCell>
            <TableCell align="left" width={300}></TableCell>

            <TableCell>{data.noOfStakeholders}</TableCell>

            <TableCell>{data.responseCount}</TableCell>
            <TableCell>{data.organizationDated}</TableCell>
          </TableRow>
        ))}
      </DynamicTable>
    </Box>
  );
}

export default SurveyResponses;
