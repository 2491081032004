import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },

    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
};

const labels = [10];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [630],
      borderColor: "rgba(85, 144, 238, 1)",
      backgroundColor: "rgba(85, 144, 238, 0.5)",
      tension: 0.1,
      borderWidth: 1,
    },
    // {
    //   label: "Dataset 2",
    //   data: [340, 568, 796, 508, 50, 100],
    //   borderWidth: 1,
    //   borderColor: "rgba(236, 171, 53, 1)",
    //   backgroundColor: "rgba(236, 171, 53, 0.5)",
    //   tension: 0.1,
    // },
  ],
};

const MultiLineChart = () => {
  return (
    <Card
      style={{
        height: "100%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
        borderRadius: "8px",
        border: "2px solid #DADBE6",
      }}
    >
      <CardContent style={{ height: "100%" }}>
        <Typography
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#242424",
          }}
        >
          Climate Change Trend
        </Typography>
        <Line options={options} data={data} style={{ padding: 5 }} />
      </CardContent>
    </Card>
  );
};

export default MultiLineChart;
