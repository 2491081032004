import { Box, IconButton } from "@mui/material";
import { FiPlusCircle } from "react-icons/fi";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

const ImageUpload = ({ image, setImage, acceptedTypes, readOnly = false }) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageChange = (e) => {
    if (readOnly) return;
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUploadedImage(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);

      setImage(e.target.files);
    }
  };

  const handleRemoveImage = (e) => {
    if (readOnly) return;
    e.stopPropagation();
    setUploadedImage(null);
    setImage(null);

    // Reset the file input value to allow re-uploading the same file
    const fileInput = document.getElementById("image-upload-input");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  useEffect(() => {
    if (image === null) {
      setUploadedImage(null);
    }
  }, [image]);

  return (
    <Box
      width={"10em"}
      height={"15em"}
      border={"1px dashed"}
      borderColor={"action.disabled"}
      borderRadius={"10px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      onClick={() =>
        !readOnly && document.getElementById("image-upload-input").click()
      }
      sx={{ cursor: readOnly ? "default" : "pointer" }}
    >
      <input
        id="image-upload-input"
        type="file"
        accept={acceptedTypes || "image/*"}
        style={{ display: "none" }}
        onChange={handleImageChange}
        disabled={readOnly}
      />
      {uploadedImage || readOnly ? (
        <>
          <Box
            component={"img"}
            src={readOnly ? image : uploadedImage}
            alt="Uploaded"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              objectFit: "contain",
            }}
          />
          {!readOnly && (
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
              onClick={handleRemoveImage}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </>
      ) : (
        !readOnly && (
          <IconButton sx={{ color: "primary.main" }}>
            <FiPlusCircle size={35} />
          </IconButton>
        )
      )}
    </Box>
  );
};

export default ImageUpload;
