import { Box, Typography } from "@mui/material";
import SearchTextField from "components/UI/textfield/searchTextField";

const OrganizationList = () => {
  const handleSearch = (keyword) => {
    console.log(keyword);
  };

  return (
    <Box width={"100%"}>
      <Box bgcolor={"primary.lighter"} p={2}>
        <Typography variant="subtitle2" color={"primary.darker"}>
          Organisation List
        </Typography>
      </Box>

      <Box p={1}>
        {/* Search */}
        <SearchTextField searchClick={handleSearch} fullWidth />
      </Box>
    </Box>
  );
};
export default OrganizationList;
