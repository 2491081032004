import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Chip,
  Autocomplete,
} from "@mui/material";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import ImageUplaod from "components/UI/phase-3/ImageUpload/ImageUplaod";

// hooks
import useFormValidation from "customHooks/useFormValidation";
import { GrLocation } from "react-icons/gr";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEntityListAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/EntryListApis";
import { useUnitsAPIs } from "components/screen/phase-3/OrgDetailsScreens/APIs/UnitsApis";
import { useDispatch, useSelector } from "react-redux";
import {
  createAssets,
  getParentEntity,
} from "actions/phase-3/orgDetails/orgDetailsAction";
import requiredField from "components/UI/phase-3/inputField/requiredShow";

const AssetsDrawer = ({ open, onClose, fetchAssets }) => {
  const dispatch = useDispatch();
  const [parentName, setParentName] = useState("");
  const [assetImage, setAssetImage] = useState(null);
  const [selectedTypeID, setSelectedTypeID] = useState("");

  const { parent_entity } = useSelector((state) => state.orgDetails);
  const { parent_id } = useSelector((state) => state.user);

  const initialState = {
    name: "",
    asset_id: "",
    type: "entity",
  };

  const validationRules = {
    name: { maxCharacters: 30, required: true },
    asset_id: { maxCharacters: 21, required: true },
    type: { required: true },
  };

  const { formData, errors, handleChange, setFormData, hasErrors } =
    useFormValidation(initialState, validationRules);

  // Handle search keyword and fetch entity list
  const { keyword, handleSearch } = usePaginationSearch(
    true /*searchDebounce*/
  );
  const { entity_list } = useEntityListAPIs(keyword, 50, 1, true, true);

  const { units_list } = useUnitsAPIs(keyword, 50, 1, true, true);

  const disableSubmit =
    !formData.name ||
    !formData.asset_id ||
    !formData.type ||
    !selectedTypeID ||
    !assetImage ||
    hasErrors;

  const handleSubmit = async () => {
    if (!disableSubmit) {
      // dispatch action
      await dispatch(
        createAssets(
          parent_id,
          formData.name,
          formData.asset_id,
          formData.type,
          formData.type === "entity" ? selectedTypeID : undefined,
          formData.type === "unit" ? selectedTypeID : undefined,
          { file: assetImage[0] },
          fetchAssets
        )
      );

      // clear data
      setFormData(initialState);
      setParentName("");
      setSelectedTypeID("");
      setAssetImage(null);
      onClose();
    }
  };

  const handleTypeClick = (type) => {
    setFormData((prev) => ({ ...prev, type }));
    setSelectedTypeID("");
  };

  useEffect(() => {
    if (open && !parent_entity) {
      dispatch(getParentEntity(parent_id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Asset"}
      buttonText={"Add Asset"}
      submitClick={handleSubmit}
      disableSubmit={disableSubmit}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        {/* Asset Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Asset Name {requiredField(Boolean(errors.name) || !formData.name)}
          </Typography>
          <TextField
            name="name"
            placeholder="Enter Name"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
            required
            fullWidth
          />
        </Box>

        {/* Asset ID */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Asset ID{" "}
            {requiredField(Boolean(errors.asset_id) || !formData.asset_id)}
          </Typography>
          <TextField
            name="asset_id"
            value={formData.asset_id}
            onChange={handleChange}
            error={Boolean(errors.asset_id)}
            helperText={errors.asset_id}
            required
            fullWidth
          />
        </Box>

        {/* Entity or Units */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Data Type{" "}
            {requiredField(Boolean(errors.type) || !formData.type)}
          </Typography>
          <Box display="flex" gap={1}>
            <Chip
              label="entity"
              onClick={() => handleTypeClick("entity")}
              color={formData.type === "entity" ? "primary" : "default"}
            />
            <Chip
              label="unit"
              onClick={() => handleTypeClick("unit")}
              color={formData.type === "unit" ? "primary" : "default"}
            />
          </Box>
        </Box>

        {formData?.type === "entity" ? (
          <Box>
            {/* Entity ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Entity ID {requiredField(!selectedTypeID)}
            </Typography>
            <Autocomplete
              options={entity_list?.entity_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.entity_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select entity name"
                  fullWidth
                />
              )}
            />
          </Box>
        ) : (
          <Box>
            {/* Unit ID */}
            <Typography variant="body1" color="action.active" gutterBottom>
              Select Unit ID {requiredField(!selectedTypeID)}
            </Typography>
            <Autocomplete
              options={units_list?.unit_list || []} // Ensure options is an array
              getOptionLabel={(option) => option.unit_name}
              onInputChange={(event, value) => handleSearch(value)} // Update search keyword
              onChange={(event, newValue) =>
                setSelectedTypeID(newValue ? newValue?._id : "")
              } // Store selected entity's id
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search and select unit name"
                  fullWidth
                />
              )}
            />
          </Box>
        )}

        {/* Parent Name */}
        <Box>
          <Typography variant="body1" color="action.active" gutterBottom>
            Select Parent Name {requiredField(!parentName)}
          </Typography>
          <Select
            labelId="parent-name-label"
            name="parent_name"
            value={parentName}
            onChange={(e) => setParentName(e.target.value)}
            placeholder="Parent Name"
            fullWidth
            required
          >
            {[parent_entity?.entity_name]?.map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Typography variant="body2" color={"text.faded"}>
          The unit in creation will be placed under this parent
        </Typography>

        {/* Image */}
        <ImageUplaod image={setAssetImage} setImage={setAssetImage} />
      </Box>
    </DynamicDrawer>
  );
};

export default AssetsDrawer;
