import { Autocomplete, Avatar, Box, TextField } from "@mui/material";
import React, { useState } from "react";
import Heading from "../heading/Heading";
import { countries } from "./CountryData";

function CountryInput({
  heading,
  label = "Label Name",
  rows = 1,
  marginBottom = "20px",
  marginTop = "10px",
}) {
  const [selectedCountry, setSelectedCountry] = useState(null);

  console.log(selectedCountry);

  return (
    <Box
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        width: "100%",
      }}
    >
      <Heading text={heading} size="13px" marginBottom="10px" color="#999" />
      <Autocomplete
        options={countries}
        getOptionLabel={(option) => option.country_name}
        renderOption={(props, option) => (
          <li {...props}>
            <Avatar
              alt={option.country_name}
              src={option.country_flag}
              style={{ marginRight: 8, width: "20px", height: "20px" }}
            />
            {option.country_name}
          </li>
        )}
        value={selectedCountry}
        onChange={(event, newValue) => {
          setSelectedCountry(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size="small"
            InputLabelProps={{
              style: {
                fontSize: "14px",
              },
            }}
          />
        )}
      />
    </Box>
  );
}

export default CountryInput;
