import React from "react";

function ColorBorders({ color, selected, onClick }) {
  return (
    <div
      onClick={onClick}
      style={{
        width: 25,
        height: 25,
        borderRadius: 6,
        border: `2px solid ${color || "red"}`,
        boxShadow: selected && `0px 0px 6px ${color || "red"}`,
        margin: 2,
        cursor: "pointer",
      }}
    />
  );
}

export default ColorBorders;
