// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.palette.text.disabled },
          },
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            // borderBottomColor: theme.palette.grey[500_56],
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none", // No underline on hover
          },
          "&:after": {
            // borderBottomColor: theme.palette.grey[500_56],
            borderBottom: "none",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          backgroundColor: theme.palette.primary.lighter,
          "&:hover": {
            backgroundColor: theme.palette.primary.lighter,
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.primary.lighter,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grey[500_32],
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
    // styling for text field input only
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "2px",
              // borderColor: theme.palette.primary.lighter,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.primary.main}99`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: `${theme.palette.primary.main}`,
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
          "& .MuiFilledInput-underline": {
            "&:before": {
              borderBottom: "none",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "none", // No underline on hover
            },
            "&:after": {
              borderBottom: "none",
            },
          },
          "& .MuiInputLabel-filled": {
            color: theme.palette.grey[700], // Change label color for filled variant
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // "&.MuiInputLabel-outlined": {
          //   color: "#000000",
          //   fontWeight: 500,
          // },
          // "&.Mui-focused": {
          //   color: theme.palette.primary.main, // Keep red when focused (outlined)
          // },
        },
      },
    },
  };
}
