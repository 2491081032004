import { Box, Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import OrganizationList from "./AssignComps/OrganizationList";

const AssignStakeholders = () => {
  const [entityUnits, setEntityUnits] = useState(null);
  const [role, setRole] = useState(null);

  const entityUnitsOptions = ["Entity 1", "Unit 1"];
  const roleOptions = ["Role 1"];

  return (
    <Box display="flex">
      {/* Organisation List */}
      <OrganizationList />

      {/* Contributors */}
      <Box width={"100%"} p={2}>
        <Box display="flex" gap={1}>
          <Autocomplete
            id="entity_units"
            options={entityUnitsOptions}
            value={entityUnits}
            onChange={(event, newValue) => setEntityUnits(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Select Entity/units" size="small" />
            )}
            fullWidth
          />
          <Autocomplete
            id="role"
            options={roleOptions}
            value={role}
            onChange={(event, newValue) => setRole(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Select by role" size="small" />
            )}
            fullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AssignStakeholders;
