import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function DataHubBreadCrumbs(props) {
  const { tabName, pathNames, back, setImportFileData } = props;

  const secondPageSurvey = `/data_hub/sheets_import/${tabName}`;

  const breadcrumbNameMap = {
    "/data_hub": "Data Hub",
    "/data_hub/survey": "Survey",
    "/data_hub/api_integration": "API Integration",
    "/data_hub/sheets_import": "Sheet Imports",
    "/data_hub/data_sets": "Data Sets",
    [secondPageSurvey]: tabName,
  };

  const handleBack = () => {
    back();
    setImportFileData([]);
  };

  return (
    <div
      style={{
        display: "flex",
        padding: pathNames.length === 3 && "2% 2% 1% 5%",
        alignItems: "center",
      }}
    >
      {pathNames.length === 3 && (
        <Button
          variant="outlined"
          style={{
            background: "#F0F6FD",
            border: "1px solid #3374B9",
            borderRadius: " 5px",
            textAlign: "center",
            height: "40px",
            width: "20px",
            marginRight: "20px",
          }}
          onClick={() => handleBack()}
        >
          <ArrowBackIcon fontSize="large" style={{ padding: "5px" }} />
        </Button>
      )}
      <Breadcrumbs
        separator={
          <NavigateNextIcon style={{ color: "#76A2D0" }} fontSize="medium" />
        }
        aria-label="breadcrumb"
        style={{ padding: pathNames.length !== 3 && "2% 2% 1% 10%" }}
      >
        {pathNames.map((value, index, arr) => {
          const updatePathName =
            arr.length === 3
              ? arr.slice(0, arr.length - 1).concat(tabName)
              : pathNames;
          const to = `/${updatePathName.slice(0, index + 1).join("/")}`;
          return (
            <Typography
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "22px",
                lineHeight: "28px",
                color: "#15314E",
              }}
              color="textPrimary"
              key={to}
            >
              {breadcrumbNameMap[to]}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

export default React.memo(DataHubBreadCrumbs);
