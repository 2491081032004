import React from "react";
import { connect } from "react-redux";
import Login from "../../components/screen/login/login";
import {
  setEmail,
  setPwd,
  login_with_email,
  forgetPassword,
  loginFromOtherSources,
  getCompanyLogo,
} from "../../actions/login/loginActions";

export class LoginCon extends React.PureComponent {
  render() {
    return <Login {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setEmail: (payload) => {
      dispatch(setEmail(payload));
    },
    setPwd: (payload) => {
      dispatch(setPwd(payload));
    },
    login_with_email: (email, password) => {
      dispatch(login_with_email(email, password));
    },
    forgetPassword: (email, domain_name) => {
      dispatch(forgetPassword(email, domain_name));
    },
    loginFromOtherSources: (token) => {
      dispatch(loginFromOtherSources(token));
    },
    getCompanyLogo: (domain) => {
      dispatch(getCompanyLogo(domain));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCon);
