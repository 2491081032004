import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Chip,
} from "@mui/material";
import ImageUplaod from "components/UI/phase-3/ImageUpload/ImageUplaod";

// icons
import { GrLocation } from "react-icons/gr";

const nodeData = {
  name: "Aerofusion Dynamics R&D Center",
  type: "Subsidiary",
  cin_no: "U55209KL2024PLC789012",
  street: "baijran street",
  city_state: "Ahmedabad",
  coordinates: " 28.4595° N, 77.0266° E",
  img: {
    url: "https://s3-alpha-sig.figma.com/img/796f/358e/604dbfe1fffa46fb3f9059cb9af46f17?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bI3uLi-3lRDPA-j5JKqsTCGZhnXk7818YDMAeVLIxAA8i~Cq1iRza7UBxO~qyR1NPvbIO4hVoRVpQXamJQD2CKssGfcLPMobmOtJPngA0fUGW1doLcGFo2uVMp81FJpPI~0DKIZCQAzvm~9JNZAuwhu4--1vg9vMZFFKBTs~3m1y9dFRD1Liejf27yTum27FiNSzKpzdJbbPbzdIl7907KREEYzHLl3mWsSYlfOUtDST4wjtpBf~ykxDwXsGf1Bj1eQPOIs4ByoksonsflkqnQSJW~EX2GJFrhXRvNPHipO3f3iExGjGdsJuvrqaXmlp2JtoXlA72tD~mE0pY6p9-g__",
    name: "testimg",
  },
};

const DetailsTab = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      {/* Entity Name */}
      <Box>
        <Typography variant="body1" color="action.active" gutterBottom>
          Entity Name
        </Typography>
        <TextField
          name="name"
          placeholder="Enter Name"
          value={nodeData?.name}
          inputProps={{ readOnly: true }}
          fullWidth
        />
      </Box>

      {/* Entity Type */}
      <Box>
        <Typography variant="body1" color="action.active" gutterBottom>
          Entity Type
        </Typography>
        <Chip label={nodeData?.type} color={"primary"} />
      </Box>

      {/* CIN No. */}
      <Box>
        <Typography variant="body1" color="action.active" gutterBottom>
          CIN No.
        </Typography>
        <TextField
          name="cin_no"
          value={nodeData?.cin_no}
          inputProps={{ readOnly: true }}
          fullWidth
        />
      </Box>

      {/* Location */}
      <Box>
        <Typography variant="body1" color="action.active" gutterBottom>
          Location
        </Typography>
        <TextField
          name="street"
          placeholder="Street name, building name"
          value={nodeData?.street}
          sx={{ mb: 2 }}
          inputProps={{ readOnly: true }}
          fullWidth
        />
        <TextField
          name="city_state"
          placeholder="City, state"
          value={nodeData?.city_state}
          inputProps={{ readOnly: true }}
          fullWidth
        />
      </Box>

      {/* GPS Coordinates */}
      <Box>
        <Typography variant="body1" color="action.active" gutterBottom>
          GPS Coordinates
        </Typography>
        <TextField
          name="coordinates"
          value={nodeData?.coordinates}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ color: "primary.main" }}>
                <GrLocation size={24} />
              </InputAdornment>
            ),
          }}
          inputProps={{ readOnly: true }}
          fullWidth
        />
      </Box>

      {/* Image */}
      <ImageUplaod image={nodeData?.img?.url} readOnly />
    </Box>
  );
};

export default DetailsTab;
