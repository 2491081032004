import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_TASKS_LIST,
  SET_TASK_MATURITY_QUES,
} from "constants/phase-3/task/maturity/maturityConst";

// ----------------- Payloads -----------------
export function setTasksList(payload) {
  return {
    type: SET_TASKS_LIST,
    payload: payload,
  };
}

export function setTaskMaturityQues(payload) {
  return {
    type: SET_TASK_MATURITY_QUES,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export function getTasksList(entity_id) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      bodyData: { entity_id },
      url: "/tasks/get_tasks",
      callback: (data) => {
        // final actions
        dispatch(setTasksList(data?.result));
      },
    });
  };
}

export function getTaskMaturityQues(entity_id) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      bodyData: { entity_id },
      url: "/tasks/get_assigned_maturity_matrix",
      callback: (data) => {
        // final actions
        dispatch(setTaskMaturityQues(data?.result));
      },
    });
  };
}

export function submitSurveyAns({
  entity_id,
  question_id,
  value,
  comment,
  addPrevAns,
  moveNext,
}) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      bodyData: { entity_id, question_id, value, comment },
      url: "/maturity_matrix/submit_answer",
      callback: (data) => {
        // final actions
        const checkAndCall = (func) => {
          if (Boolean(func)) {
            func();
          }
        };

        checkAndCall(addPrevAns);
        checkAndCall(moveNext);
      },
    });
  };
}
