import { useEffect, useState, useRef } from "react";

// icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

// import QuestionListHeader from "./comps/QuestionListHeader";
// import NoData from "../../../../../../NoData/NoData";
// import QuestionsLayout from "./QuestionsLayout";
import { Grid } from "@mui/material";
import NoData from "components/widgets/DatahubNoData/NoData";

const questionsData = [
  {
    sectionId: "663a2a2498facc12099dc53e",
    questions: [
      {
        _id: "663a0354dce0e88511784c40",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "MULTICHOICE",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: null,
        answers: [
          {
            option: "option 1",
            weightage: 1,
            justification: null,
            documentUpload: null,
          },
          {
            option: "option 2",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
        ],
      },
      {
        _id: "663a0354dce0e88511784c41",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "DROPDOWN",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: [],
        answers: [
          {
            option: "Hello",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Hey",
            weightage: 4,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Heya",
            weightage: 6,
            justification: null,
            documentUpload: null,
          },
        ],
      },
    ],
  },
  {
    sectionId: "663a2a2498facc12099dc53f",
    questions: [
      {
        _id: "663a0354dce0e88511784c40",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "MULTICHOICE",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: null,
        answers: [
          {
            option: "option 1",
            weightage: 1,
            justification: null,
            documentUpload: null,
          },
          {
            option: "option 2",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
        ],
      },
      {
        _id: "663a0354dce0e88511784c41",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "DROPDOWN",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: [],
        answers: [
          {
            option: "Hello",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Hey",
            weightage: 4,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Heya",
            weightage: 6,
            justification: null,
            documentUpload: null,
          },
        ],
      },
    ],
  },
  {
    sectionId: "663a2a2498facc12099dc53g",
    questions: [
      {
        _id: "663a0354dce0e88511784c40",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "MULTICHOICE",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: null,
        answers: [
          {
            option: "option 1",
            weightage: 1,
            justification: null,
            documentUpload: null,
          },
          {
            option: "option 2",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
        ],
      },
      {
        _id: "663a0354dce0e88511784c41",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "DROPDOWN",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: [],
        answers: [
          {
            option: "Hello",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Hey",
            weightage: 4,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Heya",
            weightage: 6,
            justification: null,
            documentUpload: null,
          },
        ],
      },
    ],
  },
  {
    sectionId: "663a2a2498facc12099dc53h",
    questions: [
      {
        _id: "663a0354dce0e88511784c40",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "MULTICHOICE",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: null,
        answers: [
          {
            option: "option 1",
            weightage: 1,
            justification: null,
            documentUpload: null,
          },
          {
            option: "option 2",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
        ],
      },
      {
        _id: "663a0354dce0e88511784c41",
        categorySuggestion: null,
        order: 1,
        question: "Please provide a question",
        questionType: "DROPDOWN",
        version: 2,
        weightage: 2,
        commentBox: null,
        isMandatory: false,
        hints: null,
        showComment: false,
        tableHeaders: [],
        answers: [
          {
            option: "Hello",
            weightage: 2,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Hey",
            weightage: 4,
            justification: null,
            documentUpload: null,
          },
          {
            option: "Heya",
            weightage: 6,
            justification: null,
            documentUpload: null,
          },
        ],
      },
    ],
  },
];

const SurveyQuestionsRight = ({ selectedSection }) => {
  // from redux

  // states
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [loadedUpTo, setLoadedUpTo] = useState(0); // Track index up to which questions are loaded
  const containerRef = useRef(null);

  const sectionWiseQuestions = questionsData?.find(
    (section) => section.sectionId === selectedSection?._id
  );

  console.log(sectionWiseQuestions);

  //------------------- Inner Functions -------------------

  //   const handleAddNewQuestion = () => {
  //     // Dispatch action to add a new question
  //     const createQuestionInput = {
  //       answers: [
  //         { option: "Very dissatisfied", weightage: 1 },
  //         { option: "Somewhat dissatisfied", weightage: 2 },
  //         { option: "Neither satisfied nor dissatisfied", weightage: 3 },
  //         { option: "Somewhat satisfied", weightage: 4 },
  //         { option: "Satisfied", weightage: 5 },
  //         { option: "Very satisfied", weightage: 6 },
  //       ],
  //       order: 1,
  //       question: "Please provide a question",
  //       questionType: "SATISFACTION",
  //       sectionId: selectedSection?._id,
  //       version: selectedSurveyData?.latestVersion,
  //       weightage: 2,
  //     };

  //   };

  //   const loadMoreQuestions = () => {
  //     // Calculate the index to slice from sectionWiseQuestion
  //     const startIndex = loadedUpTo;
  //     const endIndex = startIndex + 10;

  //     // Check if startIndex exceeds the length of sectionWiseQuestion
  //     if (startIndex >= sectionWiseQuestion.length) {
  //       return; // No more questions to load
  //     }

  //     // Slice questions from sectionWiseQuestion based on calculated indexes
  //     const newQuestions = sectionWiseQuestion.slice(startIndex, endIndex);

  //     // Update displayedQuestions state by appending newQuestions
  //     setDisplayedQuestions((prevQuestions) => [
  //       ...prevQuestions,
  //       ...newQuestions,
  //     ]);

  //     // Update loadedUpTo state
  //     setLoadedUpTo(endIndex);
  //   };

  return (
    <Grid
      container
      item
      md={10}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "60vh",
        overflow: "scroll",
        padding: "5px 15px",
      }}
      ref={containerRef}
    >
      {/* <QuestionListHeader selectedSection={selectedSection} /> */}
      {!selectedSection?._id ? (
        // when section is not selected
        <NoData message={"Please select a section"} />
      ) : sectionWiseQuestions.questions.length > 0 ? (
        // when questions are available
        // questionsData.map((question) => (
        //   <QuestionsLayout
        //     key={question?._id}
        //     question={question}
        //     isDefault={selectedSection?.default}
        //     selectedSection={selectedSection}
        //     setDisplayedQuestions={setDisplayedQuestions}
        //   />
        // ))
        ""
      ) : (
        // when questions are not available
        // <NoData message={"No Available Questions"} />
        ""
      )}
      {/* {!selectedSurveyData?.default && selectedSection && (
        <Button
          style={{
            textTransform: "none",
            marginRight: 10,
            alignSelf: "center",
            borderRadius: "8px",
          }}
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleAddNewQuestion}
        >
          <AddIcon />
          <Typography>Add Question</Typography>
        </Button>
      )} */}
    </Grid>
  );
};

export default SurveyQuestionsRight;
