import { Box } from "@mui/material";
import GetStartedLeft from "components/widgets/phase-3/getStarted/getStartedLeft/GetStartedLeft";
import GetStartedRight from "components/widgets/phase-3/getStarted/getStartedRight/GetStartedRight";
import React, { useState } from "react";

function GetStarted() {
  const [selectedIndex, setSelectedIndex] = useState(1);
  return (
    <Box style={{ display: "flex", gap: "30px" }}>
      <GetStartedLeft
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
      />
      <GetStartedRight selectedIndex={selectedIndex} />
    </Box>
  );
}

export default GetStarted;
