import makeStyles from "@mui/styles/makeStyles";

const materialityAssessmentRightStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    marginBottom: "15px",
    borderRadius: 5,
    height: "100%",
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  divider: {
    width: 2,
    height: 22,
    backgroundColor: "#9FA1AF",
    borderRadius: 1,
    margin: "0px 5px",
  },
  divider2: {
    display: "block",
    width: 1,
    height: 15,
    backgroundColor: "#9FA1AF",
    borderRadius: 1,
    margin: "0px 15px",
    [theme.breakpoints.down("lg")]: {
      // margin: '0px 5px',
      display: "none",
      // width: 0,
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  whiteDivider: {
    width: 2,
    height: 22,
    backgroundColor: "#fff",
    borderRadius: 1,
    margin: "0px 10px",
  },
  topicBarContainer: {
    width: "100%",
    padding: 3,
    display: "flex",
    justifyContent: "space-between",
    border: "2px solid #DADBE655",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
    "&:hover": {
      border: "2px solid #3374B9",
      "& $iconContainer": {
        visibility: "visible",
      },
    },
  },
  topicBarContents: {
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    visibility: "hidden",
  },
  topicAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#DAF1E4",
  },
  topicAvatarEnvironmental: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#DAF1E4",
  },
  topicAvatarSocial: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#EAD6F1",
  },
  topicAvatarHuman: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#F6F3DA",
  },
  topicAvatarBusiness: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#F1D6D6",
  },
  topicAvatarGovernance: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#D6E3F1",
  },
  emptyTopicHeader: {
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topicHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 10px",
    position: "relative",
  },
  signleTopicTypeContainer: {
    display: "flex",
    alignItems: "center",
  },
  topicTypeCount: {
    padding: "0px 8px",
    color: "#9FA1AF",
    fontWeight: "600",
  },
  removeAllButton: {
    color: "#9FA1AF",
    fontWeight: "600",
    cursor: "pointer",
  },
  tableHeaderText: {
    color: "#9FA1AF",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  tableBodyText: {
    color: "black",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
  },
  tableHeaderText1: {
    color: "#3374B9",
    fontWeight: "600",
    fontSize: 16,
    lineHeight: "24px",
  },
  centerLeft: {
    display: "flex",
    marginLeft: 10,
    alignItems: "center",
  },
  surveyStatGreen: {
    color: "#9FA1AF",
    fontWeight: "400",
    fontSize: 16,
    paddingLeft: 5,
    borderLeft: "3px solid green",
  },
  surveyStatGreenFormap: {
    color: "black",
    fontWeight: "400",
    fontSize: 16,
    paddingLeft: 5,
    borderLeft: "3px solid green",
  },
  surveyStatOrange: {
    color: "#9FA1AF",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
    paddingLeft: 5,
    borderLeft: "3px solid orange",
  },
  surveyStatOrangeForMap: {
    color: "black",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
    paddingLeft: 5,
    borderLeft: "3px solid orange",
  },
  surveyStatOrange1: {
    color: "#9FA1AF",
    fontWeight: "600",
    fontSize: 12,

    borderLeft: "3px solid orange",
    marginLeft: 5,
  },
  boldText: {
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
    color: "#242424",
  },
  blueText: {
    color: "#3374B9",
    fontWeight: "600",
    fontSize: 12,
  },
  previewBlueText: {
    color: "#3374B9",
    fontWeight: "600",
  },
  activeContainer: {
    width: 125,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DAF1E4",
    borderRadius: 5,
  },
  activeText: {
    color: "#32A05F",
    // wordBreak:'break'
  },
  updateCountContainer: {
    display: "flex",
    // alignItems: 'center',
    justifyContent: "flex-end",
    paddingRight: 10,
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: 10,
      flexDirection: "row",
    },
  },
  search2: {
    width: "60%",
  },
  grid1: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typography1: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  CustomButton1: { marginRight: 30 },
  Card1: { width: 1000 },
  grid2: { marginBottom: "0px" },
  grid3: { marginTop: "25px" },
  grid4: { marginBottom: 10 },
  Radio: { color: "#3374B9" },
  grid5: { marginTop: 30 },
  Typography2: { fontWeight: "600", margin: "10px 0" },
  Box1: {
    margin: "10px 0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  AttachFileIcon: { backgroundColor: "#ced4da", padding: 4 },
  div1: {
    borderBottom: "2px solid #ced4da",
    marginLeft: 20,
    width: "100%",
  },
  input: { opacity: 0, width: 0 },
  grid5: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  grid6: { marginTop: 15 },
  grid7: { paddingLeft: 75, paddingRight: 75 },
  grid8: { paddingLeft: 75 },
  grid9: { paddingRight: 75 },
  grid10: { marginTop: 20 },
  grid11: { display: "flex", justifyContent: "center" },
  Typography3: {
    paddingBottom: 5,
    display: "flex",
    justifyContent: "center",
  },
  grid12: { paddingLeft: 25 },
  Typography4: {
    paddingBottom: 5,
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  grid13: { marginTop: 15 },
  grid14: { display: "flex", justifyContent: "flex-end" },
  grid15: { display: "flex", justifyContent: "flex-start" },
  Typography5: { fontWeight: "600" },
  Typography6: { display: "flex", marginLeft: "auto" },
  CustomButton3: { marginLeft: 30, fontSize: 12 },
  mainDiv: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  TableCell: { display: "flex", marginLeft: -20 },
  Typography7: {
    fontWeight: "600",
    marginRight: 10,
  },
  Typography8: { fontWeight: "600", margin: "15px 0px" },
  iconColor: { color: "#000" },
  CardContent: { padding: 10 },
  size: { fontSize: 46 },
  Typography: {
    overflowWrap: "anywhere",
    fontWeight: "400",
  },
  Typography9: {
    overflowWrap: "break-word",
    fontWeight: "400",
  },
  grid16: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default materialityAssessmentRightStyles;
