import { useNavigate } from "react-router-dom";

import Heading from "components/UI/phase-3/heading/Heading";
import { Button } from "@mui/material";
import PlusOneOutlined from "@mui/icons-material/Add";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";

function EsgComplianceListing() {
  const navigate = useNavigate();
  return (
    <>
      <Heading text="">
        <Button
          variant="contained"
          size="medium"
          startIcon={<PlusOneOutlined />}
          onClick={() => navigate("/esg_compliance/New_Framework")}
        >
          New Report
        </Button>
      </Heading>

      <NoDataComponent mainTitle={"No Framework Selected"} />
    </>
  );
}

export default EsgComplianceListing;
