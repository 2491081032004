import React from "react";

function useHoverEffect() {
  const [isHovering, setIsHovering] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(false);

  const handleMouseEnter = (hasChange = false) => {
    hasChange === true && setIsHovering(true);
    typeof hasChange === "number" && setSelectedItem(hasChange);
  };

  const handleMouseLeave = (hasChange = false) => {
    hasChange === true && setIsHovering(false);
    typeof hasChange === "number" && setSelectedItem(false);
  };
  return { isHovering, selectedItem, handleMouseEnter, handleMouseLeave };
}

export default useHoverEffect;
