import UNIVERSAL from "../../config/config";
import {
  SET_ENVIRONMENT_SURVEY,
  SET_INTERNAL_MATURITY_MATRIX,
  SET_MATURITY_MATRIX_ASSIGNED_MEMBER,
  SET_TASK_ANSWERS,
  SET_EXTERNAL_MATURITY_MATRIX,
  SET_MATURITY_MATRIX_TASK_QUESTIONS,
  SET_OVERALL_SURVEY,
  SET_SURVEY_QUESTIONS,
  RESET_MATURITY_MATRIX,
  SET_MATURITY_MATRIX_NOT_ASSIGNED_USERS,
} from "../../constants/maturityMetricsConst/maturityMatrixConstants";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../../actions/snackbar/snackbar_action";
import { set_loader, unset_loader } from "../../actions/loader/loader_action";

export const setAllSurvey = (payload) => {
  return {
    type: SET_OVERALL_SURVEY,
    payload: payload,
  };
};
export const setChartSurvey = (payload) => {
  return {
    type: SET_ENVIRONMENT_SURVEY,
    payload: payload,
  };
};

export const setSurveyQuestions = (payload) => {
  return {
    type: SET_SURVEY_QUESTIONS,
    payload: payload,
  };
};
export const setTaskAnswers = (payload) => {
  return {
    type: SET_TASK_ANSWERS,
    payload: payload,
  };
};

export const setMaturityMatrixAssignedMembers = (payload) => {
  return {
    type: SET_MATURITY_MATRIX_ASSIGNED_MEMBER,
    payload: payload,
  };
};

export const setMaturityMatrixTaskQuestions = (payload) => {
  return {
    type: SET_MATURITY_MATRIX_TASK_QUESTIONS,
    payload: payload,
  };
};
export const setInternalMaturityMatrix = (payload) => {
  return {
    type: SET_INTERNAL_MATURITY_MATRIX,
    payload: payload,
  };
};

export const setExternalMaturityMatrix = (payload) => {
  return {
    type: SET_EXTERNAL_MATURITY_MATRIX,
    payload: payload,
  };
};

export const setMaturityMatrixNotAssignedUsers = (payload) => {
  return {
    type: SET_MATURITY_MATRIX_NOT_ASSIGNED_USERS,
    payload: payload,
  };
};

export const resetMaturityMatrix = () => {
  return {
    type: RESET_MATURITY_MATRIX,
  };
};

export function getMaturityMatrix(token) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
    });
    return fetch(UNIVERSAL.BASEURL + "/maturity_matrix/get_maturity_matrix", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSurveyQuestions(responseJson.result));
          dispatch(setMaturityMatrixTaskQuestions(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getInternalMaturityMatrix(token, assessment_id) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/maturity_matrix/get_internal_maturity_matrix",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setInternalMaturityMatrix(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function getExternalUsers(token, assessment_id) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/maturity_matrix/get_external_users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setExternalMaturityMatrix(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateMaturityMatrix(
  token,
  assessment_id,
  q_id,
  value,
  comment,
  question_no,
  organisation_id
) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id,
      question_id: q_id,
      value,
      comment,
      question_no,
      organisation_id,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/maturity_matrix/update_maturity_matrix",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setTaskAnswers(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function getAssignedMaturityMatrix(token, assessment_id) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id,
    });
    // console.log('final data',data)
    return fetch(
      UNIVERSAL.BASEURL + "/maturity_matrix/get_assigned_maturity_matrix",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setTaskAnswers(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function viewAssignedMembers(token, assessment_id, step_no) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id,
      step_no,
    });
    return fetch(UNIVERSAL.BASEURL + "/assignMembers/view_stakeholder", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setMaturityMatrixAssignedMembers(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((err) => console.log(err));
  };
}

export function viewAssignedMatrixUsers(token, assessment_id, organisation_id) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id,
      organisation_id,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/assignmembers/get_maturity_matrix_users",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setMaturityMatrixNotAssignedUsers(responseJson.result));
          dispatch(unset_loader());
        }
      })
      .catch((err) => console.log(err));
  };
}
