import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import freshDesk from "../../../images/freshDesk.png";
import CSV from "../../../images/CSV.png";
import googleSheets from "../../../images/googleSheets.png";
import jira from "../../../images/jira.png";
import salesForce from "../../../images/salesForce.png";
import quickbooks from "../../../images/quickBooks.png";
import zendesk from "../../../images/zendesk.png";
import airtable from "../../../images/airtable.png";
import chargify from "../../../images/chargify.png";
import surveyMonkey from "../../../images/surveyMonkey.png";
import mailgun from "../../../images/mailgun.png";
import powerBi from "../../../images/powerBi.png";
import oracleDb from "../../../images/oracleDb.png";
import mySQL from "../../../images/mySQL.png";
import postgresQL from "../../../images/postgresQL.png";
import mongoDB from "../../../images/mongoDB.png";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import {
  AppBar,
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  Tab,
  Tabs,
  adaptV4Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import CustomButton from "../../UI/button/button";

function DataHubIntegrationDrawer(props) {
  const { isIntegrationDrawerOpen, handleDrawer, setIsIntegrationDrawerOpen } =
    props;
  const theme = createTheme(
    adaptV4Theme({
      palette: {
        secondary: {
          main: "#47BB76",
        },
        // [theme.breakpoints.down('lg')]: {
        //   width: '83%',
        //   marginTop: '8px'
        // }
      },
      topicname: {
        fontFamily: ["poppins", "sans-serif"].join(","),
      },
      selectTopicText: {
        color: "#9FA1AF",
        fontSize: 13,
        padding: "10px 0px",
        fontFamily: ["poppins", "sans-serif"].join(","),
      },
      containerSelectTopicText: {
        marginBottom: 7,
      },
      emptyTopicBar: {
        width: "100%",
        height: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#DADBE655",
        borderRadius: 10,
      },
    })
  );
  const integraionStyles = makeStyles(() => ({
    AppBar: { boxShadow: "none", marginTop: "10px" },
    tab: {
      fontSize: 12,
      minWidth: "110px",
    },
    tabLabel: {
      textTransform: "capitalize",
      fontSize: 14,
      fontFamily: "Poppins",
    },
  }));
  const classes = integraionStyles();

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <Box>{children}</Box>}</div>;
  }

  const [IntegrationTabNo, setIntegrationTabNo] = useState(0);
  const miscellaneous = [
    {
      id: 1,
      img: CSV,
      connected: false,
      name: "Comma Separated Values",
      discription:
        "The target-csv loader sends data into Comma Separated Values (CSV) after it was pulled from a source using an extractor",
    },
    {
      id: 2,
      img: googleSheets,
      connected: false,
      name: "GoogleSheets",
      discription:
        "The target-gsheet loader sends data into Google Sheets after it was pulled from a source using an extractor",
    },
    {
      id: 10,
      img: surveyMonkey,
      connected: false,
      name: "SurveyMonkey",
      discription:
        "The tap-surveymonkey extractor pulls data from SurveyMonkey that can then be sent to a destination using a loader.",
    },
    {
      id: 11,
      img: mailgun,
      connected: false,
      name: "Mailgun",
      discription:
        "The tap-mailgun extractor pulls data from Mailgun that can then be sent to a destination using a loader.",
    },
    {
      id: 12,
      img: powerBi,
      connected: false,
      name: "PowerBi",
      discription:
        "The tap-powerbi-metadata extractor pulls data from PowerBI that can then be sent to a destination using a loader.",
    },
  ];
  const dataBase = [
    {
      id: 13,
      img: oracleDb,
      connected: false,
      name: "OracleDb",
      discription:
        "The tap-oracle extractor pulls data from Oracle DB that can then be sent to a destination using a loader.",
    },
    {
      id: 14,
      img: mySQL,
      connected: false,
      name: "MySQL",
      discription:
        "The tap-mysql extractor pulls data from MySQL that can then be sent to a destination using a loader.",
    },
    {
      id: 15,
      img: postgresQL,
      connected: false,
      name: "PostgreSQL",
      discription:
        "The target-postgres loader sends data into PostgreSQL after it was pulled from a source using an extractor",
    },
    {
      id: 16,
      img: mongoDB,
      connected: false,
      name: "MongoDB",
      discription:
        "The tap-mongodb extractor pulls data from MongoDB that can then be sent to a destination using a loader.",
    },
  ];
  const SaaS = [
    {
      id: 3,
      img: freshDesk,
      connected: false,
      name: "FreshDesk",
      discription:
        "The tap-freshdesk extractor pulls data from Freshdesk that can then be sent to a destination using a loader.",
    },
    {
      id: 4,
      img: jira,
      connected: false,
      name: "Jira",
      discription:
        "The tap-jira extractor pulls data from Jira that can then be sent to a destination using a loader.",
    },
    {
      id: 5,
      img: salesForce,
      connected: false,
      name: "SalesForce",
      discription:
        "The tap-salesforce extractor pulls data from Salesforce that can then be sent to a destination using a loader.",
    },
    {
      id: 6,
      img: quickbooks,
      connected: false,
      name: "QuickBooks",
      discription:
        "The tap-quickbooks extractor pulls data from Quickbooks that can then be sent to a destination using a loader.",
    },
    {
      id: 7,
      img: zendesk,
      connected: false,
      name: "Zendesk",
      discription:
        "The tap-zendesk extractor pulls data from Zendesk that can then be sent to a destination using a loader.",
    },
    {
      id: 8,
      img: airtable,
      connected: false,
      name: "Airtable",
      discription:
        "The target-airtable loader sends data into Airtable after it was pulled from a source using an extractor",
    },
    {
      id: 9,
      img: chargify,
      connected: false,
      name: "Chargify",
      discription:
        "The tap-chargify extractor pulls data from Chargify that can then be sent to a destination using a loader.",
    },
  ];
  // const [intigrationList, setIntigrationList] = useState(integrated);

  const handleConnect = (id) => {
    console.log(id);
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={isIntegrationDrawerOpen}
        onClose={() => setIsIntegrationDrawerOpen((prev) => !prev)}
        PaperProps={{ style: { background: "#F6F6F6" } }}
      >
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#FFFFFF",
            boxShadow: "none",
            borderRadius: "5px",
            padding: "16px",
            height: "72px",
          }}
        >
          <CardContent style={{ marginRight: "12px" }}>
            <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
              Integration & Connected Apps
            </Typography>
          </CardContent>
          {/* <CardActions onClick={() => handleCloseDrawer()}> */}
          <CardActions
            onClick={() => setIsIntegrationDrawerOpen((prev) => !prev)}
          >
            <CloseIcon style={{ color: "#3374B9", cursor: "pointer" }} />
          </CardActions>
        </Card>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppBar
              position="static"
              color="inherit"
              className={classes.AppBar}
            >
              <Tabs
                value={IntegrationTabNo}
                onChange={(e, i) => {
                  setIntegrationTabNo(i);
                }}
              >
                <Tab
                  disableRipple
                  className={classes.tab}
                  label={<span className={classes.tabLabel}>SaaS</span>}
                />
                <Tab
                  disableRipple
                  className={classes.tab}
                  label={<span className={classes.tabLabel}>Data Base</span>}
                />
                {/* <Tab
                  disableRipple
                  className={classes.tab}
                  label={
                    <span className={classes.tabLabel}>Project Mangement</span>
                  }
                /> */}
                <Tab
                  disableRipple
                  className={classes.tab}
                  label={
                    <span className={classes.tabLabel}>Miscellaneous</span>
                  }
                />
              </Tabs>
            </AppBar>
          </ThemeProvider>
        </StyledEngineProvider>
        <div
          style={{
            width: "50vw",
            overflowY: "scroll",
            zIndex: "-1",
            height: "80vh",
          }}
        >
          <TabPanel value={IntegrationTabNo} index={0}>
            <Grid>
              {/* <Typography  style={{
            borderRadius: ' 5px',
          //   background: ' #FFFFFF',
            width: '95%',
            marginLeft: '2%',
          }}
          >Overall</Typography> */}
              {SaaS.map((company, i) => {
                return (
                  <Card
                    style={{
                      borderRadius: " 5px",
                      //   background: ' #FFFFFF',
                      marginTop: "15px",
                      width: "95%",
                      marginLeft: "2%",
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <img
                            src={company.img}
                            width="50%"
                            height="40%%"
                            alt="freshDesk"
                          />
                        </Grid>
                        <Grid item>
                          <CustomButton
                            onClick={() => handleConnect(company.id)}
                            variant={
                              company.connected ? "outlined" : "contained"
                            }
                            color="secondary"
                          >
                            <ImportExportIcon />
                            {company.connected ? "Connected" : "Connect"}
                          </CustomButton>
                        </Grid>
                      </Grid>
                      <Grid Style={{ marginTop: "10px" }}>
                        <Typography
                          style={{ fontWeight: "bold", marginTop: "8px" }}
                        >
                          {company.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "small",
                            color: "grey",
                            marginTop: "2px",
                          }}
                        >
                          {company.discription}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ paddingTop: "15px", paddingBottom: "12px" }}
                      >
                        <div style={{ border: "0.5px solid lightgrey" }} />
                      </Grid>{" "}
                      <Grid>
                        <Link
                          style={{
                            float: "right",
                            fontSize: "medium",
                            padding: "0px 5px 10px 5px",
                          }}
                        >
                          View Integration
                        </Link>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
          </TabPanel>
          <TabPanel value={IntegrationTabNo} index={1}>
            <Grid>
              {/* <Typography  style={{
            borderRadius: ' 5px',
          //   background: ' #FFFFFF',
            width: '95%',
            marginLeft: '2%',
          }}
          >Overall</Typography> */}
              {dataBase.map((company, i) => {
                return (
                  <Card
                    style={{
                      borderRadius: " 5px",
                      //   background: ' #FFFFFF',
                      marginTop: "15px",
                      width: "95%",
                      marginLeft: "2%",
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <img
                            src={company.img}
                            width="50%"
                            height="40%%"
                            alt="freshDesk"
                          />
                        </Grid>
                        <Grid item>
                          <CustomButton
                            onClick={() => handleConnect(company.id)}
                            variant={
                              company.connected ? "outlined" : "contained"
                            }
                            color="secondary"
                          >
                            <ImportExportIcon />
                            {company.connected ? "Connected" : "Connect"}
                          </CustomButton>
                        </Grid>
                      </Grid>
                      <Grid Style={{ marginTop: "10px" }}>
                        <Typography
                          style={{ fontWeight: "bold", marginTop: "8px" }}
                        >
                          {company.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "small",
                            color: "grey",
                            marginTop: "2px",
                          }}
                        >
                          {company.discription}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ paddingTop: "15px", paddingBottom: "12px" }}
                      >
                        <div style={{ border: "0.5px solid lightgrey" }} />
                      </Grid>{" "}
                      <Grid>
                        <Link
                          style={{
                            float: "right",
                            fontSize: "medium",
                            padding: "0px 5px 10px 5px",
                          }}
                        >
                          View Integration
                        </Link>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
          </TabPanel>
          {/* <TabPanel value={IntegrationTabNo} index={2}>
          <Grid>
            <Typography style={{textAlign:'center'}}>Project Management</Typography>
          </Grid>
        </TabPanel> */}
          <TabPanel value={IntegrationTabNo} index={2}>
            <Grid>
              {/* <Typography  style={{
            borderRadius: ' 5px',
          //   background: ' #FFFFFF',
            width: '95%',
            marginLeft: '2%',
          }}
          >Overall</Typography> */}
              {miscellaneous.map((company, i) => {
                return (
                  <Card
                    style={{
                      borderRadius: " 5px",
                      //   background: ' #FFFFFF',
                      marginTop: "15px",
                      width: "95%",
                      marginLeft: "2%",
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <img
                            src={company.img}
                            width="50%"
                            height="40%%"
                            alt="freshDesk"
                          />
                        </Grid>
                        <Grid item>
                          <CustomButton
                            onClick={() => handleConnect(company.id)}
                            variant={
                              company.connected ? "outlined" : "contained"
                            }
                            color="secondary"
                          >
                            <ImportExportIcon />
                            {company.connected ? "Connected" : "Connect"}
                          </CustomButton>
                        </Grid>
                      </Grid>
                      <Grid Style={{ marginTop: "10px" }}>
                        <Typography
                          style={{ fontWeight: "bold", marginTop: "8px" }}
                        >
                          {company.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "small",
                            color: "grey",
                            marginTop: "2px",
                          }}
                        >
                          {company.discription}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ paddingTop: "15px", paddingBottom: "12px" }}
                      >
                        <div style={{ border: "0.5px solid lightgrey" }} />
                      </Grid>{" "}
                      <Grid>
                        <Link
                          style={{
                            float: "right",
                            fontSize: "medium",
                            padding: "0px 5px 10px 5px",
                          }}
                        >
                          View Integration
                        </Link>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
          </TabPanel>
        </div>
      </Drawer>
    </>
  );
}

export default DataHubIntegrationDrawer;
