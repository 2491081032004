import { useState } from "react";

import { Box } from "@mui/material";
import DisclosureListing from "./DisclosureListing";
import ExploreDisclosure from "./ExploreDisclosure";

export default function ComplianceRight() {
  const [exploreDisclosure, setExploreDisclosure] = useState(false);
  const toggleExplore = () => {
    setExploreDisclosure(!exploreDisclosure);
  };
  return (
    <Box sx={{ height: "98%", overflow: "scroll" }}>
      {exploreDisclosure ? (
        <ExploreDisclosure toggleExplore={toggleExplore} />
      ) : (
        <DisclosureListing toggleExplore={toggleExplore} />
      )}
    </Box>
  );
}
