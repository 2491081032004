import { Stack, Typography, Chip, Box, Tabs, Tab } from "@mui/material";
import { GoDotFill } from "react-icons/go";

import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import { CustomNumber } from "../DisclosureListing";
import useTabChanges from "customHooks/useTabChanges";
import ChatMain from "./ChatMain";
import PinnedMessageMain from "./PinnedMessageMain";
import UserAvatar from "./chat/UerAvatar";
import ChatAction from "./chat/ChatAction";

const tabs = [
  {
    label: "Chat",
  },
  {
    label: "Pinned Suggestion",
  },
];

export default function ChatThreadMain({ hasDrawerOpen, handleClose }) {
  const { selectedTab, handleChange } = useTabChanges();
  return (
    <DynamicDrawer
      heading={
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={2}
        >
          <CustomNumber index={0} hasBGColor={true} />
          <Typography>
            <Typography variant="subtitle1">name of the company</Typography>
          </Typography>
          <Chip
            size="small"
            sx={{
              backgroundColor: "warning.lighter",
              color: "warning.darkest",
              marginLeft: "auto",
            }}
            icon={<GoDotFill color="warning.darkest" />}
            label="pending"
          />
        </Stack>
      }
      hasDrawerOpen={hasDrawerOpen}
      handleClose={handleClose}
      hasDefaultActionButton={false}
      hasCustomActionButton={selectedTab === 0 ? <ChatAction /> : false}
      hasSecondaryHeader={
        <>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="chat tabs"
            sx={{ marginBottom: "15px" }}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
          {selectedTab === 0 && <UserAvatar />}
        </>
      }
      sx={
        selectedTab === 0 && {
          border: "1px solid",
          borderColor: "primary.lighter",
        }
      }
    >
      <Box
        style={{
          height: "100%",
          width: "90vw",
          overflow: "scroll",
        }}
      >
        {selectedTab === 0 && <ChatMain />}
        {selectedTab === 1 && <PinnedMessageMain />}
      </Box>
    </DynamicDrawer>
  );
}
