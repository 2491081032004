import { setEsgSelectedTopic } from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import {
  getSubmittedMaterialityTopicData,
  setSubmittedMaterialityData,
  submitMaterialityTopicData,
} from "actions/phase-3/task/materiality/materialityTaskAction.js/materialityTaskAction";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useMaterialityTopicTask = () => {
  const dispatch = useDispatch();
  const { selectedMaterialityTopics } = useSelector(
    (state) => state?.materialityAssessment
  );

  const { esgSelectedTopic } = useSelector(
    (state) => state?.esgStrategyPlanning
  );

  const { materialitySelectedTask, materialitySubmittedData } = useSelector(
    (state) => state.materialityTask
  );

  const { entity_id } = useSelector((state) => state?.user);

  const { topics = [] } = selectedMaterialityTopics || {};
  const [topicCategory, setTopicCategory] = useState("");

  const handleCheck = (category) => {
    setTopicCategory(category);
  };

  const handleTopicIndex = () => {
    let index = topics.findIndex(
      (d) => esgSelectedTopic?.materiality_topic_id === d?.materiality_topic_id
    );
    return index;
  };

  const selectedTopicIndex = handleTopicIndex();
  const baseIds = {
    materiality_assessment_id:
      materialitySelectedTask?.materiality_assessment_id,
    entity_id,
    topic_id: esgSelectedTopic?.topic_id,
  };

  const isLastTopic = selectedTopicIndex === topics.length - 1;

  const handleNext = () => {

    !isLastTopic &&
      dispatch(setEsgSelectedTopic(topics[selectedTopicIndex + 1]));
  };

  const handlePrevious = () => {
    selectedTopicIndex !== 0 &&
      dispatch(setEsgSelectedTopic(topics[selectedTopicIndex - 1]));
  };

  const hasChanges = (original, updated) => {
    return JSON.stringify(original) !== JSON.stringify(updated);
  };

  const handleSubmitData = async (basicDetails) => {
    let response;

    // check changes in inputs
    if (hasChanges(materialitySubmittedData, basicDetails)) {
      response = await dispatch(
        submitMaterialityTopicData({ ...baseIds, ...basicDetails })
      );

      response?.status && handleNext();
    } else {

      handleNext();
    }

    return response;
  };

  const handleGetSubmittedTopicData = async () => {

    const response = await dispatch(
      getSubmittedMaterialityTopicData({ ...baseIds })
    );


    return response;
  };

  useEffect(() => {

    handleGetSubmittedTopicData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopicIndex]);

  return {
    topicCategory,
    inputLabel: topicCategory ? topicCategory : "Risk / Opportunity",
    handleCheck,
    selectedTopic: esgSelectedTopic,
    selectedTopicIndex: handleTopicIndex(),
    handleSubmitData,
    materialitySubmittedData,
    handlePrevious,
    isLastTopic,
  };
};

export default useMaterialityTopicTask;
