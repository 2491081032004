import React from "react";
import PercentageDoughnutChart from "components/UI/phase-3/percentageDoughnut.js/percentageDoughnutChart";
import Typography from "@mui/material/Typography";
import Folder from "images/Folder.png";
import charge from "images/charge.png";
function CompanyProfile({ scores }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 10,
      }}
    >
      <div style={{ padding: "5px", width: "100%" }}>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "13px",
            color: "#15314E",
            padding: "10px 0px",
          }}
        >
          Our Self Assessment Score provides valuable insights into your ESG
          practices, helping you meet stakeholder expectations and work towards
          a more sustainable future.
        </Typography>
        <div style={{ display: "flex", paddingBotttom: "20px" }}>
          <div style={{ width: "80%", marginTop: "15px" }}>
            <div style={{ width: "65%", display: "flex" }}>
              <img
                src={charge}
                alt="Folder"
                style={{ width: "40px", height: "40px" }}
              />

              <PercentageDoughnutChart
                color="#88bf71"
                emptyColor="#EBF6EF"
                // percentage={scores ? Math.round(scores) : 0}
                percentage={25}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "40px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#88bf71",
                  borderRadius: "100%",
                  width: "12px",
                  height: "12px",
                }}
              />{" "}
              <Typography
                style={{
                  marginLeft: "10px",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Self Assessment Score
              </Typography>
            </div>
          </div>
          {true && (
            <div style={{ width: "60%", marginTop: "15px" }}>
              <div style={{ width: "65%", display: "flex" }}>
                <img
                  src={Folder}
                  alt="Folder"
                  style={{ width: "40px", height: "40px" }}
                />

                <PercentageDoughnutChart
                  color="rgb(255, 159, 64)"
                  emptyColor="#EBF6EF"
                  percentage={50}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "40px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgb(255, 159, 64)",
                    borderRadius: "100%",
                    width: "12px",
                    height: "12px",
                  }}
                />
                <Typography
                  style={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Public Disclosure Score
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyProfile;
