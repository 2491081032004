import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";

import requiredField from "components/UI/phase-3/inputField/requiredShow";

import { useDispatch, useSelector } from "react-redux";
import { setUserDetails, userVerifyOTP } from "actions/phase-3/auth/authAction";
import OTPInput from "components/UI/phase-3/inputField/OTPInput";

const VerifyForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email, signup_process } = useSelector((state) => state.user);

  const [otp, setOTP] = useState("");

  const handleOtpInput = (otpValue) => {
    setOTP(otpValue);
  };

  const disableSubmit = !email || !otp || otp.length < 4;

  const onSubmit = (e) => {
    e.preventDefault();
    // submit logic
    if (!disableSubmit) {
      dispatch(userVerifyOTP(email, otp));
    }
  };

  const backToSignup = () => {
    dispatch(setUserDetails({ email: "", signup_process: 0 }));
  };

  useEffect(() => {
    if (!email || signup_process !== 1) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, signup_process]);

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        {/* Work Email */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Work Email
          </Typography>
          <TextField
            name="email"
            type="email"
            placeholder="Your Work Email"
            value={email}
            InputProps={{ readOnly: true }}
            required
            fullWidth
          />
        </Box>

        {/* OTP Input */}
        <Box>
          <Typography variant="body1" color="action.disabled" gutterBottom>
            Enter OTP {requiredField(!otp || otp.length < 4)}
          </Typography>
          <Box width={"20em"}>
            <OTPInput
              length={4}
              onChange={handleOtpInput}
              placeholder={"-"}
              inputPad={"5px"}
              allowedType="numbers"
              size={"small"}
            />
          </Box>
        </Box>

        {/* Actions */}
        <Stack spacing={2} textAlign={"center"}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={disableSubmit}
            fullWidth
          >
            Verify OTP
          </Button>
          <Box>
            <Typography variant="body1" color={"action.disabled"}>
              Don't have an account?{" "}
              <Button
                component={Link}
                to="/"
                variant="text"
                onClick={backToSignup}
                sx={{ color: "primary.light", p: 0 }}
              >
                Signup here
              </Button>
            </Typography>
            <Typography variant="body1" color={"action.disabled"}>
              <Button variant="text" sx={{ color: "primary.light", p: 0 }}>
                Terms of services
              </Button>{" "}
              and{" "}
              <Button variant="text" sx={{ color: "primary.light", p: 0 }}>
                Privacy Policy
              </Button>
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};
export default VerifyForm;
