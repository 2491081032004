import { Box, Tab, Tabs } from "@mui/material";

import DatActivities from "./DatActivities";
import DatKpis from "./DatKpis";
import DatPolicies from "./DatPolicies";
import DatRisk from "./DatRisk";
import useTabChanges from "customHooks/useTabChanges";
import { useEsgStrategyPlanningTopic } from "../../APIs/HandleTopicApi";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";

const tabs = [
  { label: "Activities", component: <DatActivities /> },
  { label: "KPI's", component: <DatKpis /> },
  { label: "Policies", component: <DatPolicies /> },
  { label: "Risk", component: <DatRisk /> },
];

function DefineAgainstTopicsRight() {
  const { handleChange, selectedTab } = useTabChanges();
  const { esgSelectedTopic } = useEsgStrategyPlanningTopic(false, selectedTab);

  return (
    <>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="define-against-topic"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
        <Box mt={1}>
          {!esgSelectedTopic ? (
            <NoDataComponent
              mainTitle={`Select Topic to Add ${tabs[selectedTab].label}`}
            />
          ) : (
            tabs[selectedTab].component
          )}
        </Box>
      </Box>
    </>
  );
}

export default DefineAgainstTopicsRight;
