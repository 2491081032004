import { Box } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import React from "react";

function SurveyBasicDetails() {
  return (
    <Box>
      <Heading text={"Basic Details"} size={"15px"} marginBottom={"8px"} bg />
      <Box className="scroll">
        <InputField heading={"Survey Name"} label={"Enter name here"} />
        <InputField
          heading={"Survey Details"}
          label={"Enter description here"}
          rows={10}
        />
      </Box>
    </Box>
  );
}

export default SurveyBasicDetails;
