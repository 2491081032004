import React, { useEffect, useState } from "react";

import RadarChart from "../../../../UI/maturityMatrix/RadarChart";
import {
  AppBar,
  Box,
  createTheme,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#47BB76",
    },
    // [theme.breakpoints.down('lg')]: {
    //   width: '83%',
    //   marginTop: '8px'
    // }
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  containerSelectTopicText: {
    marginBottom: 7,
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
});

const esgBreakdownStyles = makeStyles(() => ({
  AppBar: { boxShadow: "none" },
  tab: {
    fontSize: 12,
    minWidth: "85px",
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
}));

function EsgBreakdown(props) {
  const classes = esgBreakdownStyles();

  const [ChartTabNo, setChartTabNo] = useState(0);

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <Box>{children}</Box>}</div>;
  }

  const {
    login,
    asessment_id,
    updateMaturityMatrix,
    maturityMatrix,
    getMaturityMatrix,
    basicInfo,
    currentMaturityData,
  } = props;
  const [questions, setQuestions] = useState(maturityMatrix?.questions);
  const [collect, setCollect] = useState(
    (currentMaturityData?.calculated_matrix_obj &&
      currentMaturityData?.calculated_matrix_obj) ||
      {}
  );
  const [chartEsgData, setChartEsgData] = useState([]);
  const [chart2EsgData, setChart2EsgData] = useState([]);
  const [chart3EsgData, setChart3EsgData] = useState([]);
  const [displayChart, setDisplayChart] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [allData, setAllData] = useState({});

  useEffect(() => {
    charting();
    // console.log('obj',currentMaturityData)
    // console.log('questions',maturityMatrix.questions)
  }, [collect]);

  const charting = () => {
    let cat1 = { e: [], s: [], g: [], b: [], h: [] };
    let cat2 = {
      Experience: [],
      Materiality: [],
      stake: [],
      esg: [],
      innovation: [],
      data: [],
      supply: [],
      policy: [],
      incentives: [],
    };
    let radarObj = {
      bmi: [],
    };

    const array = [
      "Business Model Resilience",
      "Materials Sourcing & Efficiency",
      "Physical Impacts of Climate Change",
      "Product Design & Lifecycle Management",
      "Supply Chain Management",
      "Air Quality",
      "Ecological Impacts",
      "Energy Management",
      "GHG Emissions",
      "Waste & Hazardous Materials Management",
      "Water & Wastewater Management",
      "Employee Engagement, Diversity & Inclusion",
      "Employee Health & Safety",
      "Labor Practices",
      "Business Ethics",
      "Competitive Behavior",
      "Critical Incident Risk Management",
      "Management of the Legal & Regulatory Environment",
      "Systemic Risk Management",
      "Access & Affordability",
      "Customer Privacy",
      "Customer Welfare",
      "Data Security",
      "Human Rights & Community Relations",
      "Product Quality & Safety",
      "Selling Practices & Product Labeling",
    ];
    const environmentObj = {};
    const socialObj = {};
    const governanceObj = {};
    const businessObj = {};
    const humanObj = {};

    let chart3data = [
      radarObj.bmi.reduce((total, next) => {
        return Number(total) + Number(next);
      }, 0),
    ];

    if (Object.keys(collect).length > 0 && questions?.length > 0) {
      let qNumb = 0;
      for (let key in collect) {
        qNumb++;
        if (questions[qNumb - 1].category1 === "Environment") {
          cat1.e.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            environmentObj[questions[qNumb - 1].category2] = environmentObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: "Environment",
                  ans:
                    Number(environmentObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(
                      environmentObj[questions[qNumb - 1].category2].total
                    ) + Number(5),
                }
              : {
                  category: "Environment",
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === "Social Capital") {
          cat1.s.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            socialObj[questions[qNumb - 1].category2] = socialObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: "Social Capital",
                  ans:
                    Number(socialObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(socialObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: "Social Capital",
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === "Leadership & Governance") {
          cat1.g.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            governanceObj[questions[qNumb - 1].category2] = governanceObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: "Leadership & Governance",
                  ans:
                    Number(governanceObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(
                      governanceObj[questions[qNumb - 1].category2].total
                    ) + Number(5),
                }
              : {
                  category: "Leadership & Governance",
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === "Business Model & Innovation") {
          cat1.b.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            businessObj[questions[qNumb - 1].category2] = businessObj[
              questions[qNumb - 1].category2
            ]
              ? // console.log('a',(Number(businessObj[questions[qNumb - 1].category2].ans)))
                {
                  category: "Business Model & Innovation",
                  ans:
                    Number(businessObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(businessObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: "Business Model & Innovation",
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
        if (questions[qNumb - 1].category1 === "Human Capital") {
          cat1.h.push(collect[key]);
          if (array.includes(questions[qNumb - 1].category2)) {
            humanObj[questions[qNumb - 1].category2] = humanObj[
              questions[qNumb - 1].category2
            ]
              ? {
                  category: "Human Capital",
                  ans:
                    Number(humanObj[questions[qNumb - 1].category2].ans) +
                    Number(collect[key]),
                  total:
                    Number(humanObj[questions[qNumb - 1].category2].total) +
                    Number(5),
                }
              : {
                  category: "Human Capital",
                  ans: Number(collect[key]),
                  total: 5,
                };
          }
        }
      }

      let chartData = [
        cat1.e.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) /
          30 /
          currentMaturityData.total_no_of_users,
        cat1.s.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) /
          65 /
          currentMaturityData.total_no_of_users,
        cat1.g.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) /
          35 /
          currentMaturityData.total_no_of_users,
        cat1.b.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) /
          50 /
          currentMaturityData.total_no_of_users,
        cat1.h.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) /
          20 /
          currentMaturityData.total_no_of_users,
      ];

      let chart2data = [
        cat2.Experience.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.Materiality.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 30,
        cat2.stake.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 25,
        cat2.esg.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.innovation.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.data.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 10,
        cat2.supply.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.policy.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 15,
        cat2.incentives.reduce((total, next) => {
          return Number(total) + Number(next);
        }, 0) / 10,
      ];

      setChartEsgData(chartData);
      setChart2EsgData(chart2data);
      setAllData({
        ...allData,
        environmentObj: environmentObj,
        socialObj: socialObj,
        governanceObj: governanceObj,
        businessObj: businessObj,
        humanObj: humanObj,
      });
    } else {
    }
  };

  return (
    <Box>
      <AppBar position="static" color="inherit" className={classes.AppBar}>
        <Tabs
          value={ChartTabNo}
          variant="scrollable"
          onChange={(e, i) => {
            setChartTabNo(i);
          }}
        >
          <Tab
            disableRipple
            className={classes.tab}
            label={<span className={classes.tabLabel}>Over All</span>}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<span className={classes.tabLabel}>Environment</span>}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<span className={classes.tabLabel}>Social Capital</span>}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<span className={classes.tabLabel}>Governance</span>}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<span className={classes.tabLabel}>Business</span>}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<span className={classes.tabLabel}>Human Capital</span>}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={ChartTabNo} index={0}>
        <Grid>
          {/* <Typography>{`${
            currentMaturityData?.organisation_name
              ? currentMaturityData?.organisation_name
              : basicInfo?.companyName
          } Overall`}</Typography> */}
          <RadarChart
            {...props}
            allData={allData}
            chartEsgData={chartEsgData}
            ChartTabNo={ChartTabNo}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={ChartTabNo} index={1}>
        <Typography>{`${
          currentMaturityData?.organisation_name
            ? currentMaturityData?.organisation_name
            : basicInfo?.companyName
        } Environment`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={2}>
        <Typography>{`${
          currentMaturityData?.organisation_name
            ? currentMaturityData?.organisation_name
            : basicInfo?.companyName
        } Social Capital`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={3}>
        <Typography>{`${
          currentMaturityData?.organisation_name
            ? currentMaturityData?.organisation_name
            : basicInfo?.companyName
        } Governance`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={4}>
        <Typography>{`${
          currentMaturityData?.organisation_name
            ? currentMaturityData?.organisation_name
            : basicInfo?.companyName
        } Business`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
      <TabPanel value={ChartTabNo} index={5}>
        <Typography>{`${
          currentMaturityData?.organisation_name
            ? currentMaturityData?.organisation_name
            : basicInfo?.companyName
        } Human Capital`}</Typography>
        <RadarChart
          {...props}
          allData={allData}
          chartEsgData={chartEsgData}
          ChartTabNo={ChartTabNo}
        />
      </TabPanel>
    </Box>
  );
}

export default EsgBreakdown;
