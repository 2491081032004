import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TopicCard } from "../../reportdataleft/reportDataLeft";
import { setSelectedTopic } from "../../../../actions/datahubActions/datahubActions";

// Main Topics for BRSR
const topicNameBRSR = [
  "Social Capital",
  "Environment",
  "Human Capital",
  "Business Model & Innovation",
  "Leadership & Governance",
];
// Sub Topics for BRSR
const topicArrBRSR = {
  "Social Capital": [
    "Security, Human rights & rights of indigenous peoples",
    "Complaints",
    "Wages",
    "Materials",
  ],
  Environment: ["Waste", "Greenhouse Gas Emission", "Energy", "Water"],
  "Human Capital": [
    "Workforce Health & safety",
    "Labour Relations",
    "Training & Awareness Programmes",
    "Employee Diversity and Inclusion",
  ],
  "Business Model & Innovation": ["Product specification & clean fuel blends"],
  "Leadership & Governance": ["Legal Summary"],
};

// --------------------------------------------

// Main Topics for GRI
const topicNameGRI = [
  "Social Capital",
  "Environment",
  "Human Capital",
  "Business Model & Innovation",
  "Leadership & Governance",
];
// Sub Topics for GRI
const topicArrGRI = {
  "Social Capital": [
    "Security, Human rights & rights of indigenous peoples",
    "Wages",
    "Materials",
  ],
  Environment: [
    "Waste",
    "Greenhouse Gas Emission",
    "Energy",
    "Water",
    "Biodiversity",
    "Suppliers",
  ],
  "Human Capital": [
    "Workforce Health & safety",
    "Training & Awareness Programmes",
    "Employee Diversity and Inclusion",
    "Economic Performance",
    "Incidents",
  ],
  "Business Model & Innovation": ["Product specification & clean fuel blends"],
  "Leadership & Governance": ["Legal Summary", "Geographical Presence"],
};

function TopicCards() {
  const dataHubStore = useSelector((state) => state.dataHub);

  const { selectedTopic, selectedKpiType } = dataHubStore;

  const dispatch = useDispatch();

  const [hoverTopic, setHoverTopic] = useState(null);

  const topicNameObj =
    selectedKpiType === "BRSR" ? topicNameBRSR : topicNameGRI;

  const topicArr = selectedKpiType === "BRSR" ? topicArrBRSR : topicArrGRI;

  const cards = topicNameObj.map((topicName) => (
    <TopicCard topicMetric={{ topic_name: topicName }}>
      {topicArr[topicName].map((topic) => (
        <Box
          style={{
            borderBottom: "1px solid #EBF1F8",
            backgroundColor:
              hoverTopic === topic || selectedTopic === topic
                ? "#EBF1F8"
                : "#FBFBFB",
          }}
          onMouseEnter={() => setHoverTopic(topic)}
          onMouseLeave={() => setHoverTopic(null)}
          onClick={() => dispatch(setSelectedTopic(topic))}
        >
          <Typography
            style={{
              fontSize: "14px",
              color: "#15314E",
              padding: "15px",
              cursor: "pointer",
              fontWeight: 600,
            }}
          >
            {topic}
          </Typography>
        </Box>
      ))}
    </TopicCard>
  ));

  return cards;
}

export default TopicCards;
