import {
  SET_MATERIALITY_ASSESSMENT_TASK_LIST,
  SET_MATERIALITY_SELECTED_TASK,
  SET_SUBMITTED_MATERIALITY_DATA
} from "constants/phase-3/task/materialityMapping/materialityMappingTaskConst";

const initial_state = {
  materialityAssessmentTaskList: [],
  materialitySelectedTask: null,
  materialitySubmittedData: null
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_MATERIALITY_ASSESSMENT_TASK_LIST:
      return (state = {
        ...state,
        materialityAssessmentTaskList: action.payload,
      });

    case SET_MATERIALITY_SELECTED_TASK:
      return (state = {
        ...state,
        materialitySelectedTask: action.payload,
      });

      case SET_SUBMITTED_MATERIALITY_DATA:
        return (state = {
          ...state,
          materialitySubmittedData: action.payload,
        });

    default:
      return state;
  }
}
