import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Heading from "components/UI/phase-3/heading/Heading";
const useStyle = makeStyles((theme) => ({
  card: {
    backgroundColor: "#fafafa",
    padding: "15px",
    borderRadius: "10px",
  },
  container: {
    height: "80vh",
    overflow: "scroll",
  },
  description: {
    fontSize: "13px",
  },
}));

const items = [
  {
    title: "ESG Reporting & Compliance",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/da35/b281/10faa11508b8c9e1dd95e91c913d0f84?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c307qZF9mSSsOOcFSTzJ-SOxrUrG23u4MtgKzw5oDmEt43edr1tfn5jCbiTwB7CW-4UOs~4CcrMro7U7y~3f0hqHcjxm6XAN3yP-ZVnuGPQeNnxmvEdNRMcrJKAt9wUNB-lbh5FYLr5V-Zu03vLI7egzohO~a8xLECa1K09bW54iyn1WJd7nsAFv0OraSEz5T7e~Vt5YIKJe22K4sf40tMvSEchG5fSE30UuCo1BWEpSFQ52J5fF7vaWdcj0bB6lriJW25BHI4WgI2r~Nf4X5oKq9cBthho7A8OYyFEy8oKORU8V2nWaF5fa6XyA68iqk-2kO3iOjZrEPx5VyJ5oaw__",
  },
  {
    title: "GHG Accounting",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/c332/4c94/7c31c2f15ee6b52d684455184d6a7465?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GWrfOf5KPSSX1cWNJxB-5eResb3mkmZ7f5-RTWspvnxklv51M0U2iRiXU1UHNSWdcH1LYjWjJ0wg-jmH7lrlvGoyMh1NL~m2xSozPInvK-iGPOXteiznDH9EjEcN4wsBA-10uXdozDP4b9X~aR3ynLlfivh2yQwKYdgC1c9Xs4Vd-RXsX4MV8Yp7Ke314PqK9QQqztzUyfxjUw0IRHX1VGybPDEjSCSOXAGyjvQKg6c9jXf4dZX6WC47db8RU8PZSV07s16z9CzqxGqS34nmwXIrzpdWizoEmXs2qh1lne8SaKWGwin6M-Wkdu0tBVv8uxQb1xDK4CLLgQRSoGj~Ug__",
  },
  {
    title: "Organisation Structure",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/d12d/4b24/3f8f123ab335dfb499e79045ccf283ec?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bmhp5EKwIzjeCVgTjNVqqXL-Jb4sfT9lajqD8E1Evuw456pRfR5i4qz-CejpTCy5mQd973Yp0hlf19JMsK3bVXgScCXTfim6EBMnszlj3NrquYJT4GIrCetJ0fYuCDXNUk37kC37mWP0sm3MeLZ5V2nBP8~oVLM0Ilg5RY-humrzjIB2WvKFMjbAZzrGULw249AUF-j-koWoaF2rUpUZspD9cbNJyKAPVajI5~rdk7ps7NmUNWSsbyaHL6XlmPDwNtIodyminA-orOFCI~itmg0cYzqm7f-EaD309n7p4uyjlGHCRjTNkhtxLUOcC1UP-vPB2ffJpE~s3TYt8JM7tw__",
  },
  {
    title: "Supplier Assessment",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/825e/48fb/0c951201b36e3d7f98fddfb4574f774c?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DEqGDjHU8tjtr3NIvTNeiWTHNgZftYP5YxKFZWUQkhJ-e1D38fngHRuw4~zuV4LVNnDAKJukl5YuXfOeSRFz~wknKViU-wXJJdgbW6UGkL8tVDS3xAPXW~xf2vV-tnFQj9N342b99Qjdny31Yyl9BPCfDMDlkwEyZ3Ffch1mxXcDWg94fzjVTKfYgzt54FEDMua6OASilW8VTMHwaoN8EzwiK04k1ksi6cX3sSwN1SAfgLQJDGAr-6tUk-U-7Zi2jNNl-e~etdkRkmetwVcb3L6HE-AEPd8KIBsJS5dvPUffxFsfIrrNsdlNkphldffI2Yvd066YQhjSUlZsfYiwQQ__",
  },
  {
    title: "ESG Plan & Strategy",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/4fa3/ba69/f0bcda4e1af875dfcde4faf2d9bd1b97?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WmkwyfhWxXXiKfqc54D9t3Wy~FOBOG9jya1l4LQU5EEA~eNo~DugqfAfLy0HGapSMCvzZyFVpigHX7G~0dmutKQDW64HNpdjbCuI3uhkuR08Vxcm6MQvf02C5ksohcJMEhthXf2nkFWZJvqm14aFNWoh3htcnvdRgr72PqjxA8fRhIpKDvoV9z8SoKCUrQxHRDq2d8A5~Mgnl1Rdk0ynShCB-OW4LwmjdJ35oCXj5mpNTrL3FWgKVgyyQ~sb9JDDNtiYETnCjukhYtRFHHrVLXXVz2VvWHR7Y~1hsYcXkNHYlaMjkYMpsh~WSLP-y4GkejN9fLRwzUG4XeMdR6fqHQ__",
  },
  {
    title: "Data Hub Integration",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/ecd7/ea82/e77dd95db3f52e4d33f718b3dd3038af?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=U13IE517FG7q3q9xQmdUpbg8sZ5WJmPXGqEIiPEof~8Q5T1cPmbwLXlElYtRbBS1hQLiqUrAIEvqUQ6ttMj1BGfof2Ycp-Sz9d-ZlLetYsLcudN3qNq~1Qm7fZ~3wpESzrF8575kPPhdY8kVuTE-9MR7PHbD3hiwjaDmRHUeB6keSEAXoS7Hhlajtyzqo8tii8PYpElrKVT3-ORQdxeFRgJqPhVsMGlBo4JZyQZGXn9JeomK2ZHjgRhyhFfPfBuVheZaFRFiAiti5s5nOkj4wE8Fcv9-nUHXp7pAAXez3IL7psZYSH8wpCi37UdxvJopRykARxcBZGvtSLpOoc8MAw__",
  },
  {
    title: "Data Hub Integration",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/3af2/1c64/88d134694294c75eff29219abfb0ac63?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=p85x5UQV22T9mR9H8K8~6BRX~BNkZJ34OjP6yuzulL8jAnWYBQSuVz8HR1xxwk7un-I7wKiKNtrTBZe~LCVLjylrpwiMQDVyWaF8ODEf4WFVawwtCPgG3jhyxaf7-UQx57N9WeySbP-x2XCmg05K6qPMeUmsaf0ztJrk4KIdIQ9zXkySsLkpo3jAuuQrJ6WvW0eZLHESBxG~iqEc1TVZcJqJjMsGyKTgRzl-u3qtur1k8XWm~YUaSA7cbrMLw63NBlQvDvZXNScvdxey~ZAKzsCoMSOcjVUiAUVvv9aR6e84~YxXgDUxNbuO2lACbIeSi8BXGXBZysQhJkZ9GO3gPg__",
  },
  {
    title: "ESG Reporting & Compliance",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/c4ba/92a1/25b36ba102d152a13d30862e812df549?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jJTGeH1Ril2h1HTo-DvRrZ-6rXMNjorAbCP8itPlVlSPGmUVCJ6LLLc0RbEY-KN-OHdMX1zsndJHbaKBEhmUpPIZolJLNV5l~TY82t~HbK3JzzadCsFH-3pcH1ZqP6UFFVzK6IzpArgnfprlfLb6NJ09uA3HDJXRT4c9fpcu6uT6l-LzUpgbP9z2DpDKmluLrZ0ah7inEMVNvUiA5MLugyeDpC~N5QH~MXF~v8Qr4OKtFWWeLcXRuEoIePnIG992sPS5xlBX9mtbDMELWhts6n7ZcOb0WpuAj9~crd0svU~MwToJT3eeOH6yctCP3ZlgQsF-s0uBRkREE-hZvX6ksA__",
  },
];

function UserGuide() {
  const classes = useStyle();
  return (
    <Grid container gap={5} className={classes.container}>
      {items.map((item) => (
        <Grid item md={3} className={classes.card}>
          <img src={item.imgUrl} alt={item.title}></img>
          <Heading text={item.title} marginBottom={"5px"} marginTop={"10px"} />
          <Typography variant="body2" className={classes.description}>
            {item.description}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default UserGuide;
