import SvgIcon from "@mui/material/SvgIcon";
import EsgSocial from "../../images/socialEsg.svg";
import EsgBusiness from "../../images/businessEsg.svg";
import EsgHuman from "../../images/humanEsg.svg";
import EsgEnvironment from "../../images/EnvironmentEsg.svg";
import EsgGovernance from "../../images/governanceEsg.svg";
import topicIcon from "../../images/topicIcon.svg";
import generalDisclosure from "../../images/generalDisclosure.svg";
export function Social(props) {
  return <img {...props} src={EsgSocial} alt="social" />;
}
export function Business(props) {
  return <img {...props} src={EsgBusiness} alt="Business" />;
}

export function Human(props) {
  return <img {...props} src={EsgHuman} alt="Human" />;
}

export function Environmental(props) {
  return <img {...props} src={EsgEnvironment} alt="Environmental" />;
}

export function Governance(props) {
  return <img {...props} src={EsgGovernance} alt="Governance" />;
}

export function TopicIcon(props) {
  return <img src={topicIcon} alt="TopicIcon" />;
}
export function GeneralDisclosure(props) {
  return <img {...props} src={generalDisclosure} alt="TopicIcon" />;
}

// export function GeneralDisclosure(props) {
//   return (
//     <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
//       <g filter="url(#filter0_d_8125_165337)">
//         <path
//           d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
//           fill="#DAF1EE"
//         />
//         <g clipPath="url(#clip0_8125_165337)">
//           <path
//             d="M29.666 23.8333V18.3833C30.4369 18.6744 31.1371 19.1261 31.7202 19.7083L34.6235 22.6133C35.2064 23.1957 35.6584 23.8957 35.9494 24.6667H30.4994C30.2783 24.6667 30.0664 24.5789 29.9101 24.4226C29.7538 24.2663 29.666 24.0543 29.666 23.8333ZM36.3327 26.7375V33.8333C36.3314 34.938 35.892 35.997 35.1108 36.7781C34.3297 37.5593 33.2707 37.9987 32.166 38H23.8327C22.728 37.9987 21.669 37.5593 20.8879 36.7781C20.1068 35.997 19.6673 34.938 19.666 33.8333V22.1667C19.6673 21.062 20.1068 20.003 20.8879 19.2218C21.669 18.4407 22.728 18.0013 23.8327 18H27.5952C27.731 18 27.8652 18.0108 27.9994 18.02V23.8333C27.9994 24.4964 28.2627 25.1323 28.7316 25.6011C29.2004 26.0699 29.8363 26.3333 30.4994 26.3333H36.3127C36.3219 26.4675 36.3327 26.6017 36.3327 26.7375ZM29.666 33.8333C29.666 33.6123 29.5782 33.4004 29.4219 33.2441C29.2657 33.0878 29.0537 33 28.8327 33H24.666C24.445 33 24.233 33.0878 24.0768 33.2441C23.9205 33.4004 23.8327 33.6123 23.8327 33.8333C23.8327 34.0543 23.9205 34.2663 24.0768 34.4226C24.233 34.5789 24.445 34.6667 24.666 34.6667H28.8327C29.0537 34.6667 29.2657 34.5789 29.4219 34.4226C29.5782 34.2663 29.666 34.0543 29.666 33.8333ZM32.166 30.5C32.166 30.279 32.0782 30.067 31.9219 29.9107C31.7657 29.7545 31.5537 29.6667 31.3327 29.6667H24.666C24.445 29.6667 24.233 29.7545 24.0768 29.9107C23.9205 30.067 23.8327 30.279 23.8327 30.5C23.8327 30.721 23.9205 30.933 24.0768 31.0893C24.233 31.2455 24.445 31.3333 24.666 31.3333H31.3327C31.5537 31.3333 31.7657 31.2455 31.9219 31.0893C32.0782 30.933 32.166 30.721 32.166 30.5Z"
//             fill="#374151"
//           />
//         </g>
//       </g>
//       <defs>
//         <filter
//           id="filter0_d_8125_165337"
//           x="0"
//           y="0"
//           width="56"
//           height="56"
//           filterUnits="userSpaceOnUse"
//           colorInterpolationFilters="sRGB"
//         >
//           <feFlood floodOpacity="0" result="BackgroundImageFix" />
//           <feColorMatrix
//             in="SourceAlpha"
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//             result="hardAlpha"
//           />
//           <feOffset />
//           <feGaussianBlur stdDeviation="4" />
//           <feComposite in2="hardAlpha" operator="out" />
//           <feColorMatrix
//             type="matrix"
//             values="0 0 0 0 0.278431 0 0 0 0 0.733333 0 0 0 0 0.462745 0 0 0 0.16 0"
//           />
//           <feBlend
//             mode="normal"
//             in2="BackgroundImageFix"
//             result="effect1_dropShadow_8125_165337"
//           />
//           <feBlend
//             mode="normal"
//             in="SourceGraphic"
//             in2="effect1_dropShadow_8125_165337"
//             result="shape"
//           />
//         </filter>
//         <clipPath id="clip0_8125_165337">
//           <rect
//             width="20"
//             height="20"
//             fill="white"
//             transform="translate(18 18)"
//           />
//         </clipPath>
//       </defs>
//     </SvgIcon>
//   );
// }

export function BusinessWhite(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7575_5396)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#FFFFFF"
        />
        <g clipPath="url(#clip0_7575_5396)">
          <path
            d="M24.25 28.8333C23.5083 28.8333 22.7833 28.6134 22.1666 28.2013C21.5499 27.7893 21.0693 27.2036 20.7855 26.5184C20.5016 25.8332 20.4274 25.0792 20.5721 24.3517C20.7167 23.6243 21.0739 22.9561 21.5983 22.4317C22.1228 21.9072 22.791 21.5501 23.5184 21.4054C24.2458 21.2607 24.9998 21.335 25.6851 21.6188C26.3703 21.9026 26.956 22.3833 27.368 22.9999C27.7801 23.6166 28 24.3417 28 25.0833C27.9989 26.0776 27.6035 27.0307 26.9004 27.7338C26.1974 28.4368 25.2442 28.8322 24.25 28.8333ZM29.6667 38H18.8333C18.6123 38 18.4004 37.9122 18.2441 37.7559C18.0878 37.5996 18 37.3877 18 37.1667V36.75C18 35.0924 18.6585 33.5027 19.8306 32.3306C21.0027 31.1585 22.5924 30.5 24.25 30.5C25.9076 30.5 27.4973 31.1585 28.6694 32.3306C29.8415 33.5027 30.5 35.0924 30.5 36.75V37.1667C30.5 37.3877 30.4122 37.5996 30.2559 37.7559C30.0996 37.9122 29.8877 38 29.6667 38ZM32.5833 25.5C31.8417 25.5 31.1166 25.2801 30.4999 24.868C29.8833 24.456 29.4026 23.8703 29.1188 23.1851C28.835 22.4998 28.7607 21.7458 28.9054 21.0184C29.0501 20.291 29.4072 19.6228 29.9317 19.0984C30.4561 18.5739 31.1243 18.2168 31.8517 18.0721C32.5792 17.9274 33.3332 18.0016 34.0184 18.2855C34.7036 18.5693 35.2893 19.0499 35.7013 19.6666C36.1134 20.2833 36.3333 21.0083 36.3333 21.75C36.3322 22.7442 35.9368 23.6974 35.2338 24.4004C34.5307 25.1035 33.5776 25.4989 32.5833 25.5ZM31.3992 27.1842C30.623 27.2883 29.8767 27.5516 29.2071 27.9575C28.5374 28.3635 27.9588 28.9034 27.5075 29.5433C29.3749 30.3914 30.8422 31.9285 31.6025 33.8333H37.1667C37.3877 33.8333 37.5996 33.7455 37.7559 33.5893C37.9122 33.433 38 33.221 38 33V32.9683C37.9991 32.1377 37.8211 31.3167 37.4777 30.5604C37.1344 29.804 36.6336 29.1296 36.0088 28.5821C35.3841 28.0347 34.6498 27.6268 33.8549 27.3857C33.0599 27.1447 32.2227 27.0759 31.3992 27.1842Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7575_5396"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.827451 0 0 0 0 0.772549 0 0 0 0 0.282353 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7575_5396"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7575_5396"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7575_5396">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function HumanWhite(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7575_15273)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#FFFFFF"
        />
        <g clipPath="url(#clip0_7575_15273)">
          <path
            d="M35.6667 25.75C35.1049 25.7695 34.5465 25.8457 34 25.9775C33.9501 24.906 33.4893 23.8948 32.7134 23.1542C31.9375 22.4135 30.906 22.0001 29.8333 22H29.6067C29.7386 21.4536 29.8146 20.8951 29.8333 20.3333C29.8333 19.4493 29.4821 18.6014 28.857 17.9763C28.2319 17.3512 27.3841 17 26.5 17C25.6159 17 24.7681 17.3512 24.143 17.9763C23.5179 18.6014 23.1667 19.4493 23.1667 20.3333C23.1854 20.8951 23.2614 21.4536 23.3933 22H23.1667C22.0616 22 21.0018 22.439 20.2204 23.2204C19.439 24.0018 19 25.0616 19 26.1667V32.8333C19 33.3805 19.1078 33.9223 19.3172 34.4278C19.5266 34.9334 19.8335 35.3927 20.2204 35.7796C21.0018 36.561 22.0616 37 23.1667 37H24.1867C24.2858 37.0004 24.3837 36.9779 24.4727 36.9342C24.5617 36.8906 24.6394 36.8269 24.6997 36.7483C24.7601 36.6696 24.8014 36.5781 24.8206 36.4808C24.8397 36.3835 24.8361 36.2831 24.81 36.1875C24.5192 35.1383 24 34.5 24 33.6667C24 33.0036 24.2634 32.3677 24.7322 31.8989C25.2011 31.4301 25.837 31.1667 26.5 31.1667C27.163 31.1667 27.7989 31.4301 28.2678 31.8989C28.7366 32.3677 29 33.0036 29 33.6667C29 34.5 28.4733 35.14 28.1892 36.1908C28.1637 36.2863 28.1606 36.3863 28.18 36.4831C28.1994 36.58 28.2408 36.671 28.3011 36.7493C28.3614 36.8275 28.4389 36.8908 28.5275 36.9343C28.6162 36.9778 28.7137 37.0003 28.8125 37H29.8333C30.3805 37 30.9223 36.8922 31.4278 36.6828C31.9334 36.4734 32.3927 36.1665 32.7796 35.7796C33.1665 35.3927 33.4734 34.9334 33.6828 34.4278C33.8922 33.9223 34 33.3805 34 32.8333V32.19C34.5465 32.3215 35.1049 32.3974 35.6667 32.4167C36.5507 32.4167 37.3986 32.0655 38.0237 31.4404C38.6488 30.8152 39 29.9674 39 29.0833C39 28.1993 38.6488 27.3514 38.0237 26.7263C37.3986 26.1012 36.5507 25.75 35.6667 25.75Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7575_15273"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7575_15273"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7575_15273"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7575_15273">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(19 17)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function EnvironmentalWhite(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7369_20358)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#FFFFFF"
        />
        <g clipPath="url(#clip0_7369_20358)">
          <path
            d="M37.2659 18.7265C37.0055 18.4666 36.6906 18.2678 36.344 18.1443C35.9975 18.0208 35.6278 17.9757 35.2618 18.0123C32.9343 18.2373 25.1067 19.2065 22.0834 22.2265C20.6382 23.6756 19.7812 25.6088 19.678 27.6528C19.5747 29.6968 20.2325 31.7066 21.5242 33.294L30.7409 24.0773C30.8981 23.9255 31.1086 23.8415 31.3271 23.8434C31.5456 23.8453 31.7546 23.933 31.9091 24.0875C32.0636 24.242 32.1512 24.451 32.1531 24.6695C32.155 24.888 32.071 25.0985 31.9192 25.2557L22.7059 34.4723C24.2928 35.7649 26.3024 36.4235 28.3465 36.3208C30.3905 36.2181 32.324 35.3615 33.7734 33.9165C36.8368 30.8523 37.7734 23.049 37.9893 20.7307C38.0245 20.3639 37.978 19.9939 37.8529 19.6473C37.7278 19.3007 37.5273 18.9862 37.2659 18.7265ZM22.0834 33.9165C22.2823 34.1148 22.4887 34.3012 22.7026 34.4757L19.4192 37.759C19.2617 37.9104 19.0509 37.9939 18.8324 37.9914C18.6139 37.9889 18.4051 37.9006 18.251 37.7457C18.0969 37.5907 18.0098 37.3814 18.0086 37.1629C18.0073 36.9443 18.0919 36.734 18.2442 36.5773L21.5276 33.294C21.702 33.5073 21.8873 33.7148 22.0834 33.9165Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7369_20358"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.278431 0 0 0 0 0.733333 0 0 0 0 0.462745 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7369_20358"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7369_20358"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7369_20358">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function GovernanceWhite(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_7369_26383)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#FFFFFF"
        />
        <g clipPath="url(#clip0_7369_26383)">
          <path
            d="M18.2424 25.126C18.0628 24.7929 17.9796 24.4164 18.0021 24.0387C18.0247 23.661 18.152 23.297 18.3699 22.9876C18.7259 22.4652 19.2027 22.0364 19.7599 21.7376L26.0107 18.4843C26.6251 18.1648 27.3074 17.998 27.9999 17.998C28.6924 17.998 29.3746 18.1648 29.989 18.4843L36.239 21.7401C36.7962 22.0389 37.273 22.4677 37.629 22.9901C37.8469 23.2995 37.9742 23.6635 37.9968 24.0412C38.0193 24.4189 37.9361 24.7954 37.7565 25.1285C37.5632 25.4942 37.2733 25.7998 36.9183 26.0121C36.5633 26.2245 36.1568 26.3353 35.7432 26.3326H20.2565C19.8425 26.3352 19.4357 26.2241 19.0805 26.0113C18.7254 25.7985 18.4354 25.4923 18.2424 25.126ZM37.1665 36.3326C37.1665 35.8906 36.9909 35.4667 36.6784 35.1541C36.3658 34.8416 35.9419 34.666 35.4999 34.666V27.9993H33.8332V34.666H31.3332V27.9993H29.6665V34.666H26.3332V27.9993H24.6665V34.666H22.1665V27.9993H20.4999V34.666C20.0578 34.666 19.6339 34.8416 19.3214 35.1541C19.0088 35.4667 18.8332 35.8906 18.8332 36.3326C18.6122 36.3326 18.4002 36.4204 18.244 36.5767C18.0877 36.733 17.9999 36.945 17.9999 37.166C17.9999 37.387 18.0877 37.5989 18.244 37.7552C18.4002 37.9115 18.6122 37.9993 18.8332 37.9993H37.1665C37.3875 37.9993 37.5995 37.9115 37.7558 37.7552C37.9121 37.5989 37.9999 37.387 37.9999 37.166C37.9999 36.945 37.9121 36.733 37.7558 36.5767C37.5995 36.4204 37.3875 36.3326 37.1665 36.3326Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7369_26383"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7369_26383"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7369_26383"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_7369_26383">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function GeneralDisclosureWhite(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_8125_165337)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="#FFFFFF"
        />
        <g clipPath="url(#clip0_8125_165337)">
          <path
            d="M29.666 23.8333V18.3833C30.4369 18.6744 31.1371 19.1261 31.7202 19.7083L34.6235 22.6133C35.2064 23.1957 35.6584 23.8957 35.9494 24.6667H30.4994C30.2783 24.6667 30.0664 24.5789 29.9101 24.4226C29.7538 24.2663 29.666 24.0543 29.666 23.8333ZM36.3327 26.7375V33.8333C36.3314 34.938 35.892 35.997 35.1108 36.7781C34.3297 37.5593 33.2707 37.9987 32.166 38H23.8327C22.728 37.9987 21.669 37.5593 20.8879 36.7781C20.1068 35.997 19.6673 34.938 19.666 33.8333V22.1667C19.6673 21.062 20.1068 20.003 20.8879 19.2218C21.669 18.4407 22.728 18.0013 23.8327 18H27.5952C27.731 18 27.8652 18.0108 27.9994 18.02V23.8333C27.9994 24.4964 28.2627 25.1323 28.7316 25.6011C29.2004 26.0699 29.8363 26.3333 30.4994 26.3333H36.3127C36.3219 26.4675 36.3327 26.6017 36.3327 26.7375ZM29.666 33.8333C29.666 33.6123 29.5782 33.4004 29.4219 33.2441C29.2657 33.0878 29.0537 33 28.8327 33H24.666C24.445 33 24.233 33.0878 24.0768 33.2441C23.9205 33.4004 23.8327 33.6123 23.8327 33.8333C23.8327 34.0543 23.9205 34.2663 24.0768 34.4226C24.233 34.5789 24.445 34.6667 24.666 34.6667H28.8327C29.0537 34.6667 29.2657 34.5789 29.4219 34.4226C29.5782 34.2663 29.666 34.0543 29.666 33.8333ZM32.166 30.5C32.166 30.279 32.0782 30.067 31.9219 29.9107C31.7657 29.7545 31.5537 29.6667 31.3327 29.6667H24.666C24.445 29.6667 24.233 29.7545 24.0768 29.9107C23.9205 30.067 23.8327 30.279 23.8327 30.5C23.8327 30.721 23.9205 30.933 24.0768 31.0893C24.233 31.2455 24.445 31.3333 24.666 31.3333H31.3327C31.5537 31.3333 31.7657 31.2455 31.9219 31.0893C32.0782 30.933 32.166 30.721 32.166 30.5Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8125_165337"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.278431 0 0 0 0 0.733333 0 0 0 0 0.462745 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8125_165337"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8125_165337"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_8125_165337">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function SocialWhite(props) {
  return (
    <SvgIcon {...props} width="56" height="56" viewBox="0 0 56 56">
      <g filter="url(#filter0_d_9337_112617)">
        <path
          d="M8 28C8 16.9543 16.9543 8 28 8C39.0457 8 48 16.9543 48 28C48 39.0457 39.0457 48 28 48C16.9543 48 8 39.0457 8 28Z"
          fill="white"
        />
        <g clipPath="url(#clip0_9337_112617)">
          <path
            d="M36.5151 28.1892C35.8723 28.1893 35.255 28.4398 34.7938 28.8875L33.813 28.3971C34.6136 25.7716 33.1341 22.9942 30.5086 22.1936C29.5482 21.9008 28.5216 21.9057 27.5641 22.2077L26.9892 21.1698C27.9119 20.1594 27.8409 18.5923 26.8305 17.6696C25.8201 16.7469 24.253 16.8179 23.3303 17.8283C22.4075 18.8387 22.4786 20.4058 23.489 21.3285C23.9473 21.7471 24.5461 21.9784 25.1668 21.9766C25.286 21.9732 25.4047 21.9613 25.5222 21.941L26.0921 22.9706C23.9335 24.5724 23.4491 27.6048 25.0011 29.7994L22.5873 32.1187C21.3068 31.5453 19.8038 32.1184 19.2303 33.399C18.6569 34.6795 19.23 36.1825 20.5106 36.756C21.7911 37.3294 23.2941 36.7563 23.8676 35.4757C24.1871 34.7623 24.1603 33.9414 23.7951 33.2502L26.1608 30.9764C28.3714 32.5775 31.4612 32.0848 33.0642 29.8756L34.0582 30.3726C34.0425 30.4724 34.0332 30.5731 34.0301 30.6741C34.0301 32.0465 35.1427 33.1591 36.5151 33.1591C37.8876 33.1591 39.0001 32.0467 39.0001 30.6743C39.0001 29.3018 37.8875 28.1892 36.5151 28.1892Z"
            fill="#374151"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_9337_112617"
          x="0"
          y="0"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.454902 0 0 0 0 0.72549 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9337_112617"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9337_112617"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_9337_112617">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(19 17)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function RatingFive() {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 34 34">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM15.2646 10.2646C15.6707 9.85846 16.3292 9.85846 16.7354 10.2646L20.902 14.4313C21.3082 14.8374 21.3082 15.4959 20.902 15.9021C20.4959 16.3082 19.8374 16.3082 19.4313 15.9021L16 12.4708L12.5687 15.9021C12.1626 16.3082 11.5041 16.3082 11.0979 15.9021C10.6918 15.4959 10.6918 14.8374 11.0979 14.4313L15.2646 10.2646ZM20.902 20.2646L16.7354 16.0979C16.3292 15.6918 15.6707 15.6918 15.2646 16.0979L11.0979 20.2646C10.6918 20.6707 10.6918 21.3292 11.0979 21.7354C11.5041 22.1415 12.1626 22.1415 12.5687 21.7354L16 18.3041L19.4313 21.7354C19.8374 22.1415 20.4959 22.1415 20.902 21.7354C21.3082 21.3292 21.3082 20.6707 20.902 20.2646Z"
          fill="#32A05F"
        />
      </svg>
    </SvgIcon>
  );
}

export function RatingFour() {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 34 34">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM20.5904 17.4096L16.5904 13.4096C16.2643 13.0835 15.7357 13.0835 15.4096 13.4096L11.4096 17.4096C11.0835 17.7357 11.0835 18.2643 11.4096 18.5904C11.7357 18.9165 12.2643 18.9165 12.5904 18.5904L16 15.1809L19.4096 18.5904C19.7357 18.9165 20.2643 18.9165 20.5904 18.5904C20.9165 18.2643 20.9165 17.7357 20.5904 17.4096Z"
          fill="#32A05F"
        />
      </svg>
    </SvgIcon>
  );
}

export function RatingThree() {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 34 34">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM11.3333 15.165C10.8722 15.165 10.4983 15.5388 10.4983 16C10.4983 16.4612 10.8722 16.835 11.3333 16.835H20.6667C21.1278 16.835 21.5017 16.4612 21.5017 16C21.5017 15.5388 21.1278 15.165 20.6667 15.165H11.3333Z"
          fill="#AEB3C4"
        />
      </svg>
    </SvgIcon>
  );
}

export function RatingTwo() {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 34 34">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM11.4096 14.5904L15.4096 18.5904C15.7357 18.9165 16.2643 18.9165 16.5904 18.5904L20.5904 14.5904C20.9165 14.2643 20.9165 13.7357 20.5904 13.4096C20.2643 13.0835 19.7357 13.0835 19.4096 13.4096L16 16.8191L12.5904 13.4096C12.2643 13.0835 11.7357 13.0835 11.4096 13.4096C11.0835 13.7357 11.0835 14.2643 11.4096 14.5904Z"
          fill="#C65447"
        />
      </svg>
    </SvgIcon>
  );
}

export function RatingOne() {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 34 34">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM15.2646 21.7354L11.098 17.5687C10.6918 17.1626 10.6918 16.5041 11.098 16.0979C11.5041 15.6918 12.1626 15.6918 12.5687 16.0979L16 19.5292L19.4313 16.0979C19.8374 15.6918 20.4959 15.6918 20.9021 16.0979C21.3082 16.5041 21.3082 17.1626 20.9021 17.5687L16.7354 21.7354C16.3293 22.1415 15.6708 22.1415 15.2646 21.7354ZM15.2646 15.9021C15.6708 16.3082 16.3293 16.3082 16.7354 15.9021L20.9021 11.7354C21.3082 11.3293 21.3082 10.6708 20.9021 10.2646C20.4959 9.85848 19.8374 9.85848 19.4313 10.2646L16 13.6959L12.5687 10.2646C12.1626 9.85848 11.5041 9.85848 11.098 10.2646C10.6918 10.6708 10.6918 11.3293 11.098 11.7354L15.2646 15.9021Z"
          fill="#C65447"
        />
      </svg>
    </SvgIcon>
  );
}

// data hub utils

export const rawData = [
  { head: "datdaad", q1: 21, q2: 21, q3: 21, total: 21 },
  { head: "datdaad datdaad datdaad", q1: 21, q2: 21, q3: 21, total: 21 },
  { head: "datdaad", q1: 21, q2: 21, q3: 21, total: 21 },
];
