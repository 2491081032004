import React from "react";
import * as PropTypes from "prop-types";
// import { div, div, Card, Divider } from "antd";
import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { QueryBuilder } from "@cubejs-client/react";
import MemberGroup from "./MemberGroup";
import FilterGroup from "./FilterGroup";
import TimeGroup from "./TimeGroup";
import SelectChartType, { CustomDataInput } from "./SelectChartType";
import ChartRenderer from "../ChartRenderer";
import stateChangeHeuristics from "./stateChangeHeuristics.jsx";
import { CubeProvider } from "@cubejs-client/react";
import PivotConfig from "./pivotConfiq";
// import SimpleDialogDemo from "./ChartColorSDialog";
import UpdateOrder from "./UpdateOrder";
import LimitComponent from "./limitComponent";
import BarChartCustomization from "./barChartCustomization";
import AxisTitleCustomization from "./x_y_editableTitle";
import LineChartCustomization from "./LineChartCustomization";
import PieChartCustomization from "./PieChartCustomization";
import MemberDropdown from "./MemberDropdown";
import UpdateMeasureOrder from "./UpdateMeasureOrder";
import SimpleDialogDemo from "./stylingChartDialog";
// import { props } from "ramda";

const ControlsRow = styled("div")`
  background: #ffffff;
  margin-bottom: 12px;
  padding: 18px 28px 10px 28px;
`;

const StyledDivider = styled(Divider)`
  margin: 0 12px;
  height: 0.5em;
  top: 0.5em;
  background: #f4f5f6;
`;

const HorizontalDivider = styled(Divider)`
  padding: 0;
  margin: 0;
  background: #f4f5f6;
`;

const ChartCard = styled(Card)`
  border-radius: 4px;
  border: none;
`;

const ChartRow = styled("div")`
  padding-left: 28px;
  padding-right: 28px;
`;

const Empty = styled.div`
  text-align: center;
  margin-top: 105px;
  margin-bottom: 155px;
`;

const ExploreQueryBuilder = ({
  vizState,
  cubejsApi,
  setVizState,
  chartExtra,
  borderColor,
  setBorderColor,
  backgroundColor,
  setBackGroundColor,
  setPivotConfiq,
  setLimit,
  barChartCustomize,
  chartX_AxisCustomize,
  chartY_AxisCustomize,
  lineChartCustomize,
  pieChartCustomize,
  setBarChartCustomize,
  setChartX_AxisCustomize,
  setChartY_AxisCustomize,
  setLineChartCustomize,
  setPieChartCustomize,
  setAddChart,
  chartTitle,
  setChartTitle,
}) => {
  const [runQuery, setRunQuery] = React.useState(false);

  const handleRunQuery = (isQueryPresent) => {
    if (!isQueryPresent) {
      setRunQuery(isQueryPresent);
    }

    setAddChart(isQueryPresent);

    return isQueryPresent;
  };

  const handleRunQueryButton = (isQueryPresent) => {
    if (isQueryPresent) {
      setRunQuery(true);
    }
  };

  return (
    <QueryBuilder
      // vizState={vizState}
      setVizState={setVizState}
      // query={{
      //   timeDimensions: [
      //     {
      //       dimension: "LineItems.createdAt",
      //       granularity: "month",
      //     },
      //   ],
      // }}
      cubejsApi={cubejsApi}
      wrapWithQueryRenderer={true}
      stateChangeHeuristics={stateChangeHeuristics}
      render={({
        measures,
        availableMeasures,
        updateMeasures,
        dimensions,
        availableDimensions,
        updateDimensions,
        segments,
        availableSegments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        availableTimeDimensions,
        updateTimeDimensions,
        isQueryPresent,
        chartType,
        updateChartType,
        validatedQuery,
        pivotConfig,
        updatePivotConfig,
        orderMembers,
        updateOrder,
        updateQuery,
        refresh,

        // cubejsApi,
        query,
        ...props
      }) => [
        <ControlsRow type="flex" justify="space-around" align="top" key="1">
          <div
            span={24}
            style={{ paddingBottom: 23, display: "flex", flexWrap: "wrap" }}
          >
            <div
              type="flex"
              align="top"
              style={{
                paddingBottom: 23,
                display: "flex",
                width: "30%",
                flexDirection: "column",
              }}
            >
              {/* <div style={{ display: "flex", flexDirection: "column" }}>
                {console.log('measures',measures)}
                <MemberDropdown
                  type={measures.length > 0 ? "icon" : "new"}
                  onClick={(m) => updateMeasures.add(m)}
                  availableMembers={availableMeasures}
                >
                  Measure Value +
                </MemberDropdown>
                <div style={{ alignItems: "center", padding: "5px 10px" }}>
                  <Typography>{`Selected ${"Measures"}`}</Typography>
                </div>
              </div> */}
              <MemberGroup
                title="Measures"
                members={measures}
                availableMembers={availableMeasures}
                addMemberName="Measure Value"
                updateMethods={updateMeasures}
                pivotConfig={pivotConfig}
              />
              <MemberGroup
                title="Dimensions"
                members={dimensions}
                availableMembers={availableDimensions}
                addMemberName="Dimension value"
                updateMethods={updateDimensions}
              />

              {/* <StyledDivider type="vertical" /> */}
              {/* <TimeGroup
                title="Time"
                members={timeDimensions}
                availableMembers={availableTimeDimensions}
                addMemberName="Time"
                updateMethods={updateTimeDimensions}
              /> */}
            </div>
            {/* {!!isQueryPresent && (
              <div style={{ width: "50%" }}>
                <PivotConfig
                  title="Pivot"
                  // members={segments}
                  availableMembers={pivotConfig}
                  addMemberName="Pivot"
                  updateMethods={updatePivotConfig}
                  setPivotConfiq={setPivotConfiq}
                />
              </div>
            )} */}
            <div
              style={{
                paddingBottom: 23,
                display: "flex",
                width: "70%",
                flexDirection: "column",
              }}
            >
              <Grid md={12} container spacing={2}>
                <Grid item md={4}>
                  <div>Type of Chart </div>
                  <div style={{ marginTop: 8 }}>
                    <SelectChartType
                      chartType={chartType}
                      updateChartType={updateChartType}
                    />
                  </div>
                </Grid>

                <Grid item md={4}>
                  <div>Measure Chart Order </div>
                  {!!isQueryPresent && (
                    <div style={{ width: "100%" }}>
                      <UpdateMeasureOrder
                        updateOrder={updateOrder}
                        order={orderMembers}
                      />
                    </div>
                  )}
                </Grid>

                <Grid item md={4}>
                  <div>Set Limits for Chart Rows</div>
                  {!!isQueryPresent && (
                    <div style={{ width: "95%" }}>
                      <LimitComponent
                        availableMembers={pivotConfig}
                        setLimit={setLimit}
                        setPivotConfiq={setPivotConfiq}
                        updatePivotConfig={updatePivotConfig}
                      />
                    </div>
                  )}
                </Grid>

                <Grid item md={4}>
                  <div>Edit Chart Colors </div>
                  {!!isQueryPresent && (
                    <div style={{ marginTop: "8px" }}>
                      <SimpleDialogDemo
                        backgroundColor={backgroundColor}
                        borderColor={borderColor}
                        setBorderColor={setBorderColor}
                        setBackGroundColor={setBackGroundColor}
                      />
                    </div>
                  )}
                </Grid>

                <Grid item md={4}>
                  <div>Dimension Chart Order </div>
                  {!!isQueryPresent && (
                    <div style={{ width: "100%" }}>
                      <UpdateOrder
                        updateOrder={updateOrder}
                        order={orderMembers}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item md={4}>
                  <div>Name of the Chart </div>
                  <div style={{ marginTop: 8 }}>
                    <CustomDataInput
                      style={{ width: "100%" }}
                      value={chartTitle}
                      name="chartTitle"
                      onChange={(e) => setChartTitle(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  {chartType === "bar" && <div>Edit Bar Chart</div>}

                  {!!isQueryPresent && chartType === "bar" && (
                    <div style={{ width: "100%", marginTop: 8 }}>
                      <BarChartCustomization
                        setBarChartCustomize={setBarChartCustomize}
                      />
                    </div>
                  )}

                  {chartType === "line" && <div>Edit Line Chart</div>}
                  {!!isQueryPresent && chartType === "line" && (
                    <div style={{ width: "100%", marginTop: 8 }}>
                      <LineChartCustomization
                        setLineChartCustomize={setLineChartCustomize}
                      />
                    </div>
                  )}

                  {chartType === "pie" && <div>Edit Pie Chart</div>}
                  {!!isQueryPresent && chartType === "pie" && (
                    <div style={{ width: "100%", marginTop: 8 }}>
                      <PieChartCustomization
                        setPieChartCustomize={setPieChartCustomize}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item md={4}>
                  {chartType === "bar" && <div>Customize Chart Axis Title</div>}
                  {!!isQueryPresent && chartType === "bar" && (
                    <div style={{ width: "100%", padding: 5, marginTop: 3 }}>
                      <AxisTitleCustomization
                        setChartX_AxisCustomize={setChartX_AxisCustomize}
                        setChartY_AxisCustomize={setChartY_AxisCustomize}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>

            {/* {!!isQueryPresent && [
              <div
                type="flex"
                justify="space-around"
                align="top"
                gutter={24}
                style={{ marginTop: 10 }}
              >
                <div span={24}>
                  <FilterGroup
                    members={filters}
                    availableMembers={availableDimensions.concat(
                      availableMeasures
                    )}
                    addMemberName="Filter"
                    updateMethods={updateFilters}
                  />
                </div>
              </div>,
            ]} */}
            {/* {!!isQueryPresent && (
              <div style={{ marginLeft: "20px", width: "20%" }}>
                <SimpleDialogDemo
                  backgroundColor={backgroundColor}
                  borderColor={borderColor}
                  setBorderColor={setBorderColor}
                  setBackGroundColor={setBackGroundColor}
                />
              </div>
            )} */}
            {/* {!!isQueryPresent && (
              <div style={{ width: "40%" }}>
                <UpdateOrder updateOrder={updateOrder} order={orderMembers} />
              </div>
            )} */}
            {/* {!!isQueryPresent && (
              <div style={{ width: "20%" }}>
                <LimitComponent
                  availableMembers={pivotConfig}
                  setLimit={setLimit}
                  setPivotConfiq={setPivotConfiq}
                  updatePivotConfig={updatePivotConfig}
                />
              </div>
            )} */}
            {/* {!!isQueryPresent && chartType === "bar" && (
              <div style={{ width: "20%" }}>
                <BarChartCustomization
                  setBarChartCustomize={setBarChartCustomize}
                />
              </div>
            )} */}
            {/* {!!isQueryPresent && chartType === "line" && (
              <div style={{ width: "20%" }}>
                <LineChartCustomization
                  setLineChartCustomize={setLineChartCustomize}
                />
              </div>
            )}
            {!!isQueryPresent && chartType === "pie" && (
              <div style={{ width: "20%" }}>
                <PieChartCustomization
                  setPieChartCustomize={setPieChartCustomize}
                />
              </div>
            )} */}
            {/* {!!isQueryPresent && chartType === "bar" && (
              <div style={{ width: "20%" }}>
                <AxisTitleCustomization
                  setChartX_AxisCustomize={setChartX_AxisCustomize}
                  setChartY_AxisCustomize={setChartY_AxisCustomize}
                />
              </div>
            )} */}
          </div>
        </ControlsRow>,
        <ChartRow
          type="flex"
          justify="space-around"
          align="top"
          gutter={24}
          key="2"
        >
          <div span={24}>
            {handleRunQuery(isQueryPresent) && runQuery ? (
              <>
                <ChartCard style={{ minHeight: 440 }}>
                  <CubeProvider cubejsApi={cubejsApi}>
                    <ChartRenderer
                      vizState={{ query, chartType }}
                      cubejsApi={cubejsApi}
                      chartHeight={400}
                      isQueryPresent={isQueryPresent}
                      validatedQuery={props}
                      pivotConfig={pivotConfig}
                      backgroundColor={backgroundColor}
                      borderColor={borderColor}
                      barChartCustomize={barChartCustomize}
                      chartX_AxisCustomize={chartX_AxisCustomize}
                      chartY_AxisCustomize={chartY_AxisCustomize}
                      lineChartCustomize={lineChartCustomize}
                      pieChartCustomize={pieChartCustomize}
                      run="run"
                    />
                  </CubeProvider>
                </ChartCard>
              </>
            ) : (
              <Empty>
                <h3>Choose a measure or dimension to get started</h3>
                {isQueryPresent && (
                  <Button
                    style={{ marginTop: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleRunQueryButton(isQueryPresent)}
                  >
                    Run Chart
                  </Button>
                )}
              </Empty>
            )}
          </div>
        </ChartRow>,
      ]}
    />
  );
};

ExploreQueryBuilder.propTypes = {
  vizState: PropTypes.object,
  setVizState: PropTypes.func,
  cubejsApi: PropTypes.object,
  chartExtra: PropTypes.array,
};
ExploreQueryBuilder.defaultProps = {
  vizState: {},
  setVizState: null,
  cubejsApi: null,
  chartExtra: null,
};
export default ExploreQueryBuilder;
