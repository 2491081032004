import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Heading from "components/UI/phase-3/heading/Heading";
const useStyle = makeStyles((theme) => ({
  card: {
    backgroundColor: "#fafafa",
    padding: "15px",
    borderRadius: "10px",
  },
  container: {
    height: "70vh",
    overflow: "scroll",
  },
  description: {
    fontSize: "13px",
  },
}));

const items = [
  {
    title: "Organisation Structure",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/da35/b281/10faa11508b8c9e1dd95e91c913d0f84?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c307qZF9mSSsOOcFSTzJ-SOxrUrG23u4MtgKzw5oDmEt43edr1tfn5jCbiTwB7CW-4UOs~4CcrMro7U7y~3f0hqHcjxm6XAN3yP-ZVnuGPQeNnxmvEdNRMcrJKAt9wUNB-lbh5FYLr5V-Zu03vLI7egzohO~a8xLECa1K09bW54iyn1WJd7nsAFv0OraSEz5T7e~Vt5YIKJe22K4sf40tMvSEchG5fSE30UuCo1BWEpSFQ52J5fF7vaWdcj0bB6lriJW25BHI4WgI2r~Nf4X5oKq9cBthho7A8OYyFEy8oKORU8V2nWaF5fa6XyA68iqk-2kO3iOjZrEPx5VyJ5oaw__",
  },
  {
    title: "ESG Maturity",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/c332/4c94/7c31c2f15ee6b52d684455184d6a7465?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GWrfOf5KPSSX1cWNJxB-5eResb3mkmZ7f5-RTWspvnxklv51M0U2iRiXU1UHNSWdcH1LYjWjJ0wg-jmH7lrlvGoyMh1NL~m2xSozPInvK-iGPOXteiznDH9EjEcN4wsBA-10uXdozDP4b9X~aR3ynLlfivh2yQwKYdgC1c9Xs4Vd-RXsX4MV8Yp7Ke314PqK9QQqztzUyfxjUw0IRHX1VGybPDEjSCSOXAGyjvQKg6c9jXf4dZX6WC47db8RU8PZSV07s16z9CzqxGqS34nmwXIrzpdWizoEmXs2qh1lne8SaKWGwin6M-Wkdu0tBVv8uxQb1xDK4CLLgQRSoGj~Ug__",
  },
  {
    title: "Materiality Assessment",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/d12d/4b24/3f8f123ab335dfb499e79045ccf283ec?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bmhp5EKwIzjeCVgTjNVqqXL-Jb4sfT9lajqD8E1Evuw456pRfR5i4qz-CejpTCy5mQd973Yp0hlf19JMsK3bVXgScCXTfim6EBMnszlj3NrquYJT4GIrCetJ0fYuCDXNUk37kC37mWP0sm3MeLZ5V2nBP8~oVLM0Ilg5RY-humrzjIB2WvKFMjbAZzrGULw249AUF-j-koWoaF2rUpUZspD9cbNJyKAPVajI5~rdk7ps7NmUNWSsbyaHL6XlmPDwNtIodyminA-orOFCI~itmg0cYzqm7f-EaD309n7p4uyjlGHCRjTNkhtxLUOcC1UP-vPB2ffJpE~s3TYt8JM7tw__",
  },
  {
    title: "ESG Plan & Strategy",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/825e/48fb/0c951201b36e3d7f98fddfb4574f774c?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DEqGDjHU8tjtr3NIvTNeiWTHNgZftYP5YxKFZWUQkhJ-e1D38fngHRuw4~zuV4LVNnDAKJukl5YuXfOeSRFz~wknKViU-wXJJdgbW6UGkL8tVDS3xAPXW~xf2vV-tnFQj9N342b99Qjdny31Yyl9BPCfDMDlkwEyZ3Ffch1mxXcDWg94fzjVTKfYgzt54FEDMua6OASilW8VTMHwaoN8EzwiK04k1ksi6cX3sSwN1SAfgLQJDGAr-6tUk-U-7Zi2jNNl-e~etdkRkmetwVcb3L6HE-AEPd8KIBsJS5dvPUffxFsfIrrNsdlNkphldffI2Yvd066YQhjSUlZsfYiwQQ__",
  },
];

function EsgFoundationProcesses() {
  const classes = useStyle();
  return (
    <Grid container gap={5} className={classes.container} alignItems={"start"}>
      {items.map((item) => (
        <Grid item md={3} className={classes.card}>
          <img src={item.imgUrl} alt={item.title}></img>
          <Heading text={item.title} marginBottom={"5px"} marginTop={"10px"} />
          <Typography variant="body2" className={classes.description}>
            {item.description}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default EsgFoundationProcesses;
