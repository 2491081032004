import { Box, MenuItem, TextField } from "@mui/material";
import React from "react";
import Heading from "../heading/Heading";

function SelectAndOption({
  heading,
  label = "Select Option",
  marginTop = "10px",
  marginBottom = "20px",
  options = ["option1", "option2", "option3"],
  width = "100%",
  value,
  handleChange,
  error,
  name,
  valueKey = "value", // Property name for value when options is an array of objects
  labelKey = "label", // Property name for label when options is an array of objects
}) {
  return (
    <Box
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        width,
      }}
    >
      <Heading text={heading} size="13px" marginBottom="10px" color="#999" />

      <TextField
        name={name}
        select
        label={label}
        value={value}
        onChange={handleChange}
        error={Boolean(error)}
        helperText={error}
        fullWidth
        variant="outlined"
        size="small"
        InputLabelProps={{
          style: {
            fontSize: "14px",
          },
        }}
        InputProps={{
          style: {
            padding: "0px",
          },
        }}
      >
        {options.map((item) => (
          <MenuItem
            value={typeof item === "object" ? item[valueKey] : item}
            style={{ fontSize: "0.875rem", padding: "7px 15px" }}
          >
            {typeof item === "object" ? item[labelKey] : item}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

export default SelectAndOption;
