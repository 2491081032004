import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";

const SelectActivity = ({
  activities,
  selectedActivities,
  handleCheckboxChange,
}) => {
  return (
    <Box>
      {/* Head */}
      <Box width={"100%"} bgcolor={"primary.lighter"} p={2}>
        <Typography variant="subtitle2" color={"primary.darker"}>
          Activity List
        </Typography>
      </Box>

      {/* Checkboxes */}
      <Box p={2} display="flex" flexDirection="column" gap={1}>
        {activities.map((activity, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedActivities.includes(activity)}
                onChange={() => handleCheckboxChange(activity)}
              />
            }
            label={
              <Typography variant="body2" fontWeight={500}>
                {activity}
              </Typography>
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default SelectActivity;
