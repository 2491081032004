import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Heading from "components/UI/phase-3/heading/Heading";
import { useEsgStrategyPlanningTopic } from "../../APIs/HandleTopicApi";
import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "utils/MaterialityAssessmentUtils/materialityAssessmentUtils";

function getIconByParentId(parent_id) {
  switch (parent_id) {
    case "62317aa659ba6228342f20bb":
      return <Social width={"50px"} />;
    case "62317aa659ba6228342f20b9":
      return <Environmental width={"50px"} />;
    case "62317aa659ba6228342f20ca":
      return <Governance width={"50px"} />;
    case "62317aa659ba6228342f20be":
      return <Human width={"50px"} />;
    case "62317aa659ba6228342f20c3":
      return <Business width={"50px"} />;
    default:
      return null; // Or you can return a default icon if needed
  }
}

function DefineAgainstTopicsLeft({ from = "strategy" }) {
  const { topics, handleSelectEsgTopic, esgSelectedTopic } =
    useEsgStrategyPlanningTopic(from);
  return (
    <Box sx={{ height: "100%" }}>
      <Heading
        text={"Materiality Topics"}
        size={"15px"}
        marginBottom={"8px"}
        bg
      />
      <Box sx={{ overflow: "scroll", height: "90%" }} p={0}>
        <List dense={false} component="nav" aria-label="main mailbox folders">
          {topics?.map((topic) => (
            <ListItemButton
              selected={topic?.topic_id === esgSelectedTopic?.topic_id}
              key={topic?.topic_id}
              onClick={() => handleSelectEsgTopic(topic)}
            >
              <ListItemIcon>{getIconByParentId(topic?.parent_id)}</ListItemIcon>
              <ListItemText primary={topic?.topic_name} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default DefineAgainstTopicsLeft;
