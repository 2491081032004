// mui
import InterestMap from "./MapSection";
import ControversyChart from "./ChartSection";

// redux

// components
import { BlueBackgroundCard } from "../apiConnection/ApiConnection";

const Insights = () => {
  return (
    <div className="scroll" style={{ height: "55vh", marginTop: "10px" }}>
      {/* chart section */}
      <BlueBackgroundCard heading={"Controversy over Time"} />
      <ControversyChart />

      {/* map section */}
      <BlueBackgroundCard heading={"Interest by Region"} />
      <InterestMap />
    </div>
  );
};
export default Insights;
