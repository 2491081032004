import React from "react";

import { Box, Button, Chip, TableCell, TableRow } from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";
import { NoDataComponent } from "../../esgMaturity/shareSurvey/NoData";

const dummyData = [
  {
    slNo: 1,
    nameOfTheActivity: "Annual Audit",
    personAssigned: "John",
    materialTopic: "Finance",
    status: "In Progress",
    dueDate: "2024-08-15",
  },
  {
    slNo: 2,
    nameOfTheActivity: "Sustainability Report",
    personAssigned: "Jane",
    materialTopic: "Environment",
    status: "In Progress",
    dueDate: "2024-09-01",
  },
  {
    slNo: 3,
    nameOfTheActivity: "Employee Training",
    personAssigned: "Alice",
    materialTopic: "HR",
    status: "Completed",
    dueDate: "2024-07-30",
  },
  {
    slNo: 4,
    nameOfTheActivity: "Data Security Review",
    personAssigned: "Bob",
    materialTopic: "IT",
    status: "In Progress",
    dueDate: "2024-08-20",
  },
  {
    slNo: 5,
    nameOfTheActivity: "Customer Feedback",
    personAssigned: "Charlie",
    materialTopic: "Customer Service",
    status: "In Progress",
    dueDate: "2024-08-10",
  },
];

function ActivityTable() {
  return (
    <Box sx={{}}>
      <DynamicTable
        headings={[
          "Sl.no.",
          "Name of the Activity",
          "person assigned",
          "material topic",
          "Status",
          "Due Date",
        ]}
      >
        {/* name: "tom",
      email: "tom@example.com",
      role: "dev",
      id: 12,
      isCheck: false, */}
        {dummyData.map((rowData, rowIndex) => (
          <TableRow
            key={rowIndex}
            //   className={classes.tableRowHover}
            onClick={() => {
              // dispatch(setSelectedCorporate(rowData));
              // history.push(
              //   `/supplier_task/${rowData.company_name.replace(/ /g, "_")}`
              // );
            }}
            sx={{ borderBottom: "1px solid #EBF1F8" }}
          >
            <TableCell style={textStyle()}>{rowData.slNo}</TableCell>
            <TableCell style={textStyle("main")}>
              <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                {rowData.nameOfTheActivity}
              </Box>
            </TableCell>
            <TableCell style={textStyle()}>{rowData.personAssigned}</TableCell>
            <TableCell style={textStyle()}>{rowData.materialTopic}</TableCell>

            <TableCell style={textStyle()}>
              <Chip
                sx={(theme) => ({
                  backgroundColor:
                    rowData.status === "In Progress"
                      ? theme.palette.primary.lighter
                      : theme.palette.secondary.lighter,
                  padding: "6px 10px",
                  fontSize: "12px",
                  color:
                    rowData.status === "In Progress"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.dark,
                  borderRadius: "80px",
                })}
                label={rowData.status}
              />
            </TableCell>
            <TableCell style={textStyle()}>{rowData.dueDate}</TableCell>
          </TableRow>
        ))}
      </DynamicTable>

      {dummyData?.length === 0 && (
        <NoDataComponent
          mainTitle={
            "Currently the survey is Not been shared with any stakeholder"
          }
          subTitle={
            "Select the stakeholders & share the survey to them & view the updates here."
          }
          buttonTex={"Share to Stakeholders"}
        />
      )}
    </Box>
  );
}

export default ActivityTable;
