import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Heading from "../heading/Heading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

function YearPicker({
  heading,
  label = "Label Name",
  marginTop = "10px",
  marginBottom = "20px",
  value,
  error,
  name,
  handleChange,
  actionCreator,
}) {
  const dispatch = useDispatch();

  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    const data = [];
    if (!year) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (year) {
      for (let i = 0; i < 9; i++) {
        data.push(year.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);

  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };

  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };

  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                xs={12}
                md={12}
                gap={2}
                // spacing={2}
                style={{
                  // padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                  justifyContent: "center",
                }}
              >
                {yearRange.map((year) => {
                  return (
                    <Grid
                      item
                      xs={3}
                      md={3}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setYear("");
                handleChange((prev) => {
                  dispatch(
                    actionCreator({
                      ...prev,
                      [name]: "",
                    })
                  );
                  return {
                    ...prev,
                    [name]: "",
                  };
                });
                year && setStartYear(year.split("-")[0]);
                year && setEndYear(year.split("-")[1]);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setYear(`${startYear}-${endYear}`);
                handleChange((prev) => {
                  dispatch(
                    actionCreator({
                      ...prev,
                      [name]: `${startYear}-${endYear}`,
                    })
                  );
                  return {
                    ...prev,
                    [name]: `${startYear}-${endYear}`,
                  };
                });
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <Box
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        width: "100%",
      }}
    >
      <Heading text={heading} size="13px" marginBottom="10px" color="#999" />
      {isDatePickerDialogOpen && yearPickerDialog()}

      <TextField
        name={name}
        error={Boolean(error)}
        helperText={error}
        onClick={() => setIsDatePickerDialogOpen(true)}
        label={year ? "" : label}
        variant="outlined"
        fullWidth
        size="small"
        InputLabelProps={{
          style: {
            fontSize: "14px",
          },
        }}
        value={value ? `${value.split("-")[0]} - ${value.split("-")[1]}` : ""}
      />
    </Box>
  );
}

export default YearPicker;
