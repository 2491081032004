import React from "react";
import TextField from "@mui/material/TextField";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";

function CustomTextfield(props) {
  const colorTheme = createTheme(
    adaptV4Theme({
      palette: {
        primary: { main: "#3374B9" },
        secondary: { main: "#47BB76" },
      },
    })
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={colorTheme}>
        <TextField {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
export default React.memo(CustomTextfield);
