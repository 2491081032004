import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  ListItem,
  Typography,
  TextField,
  Radio,
} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";

import FormControlLabel from "@mui/material/FormControlLabel";

import { makeStyles } from "@mui/styles";
import { brmRightStyles } from "../companySetup/brmRightStyle";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import { FiList, FiSidebar, FiUser } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";

import { BlueBackgroundCard } from "../apiConnection/ApiConnection";

// import { initialData2 } from "../esgkpi/riskUtils";

// import { sectionWiseData as defaultData } from "../surveyComponents/Inner/Elements/MainTabs/QuestionTabComponents/QuestionData";

import clipboard from "images/clipboard.png";
import Close from "@mui/icons-material/Close";

import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";
const responseStyles = makeStyles((theme) => ({
  questionCont: {
    border: "none",
    borderRadius: 5,

    width: "100%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    padding: "16px 0px",
  },
  question: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    border: "1px solid #E9E9E9",
    backgroundColor: "#FCFCFC",
    borderRadius: "6px",
    marginLeft: 5,
    width: "80%",
  },
  weightage: {
    fontSize: 13,
    fontWeight: 400,
    padding: "5px 6px",
    wordBreak: "break-all",
    color: "#3374B9",
    border: "1px solid #EBF1F8",
    backgroundColor: "#EBF1F8",
    borderRadius: "16px",
    marginLeft: 5,
    width: "18%",
    textAlign: "center",
    height: "fit-content",
  },
  userName: {
    padding: 6,
    margin: "8px 5px",
    fontSize: 13,

    borderRadius: 5,
    cursor: "pointer",
  },
}));

const ResponseSectionListLeft = ({
  users,
  children,
  hidesearch = false,
  width = "22%",
  selected,
  setSelected,
  handleGetQuestion,
}) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  const classes = responseStyles();
  const searchUsers = (e) => {
    if (e.target.value.trim() === "") setUsersList(users);
    else {
      const filtered = users.filter((user) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsersList(filtered);
    }
  };
  return (
    <div style={{ display: "flex", padding: "10px 0px", width: "100%" }}>
      <div style={{ width: width, backgroundColor: "#FBFBFB" }}>
        {!hidesearch && (
          <TextField
            variant="outlined"
            size="small"
            placeholder="search"
            fullwidth
            onChange={searchUsers}
          />
        )}
        <div
          className="scroll"
          style={{
            height: hidesearch ? "56vh" : "48vh",
            marginTop: 8,
            paddingBottom: 12,
          }}
        >
          {usersList.length ? (
            usersList.map((user, i) => (
              <Typography
                key={i}
                className={classes.userName}
                style={{
                  fontWeight:
                    selected === i || selected === user?.section_name
                      ? 500
                      : 400,
                  backgroundColor:
                    (selected === i || selected === user?.section_name) &&
                    "#EBF1F8",
                }}
                role="button"
                onClick={() => {
                  setSelected(user?.section_name);
                  //   dispatch(setSelectedSection(user?.section_name));
                  handleGetQuestion(user, user?.section_description);
                }}
              >
                {user?.section_name}
              </Typography>
            ))
          ) : (
            <Typography>No Users Found</Typography>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

const Question = ({
  question,
  type,
  options,
  submittedAnswer,
  hasComment,
  weightage = 0,
  hideWeightage = false,
}) => {
  const classes = responseStyles();
  return (
    <div className={classes.questionCont}>
      <div style={{ display: "flex", width: "100%" }}>
        <Typography className={classes.question}>{question}</Typography>
        {!hideWeightage && (
          <Typography className={classes.weightage}>
            {weightage} Weightage
          </Typography>
        )}
      </div>
      <div
        style={{
          width: "100%",
          padding:
            type === "Textfield" || type === "Number" || type === "Currency"
              ? "25px 10px"
              : "25px 25px",
          pointerEvents: "none",
        }}
      >
        <RadioGroup
          aria-label="gender"
          name="gender1"
          //   value={""}
          // onChange={handleChange}
        >
          {[1, 2, 3, 4, 5].map((o, i) => (
            <FormControlLabel
              // defaultValue={selectedOption?.option}
              key={i}
              value={o?.option || o}
              control={
                <Radio
                  disableRipple
                  disableFocusRipple
                  size="small"
                  style={{ border: "#505050", color: "#3374B9" }}
                />
              }
              label={o?.option || o}
              //   onClick={(e) => handleChange(e, o)}
            />
          ))}
        </RadioGroup>
        {/* <QuestionBody
          questionType={type}
          answers={options}
          submittedAnswer={submittedAnswer}
        /> */}
      </div>
      {hasComment && (
        <Box
          style={{
            width: "100%",
            padding: "10px 25px",
            pointerEvents: "none",
          }}
        >
          {/* <QuestionBody
            questionType={"Comment box"}
            // answers={
            //   handleQuestionType === "Multichoice" ? questionData?.answers : null
            // }
            // checkBoxOption={checkBoxOption}
            // setCheckBoxOption={setCheckBoxOption}
          /> */}
        </Box>
      )}
    </div>
  );
};

export const ViewSurveySelect = ({
  viewListing,
  setViewListing,
  list,
  handleListSelect,
  surveySelect,
}) => {
  const classes = brmRightStyles();

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      onClose={() => setViewListing(false)}
      // variant="persistent"
      width="lg"
      open={viewListing}
      style={{ position: "relative" }}
    >
      <div
        style={{ width: "500px", position: "relative", overflowX: "hidden" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
            boxShadow: "0px 1px 3px 1px #3374B926",
            marginBottom: 10,
          }}
        >
          <Typography
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            Survey Select
          </Typography>
          <Typography
            onClick={() => setViewListing(!viewListing)}
            style={{ padding: "5px 0px", fontWeight: 600, fontSize: 16 }}
          >
            <Close style={{ cursor: "pointer", color: "#3374b9" }} />
          </Typography>
        </div>

        {/* <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            padding: 5,
          }}
        >
          Pick the type of survey that has to be sent to the vendor.
        </Typography> */}

        {[].map((v, i) => {
          return (
            <ListItem
              key={i + v._id}
              className={classes.onListHover}
              onClick={() => {
                handleListSelect(v);
              }}
              style={{
                width: "100%",
                padding: 10,
                margin: 5,
                gap: 5,
                backgroundColor: v._id !== surveySelect?._id ? "" : "#EBF1F8",
              }}
            >
              <Box width={"100%"}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    selected: true,
                  }}
                >
                  <Radio checked={v._id === surveySelect?._id} />
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"90%"}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: "#15314E",
                        padding: 5,
                      }}
                    >
                      {v.survey_name}
                    </Typography>

                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      style={{ gap: 10 }}
                    >
                      <FiUser size={18} color={"#505050"} />
                      <Typography
                        style={{
                          color: "#505050",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        {v?.user_name}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  style={{ gap: 20, padding: "5px 10px" }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ gap: 10 }}
                  >
                    <FiSidebar size={18} color={"#505050"} />
                    <Typography
                      style={{
                        color: "#505050",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {v?.no_of_sections} Sections
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    style={{ gap: 10 }}
                  >
                    <FiList size={18} color={"#505050"} />
                    <Typography
                      style={{
                        color: "#505050",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      {v?.no_of_questions} Questions
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </ListItem>
          );
        })}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 5,
          right: 5,
        }}
      >
        <Button
          style={{
            textTransform: "none",
            color: "#3374B9",
            padding: "3px 10px",
          }}
          onClick={() => setViewListing(!viewListing)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            backgroundColor: "#3374B9",
            color: "white",
            padding: "3px 10px",
          }}
          onClick={() => setViewListing(!viewListing)}
        >
          Save & Continue
        </Button>
      </div>
    </Drawer>
  );
};

function SurveyQuestions(props) {
  const {
    login,
    brmReducer,
    setShareDialogOpen,
    selectedSurveyId,
    // corporateSurveyQuestions,
  } = props;
  const classes = brmRightStyles();
  const dispatch = useDispatch();
  const { publishedSurveyList, sectionWiseData, sectionWiseQuestion } = {};

  //   const { companyDocuments, supplierOrganizationDetails } = useSelector(
  //     (state) => state.brmReducer
  //   );

  // const { sectionWiseData, selectedSection, sectionWiseQuestion } = useSelector(
  //   (state) => state.riskDashboard
  // );
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  // const [questions, setQuestions] = useState([]);
  // const [controversiesQuestions, setControversiesQuestions] = useState(
  //   corporateSurveyQuestions || []
  // );
  const [viewListing, setViewListing] = useState(false);
  const [selected, setSelected] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [data, setData] = useState({
    type: "",
    question: "",
    options: [["", 0]],
  });
  const [value, setValue] = React.useState({});
  const [selectSurvey, setSurveySelect] = useState({});

  // const listSurvey = ['survey1', 'survey2']

  // const location = useGeoLocation();
  const scroll = useRef();

  // console.log("location", location);

  useEffect(() => {
    // dispatch(
    //   getPublishedSurveys(
    //     login?.token,
    //     login?.current_organisation,
    //     supplierOrganizationDetails?._id
    //   )
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   sectionWiseData.length > 0 &&
  //     dispatch(
  //       getQuestion(
  //         login?.token,
  //         sectionWiseData.find((s) => s.title === selectedSection)?._id
  //       )
  //     );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSection, sectionWiseData.length]);

  // useEffect(() => {
  //   // getSurveyeQuestions(
  //   //   login.token,
  //   //   brmReducer?.organizationDetails[0]?._id ||
  //   //     localStorage.getItem("organization_id")
  //   // );
  //   setControversiesQuestions(corporateSurveyQuestions);
  // }, []);

  useEffect(() => {
    scroll?.current?.scrollTo(0, 0);
  }, [selected]);

  const handleListSelect = (value) => {
    setSurveySelect(value);
    // dispatch(setSelectedSurvey(value));
    // dispatch(setQuestions([]));
    setSelected("");
    // dispatch(getSections(login?.token, value?._id, value?.published_version));
  };

  const handleGetQuestion = (value, description) => {
    setSectionDescription(description);
    // dispatch(
    //   getQuestion(
    //     login?.token,
    //     value?._id,
    //     100,
    //     0,
    //     selectSurvey?.published_version,
    //     sectionWiseQuestion,
    //     false
    //   )
    // );
  };

  useEffect(() => {
    // if (publishedSurveyList?.length > 0 && sectionWiseData?.length === 0) {
    //   let getDefault = publishedSurveyList.find((s) => s?.default);
    //   handleListSelect(getDefault);
    // }
    // if (sectionWiseData?.length > 0 && sectionWiseQuestion?.length === 0) {
    //   setSelected(sectionWiseData[0]?.section_name);
    //   handleGetQuestion(
    //     sectionWiseData[0],
    //     sectionWiseData[0]?.section_description
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedSurveyList, sectionWiseData]);

  useEffect(() => {
    return () => {
      //   dispatch({
      //     type: SET_PUBLISHED_SURVEY_LIST,
      //     payload: [],
      //   });
      //   dispatch(setQuestions([]));
      //   dispatch(setSelectedSection(""));
      //   dispatch(setSection([]));
      //   dispatch(setSelectedSurvey(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: "63vh" }} className={classes.mainDiv}>
      {/* {isAddQuestionOpen && addQuestionDialog()} */}
      <ViewSurveySelect
        viewListing={viewListing}
        setViewListing={setViewListing}
        list={publishedSurveyList}
        handleListSelect={handleListSelect}
        surveySelect={selectSurvey}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 400, fontSize: "16px" }}>
            Survey
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Button
            // variant="outlined"
            size="small"
            // startIcon={<Add />}
            // color="primary"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "10px",
            }}
            onClick={() => setIsAddQuestionOpen(!isAddQuestionOpen)}
          >
            Add Question
          </Button> */}
          <Button
            size="small"
            style={{
              color: "#3374b9",
              textTransform: "none",
              marginRight: "5px",
            }}
            onClick={() => setViewListing(!viewListing)}
          >
            <img
              src={clipboard}
              alt="docIcon"
              style={{ width: 22, marginRight: 5 }}
            />
            Survey Select
          </Button>
          <Button
            size="small"
            color="primary"
            style={{
              textTransform: "none",
            }}
            onClick={() => setShareDialogOpen(true)}
            disabled={
              selectSurvey?._id === selectedSurveyId ||
              selectSurvey?.is_published
            }
          >
            {/* <Icon style={{ color: "#3374b9", cursor: "pointer" }}> */}
            <ShareOutlinedIcon style={{ marginRight: "5px" }} />{" "}
            {`${
              selectSurvey?.is_published ? "Already Shared" : "Share Survey"
            } `}
            {/* </Icon> */}
          </Button>
        </div>
      </div>
      {sectionWiseData?.length > 0 ? (
        <ResponseSectionListLeft
          users={sectionWiseData}
          selected={selected}
          setSelected={setSelected}
          hidesearch
          handleGetQuestion={handleGetQuestion}
        >
          <div
            className="scroll"
            ref={scroll}
            style={{ width: "78%", height: "59vh" }}
          >
            {/* {selected === 0 && ( */}
            <>
              {selected && (
                <BlueBackgroundCard
                  heading={selected}
                  subHeading={sectionDescription}
                />
              )}
              {[]?.map((question, idx) => {
                return (
                  <Question
                    key={idx}
                    question={question.question}
                    options={question.answers}
                    type={question.question_type}
                    weightage={question?.weightage}
                    hasComment={question?.comment}
                  />
                );
              })}
              {sectionWiseQuestion?.length === 0 && (
                <NoDataComponent mainTitle={"please select a section"} />
              )}
            </>
          </div>
        </ResponseSectionListLeft>
      ) : (
        <NoDataComponent mainTitle={"please select a survey"} />
      )}
      <br /> <br />
      {/* </div> */}
    </div>
  );
}

export default SurveyQuestions;
