import { Paper, Typography } from "@mui/material";
import React from "react";

function Mitigation({ title }) {
  const totalnonrenewableEnergyData = [
    {
      title: "Energy Efficiency Measures:",
      description: `Implement energy efficiency measures across office buildings, data centers, and other facilities. This can include upgrading lighting systems, optimizing HVAC (heating, ventilation, and air conditioning) systems, and using energy-efficient equipment and appliances.`,
    },
    {
      title: "Renewable Energy Adoption:",
      description: `Increase the use of renewable energy sources, such as solar power, wind power, or biomass energy. Install renewable energy systems on-site, engage in power purchase agreements (PPAs) with renewable energy providers, or procure renewable energy certificates (RECs) to offset nonrenewable energy consumption.`,
    },
    {
      title: "Virtualization and Cloud Computing:",
      description: `Promote virtualization and cloud computing technologies to optimize IT infrastructure and reduce energy-intensive hardware requirements. Consolidating servers and using virtual machines can significantly reduce energy consumption.`,
    },
    {
      title: "Remote Work and Telecommuting:",
      description: `Encourage remote work and telecommuting options for employees, which can lead to reduced energy consumption associated with commuting and office space requirements.`,
    },
    {
      title: "Energy Management Systems:",
      description: `Deploy advanced energy management systems that monitor and optimize energy usage in real-time. These systems can provide insights into energy consumption patterns, identify areas of improvement, and facilitate energy-saving actions.`,
    },
    {
      title: "Employee Awareness and Training:",
      description: `Conduct energy awareness campaigns and provide training to employees on energy-saving practices, such as turning off lights and equipment when not in use, optimizing computer power settings, and promoting responsible energy consumption behaviors.`,
    },
    {
      title: "Green Building Design and Retrofitting:",
      description: `Incorporate green building principles in the design and construction of new facilities. For existing buildings, consider retrofitting measures such as improving insulation, upgrading windows, and installing energy-efficient lighting and controls.`,
    },
    {
      title: "Energy Audits and Benchmarking:",
      description: `Conduct regular energy audits to identify energy-saving opportunities and benchmark energy performance against industry standards. Use the audit findings to develop and implement targeted energy reduction strategies.`,
    },
    {
      title: "Supplier Engagement:",
      description: `Encourage suppliers to adopt energy-efficient practices and renewable energy sources in their operations. Collaborate with suppliers to reduce the carbon footprint associated with the company's supply chain.`,
    },
    {
      title: "Reporting and Transparency:",
      description: `Regularly report and disclose energy consumption data, initiatives, and progress towards energy reduction goals to stakeholders. This demonstrates commitment and transparency in addressing nonrenewable energy consumption`,
    },
  ];

  const totalPlasticwasteGeneratedForRecycling = [
    {
      title: "Plastic Reduction Initiatives",
      description: ` Implement initiatives to minimize the use of plastic within the organization. Encourage the use of alternatives to single-use plastics, such as reusable bottles, mugs, and cutlery. Promote the adoption of digital documents and reduce paper usage.`,
    },
    {
      title: "Plastic-Free Packaging",
      description: ` Collaborate with suppliers and vendors to adopt plastic-free packaging solutions. Encourage the use of eco-friendly packaging materials, such as biodegradable or compostable alternatives, for products and deliveries.`,
    },
    {
      title: " Waste Segregation and Recycling Infrastructure",
      description: `Set up an effective waste segregation system within the organization's premises. Provide dedicated recycling bins for plastic waste and ensure proper labeling and education for employees to correctly dispose of plastic waste.`,
    },
    {
      title: "Partner with Recycling Facilities",
      description: ` Establish partnerships with local recycling facilities that specialize in plastic waste recycling. Ensure that the collected plastic waste is effectively recycled and transformed into reusable materials.`,
    },
    {
      title: "Employee Awareness and Training",
      description: `Raise awareness among employees about the importance of plastic waste reduction and recycling. Conduct training sessions to educate employees on proper waste management practices, including the segregation and disposal of plastic waste.`,
    },
    {
      title: "Supplier Engagement",
      description: `Encourage suppliers to adopt sustainable packaging practices and reduce plastic waste in their products and deliveries. Give preference to suppliers that prioritize environmentally friendly packaging solutions.`,
    },
    {
      title: " Promote Circular Economy",
      description: `Explore opportunities to integrate circular economy principles into the organization's operations. This can involve identifying ways to reuse plastic waste within the company or collaborating with external partners for upcycling or recycling initiatives.`,
    },
    {
      title: "Product Redesign",
      description: ` Assess the design of products and packaging to reduce the use of plastic materials. Opt for designs that use less plastic or incorporate recycled or biodegradable materials, ensuring they can be easily recycled at the end of their lifecycle.`,
    },
    {
      title: "Consumer Education",
      description: `Educate customers and clients about the importance of plastic waste reduction and recycling. Provide information on proper disposal methods and promote the use of reusable and sustainable alternatives.`,
    },
    {
      title: "Track and Measure Progress",
      description: `Implement a system to track and measure the amount of plastic waste generated for recycling. Set targets and regularly monitor progress to ensure continuous improvement and identify areas for further reduction.`,
    },
  ];

  const totalEwasteGeneratedForRecycling = [
    {
      title: "Responsible Disposal Policy",
      description: `Develop and implement a comprehensive e-waste disposal policy that ensures the proper handling and recycling of electronic devices. Establish guidelines for employees to follow when disposing of electronic equipment.`,
    },
    {
      title: "Extended Producer Responsibility (EPR)",
      description: `Adhere to EPR principles by taking responsibility for the entire lifecycle of electronic products. Collaborate with suppliers and manufacturers to design products with recyclability in mind and establish take-back programs for end-of-life devices.`,
    },
    {
      title: "E-waste Collection Drives",
      description: `Organize e-waste collection drives within the company to encourage employees to properly dispose of their electronic devices. Partner with certified e-waste recyclers who can collect and recycle the collected e-waste in an environmentally responsible manner.`,
    },
    {
      title: "Internal Reuse and Refurbishment",
      description: `Establish an internal system for the reuse and refurbishment of electronic devices whenever possible. This can involve upgrading and repurposing equipment within the organization to extend their useful life and minimize e-waste generation.`,
    },
    {
      title: "Employee Education and Awareness",
      description: `Conduct training sessions and awareness campaigns to educate employees about the importance of proper e-waste disposal. Provide guidelines on how to identify recyclable electronic items and inform them about the available recycling options.`,
    },
    {
      title: "Supplier Collaboration",
      description: `Collaborate with suppliers and manufacturers to promote sustainable practices throughout the supply chain. Encourage them to design products that are more easily recyclable and support their own e-waste management initiatives.`,
    },
    {
      title: "Certified E-waste Recyclers",
      description: `Partner with certified e-waste recycling companies that follow environmentally sound recycling practices. Ensure that the selected recyclers comply with all necessary regulations and standards to avoid improper disposal or unethical handling of e-waste.`,
    },
    {
      title: "Data Security Measures",
      description: `Prioritize data security during the disposal of electronic devices. Implement secure data wiping or destruction protocols to ensure that sensitive information is properly handled and protected during the recycling process.`,
    },
    {
      title: "Promote Repair and Upgradability",
      description: `Encourage the repair and upgradability of electronic devices to extend their lifespan. Support repair programs or collaborate with third-party repair service providers to minimize the need for premature replacement and reduce e-waste generation.`,
    },
    {
      title: "Continuous Improvement and Reporting",
      description: `Regularly monitor and measure e-waste generation within the organization. Set targets for reducing e-waste and track progress over time. Report transparently on e-waste management efforts and achievements to stakeholders.
    `,
    },
  ];

  const totalHazardouswasteGeneratedForRecycling = [
    {
      title: "Hazardous Waste Identification and Segregation",
      description: `Implement a robust system to identify and segregate hazardous waste generated within the organization. Provide appropriate containers and labeling to ensure proper storage and disposal of hazardous materials.`,
    },
    {
      title: "Hazardous Waste Minimization",
      description: `Adopt practices that minimize the generation of hazardous waste. This includes optimizing chemical usage, exploring alternative materials, and implementing process modifications to reduce waste generation at the source.`,
    },
    {
      title: "Green Chemistry Practices",
      description: `Embrace green chemistry principles by choosing less hazardous alternatives for products and processes. Evaluate and replace hazardous chemicals with safer and more environmentally friendly options.`,
    },
    {
      title: "Substitution of Hazardous Materials",
      description: `Identify opportunities to substitute hazardous materials with non-hazardous or less hazardous alternatives. Explore greener options for cleaning agents, solvents, and other chemicals used within the organization.`,
    },
    {
      title: "Proper Handling and Storage",
      description: `Ensure that hazardous waste is handled, stored, and transported according to applicable regulations and best practices. Train employees on proper handling techniques to prevent spills, leaks, or other incidents that could result in the generation of hazardous waste.`,
    },
    {
      title: "Certified Hazardous Waste Recyclers",
      description: `Partner with certified hazardous waste recyclers who have the necessary expertise and infrastructure to handle hazardous waste recycling. Verify that the chosen recyclers follow all relevant regulations and employ environmentally sound recycling practices.`,
    },
    {
      title: " Responsible Disposal",
      description: `If hazardous waste cannot be recycled, ensure that it is disposed of properly and in accordance with applicable regulations. Engage licensed and authorized waste disposal facilities to handle the final disposal of hazardous waste.`,
    },
    {
      title: "Employee Training and Awareness",
      description: `Provide comprehensive training to employees regarding the identification, handling, and disposal of hazardous waste. Increase awareness about the potential hazards associated with specific materials and processes to promote responsible waste management practices.`,
    },
    {
      title: "Continuous Improvement and Auditing",
      description: `Establish regular audits and assessments to monitor and improve hazardous waste management practices. Identify areas for improvement, set targets for waste reduction, and track progress over time.`,
    },
    {
      title: "Compliance with Regulatory Requirements",
      description: `Stay updated with the latest regulations and legal requirements related to hazardous waste management. Ensure compliance with all relevant environmental regulations and obtain necessary permits for handling and recycling hazardous waste`,
    },
  ];

  const s1emission = [
    {
      title: "Energy Efficiency Measures",
      description: `Implement energy-efficient technologies and practices to reduce fuel consumption and associated emissions. This can include optimizing heating, ventilation, and air conditioning (HVAC) systems, using energy-efficient equipment, and improving insulation in buildings.`,
    },
    {
      title: "Renewable Energy Adoption:",
      description: `Increase the use of renewable energy sources, such as solar power or wind power, to generate electricity and heat. Install on-site renewable energy systems or procure renewable energy from external sources to replace fossil fuel-based energy.`,
    },
    {
      title: "Green Transport Initiatives",
      description: `Promote sustainable transportation options for employees, such as encouraging the use of public transportation, carpooling, cycling, and electric vehicles (EVs). Provide EV charging infrastructure and incentives for employees to switch to electric vehicles.`,
    },
    {
      title: "Fleet Optimization",
      description: `Optimize the company's vehicle fleet by adopting fuel-efficient vehicles, using telematics for efficient routing and reducing idling time, and implementing maintenance programs to keep vehicles operating at peak efficiency.`,
    },
    {
      title: "Waste Management",
      description: `Implement efficient waste management practices, such as recycling and composting, to reduce methane emissions from landfills. Encourage waste reduction and reuse initiatives within the organization.`,
    },
    {
      title: "Green Building Design",
      description: `Incorporate green building principles in the design and construction of new facilities. This includes using energy-efficient materials, optimizing insulation, installing energy-efficient lighting and controls, and utilizing renewable energy systems.`,
    },
    {
      title: "Data Center Efficiency",
      description: `Improve the energy efficiency of data centers by implementing virtualization, optimizing cooling systems, and upgrading to more energy-efficient servers and storage systems. Utilize energy management software to monitor and optimize data center operations.`,
    },
    {
      title: "Employee Awareness and Training",
      description: `Raise employee awareness about energy conservation and greenhouse gas emissions through training programs and communication campaigns. Encourage employees to adopt energy-saving behaviors in the workplace.`,
    },
    {
      title: "Carbon Offsetting",
      description: `Offset unavoidable Scope 1 emissions by investing in certified carbon offset projects. This can include supporting renewable energy projects or reforestation initiatives that sequester carbon dioxide.`,
    },
    {
      title: "Continuous Monitoring and Reporting",
      description: `Implement robust monitoring systems to track and report Scope 1 emissions regularly. Use the data to set reduction targets, identify emission hotspots, and drive continuous improvement in emission management.`,
    },
  ];

  const s2emission = [
    {
      title: "Renewable Energy Procurement",
      descripion: `Procure electricity from renewable energy sources such as solar, wind, or hydroelectric power. Enter into power purchase agreements (PPAs) with renewable energy providers or invest in on-site renewable energy generation to directly offset electricity consumption.`,
    },
    {
      title: "Energy Efficiency Measures",
      description: `Implement energy-efficient technologies and practices to reduce overall electricity consumption. This can include optimizing lighting systems, upgrading to energy-efficient equipment, and implementing smart building controls and automation.`,
    },
    {
      title: "Virtualization and Cloud Computing",
      description: `Promote virtualization and cloud computing technologies to optimize IT infrastructure. Consolidating servers and migrating to cloud-based platforms can significantly reduce electricity consumption associated with data centers.`,
    },
    {
      title: "Green Building Design and Retrofits",
      description: `Incorporate green building principles in the design of new facilities and retrofit existing buildings. This includes optimizing insulation, installing energy-efficient windows, utilizing natural lighting, and implementing efficient HVAC systems.`,
    },
    {
      title: "Employee Engagement",
      description: `Raise awareness among employees about the importance of energy conservation and encourage energy-saving behaviors. Provide training on energy-efficient practices and involve employees in identifying and implementing energy-saving initiatives.`,
    },
    {
      title: "Energy Monitoring and Management",
      description: `Deploy energy monitoring systems to track electricity consumption patterns and identify areas for improvement. Use real-time data to optimize energy usage, detect inefficiencies, and drive continuous energy management efforts.`,
    },
    {
      title: "Energy Procurement Strategies",
      description: `Evaluate electricity suppliers and prioritize those with a higher share of renewable energy in their energy mix. Consider engaging in green tariff programs or renewable energy certificate (REC) purchases to support renewable energy generation.`,
    },
    {
      title: "Load Management and Demand Response",
      description: `Implement load management strategies to shift energy-intensive activities to off-peak hours, when electricity demand and costs are lower. Participate in demand response programs to reduce electricity consumption during peak demand periods.`,
    },
    {
      title: "Energy Audits and Benchmarking",
      description: `Conduct regular energy audits to identify energy-saving opportunities and benchmark energy performance against industry standards. Use the audit findings to develop and implement targeted energy reduction strategies.`,
    },
    {
      title: "Reporting and Transparency",
      description: `Regularly report and disclose Scope 2 emissions and initiatives taken to reduce them. Transparently communicate progress towards renewable energy targets and share best practices with stakeholders`,
    },
  ];

  const totalElectricityConsumptionRenewable = [
    {
      title: "Renewable Energy Procurement",
      description: `Enter into power purchase agreements (PPAs) or renewable energy contracts with renewable energy providers to directly source electricity from renewable sources such as solar, wind, or hydroelectric power.`,
    },
    {
      title: "On-Site Renewable Energy Generation",
      description: `Install renewable energy systems, such as solar panels or wind turbines, on company premises to generate clean electricity. This can offset the need for electricity sourced from non-renewable locations.`,
    },
    {
      title: "Off-Site Renewable Energy Procurement",
      description: `Invest in off-site renewable energy projects, such as solar or wind farms, through renewable energy certificates (RECs) or green tariff programs. This allows the company to support renewable energy generation and claim the associated environmental benefits.`,
    },
    {
      title: "Collaborate with Energy Service Providers",
      description: ` Engage with energy service providers who specialize in renewable energy solutions. They can help identify opportunities for increasing renewable energy procurement and advise on the most suitable options for the company's energy needs.`,
    },
    {
      title: "Energy Efficiency Measures",
      description: `Implement energy-efficient technologies and practices to reduce overall electricity consumption. By optimizing lighting systems, upgrading to energy-efficient equipment, and utilizing smart building controls, the company can reduce the demand for electricity, including that from non-renewable sources.`,
    },
    {
      title: "Employee Engagement",
      description: ` Raise awareness among employees about the importance of renewable energy and encourage energy-saving behaviors. Promote initiatives such as turning off lights and equipment when not in use, optimizing computer power settings, and reducing energy waste.`,
    },
    {
      title: "Green Building Design and Retrofits",
      description: ` Incorporate green building principles in the design of new facilities and retrofit existing buildings. This includes optimizing insulation, installing energy-efficient windows, utilizing natural lighting, and implementing efficient HVAC systems, which can contribute to overall energy savings.`,
    },
    {
      title: "Continuous Monitoring and Reporting",
      description: `Implement systems to monitor and track electricity consumption, including the share of renewable energy. Regularly report and disclose the company's progress in increasing the use of electricity from renewable locations.`,
    },
    {
      title: "Collaborate with Renewable Energy Initiatives",
      description: ` Partner with local renewable energy initiatives, non-profit organizations, or industry associations focused on promoting renewable energy adoption. Participate in renewable energy advocacy programs to drive policy changes and support the growth of renewable energy infrastructure.`,
    },
    {
      title: "Set Renewable Energy Targets",
      description: `Establish specific targets for increasing the percentage of electricity consumed from renewable locations. These targets can serve as a guiding principle for decision-making and drive the company's renewable energy procurement strategies.`,
    },
  ];

  const totalFuelConsumptionFromRenewable = [
    {
      title: "Transition to Electric Vehicles",
      description: `Replace conventional fuel-powered vehicles with electric vehicles (EVs) for the company's fleet. This includes passenger vehicles, delivery vehicles, and any other vehicles used for transportation purposes. EVs can be powered by electricity sourced from renewable energy, thereby reducing the reliance on fossil fuel-based fuels.`,
    },
    {
      title: "Renewable Energy in On-Site Power Generation",
      description: `Install renewable energy systems, such as solar panels or wind turbines, to generate electricity on-site. This renewable energy can be used to power equipment and facilities, reducing the need for fuel-powered generators or backup systems.`,
    },
    {
      title: "Energy Efficiency Measures",
      description: `Implement energy-saving practices and technologies across facilities and operations. This includes optimizing heating, ventilation, and air conditioning (HVAC) systems, improving insulation, upgrading to energy-efficient equipment, and adopting smart building controls.`,
    },
    {
      title: "Virtual Meetings and Telecommuting",
      description: `Promote the use of virtual meetings and telecommuting to reduce the need for business travel and commuting. This can help reduce fuel consumption associated with transportation and decrease the overall demand for fuel.`,
    },
    {
      title: "Optimize Logistics and Supply Chain",
      description: `Evaluate and optimize the logistics and supply chain operations to reduce fuel consumption. This can involve optimizing transportation routes, utilizing fuel-efficient vehicles, and consolidating shipments to minimize fuel-intensive activities.`,
    },
    {
      title: "Employee Awareness and Training",
      description: `Raise employee awareness about the importance of fuel conservation and promote fuel-efficient driving practices. Provide training on eco-driving techniques, such as avoiding rapid acceleration and excessive idling, and encourage carpooling and public transportation options.`,
    },
    {
      title: "Collaboration with Suppliers",
      description: `Engage with suppliers to promote fuel efficiency and sustainability in their operations. Encourage them to adopt fuel-efficient transportation methods and renewable energy sources for their own operations.`,
    },
    {
      title: "Reporting and Transparency",
      description: `Regularly track and report fuel consumption data, including the proportion of fuel consumed from renewable sources. Transparently communicate the company's progress in reducing fuel consumption and increasing the use of renewable fuels.`,
    },
    {
      title: "Research and Development",
      description: `Invest in research and development efforts to explore alternative fuels and energy sources. This can involve exploring biofuels, hydrogen fuel cells, or other renewable fuel options that can replace or supplement conventional fossil fuels.`,
    },
    {
      title: "Set Fuel Consumption Reduction Targets",
      description: `Establish specific targets for reducing fuel consumption and increasing the proportion of fuel consumed from renewable sources. These targets can serve as a benchmark for progress and drive continuous improvement efforts.`,
    },
  ];

  const otherenergyconsumptonRenewable = [
    {
      title: "Renewable Energy Procurement",
      description: `Enter into long-term power purchase agreements (PPAs) or renewable energy contracts with renewable energy providers to directly source energy from renewable sources such as solar, wind, or hydroelectric power.`,
    },
    {
      title: "On-Site Renewable Energy Generation",
      description: `Install renewable energy systems, such as solar panels or wind turbines, on company premises to generate clean energy. This can offset the need for energy sourced from non-renewable locations.`,
    },
    {
      title: "Off-Site Renewable Energy Procurement",
      description: `Invest in off-site renewable energy projects, such as solar or wind farms, through renewable energy certificates (RECs) or green tariff programs. This allows the company to support renewable energy generation and claim the associated environmental benefits.`,
    },
    {
      title: "Energy Efficiency Measures",
      description: `Implement energy-efficient technologies and practices to reduce overall energy consumption. This includes optimizing lighting systems, upgrading to energy-efficient equipment, and utilizing smart building controls and automation.`,
    },
    {
      title: "Demand Response and Load Management",
      description: `Participate in demand response programs and implement load management strategies to shift energy-intensive activities to off-peak hours or times when renewable energy generation is higher. This helps align energy consumption with renewable energy availability.`,
    },
    {
      title: "Green Building Design and Retrofits",
      description: `Incorporate green building principles in the design of new facilities and retrofit existing buildings. This includes optimizing insulation, installing energy-efficient windows, utilizing natural lighting, and implementing efficient HVAC systems.`,
    },
    {
      title: "Employee Engagement",
      description: `Raise awareness among employees about the importance of renewable energy and encourage energy-saving behaviors. Promote initiatives such as turning off lights and equipment when not in use, optimizing computer power settings, and reducing energy waste.`,
    },
    {
      title: "Continuous Monitoring and Energy Management",
      description: `Implement energy monitoring systems to track and analyze energy consumption patterns. Use real-time data to identify opportunities for improvement, optimize energy usage, and drive continuous energy management efforts.`,
    },
    {
      title: "ollaborate with Renewable Energy Providers",
      description: `Engage with renewable energy providers and technology partners to explore innovative solutions and opportunities for increasing renewable energy consumption. This can involve joint projects, research collaborations, or technology pilot programs.`,
    },
    {
      title: "Reporting and Transparency",
      description: `Regularly report and disclose the company's energy consumption data, including the share of energy consumed from renewable locations. Transparently communicate the company's commitment to renewable energy and progress in increasing renewable energy consumption.`,
    },
  ];

  const totalVolumeOfWaterWithDrawen = [
    {
      title: " Water Conservation Measures",
      description: `Implement water conservation practices throughout the organization's facilities. This includes repairing leaks, optimizing water use in restrooms and kitchens, installing water-efficient fixtures and appliances, and promoting awareness among employees about the importance of water conservation.`,
    },
    {
      title: "Water-Efficient Landscaping",
      description: `Implement water-efficient landscaping techniques, such as using native plants that require less water, installing drip irrigation systems, and utilizing rainwater harvesting methods to reduce the need for additional water sources.`,
    },
    {
      title: "Water Recycling and Reuse",
      description: `Explore opportunities for water recycling and reuse within the organization. Implement systems to treat and reuse wastewater for non-potable purposes such as landscaping or toilet flushing. This reduces the demand for fresh water sources.`,
    },
    {
      title: "Rainwater Harvesting",
      description: `Install rainwater harvesting systems to capture and utilize rainwater for various purposes, such as irrigation or toilet flushing. This helps to reduce dependence on other water sources for non-potable uses.`,
    },
    {
      title: "Water-Efficient Cooling Systems",
      description: `Optimize cooling systems, such as air conditioning units and cooling towers, to minimize water consumption. Implement technologies and practices that enhance the efficiency of these systems and reduce water requirements.`,
    },
    {
      title: "Leak Detection and Repair",
      description: `Regularly inspect and maintain water supply and distribution systems to identify and promptly repair leaks or faulty equipment. Implement automated leak detection systems to detect and address leaks more efficiently.`,
    },
    {
      title: "Collaborate with Suppliers",
      description: ` Engage with suppliers and contractors to promote water-efficient practices and products. Encourage them to adopt water-saving measures and utilize water-efficient technologies in their operations.`,
    },
    {
      title: "Water Footprint Assessment",
      description: `Conduct a water footprint assessment to identify areas of high water consumption and potential opportunities for reduction. Use the assessment findings to develop targeted water conservation strategies.`,
    },
    {
      title: "Employee Engagement and Education",
      description: `Raise awareness among employees about the importance of water conservation. Educate them about water-saving techniques, such as turning off taps when not in use, reporting leaks promptly, and using water responsibly.`,
    },
    {
      title: "Monitoring and Reporting",
      description: `Implement water monitoring systems to track water consumption and identify trends or areas of improvement. Establish regular reporting mechanisms to communicate water consumption data and progress on water conservation efforts to stakeholders.`,
    },
  ];

  const totalVolumeOfWaterConsumption = [
    {
      title: "Water Conservation Measures",
      description: `Implement water conservation practices throughout the organization's facilities. This includes optimizing water use in restrooms, kitchens, and other areas, repairing leaks promptly, and promoting employee awareness about water conservation.`,
    },
    {
      title: "Water-Efficient Fixtures and Appliances",
      description: `Install water-efficient fixtures and appliances such as low-flow faucets, toilets, and urinals. Utilize dishwashers and washing machines with high water efficiency ratings. These measures can significantly reduce water consumption.`,
    },
    {
      title: "Water Recycling and Reuse",
      description: `Implement systems to treat and reuse wastewater within the organization. Depending on the specific operations, treated wastewater can be utilized for non-potable purposes such as irrigation, cooling systems, or toilet flushing.`,
    },
    {
      title: "Process Optimization",
      description: ` Identify processes within the organization that involve high water consumption. Analyze these processes to find opportunities for optimization and water use reduction. Explore alternatives or implement technologies that require less water.`,
    },
    {
      title: "Rainwater Harvesting",
      description: `Install rainwater harvesting systems to capture and utilize rainwater for various purposes such as landscaping or non-potable water uses. This reduces the reliance on freshwater sources for such activities.`,
    },
    {
      title: "Monitoring and Auditing",
      description: `Implement water monitoring systems to track water consumption and identify areas of high usage or potential wastage. Conduct regular water audits to assess usage patterns, identify inefficiencies, and develop strategies for improvement.`,
    },
    {
      title: "Supplier Engagement",
      description: `Collaborate with suppliers to encourage and source products and services that prioritize water efficiency. Consider partnering with suppliers who have implemented water-saving practices in their operations.`,
    },
    {
      title: "Employee Engagement and Education",
      description: `Foster a culture of water conservation among employees. Raise awareness about the importance of water conservation, provide training on water-efficient practices, and encourage employees to contribute their ideas and suggestions for water-saving initiatives.`,
    },
    {
      title: "Water Management Planning",
      description: `Develop a comprehensive water management plan that sets goals, targets, and timelines for reducing water consumption. Include strategies for continuous improvement, regular monitoring, and reporting on progress.`,
    },
    {
      title: "Stakeholder Collaboration",
      description: `Engage with local communities, industry peers, and relevant stakeholders to share best practices, collaborate on water conservation initiatives, and support regional water management efforts.`,
    },
  ];

  // Total Energy Renewable (MegaJoules)

  const returnMitigationData = (title) => {
    if (title === "Total Energy Non-Renewable (MegaJoules)")
      return totalnonrenewableEnergyData;
    if (title === "Total Energy Renewable (MegaJoules)")
      return totalnonrenewableEnergyData;
    if (title === "Total Energy Renewable") return totalnonrenewableEnergyData;
    if (title === "Total Energy Non-Renewable")
      return totalnonrenewableEnergyData;
    if (title === "Total S1 Emission") return s1emission;
    if (title === "Total S2 Emission") return s2emission;
    if (title === "Total E-Waste Recycling")
      return totalEwasteGeneratedForRecycling;
    if (title === "Total Hazardous Waste Recycling")
      return totalHazardouswasteGeneratedForRecycling;
    if (title === "Total Other Hazardous Waste Recycling")
      return totalHazardouswasteGeneratedForRecycling;
    if (title === "Total Plastic Waste Recycling")
      return totalPlasticwasteGeneratedForRecycling;
    if (title === "Total Other Energy Consumption Renewable")
      return otherenergyconsumptonRenewable;
    if (title === "Total Fuel Consumption Renewable")
      return totalFuelConsumptionFromRenewable;
    if (title === "Total Volume of Water Withdrawal")
      return totalVolumeOfWaterWithDrawen;
    if (title === "Total volume of water withdrawal from Other Sources")
      return totalVolumeOfWaterWithDrawen;
    if (
      title === "Total volume of water withdrawal from Third Party Water Source"
    )
      return totalVolumeOfWaterWithDrawen;
    if (title === "Total volume of water withdrawal from Surface water Sources")
      return totalVolumeOfWaterWithDrawen;
    if (title === "Total volume of water withdrawal from Seawater Sources")
      return totalVolumeOfWaterWithDrawen;
    if (title === "Total Volume of Water Consumption")
      return totalVolumeOfWaterConsumption;
    if (title === "Total Electricity Consumption Renewable")
      return totalElectricityConsumptionRenewable;
    else return [];
  };

  return (
    returnMitigationData(title)?.length > 0 && (
      <div>
        <div style={{ padding: "18px 10px" }}>
          <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
            Suggested Mitigation
          </Typography>
        </div>
        <div className="scroll" style={{ height: "60vh" }}>
          {returnMitigationData(title).map((suggestion) => (
            <div
              style={{
                margin: "10px",
                borderRadius: "8px",
                boxShadow: " -1px 2px 25px -11px rgba(189,208,240,0.98)",
              }}
            >
              <Typography
                style={{
                  color: "#15314E",
                  fontSize: "14px",
                  padding: "12px 10px",
                  fontWeight: 600,
                }}
              >
                {suggestion?.title}
              </Typography>
              <Typography
                style={{
                  color: "#676767",
                  fontSize: "14px",
                  padding: "5px 10px 10px 10px",
                  fontWeight: 400,
                }}
              >
                {suggestion?.description}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default Mitigation;
