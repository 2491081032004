import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  blueText: {
    fontWeight: "600",
    color: "#3374B9",
    padding: "10px 0px",
  },
  grid: { paddingTop: 20 },
  CustomTextfield: { width: "100%" },
  Box1: {
    borderBottom: "2px solid gray",
    cursor: "pointer",
  },
  Box2: { borderBottom: "2px solid #3374B9" },
  pointer: { cursor: "pointer" },
});

const typeThreeData = [
  {
    row: "Row 1",
    columns: [
      { column: "Column 1", data: "Data 1-1" },
      { column: "Column 2", data: "Data 1-2" },
      { column: "Column 3", data: "Data 1-3" },
    ],
  },
  {
    row: "Row 2",
    columns: [
      { column: "Column 1", data: "Data 2-1" },
      { column: "Column 2", data: "Data 2-2" },
      { column: "Column 3", data: "Data 2-3" },
    ],
  },
  {
    row: "Row 3",
    columns: [
      { column: "Column 1", data: "Data 3-1" },
      { column: "Column 2", data: "Data 3-2" },
      { column: "Column 3", data: "Data 3-3" },
    ],
  },
];

function DisclosureTypeThree() {
  const classes = useStyles();
  return (
    <>
      {typeThreeData.length > 0 && (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ minWidth: 150 }}></TableCell>
                {typeThreeData.length > 0 &&
                  typeThreeData[0].columns &&
                  typeThreeData[0].columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      style={{ minWidth: 150 }}
                    >
                      {column.column}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {typeThreeData.length > 0 &&
                typeThreeData.map((row, rowIndex) => {
                  return (
                    <TableRow key={rowIndex} hover role="checkbox">
                      <TableCell align="center">
                        <Typography>{row.row}</Typography>
                      </TableCell>
                      {row.columns &&
                        row.columns.map((col, colIndex) => {
                          return (
                            <TableCell key={colIndex} align="center">
                              <TextField
                                name={`Text_type3${colIndex}`}
                                size="small"
                                variant="outlined"
                                className={classes.CustomTextfield}
                                value={
                                  typeThreeData[rowIndex].columns[colIndex].data
                                }
                                disabled
                              />
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default DisclosureTypeThree;
