import React, { useMemo } from "react";

import { Box } from "@mui/material";
import YearPicker from "components/UI/phase-3/datePicker/YearPicker";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import SelectAndOption from "components/UI/phase-3/selectAndOption/SelectAndOption";

import useFormValidation from "customHooks/useFormValidation";
import { setEsgStrategyPlanningAssessmentDetails } from "actions/phase-3/esgstrategyPlanning/esgstrategyPlanningAction";
import { useEsgStrategyPlanningBasicDetail } from "../APIs/HandleBasicDetailApi";

const EsgStrategyBasicDetails = React.memo(() => {
  const {
    esgStrategyPlanningAssessmentDetail,
    disableButton,
    materialityAssessmentYearList,
  } = useEsgStrategyPlanningBasicDetail();
  
  const initialAssessmentState = useMemo(
    () => ({
      assessment_name: "",
      materiality_assessment_id: "66cc2ce2eb78ffae807d54c0",
      year_of_assessment: "",
      esg_plan_overview: "",
      stakeholder_group_included: "",
      entity_id: "66c5c99aafeb50a530d72b28",
    }),
    []
  );

  const validationRules = useMemo(
    () => ({
      assessment_name: { maxCharacters: 30, required: true },
      materiality_assessment_id: { required: true },
      year_of_assessment: { required: true },
      esg_plan_overview: { maxCharacters: 300, required: true },
      stakeholder_group_included: {
        maxCharacters: 200,
        required: true,
      },
      entity_id: { required: true },
    }),
    []
  );

  const { formData, errors, handleChange, setFormData } =
    useFormValidation(
      disableButton
        ? esgStrategyPlanningAssessmentDetail
        : initialAssessmentState,
      validationRules,
      setEsgStrategyPlanningAssessmentDetails
    );

  return (
    <Box>
      <Heading text={"Basic Details"} size={"15px"} marginBottom={"8px"} bg />
      <Box className="scroll">
        <InputField
          value={formData?.assessment_name}
          error={errors.assessment_name}
          name={"assessment_name"}
          handleChange={handleChange}
          heading={"Assessment name"}
          label={"Enter name here"}
        />
        <YearPicker
          heading={"Pick the year of assessment"}
          label="Select to open year picker"
          value={formData?.year_of_assessment}
          error={errors.year_of_assessment}
          name={"year_of_assessment"}
          handleChange={setFormData}
          actionCreator={setEsgStrategyPlanningAssessmentDetails}
        />
        <SelectAndOption
          heading={"Pick Materiality Assessment"}
          options={materialityAssessmentYearList}
          value={formData?.materiality_assessment_id}
          error={errors.materiality_assessment_id}
          name={"materiality_assessment_id"}
          handleChange={handleChange}
          labelKey="assessment_name"
          valueKey="materiality_assessment_id"
          
        />
        <InputField
          heading={"Stakeholder Groups Included"}
          label={"Enter here"}
          rows={3}
          value={formData?.stakeholder_group_included}
          error={errors.stakeholder_group_included}
          name={"stakeholder_group_included"}
          handleChange={handleChange}
        />
        <InputField
          heading={"ESG Plan Overview"}
          label={"Enter here"}
          rows={12}
          value={formData?.esg_plan_overview}
          error={errors.esg_plan_overview}
          name={"esg_plan_overview"}
          handleChange={handleChange}
        />
      </Box>
    </Box>
  );
});

export default EsgStrategyBasicDetails;
