import { Box, IconButton, Button, Stack, Pagination } from "@mui/material";

import DynamicTableFull from "components/UI/phase-3/dynamicTable/DynamicTableFull";
import SearchTextField from "components/UI/textfield/searchTextField";
import UnitsDrawer from "components/widgets/phase-3/OrgDetailsProfile/UnitsComps/UnitsDrawer";

import { FiUpload, FiDownload, FiPlus } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useUnitsAPIs } from "./APIs/UnitsApis";

const unitsListHead = [
  { name: "Unit Name" },
  { name: "Unit Id" },
  { name: "Unit Type" },
  { name: "Location" },
  { name: "Entity ISIN" },
  { name: "Handled By" },
  { name: "View" },
];

const tableRowOrder = [
  "unit_name",
  "unit_id",
  "unit_type",
  "location.city_state",
  "entity.isin",
  "created_by.name",
];

const customAction = (row) => (
  <IconButton
    aria-label="CustomAction"
    size="small"
    sx={{ color: "primary.main" }}
  >
    <IoIosArrowForward />
  </IconButton>
);

const Units = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const { units_list, fetchUnits } = useUnitsAPIs(keyword, limit, page);

  return (
    <Box position={"relative"} height={"65vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={2} mb={2} justifyContent="flex-end">
        <SearchTextField searchClick={handleSearch} sx={{ minWidth: "30em" }} />
        <Button variant="text" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button variant="text" startIcon={<FiUpload />}>
          Upload Sheet
        </Button>
        <Button
          variant="contained"
          startIcon={<FiPlus />}
          size="small"
          onClick={toggleDrawer}
        >
          Add Unit
        </Button>
      </Stack>

      {/* Entry List Table */}
      <DynamicTableFull
        tableHead={unitsListHead}
        tableData={units_list?.unit_list}
        tableRowOrder={tableRowOrder}
        renderAction={customAction}
      />

      <Box position={"absolute"} right={2} bottom={0}>
        <Pagination
          count={Math.ceil(units_list?.total_count / limit)}
          variant="outlined"
          page={page}
          onChange={handlePageChange}
        />
      </Box>

      {/* Drawer */}
      <UnitsDrawer
        open={openDrawer}
        onClose={toggleDrawer}
        fetchUnits={fetchUnits}
      />
    </Box>
  );
};

export default Units;
