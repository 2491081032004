import React from "react";
import * as PropTypes from "prop-types";
// import { Menu, Dropdown } from "antd";
import {
  Menu,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputBase,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import styled from "styled-components";
// import { IconMap } from "antd/es/result";

const StyledDropdownTrigger = styled.span`
  color: #43436b;
  cursor: pointer;
  margin-left: 13px;

  & > span {
    margin: 0 8px;
  }
`;

const ChartTypes = [
  { name: "line", title: "Line", icon: "line-chart" },
  { name: "area", title: "Area", icon: "area-chart" },
  { name: "bar", title: "Bar", icon: "bar-chart" },
  { name: "pie", title: "Pie", icon: "pie-chart" },
  { name: "table", title: "Table", icon: "table" },
  { name: "number", title: "Number", icon: "info-circle" },
];

export const CustomDataInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["poppins"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const SelectChartType = ({ chartType, updateChartType }) => {
  const menu = (
    <Menu>
      {ChartTypes.map((m) => (
        <Menu.Item key={m.title} onClick={() => updateChartType(m.name)}>
          {m.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const foundChartType = ChartTypes.find((t) => t.name === chartType);

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    //   <Select

    //   value={age}
    //   label="Age"
    //   onChange={handleChange}
    // >
    //   {ChartTypes.length && (
    //     ChartTypes.map((m) => (
    //       <MenuItem key={m.name} value={m.name} onClick={() => updateChartType(m.name)}>
    //         {m.title}
    //       </MenuItem>
    //     ))
    //   )}
    // </Select>
    <FormControl style={{ width: "100%" }}>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={age}
        onChange={handleChange}
        input={<CustomDataInput />}
      >
        {ChartTypes.length &&
          ChartTypes.map((m) => (
            <MenuItem
              key={m.name}
              value={m.name}
              onClick={() => updateChartType(m.name)}
            >
              {m.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
    // <Select
    //   overlay={menu}
    // //   icon={foundChartType.icon}
    //   lacement="bottomLeft"
    //   trigger={["click"]}
    // >
    //   <StyledDropdownTrigger>

    //     <span>{foundChartType.title}</span>
    //   </StyledDropdownTrigger>
    // </Select>
  );
};

SelectChartType.propTypes = {
  chartType: PropTypes.string.isRequired,
  updateChartType: PropTypes.func.isRequired,
};

export default SelectChartType;
