import React, { useEffect } from "react";

import Heading from "components/UI/phase-3/heading/Heading";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMaturitySurvey } from "actions/phase-3/esgMaturity/esgMaturityAction";
import MaturitySurveyListing from "../MaturitySurveyListing";

function MaturityQuestionMain({ heading = "", source, questions }) {
  const dispatch = useDispatch();
  const { maturity_survey } = useSelector((state) => state.esgMaturity);

  useEffect(() => {
    dispatch(getMaturitySurvey());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <Heading size="16px" text={heading} />

      <MaturitySurveyListing maturity_survey={maturity_survey} />
    </Box>
  );
}

export default MaturityQuestionMain;
