import MainLayoutStepper from "components/layout/stepperLayout/mainLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import EsgStrategyBasicDetails from "components/widgets/phase-3/esgStrategyAndPlanning/esgStrategyBasicDetails/EsgStrategyBasicDetails";
import { useNavigate } from "react-router-dom";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import ReportLeftMain from "components/widgets/phase-3/esgCompliance/sustainabilityReport/left/ReportLeftMain";
import ReportRightMain from "components/widgets/phase-3/esgCompliance/sustainabilityReport/right/ReportRightMain";
import EsgComplianceBasicDetails from "components/widgets/phase-3/esgCompliance/basicDetails/EsgComplianceBasicDetails";

function EsgComplianceSteps() {
  const navigate = useNavigate();
  return (
    <MainLayoutStepper
      steps={[
        "Basic Details",
        "Report Data",
        "Approve & Audit",
        "Sustainability Report",
      ]}
      mainCrumb={""}
      heightAdjust="150px"
      handleFinalStep={() => navigate("/esg_compliance")}
      finalStepText={"Back to Listing Page"}
      stepContentMapping={{
        0: () => (
          <MainLayoutSection
            left={
              <LeftSectionLayout>
                <EsgComplianceBasicDetails />
              </LeftSectionLayout>
            }
            rightHidden={true}
            leftWidth="100%"
          />
        ),
        1: () => (
          <MainLayoutSection
            left={<LeftSectionLayout></LeftSectionLayout>}
            rightHidden={true}
            leftWidth="100%"
          />
        ),
        2: () => (
          <MainLayoutSection
            left={<LeftSectionLayout></LeftSectionLayout>}
            rightHidden={true}
            leftWidth="100%"
          />
        ),
        3: () => (
          <MainLayoutSection
            left={
              <LeftSectionLayout padding="0px" hasShadow={false}>
                <ReportLeftMain />
              </LeftSectionLayout>
            }
            right={
              <RightSectionLayout>
                <ReportRightMain />
              </RightSectionLayout>
            }
            leftWidth="60%"
            rightWidth="30%"
          />
        ),
      }}
    />
  );
}

export default EsgComplianceSteps;
