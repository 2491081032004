import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

export default class Snackbar1 extends React.PureComponent {
  render() {
    const { snackbar } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.response_received}
        autoHideDuration={3000}
        onClose={() => {
          this.props.closeSnackBar();
        }}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackbar.message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              this.props.closeSnackBar();
            }}
            size="large"
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    );
  }
}
