import makeStyles from "@mui/styles/makeStyles";

const materialityAssessmentLeftStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    marginBottom: "15px",
    borderRadius: 5,
    height: "100%",
  },
  tab: {
    fontSize: 12,
  },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  iconContainer: {
    display: "none",
    animation: `$slideEffect 400ms ${theme.transitions.easing.easeIn}`,
  },
  "@keyframes slideEffect": {
    "0%": {
      opacity: 0,
      transform: "translateX(100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0%)",
    },
  },
  topicBarContainer: {
    width: "100%",
    padding: 3,
    display: "flex",
    justifyContent: "space-between",
    border: "2px solid #DADBE6",
    borderRadius: 10,
    "&:hover": {
      border: "2px solid #3374B9",
      "& $iconContainer": {
        display: "block",
      },
    },
  },
  relavanceBarContainer: {
    width: "100%",
    padding: 3,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: "#DADBE655",
    },
  },
  topicBarContents: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  topicContentDivider: {
    width: 2,
    height: 15,
    backgroundColor: "#9FA1AF",
    borderRadius: 1,
    margin: "0px 5px",
  },
  topicContentDividerInfo: {
    width: 2,
    height: 35,
    backgroundColor: "#9FA1AF",
    borderRadius: 1,
    margin: "0px 5px",
  },
  search2: {
    width: "60%",
    fontFamily: "Poppins",
    [theme.breakpoints.down("lg")]: {
      width: "100%%",
    },
    [theme.breakpoints.down("xl")]: {
      width: "83%",
      marginTop: "8px",
    },
  },
  buttonsGrid: {
    display: "flex",
    justifyContent: "right",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "left",
    },
  },
  topicname: {
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  selectTopicText: {
    color: "#9FA1AF",
    fontSize: 13,
    padding: "10px 0px",
    fontFamily: ["poppins", "sans-serif"].join(","),
  },
  emptyTopicBar: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DADBE655",
    borderRadius: 10,
  },
  relavanceAvatar: {
    width: 35,
    height: 35,
    color: "#000",
    backgroundColor: "#fff",
    border: "1px solid #DADBE6",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  stakholderTitle: {
    fontWeight: "600",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "capitalize",
  },
  circleIcon: {
    color: "#FFFFFF",
  },
  topicNameChipSocial: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#E0E0E033",
    borderRadius: "5%",
    border: "0px",
    cursor: "pointer",
    "& $circleIcon": {
      color: "#EAD6F1",
    },
    "&&:hover": {
      color: "#000000",
      backgroundColor: "#EAD6F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#000000",
      backgroundColor: "#EAD6F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  selectedTopicNameChipSocial: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#EAD6F1",
    borderRadius: "5%",
    border: "0px",
    "& $circleIcon": {
      color: "#FFFFFF",
    },
    "&&:hover": {
      color: "#242424",
      backgroundColor: "#EAD6F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#242424",
      backgroundColor: "#EAD6F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  topicNameChipHuman: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#E0E0E033",
    borderRadius: "5%",
    border: "0px",
    cursor: "pointer",
    "& $circleIcon": {
      color: "#F6F3DA",
    },
    "&&:hover": {
      color: "#000000",
      backgroundColor: "#F6F3DA",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#000000",
      backgroundColor: "#F6F3DA",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  selectedTopicNameChipHuman: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#F6F3DA",
    borderRadius: "5%",
    border: "0px",
    "& $circleIcon": {
      color: "#FFFFFF",
    },
    "&&:hover": {
      color: "#242424",
      backgroundColor: "#F6F3DA",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#242424",
      backgroundColor: "#F6F3DA",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  topicNameChipEnvironement: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#E0E0E033",
    borderRadius: "5%",
    border: "0px",
    cursor: "pointer",
    "& $circleIcon": {
      color: "#DAF1E4",
    },
    "&&:hover": {
      color: "#000000",
      backgroundColor: "#DAF1E4",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#000000",
      backgroundColor: "#DAF1E4",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  selectedTopicNameChipEnvironement: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#DAF1E4",
    borderRadius: "5%",
    border: "0px",
    "& $circleIcon": {
      color: "#FFFFFF",
    },
    "&&:hover": {
      color: "#242424",
      backgroundColor: "#DAF1E4",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#242424",
      backgroundColor: "#DAF1E4",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  topicNameChipBusiness: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#E0E0E033",
    borderRadius: "5%",
    border: "0px",
    cursor: "pointer",
    "& $circleIcon": {
      color: "#F1D6D6",
    },
    "&&:hover": {
      color: "#000000",
      backgroundColor: "#F1D6D6",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#000000",
      backgroundColor: "#F1D6D6",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  selectedTopicNameChipBusiness: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#F1D6D6",
    borderRadius: "5%",
    border: "0px",
    "& $circleIcon": {
      color: "#FFFFFF",
    },
    "&&:hover": {
      color: "#242424",
      backgroundColor: "#F1D6D6",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#242424",
      backgroundColor: "#F1D6D6",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  topicNameChipGovernance: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#E0E0E033",
    borderRadius: "5%",
    border: "0px",
    cursor: "pointer",
    "& $circleIcon": {
      color: "#D6E3F1",
    },
    "&&:hover": {
      color: "#000000",
      backgroundColor: "#D6E3F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#000000",
      backgroundColor: "#D6E3F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  selectedTopicNameChipGovernance: {
    width: 250,
    justifyContent: "flex-start",
    color: "#242424",
    backgroundColor: "#D6E3F1",
    borderRadius: "5%",
    border: "0px",
    "& $circleIcon": {
      color: "#FFFFFF",
    },
    "&&:hover": {
      color: "#242424",
      backgroundColor: "#D6E3F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
    "&&:focus": {
      color: "#242424",
      backgroundColor: "#D6E3F1",
      "& $circleIcon": {
        color: "#FFFFFF",
      },
    },
  },
  blueText: {
    color: "#3374B9",
    fontWeight: "600",
    fontFamily: "Poppins",
  },
  contentDivider: {
    width: "96%",
    height: 1,
    margin: 10,
    backgroundColor: "#DADBE6",
  },
  riskOpprotunityInfoDivider: {
    width: 1,
    height: 25,
    backgroundColor: "#9FA1AF",
    borderRadius: 1,
    margin: "0px 5px",
  },
  tableHeaderText: {
    color: "#9FA1AF",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontSize: 16,
    lineHeight: "24px",
  },
  shareContentText: {
    color: "#9FA1AF",
  },
  boldText: {
    fontWeight: "400",
    fontSize: 16,
    lineHeight: "24px",
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  stakeholderTableRow: {
    cursor: "pointer",
    "&&:hover": {
      backgroundColor: "#D6E3F1",
    },
    surveyStatOrange: {
      color: "#9FA1AF",
      fontWeight: "600",
      fontSize: 12,
      paddingLeft: 5,
      borderLeft: "3px solid orange",
    },
  },
  iconColor: { color: "#000" },
  Typography1: {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: "24px",
  },
  Typography2: { fontSize: 14, fontFamily: "Poppins" },
  mainDiv: { display: "flex" },
  mainDiv1: { margin: "10px 0", wordBreak: "break-word" },
  box1: {
    margin: "10px 0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  AttachFileIcon: { padding: 4, color: "#3374B9" },
  mainDiv2: {
    borderBottom: "2px solid #3374B9",
    color: "#3374B9",
    marginLeft: 20,
    width: "100%",
  },
  input: { opacity: 0, width: 0 },
  mainGrid: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  Typography3: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  Typography4: { fontWeight: "600" },
  grid1: { marginLeft: 65 },
  grid2: { marginTop: 15 },
  grid3: { display: "flex", justifyContent: "flex-end" },
  grid4: { paddingLeft: 75 },
  CustomButton1: { fontSize: 12 },
  CustomButton2: { marginLeft: 15, fontSize: 12 },
  grid5: { paddingRight: 75 },
  grid6: { paddingRight: 10 },
  grid7: { marginTop: 20 },
  CustomButton3: { paddingLeft: 50, paddingRight: 50 },
  grid8: { width: "100%" },
  CustomTextfield: { marginTop: 5, color: "#9FA1AF" },
  Typography5: {
    fontWeight: "bold",
  },
  CardContent: { padding: 10 },
  AppBar: { boxShadow: "none" },
  CustomButton3: { textTransform: "none", fontFamily: "Poppins" },
  CustomButton4: {
    marginLeft: 15,
    textTransform: "none",
    fontFamily: "Poppins",
  },
  grid9: { padding: "10px 0px" },
  box2: { display: "flex", justifyContent: "center" },
  CustomButton5: {
    textTransform: "none",
    marginLeft: 15,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "24px",
  },
  CustomButton6: {
    marginLeft: 10,
    fontSize: "16px",
    marginRight: 0,
    textTransform: "none",
    fontFamily: "Poppins",
    lineHeight: "24px",
  },
  mainDiv3: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  subDiv2: { margin: "10px 0" },
  card: { border: "none", cursor: "pointer" },
  CardContent2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 15px",
  },
  Typography6: {
    fontWeight: "600",
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "28px",
  },
  card1: { margin: 15 },
  subDiv3: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainDiv4: {
    marginTop: 5,
    position: "relative",
    height: 48,
  },
  mainDiv5: {
    position: "absolute",
    top: 0,
    right: 0,
    height: 50,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: "#3374B933",
  },
  Typography7: {
    color: "#3374B9",
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  mainGrid1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingRight: 10,
  },
  Typography8: {
    fontSize: 13,
    fontFamily: "Poppins",
  },
  Typography9: {
    fontSize: 10,
    fontFamily: "Poppins",
  },
  refreshBtn: {
    marginTop: "8px",
    marginRight: "3px",
  },
}));

export default materialityAssessmentLeftStyles;
