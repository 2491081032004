import { useEffect } from "react";
import { Box, Radio, FormControlLabel, RadioGroup } from "@mui/material";

const FoundUsForm = ({ setHasChanges, validationData, handleFormError }) => {
  const { formData, handleChange, hasErrors } = validationData;

  const disableSubmit = !formData.how_you_found_us || hasErrors;

  const handleDataChange = (e) => {
    const { value } = e.target;

    handleChange({
      target: {
        name: "how_you_found_us",
        value,
      },
    });

    setHasChanges(true);
  };

  useEffect(() => {
    handleFormError(disableSubmit);
  }, [disableSubmit, handleFormError]);

  const found_on = [
    "Industry Event",
    "Social Media",
    "Referral",
    "Online Search",
    "Other",
  ];

  return (
    <RadioGroup
      value={formData.how_you_found_us || ""}
      onChange={handleDataChange}
    >
      <Box display={"flex"} gap={2}>
        {found_on.map((option) => (
          <Box
            key={option}
            bgcolor={
              formData.how_you_found_us === option
                ? "primary.lighter"
                : "transparent"
            }
            p={0.5}
            pl={1}
            borderRadius={2}
            width="100%"
          >
            <FormControlLabel
              value={option}
              control={<Radio />}
              label={option}
            />
          </Box>
        ))}
      </Box>
    </RadioGroup>
  );
};

export default FoundUsForm;
