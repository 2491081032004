import { useMemo } from "react";

import MainLayoutStepper from "components/layout/stepperLayout/mainLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import EsgStrategyBasicDetails from "components/widgets/phase-3/esgStrategyAndPlanning/esgStrategyBasicDetails/EsgStrategyBasicDetails";
import DefineAgainstTopicsLeft from "components/widgets/phase-3/esgStrategyAndPlanning/defineAgaintsTopics/left/DefineAgainstTopicsLeft";
import DefineAgainstTopicsRight from "components/widgets/phase-3/esgStrategyAndPlanning/defineAgaintsTopics/right/DefineAgainstTopicsRight";
import PlanMonitoring from "components/widgets/phase-3/esgStrategyAndPlanning/planMonitoring/PlanMonitoring";
import { useNavigate } from "react-router-dom";
import { useEsgStrategyPlanningBasicDetail } from "components/widgets/phase-3/esgStrategyAndPlanning/APIs/HandleBasicDetailApi";

function StrategyPlanningSteps() {
  const navigate = useNavigate();
  const {handleEsgStrategyPlanningStep, disableButton} = useEsgStrategyPlanningBasicDetail()
  const stepContentMapping = useMemo(
    () => ({
      0: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <EsgStrategyBasicDetails />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
      1: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <DefineAgainstTopicsLeft />
            </LeftSectionLayout>
          }
          right={
            <RightSectionLayout>
              <DefineAgainstTopicsRight />
            </RightSectionLayout>
          }
          leftWidth="26%"
          rightWidth="72%"
        />
      ),
      2: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <PlanMonitoring />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Add any dependencies that can change the content
  );
  return (
    <MainLayoutStepper
      steps={["Basic Details", "Define against topics ", "Plan monitoring"]}
      mainCrumb={""}
      heightAdjust="150px"
      handleFinalStep={() => navigate("/esg_strategy")}
      handleStepChangeApi={handleEsgStrategyPlanningStep}
      handleDisableButton={disableButton}
      stepContentMapping={stepContentMapping}
    />
  );
}

export default StrategyPlanningSteps;
