import { Stack, TextField, IconButton } from "@mui/material";
import { MdAttachFile } from "react-icons/md";
import { FaArrowUp } from "react-icons/fa6";

import { useTheme } from "@mui/styles";

function ChatAction() {
  const theme = useTheme();
  return (
    <Stack
      sx={{ width: "100%" }}
      direction={"row"}
      alignItems={"center"}
      gap={2}
    >
      <TextField fullWidth size="large" />
      <IconButton>
        <MdAttachFile style={{ color: theme.palette.primary.light }} />
      </IconButton>

      <IconButton>
        <FaArrowUp style={{ color: theme.palette.primary.light }} />
      </IconButton>
    </Stack>
  );
}

export default ChatAction;
