import {
  SET_MATERIALITY_ASSESSMENT_LIST,
  SET_MATERIALITY_ASSESSMENT_DETAILS,
  SET_ORIGINAL_MATERIALITY_ASSESSMENT_DETAILS,
  SET_MATERIALITY_MAIN_TOPIC,
  SET_MATERIALITY_TOPIC,
  SET_SELECTED_MATERIALITY_TOPIC,
  SET_TOPIC_LIMIT,
  SET_MATERIALITY_ASSESSMENT_YEAR_LIST
} from "constants/phase-3/materialityAssessment/matAssessmentConst";

const initial_state = {
  materialityAssessmentList: [],
  materialityAssessmentDetails: {},
  originalMaterialityAssessmentDetails: {},
  totalMaterialityAssessments: null,
  materialityMainTopics: [],
  materialityTopics: [],
  selectedMaterialityTopics: {},
  topicCount: null,
  topicLimit: 10,
  materialityAssessmentYearList:[]
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_MATERIALITY_ASSESSMENT_LIST:
      return (state = {
        ...state,
        materialityAssessmentList: action.payload?.result,
        totalMaterialityAssessments: action.payload?.total,
      });

    case SET_MATERIALITY_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        materialityAssessmentDetails: action.payload,
      });
    case SET_ORIGINAL_MATERIALITY_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        originalMaterialityAssessmentDetails: action.payload,
      });

    case SET_MATERIALITY_MAIN_TOPIC:
      return (state = {
        ...state,
        materialityMainTopics: action.payload,
      });

    case SET_MATERIALITY_TOPIC:
      return (state = {
        ...state,
        materialityTopics: action.payload.result,
        topicCount: action.payload.total,
      });

    case SET_SELECTED_MATERIALITY_TOPIC:
      return (state = {
        ...state,
        selectedMaterialityTopics: action.payload,
      });

    case SET_TOPIC_LIMIT:
      return (state = {
        ...state,
        topicLimit: action.payload,
      });
    
    case SET_MATERIALITY_ASSESSMENT_YEAR_LIST:
      return (state = {
        ...state,
        materialityAssessmentYearList: action.payload
      })  

    default:
      return state;
  }
}
