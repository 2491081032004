const freeTrialFeatures = [
  {
    title: "Easy Materiality Assessment",
    subTitle: "Select Key topics easily & build matrix",
  },
  {
    title: "Upto 5 Power Users",
    subTitle: "Yearly, annually, monthly",
  },
  {
    title: "Upto 50 Contributors & Data collectors",
    subTitle: "Yearly, annually, monthly Or custom",
  },
  {
    title: "Access to global Frameworks ",
    subTitle: "Access to global Frameworks ",
  },
  {
    title: "Generate unlimited reports",
    subTitle: "No hidden payment, ",
  },
];

export default freeTrialFeatures;
