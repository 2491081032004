import React from "react";
import { connect } from "react-redux";
import GeneralSettings from "../../components/screen/generalSettings/generalSettings";

import {
  setSelectedMenu,
  viewUserProfile,
  upodateUserProfile,
  setUserImage,
  setUserName,
  setUserPosition,
  resetUserPassword,
  setUserContact,
  setUserDistrict,
  setUserLocation,
  setUserPincode,
  setUserState,
  viewCompanyInfo,
  unsetAddress,
  upodateCompanyDetails,
  pushBranchAddress,
  viewAllUsers,
  deleteUserFromOrganisation,
  updateUserPosition,
  removeSettingsFile,
  upgradePlan,
  getInvoice,
  getPlans,
} from "../../actions/generalSettings/generalSettingsAction";

import { getIndustries } from "../../actions/sustainabilityReport/sustainabilityReportAction";

import { set_snack_bar } from "../../actions/snackbar/snackbar_action";

import {
  addStakeholder,
  addUser,
} from "../../actions/materialityAction/materialityAction";
import { getOrganizations } from "../../actions/surveyListing/surveyListingAction";
import { setIsSubscriptionActive } from "../../actions/login/loginActions";

export class GeneralSettingsCont extends React.PureComponent {
  render() {
    return <GeneralSettings {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    generalSettings: store.generalSettings,
    login: store.login,
    basicInfo: store.basicInfo,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedMenu: (payload) => {
      dispatch(setSelectedMenu(payload));
    },
    viewUserProfile: (payload) => {
      dispatch(viewUserProfile(payload));
    },
    setUserName: (payload) => {
      dispatch(setUserName(payload));
    },
    setUserPosition: (payload) => {
      dispatch(setUserPosition(payload));
    },
    setUserContact: (payload) => {
      dispatch(setUserContact(payload));
    },
    setUserDistrict: (payload) => {
      dispatch(setUserDistrict(payload));
    },
    setUserLocation: (payload) => {
      dispatch(setUserLocation(payload));
    },
    setUserPincode: (payload) => {
      dispatch(setUserPincode(payload));
    },
    setUserState: (payload) => {
      dispatch(setUserState(payload));
    },
    setUserImage: (payload) => {
      dispatch(setUserImage(payload));
    },
    upodateUserProfile: (
      token,
      name,
      position,
      contact_info,
      profile_image,
      makeDefault
    ) => {
      dispatch(
        upodateUserProfile(
          token,
          name,
          position,
          contact_info,
          profile_image,
          makeDefault
        )
      );
    },
    upodateCompanyDetails: (
      token,
      organisation_id,
      company_name,
      website,
      head_office,
      branch_office,
      logo,
      makeDefault,
      email,
      company_operation,
      employee_count,
      sector
    ) => {
      dispatch(
        upodateCompanyDetails(
          token,
          organisation_id,
          company_name,
          website,
          head_office,
          branch_office,
          logo,
          makeDefault,
          email,
          company_operation,
          employee_count,
          sector
        )
      );
    },
    resetUserPassword: (token, current_password, new_password) => {
      dispatch(resetUserPassword(token, current_password, new_password));
    },
    viewCompanyInfo: (token, organisation_id) => {
      dispatch(viewCompanyInfo(token, organisation_id));
    },
    getIndustries: (sector_id) => {
      dispatch(getIndustries(sector_id));
    },
    unsetAddress: (payload) => {
      dispatch(unsetAddress(payload));
    },
    pushBranchAddress: (payload) => {
      dispatch(pushBranchAddress(payload));
    },
    set_snack_bar: (bool, message) => {
      dispatch(set_snack_bar(bool, message));
    },
    viewAllUsers: (token, organisation_id, keyword) => {
      dispatch(viewAllUsers(token, organisation_id, keyword));
    },
    updateUserPosition: (token, user_id, organisation_id, user_type) => {
      dispatch(updateUserPosition(token, user_id, organisation_id, user_type));
    },
    deleteUserFromOrganisation: (token, user_id, organisation_id) => {
      dispatch(deleteUserFromOrganisation(token, user_id, organisation_id));
    },
    upgradePlan: (token, organisation_id, name, image, amount) => {
      dispatch(upgradePlan(token, organisation_id, name, image, amount));
    },
    getPlans: (token, organisation_id, setPaymentPlan) => {
      dispatch(getPlans(token, organisation_id, setPaymentPlan));
    },
    getInvoice: (token, organisation_id, payment_status) => {
      dispatch(getInvoice(token, organisation_id, payment_status));
    },
    addStakeholder: (
      token,
      assessment_id,
      organisation_id,
      name,
      role,
      email,
      organisation,
      category,
      position,
      step_no
    ) => {
      dispatch(
        addStakeholder(
          token,
          assessment_id,
          organisation_id,
          name,
          role,
          email,
          organisation,
          category,
          position,
          step_no
        )
      );
    },
    removeSettingsFile: (token, url, field_name, company_id) => {
      dispatch(removeSettingsFile(token, url, field_name, company_id));
    },
    addUser: (
      token,
      organisation_id,
      name,
      email,
      role,
      organisation,
      category,
      type,
      weightage,
      assessment_id
    ) => {
      dispatch(
        addUser(
          token,
          organisation_id,
          name,
          email,
          role,
          organisation,
          category,
          type,
          weightage,
          assessment_id
        )
      );
    },
    getOrganizations: (token, hostname) => {
      dispatch(getOrganizations(token, hostname));
    },
    setIsSubscriptionActive: (payload) => {
      dispatch(setIsSubscriptionActive(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSettingsCont);
