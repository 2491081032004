import { useState } from "react";
import { useSelector } from "react-redux";

const useMaterialityTopicsPreview = () => {
  const { selectedMaterialityTopics } = useSelector(
    (state) => state?.materialityAssessment
  );

  const { topics = [] } = selectedMaterialityTopics || {};
  const [visibleTopics, setVisibleTopics] = useState(10);

  const handleLoadMore = () => {
    setVisibleTopics((prevVisibleTopics) => prevVisibleTopics + 10);
  };

  return {
    topics: topics.slice(0, visibleTopics),
    hasMoreTopics: topics.length > visibleTopics,
    handleLoadMore,
  };
};

export default useMaterialityTopicsPreview;
