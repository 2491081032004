import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "@mui/material/Icon";
import moreVertical from "../../../images/moreVertical.png";
import SideIndication from "../../../images/SideIndication.png";
import uploadWhite from "../../../images/uploadBlack.png";
import { Link } from "react-router-dom";
import moment from "moment";
import CustomButton from "../../UI/button/button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./style.css";
import { Button } from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";

import { useSelector, useDispatch } from "react-redux";
import { publishDataStream } from "../../../actions/datahubActions/datahubActions";
import SheetTable from "./SheetTable";

const useStyles = makeStyles({
  circle: {
    color: "#3374B9",
    background: "red",
  },
  svg: {
    // background:'red'
  },
  successTag: {
    backgroundColor: "#76bf94",
    borderRadius: "8px",
    padding: "8px",
    gap: "8px",
    display: "flex",
    alignItems: "center",
    height: "32px",
    width: "167px",
  },
});

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

function DataHubSurveySheetTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    file,
    progress,
    getImportFileData,
    token,
    status,
    getMatatikaJobStatus,
    restartMatatikaJob,
    currentOrganisation,
  } = props;

  const [datahubFiles, setDatahubFiles] = useState([]);

  useEffect(() => {
    if (file.length > 0) {
      setDatahubFiles(file);
    }
  }, [file]);

  useEffect(() => {
    if (datahubFiles && status) {
      setDatahubFiles((prev) => {
        return prev.map((df) => {
          df.status = status[df._id];
          return df;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(status)]);

  return (
    <Box padding={"20px"}>
      <SheetTable
        datahubFiles={datahubFiles}
        getImportFileData={getImportFileData}
        token={token}
        currentOrganisation={currentOrganisation}
        getMatatikaJobStatus={getMatatikaJobStatus}
        restartMatatikaJob={restartMatatikaJob}
      />
    </Box>
  );
}

export default DataHubSurveySheetTable;

{
  /* <Typography
  style={{
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#242424",
    marginLeft: "9%",
    marginTop: "1%"
  }}
>
  Recently uploaded
</Typography>
<Grid container justifyContent="center">
  <Grid
    item
    md={10}
    xs={12}
    sm={12}
    style={{
      padding: "15px"
    }}
  >
    <TableContainer
      style={{
        marginTop: "1%"
      }}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "20%" }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#9FA1AF",
                  marginLeft: "5%"
                }}
              >
                Name of data Stream
              </Typography>
            </TableCell>

            <TableCell align="center" style={{ width: "25%" }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#9FA1AF"
                }}
              >
                Duration of data
              </Typography>
            </TableCell>
            <TableCell align="left" style={{ width: "25%" }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#9FA1AF"
                }}
              >
                File Uploaded
              </Typography>
            </TableCell>

            <TableCell align="center" style={{ width: "15%" }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#9FA1AF"
                }}
              >
                Tags
              </Typography>
            </TableCell>

            <TableCell align="right" style={{ width: "5%" }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#9FA1AF"
                }}
              >
                Publish
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datahubFiles.map((data, index) => (
            <TableRow
              key={index}
              style={{ borderBottom: "1px solid #DADBE6" }}
            >
              <Link
                to={{
                  pathname: `/data_hub/survey/${data.data_stream_name}`,
                  date: data.created_on,
                  state: data._id
                }}
                style={{
                  textDecoration: "none",
                  border: "1px solid  #F6F6F6"
                }}
              >
                <TableCell
                  align="left"
                  style={{
                    padding: "8px",
                    width: "100%",
                    cursor: "pointer",
                    display: "flex"
                  }}
                  onClick={() => getImportFileData(token, data._id)}
                >
                  <Icon
                    component={"img"}
                    src={SideIndication}
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#242424",
                      marginLeft: "5px"
                    }}
                  >
                    {data.data_stream_name}
                  </Typography>
                </TableCell>
              </Link>

              <TableCell
                align="center"
                style={{ width: "25%", cursor: "default" }}
              >
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#242424"
                  }}
                >
                  {moment(
                    new Date(data.data_duration[0].startDate)
                  ).format("DD MMM yy")}{" "}
                  -{" "}
                  {moment(new Date(data.data_duration[0].endDate)).format(
                    "DD MMM yy"
                  )}
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                style={{
                  width: "25%",
                  cursor: "default",

                  height: "100%"
                }}
              >
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#242424"
                  }}
                >
                  {data.status === null || data.status === undefined
                    ? "QUEUED"
                    : data.status}
                  {data.status !== "COMPLETE" &&
                    data.status !== "STOPPED" && (
                      <CustomButton
                        onClick={() =>
                          getMatatikaJobStatus(token, data._id)
                        }
                        color="secondary"
                        variant="outlined"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          background: "#FFFFFF",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          // height: "35px",
                          height: "3.1em",
                          marginLeft: "20px"
                        }}
                      >
                        Refresh
                      </CustomButton>
                    )}
                  {data.status === "STOPPED" && (
                    <CustomButton
                      onClick={() =>
                        restartMatatikaJob(
                          token,
                          data._id,
                          currentOrganisation,
                          "first_template"
                        )
                      }
                      color="secondary"
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        background: "#FFFFFF",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        // height: "35px",
                        height: "3.1em",
                        marginLeft: "20px"
                      }}
                    >
                      Restart
                    </CustomButton>
                  )}
                </Typography>
              </TableCell>

              <TableCell
                align="center"
                style={{ width: "15%", cursor: "default" }}
              >
                <CustomButton
                  color="secondary"
                  variant="outlined"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    // height: "35px",
                    height: "3.1em"
                  }}
                >
                  +
                  {data.functional_tag.length +
                    data.location_tag.length +
                    data.value_chain_tag.length}{" "}
                  Tags
                </CustomButton>
              </TableCell>

              <TableCell
                align="right"
                style={{ width: "5%", cursor: "pointer" }}
              >
                {/* <Icon component={"img"} src={moreVertical} />
                 
                 <PublishIcon color="action" onClick={() => dispatch(publishDataStream(token, data._id))} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
</Grid> */
}
