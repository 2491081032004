import LayoutHeader from "components/layout/stepperLayout/LayoutHeader";

import { Outlet } from "react-router-dom";

// @mui
import { Box } from "@mui/material";
//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MaterialityMappingLayout() {
  return (
    <Box>
      <LayoutHeader mainCrumb={"Materiality Assessment"} />
      <Box p={2}>
        <Outlet />
      </Box>
    </Box>
  );
}
