import axios from "axios";
import UNIVERSAL from "config/config";

// Set the base URL for all axios requests
axios.defaults.baseURL = UNIVERSAL.BASEURL || "http://localhost:8002/api/v3";

// Set credentials to true
axios.defaults.withCredentials = true;

export default axios;
