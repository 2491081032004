import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Slide from "@mui/material/Slide";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import left from "../../../images/left.png";
import right from "../../../images/right.png";
import sideleft from "../../../images/sidebarLeft.png";
import sideright from "../../../images/sidebarRight.png";
import cert from "../../../images/cert.png";
import build from "../../../images/build.png";
import logoWhite from "../../../images/ImpactGrows White Corrected.svg";
import user from "../../../images/Avatar.svg";
import SignupCard from "../../UI/signupCard/signupCard";
import "../../../styles/getStarted.css";

import { Navigate } from "react-router-dom";
import getStartedStyles from "./getStartedStyles";

const whiteButton = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: "#ffffff" },
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          color: "#3374B9",
          "&:hover": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
  })
);

export default function GetStarted(props) {
  const classes = getStartedStyles();
  const { login, getCompanyLogo } = props;

  const [shrink, setShrink] = useState(false);
  const [redirect] = useState(false);

  useEffect(() => {
    getCompanyLogo(window.location.hostname);
  }, []);

  if (redirect) {
    return <Navigate to="/drawer"></Navigate>;
  }
  return (
    <div className={classes.mainDiv}>
      <Hidden lgDown>
        <div className={classes.mainDiv1}>
          {login.company_logo && login.company_logo.logo && (
            <img
              style={
                JSON.parse(localStorage.getItem("logo_styles"))
                  ? JSON.parse(localStorage.getItem("logo_styles"))
                  : login.company_logo
                    ? login.company_logo.logo_tyles
                    : {}
              }
              src={
                localStorage.getItem("logo")
                  ? localStorage.getItem("logo")
                  : login.company_logo.logo
                    ? login.company_logo.logo
                    : logoWhite
              }
              alt="logo"
            />
          )}
          {login.company_logo && !login.company_logo.logo && (
            <img src={logoWhite} alt="logo" />
          )}
        </div>
        <Grid container className={classes.mainGrid}>
          <Grid
            container
            item
            className={
              shrink
                ? `blueDivShrinked ${shrink ? "blueDivAnimation" : ""}`
                : `blueDiv ${shrink ? "blueDivAnimation" : ""}`
            }
            style={{ zIndex: "2" }}
          >
            {!shrink && (
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  container
                  item
                  xs={4}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "35em" }}
                >
                  <Grid item xs={2}>
                    <img src={user} alt="" className="avatar" />
                  </Grid>
                  <Grid item xs={12} className={classes.grid}>
                    <Typography style={{ color: "white" }} variant="h6">
                      Hi, <br />
                      This is Kevin
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.grid1}>
                    <Typography className={classes.typo} variant="body1">
                      I'll be handling your onboarding process & be available
                      for any questions regarding our product
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={6}
                    className={classes.grid2}
                  >
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={whiteButton}>
                        <Grid item>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => {
                              setShrink(true);
                            }}
                            className={classes.Button}
                          >
                            Let's Get Started
                          </Button>
                        </Grid>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </Grid>
                </Grid>
                <img src={left} alt="left" className={classes.img1} />
                <img src={right} alt="right" className={classes.img2} />
              </Grid>
            )}
            {shrink && (
              <Grid container>
                <Grid
                  item
                  container
                  justifyContent="center"
                  className={classes.grid3}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    paddingRight="20px"
                  >
                    <img src={sideright} alt="left" className={classes.img3} />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    paddingBottom="190px"
                    paddingTop="16px"
                    paddingLeft="55px"
                    maxWidth="270px"
                  >
                    <img src={user} alt="user" className="avatar" />
                    {login.sideMessage === "signup" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          Signup Flow
                        </Typography>
                        <Typography className={classes.sideBarSubTitle}>
                          These details help you fill out the Reportings much
                          more faster
                        </Typography>
                      </>
                    )}
                    {login.sideMessage === "otp" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          OTP
                        </Typography>
                        <Typography className={classes.sideBarSubTitle}>
                          Please check your given email id for an OTP mail of 4
                          digits.
                        </Typography>
                      </>
                    )}
                    {login.sideMessage === "plan" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          Plan Selection
                        </Typography>
                        <Typography className={classes.sideBarSubTitle}>
                          These details help you fill out the Reportings much
                          more faster
                        </Typography>
                      </>
                    )}
                    {login.sideMessage === "onboarding" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          Onboarding
                        </Typography>
                        <Typography className={classes.sideBarSubTitle}>
                          On the right you have the Section Split to get a
                          better output, start from Basic company details
                        </Typography>
                      </>
                    )}
                    {login.sideMessage === "firstStep" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          Basic Company details
                        </Typography>
                        <Typography className={classes.sideBarSubTitle}>
                          These details help you fill out the Reportings much
                          more faster
                        </Typography>
                      </>
                    )}
                    {login.sideMessage === "secondStep" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          Materiality assesment
                        </Typography>
                        <Typography className={classes.sideBarSubTitle}>
                          This involves frameworks required to make your reports
                        </Typography>
                      </>
                    )}
                    {login.sideMessage === "thirdStep" && (
                      <>
                        <Typography className={classes.sideBarTitle}>
                          Alright, Lets get started
                        </Typography>
                        <Typography className={classes.sideBarSubTitle}>
                          This involves frameworks required to make your reports
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    paddingLeft="16px"
                  >
                    <img src={sideleft} alt="right" className={classes.img3} />
                  </Box>
                </Grid>

                <Grid
                  container
                  sx={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  item
                  style={{
                    marginLeft: "80px",
                    alignSelf: "center",
                  }}
                  justifyContent="center"
                >
                  <Slide
                    direction="right"
                    in={props.login.sideImage === "build"}
                    timeout={500}
                    mountOnEnter
                    unmountOnExit
                  >
                    <img src={build} alt="build" />
                  </Slide>
                  <Slide
                    direction="right"
                    in={props.login.sideImage === "cert"}
                    timeout={500}
                    mountOnEnter
                    unmountOnExit
                  >
                    <img src={cert} alt="build" />
                  </Slide>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.grid4}
                >
                  <div className={classes.grid4}>
                    <ul className={classes.ul}>
                      <li className="footerList">
                        <a className={classes.link} href="/faq" target="_blank">
                          FAQ
                        </a>
                      </li>
                      <li className="footerList">
                        <a className={classes.link} href="/tnc" target="_blank">
                          Terms & Conditions{" "}
                        </a>
                      </li>
                      <li className="footerList">
                        <a
                          className={classes.link}
                          href="/privacy_policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={9}
          >
            <Grid item xs={7} sm={7} md={7}>
              {shrink && <SignupCard props={props} shrink={shrink} />}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden lgUp>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div>
            <Grid item style={{ marginTop: "10px" }}>
              <img
                style={
                  JSON.parse(localStorage.getItem("logo_styles"))
                    ? JSON.parse(localStorage.getItem("logo_styles"))
                    : login.company_logo
                      ? login.company_logo.logo_tyles
                      : {}
                }
                src={
                  localStorage.getItem("logo")
                    ? localStorage.getItem("logo")
                    : login.company_logo && login.company_logo.logo
                      ? login.company_logo.logo
                      : logoWhite
                }
                alt="logo"
              />
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              item
              className={classes.grid5}
            >
              <SignupCard props={props} shrink={true} />
            </Grid>
          </div>
        </Grid>
      </Hidden>
    </div>
  );
}
