import { Box, Typography } from "@mui/material";
import OrgDetailsScreens from "components/screen/phase-3/OrgDetailsScreens";

const OrgDetailsCont = () => {
  return (
    <Box width={"100%"} p={2}>
      {/* Heading */}
      <Typography variant="h2" fontWeight={600} color={"primary.darker"} mb={2}>
        Organisation Details
      </Typography>

      {/* Main Content */}
      <OrgDetailsScreens />
    </Box>
  );
};
export default OrgDetailsCont;
