import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Heading from "components/UI/phase-3/heading/Heading";
const useStyle = makeStyles((theme) => ({
  card: {
    backgroundColor: "#fafafa",
    padding: "15px",
    borderRadius: "10px",
  },
  container: {
    height: "70vh",
    overflow: "scroll",
  },
  description: {
    fontSize: "13px",
  },
}));

const items = [
  {
    title: "ESG Wise Assistant",
    description:
      "We believe that every company has the right to have access to the necessary tools to measure their impact.",
    imgUrl:
      "https://s3-alpha-sig.figma.com/img/da35/b281/10faa11508b8c9e1dd95e91c913d0f84?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c307qZF9mSSsOOcFSTzJ-SOxrUrG23u4MtgKzw5oDmEt43edr1tfn5jCbiTwB7CW-4UOs~4CcrMro7U7y~3f0hqHcjxm6XAN3yP-ZVnuGPQeNnxmvEdNRMcrJKAt9wUNB-lbh5FYLr5V-Zu03vLI7egzohO~a8xLECa1K09bW54iyn1WJd7nsAFv0OraSEz5T7e~Vt5YIKJe22K4sf40tMvSEchG5fSE30UuCo1BWEpSFQ52J5fF7vaWdcj0bB6lriJW25BHI4WgI2r~Nf4X5oKq9cBthho7A8OYyFEy8oKORU8V2nWaF5fa6XyA68iqk-2kO3iOjZrEPx5VyJ5oaw__",
  },
];

function EsgWiseAssistance() {
  const classes = useStyle();
  return (
    <Grid container gap={5} className={classes.container} alignItems={"start"}>
      {items.map((item) => (
        <Grid item md={3} className={classes.card}>
          <img src={item.imgUrl} alt={item.title}></img>
          <Heading text={item.title} marginBottom={"5px"} marginTop={"10px"} />
          <Typography variant="body2" className={classes.description}>
            {item.description}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default EsgWiseAssistance;
