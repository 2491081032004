import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
} from "@mui/material";
// import countryList from "../../../utils/countries/countryList";

export const TurnoverYearPickerDialog = ({
  isOpen,
  onCancel,
  onSet,
  start,
  end,
  onYearBack,
  onYearForward,
  onYearClick,
  onClear,
  range,
  yearRange,
}) => {
  return (
    <>
      <Dialog maxWidth="lg" open={isOpen}>
        <DialogContent style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`${start || ""}-${end || ""}`}</Typography>
            <Typography
              onClick={onYearBack}
              style={{
                fontSize: "22px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`<`}</Typography>
            <Typography
              onClick={onYearForward}
              style={{
                fontSize: "22px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >{`>`}</Typography>
            <Typography
              onClick={onClear}
              style={{
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: 600,
                marginLeft: "15px",
                marginBottom: "15px",
                float: "right",
              }}
            >{`Clear`}</Typography>
          </div>
          <div>
            <Grid
              container
              xs={12}
              md={12}
              gap={1}
              spacing={2}
              style={{
                padding: "2% 1% 4% 1%",
                // // marginLeft: '2%',
              }}
            >
              {yearRange.map((year) => {
                return (
                  <Grid
                    item
                    xs={4}
                    md={4}
                    align="center"
                    style={{
                      // margin:'6px 8px',
                      // border: "1px solid blue",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      onClick={() => onYearClick(year)}
                      style={{
                        fontWeight: 500,
                        padding: "8px 5px",
                        border: range(year) ? "1px solid #3374B9" : "none",
                        borderRadius: "5px",
                        backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                      }}
                    >
                      {year}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            // variant="outlined"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ padding: "6px 20px", textTransform: "none" }}
            disabled={!start || !end}
            onClick={onSet}
          >
            Set Period
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
