import React, { useMemo } from "react";
// import "./table.css";
import SingleFileTable from "./singleFileTable";
import CircularProgress from "@mui/material/CircularProgress";

const DataHubSurveyFile = (props) => {
  const { files, selectedTab, selectedTabName, showTabs } = props;

  let objects = files[selectedTab];

  let value = [];

  for (var key in objects) {
    value = objects[key];
  }

  const COLUMNS =
    value.length > 0
      ? Object.keys(value[0]).map((column) => {
          return {
            Header: column,
            accessor: column,
            align: "center",
          };
        })
      : [];

  const columns = useMemo(() => COLUMNS, [selectedTab, value.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = useMemo(() => value, [selectedTab, value.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
  };

  return (
    <>
      {data.length > 0 ? (
        <SingleFileTable
          columns={columns}
          data={data}
          // setData={setData}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          selectedTab={selectedTab}
          selectedTabName={selectedTabName}
          showTabs={showTabs}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default React.memo(DataHubSurveyFile);
