import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_ASSETS_LIST,
  SET_ENTITY_LIST,
  SET_PARENT_ENTITY,
  SET_PRODUCTNSERVICE_LIST,
  SET_STAKEHOLDERS_LIST,
  SET_SUPPLIERS_LIST,
  SET_UNITS_LIST,
} from "constants/phase-3/orgDetails/orgDetailsConst";

// ----------------- Payloads -----------------
export function setEntityList(payload) {
  return {
    type: SET_ENTITY_LIST,
    payload: payload,
  };
}

export function setUnitsList(payload) {
  return {
    type: SET_UNITS_LIST,
    payload: payload,
  };
}

export function setAssetsList(payload) {
  return {
    type: SET_ASSETS_LIST,
    payload: payload,
  };
}

export function setStakeholdersList(payload) {
  return {
    type: SET_STAKEHOLDERS_LIST,
    payload: payload,
  };
}

export function setSuppliersList(payload) {
  return {
    type: SET_SUPPLIERS_LIST,
    payload: payload,
  };
}

export function setProductnServiceList(payload) {
  return {
    type: SET_PRODUCTNSERVICE_LIST,
    payload: payload,
  };
}

export function setParentEntity(payload) {
  return {
    type: SET_PARENT_ENTITY,
    payload: payload,
  };
}

// ----------------- Actions -----------------
export function getEntityList(
  parent_id,
  keyword,
  limit,
  starting_after,
  hideLoader = false,
  hideMsg = false
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/entity/get_all_entity",
      bodyData: { parent_id, keyword, limit, starting_after },
      hideLoader: hideLoader,
      hideMsg: hideMsg,
      callback: (data) => {
        // final actions
        dispatch(setEntityList(data?.result));
      },
    });
  };
}

export function getUnitsList(
  parent_id,
  keyword,
  limit,
  starting_after,
  hideLoader = false,
  hideMsg = false
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/unit/get_all_unit",
      hideLoader: hideLoader,
      hideMsg: hideMsg,
      bodyData: { parent_id, keyword, limit, starting_after },
      callback: (data) => {
        // final actions
        dispatch(setUnitsList(data?.result));
      },
    });
  };
}

export function getAssetsList(parent_id, keyword, limit, starting_after) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/assets/get_all_assets",
      bodyData: { parent_id, keyword, limit, starting_after },
      callback: (data) => {
        // final actions
        dispatch(setAssetsList(data?.result));
      },
    });
  };
}

export function getStakeholdersList(parent_id, keyword, limit, starting_after) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/stakeholder/get_all_stakeholder",
      bodyData: { parent_id, keyword, limit, starting_after },
      callback: (data) => {
        // final actions
        dispatch(setStakeholdersList(data?.result));
      },
    });
  };
}

export function getSuppliersList(parent_id, keyword, limit, starting_after) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/supplier/get_all_supplier",
      bodyData: { parent_id, keyword, limit, starting_after },
      callback: (data) => {
        // final actions
        dispatch(setSuppliersList(data?.result));
      },
    });
  };
}

export function getProductnServiceList(
  parent_id,
  keyword,
  limit,
  starting_after
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/product/get_all_product",
      bodyData: { parent_id, keyword, limit, starting_after },
      callback: (data) => {
        // final actions
        dispatch(setProductnServiceList(data?.result));
      },
    });
  };
}

export function getParentEntity(parent_id) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/entity/get_parent_entity",
      bodyData: { parent_id },
      callback: (data) => {
        // final actions
        dispatch(setParentEntity(data?.result));
      },
    });
  };
}

export function createEntity(
  parent_id,
  entity_name,
  entity_type,
  isin,
  location,
  gps_cordinate,
  file,
  fetchEntityList
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/entity/create_entity",
      bodyData: {
        parent_id,
        entity_name,
        entity_type,
        isin,
        location,
        gps_cordinate,
      },
      files: file,
      callback: (data) => {
        // final actions
        if (Boolean(fetchEntityList)) {
          fetchEntityList();
        }
      },
    });
  };
}

export function createUnits(
  parent_id,
  unit_name,
  unit_type,
  unit_category,
  unit_id,
  location,
  gps_cordinate,
  entity_id,
  file,
  fetchUnits
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/unit/create_unit",
      bodyData: {
        parent_id,
        unit_name,
        unit_type,
        unit_category,
        unit_id,
        location,
        gps_cordinate,
        entity_id,
      },
      files: file,
      callback: (data) => {
        // final actions
        if (Boolean(fetchUnits)) {
          fetchUnits();
        }
      },
    });
  };
}

export function createAssets(
  parent_id,
  asset_name,
  asset_id,
  unit_or_entity,
  entity_id,
  unit_id,
  file,
  fetchAssets
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/assets/create_assets",
      bodyData: {
        parent_id,
        asset_name,
        asset_id,
        unit_or_entity,
        entity_id,
        unit_id,
      },
      files: file,
      callback: (data) => {
        // final actions
        if (Boolean(fetchAssets)) {
          fetchAssets();
        }
      },
    });
  };
}

export function createStakeholders(
  parent_id,
  name,
  email,
  stakeholder_id,
  user_type,
  category,
  employee_type,
  unit_or_entity,
  entity_id,
  unit_id,
  file,
  fetchStakeholders
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/stakeholder/create_stakeholder",
      bodyData: {
        parent_id,
        name,
        email,
        stakeholder_id,
        user_type,
        category,
        employee_type,
        unit_or_entity,
        entity_id,
        unit_id,
      },
      files: file,
      callback: (data) => {
        // final actions
        if (Boolean(fetchStakeholders)) {
          fetchStakeholders();
        }
      },
    });
  };
}

export function createSuppliers(
  parent_id,
  supplier_name,
  supplier_code,
  unit_or_entity,
  entity_id,
  unit_id,
  location,
  gps_cordinate,
  file,
  fetchSuppliers
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/supplier/create_supplier",
      bodyData: {
        parent_id,
        supplier_name,
        supplier_code,
        unit_or_entity,
        entity_id,
        unit_id,
        location,
        gps_cordinate,
      },
      files: file,
      callback: (data) => {
        // final actions
        if (Boolean(fetchSuppliers)) {
          fetchSuppliers();
        }
      },
    });
  };
}

export function createProductService(
  parent_id,
  name,
  product_service_id,
  product_or_service,
  unit_or_entity,
  entity_id,
  unit_id,
  file,
  fetchProductService
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/product/create_product",
      bodyData: {
        parent_id,
        name,
        product_service_id,
        product_or_service,
        unit_or_entity,
        entity_id,
        unit_id,
      },
      files: file,
      callback: (data) => {
        // final actions
        if (Boolean(fetchProductService)) {
          fetchProductService();
        }
      },
    });
  };
}
