import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";

// components
import Profile from "./Profile";
import OrgStructure from "./OrgStructure";
import EntryList from "./EntryList";
import Units from "./Units";
import Assets from "./Assets";
import Stakeholders from "./Stakeholders";
import Suppliers from "./Suppliers";
import ProductnServices from "./ProductnServices";

// Array of tab details
const tabs = [
  { label: "Profile", component: <Profile /> },
  { label: "Organisation Structure", component: <OrgStructure /> },
  { label: "Entity List", component: <EntryList /> },
  { label: "Units", component: <Units /> },
  { label: "Assets", component: <Assets /> },
  { label: "Stakeholders", component: <Stakeholders /> },
  { label: "Suppliers", component: <Suppliers /> },
  { label: "Products/Services", component: <ProductnServices /> },
];

const OrgDetailsScreens = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="org details tabs"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Box mt={2}>{tabs[selectedTab].component}</Box>
    </Box>
  );
};

export default OrgDetailsScreens;
