import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import Heading from "components/UI/phase-3/heading/Heading";

import { Box, Button, Chip, TableCell, TableRow } from "@mui/material";
import DynamicTable, {
  textStyle,
} from "components/UI/phase-3/dynamicTable/DynamicTable";

import { NoDataComponent } from "./NoData";
import ShareSurveyDrawer from "./ShareSurveyDrawer.jsx";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useShareSurveyAPIs } from "../APIs/shareSurveyApis/shareSurveyApis";

function ShareSurveyMain({source}) {
  const { openDrawer, toggleDrawer } = usePaginationSearch();
  const { assigned_stakeholders, fetchAssignedStakeholders } =
    useShareSurveyAPIs(source);

  return (
    <Box sx={{ height: "100%" }}>
      <Heading size="16px" text="Assign Stakeholder">
        <Button
          variant="contained"
          size="medium"
          startIcon={<ShareOutlinedIcon />}
          onClick={toggleDrawer}
        >
          Share
        </Button>
      </Heading>
      <Box sx={{ overflow: "scroll", height: "85%" }}>
        {assigned_stakeholders?.length !== 0 ? (
          <DynamicTable
            headings={["Name", "Role", "Survey Status", "Employee Type"]}
          >
            {assigned_stakeholders?.map((rowData, rowIndex) => (
              <TableRow
                key={rowData?._id}
                sx={{ borderBottom: "1px solid #EBF1F8" }}
              >
                <TableCell style={textStyle("main")}>
                  <Box display={"flex"} alignItems={"center"} sx={{ gap: 10 }}>
                    {rowData?.stakeholder_name}
                  </Box>
                </TableCell>
                <TableCell style={textStyle()}>
                  {rowData?.stakeholder_role}
                </TableCell>
                <TableCell style={textStyle()}>
                  <Chip
                    sx={{
                      backgroundColor: "#EBF1F8",
                      padding: "6px 10px",
                      fontSize: "12px",
                      color: "#3374B9",
                      borderRadius: "80px",
                    }}
                    label="Assigned"
                  />
                </TableCell>
                <TableCell sx={{ ...textStyle(), textTransform: "capitalize" }}>
                  {rowData?.stakeholder_employee_type}
                </TableCell>
              </TableRow>
            ))}
          </DynamicTable>
        ) : (
          <NoDataComponent
            mainTitle={
              "Currently the survey is Not been shared with any stakeholder"
            }
            subTitle={
              "Select the stakeholders & share the survey to them & view the updates here."
            }
            buttonTex={"Share to Stakeholders"}
            handleButtonClick={toggleDrawer}
          />
        )}
      </Box>

      <ShareSurveyDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        fetchAssignedStakeholders={fetchAssignedStakeholders}
        source={source}
      />
    </Box>
  );
}

export default ShareSurveyMain;
