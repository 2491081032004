import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

// color gradient

import {
  Wheel,
  ShadeSlider,
  Alpha,
  Swatch,
  EditableInput,
  hsvaToHex,
  hexToHsva,
  hsvaToHexa,
} from "@uiw/react-color";
import Values from "values.js";
import { prominent } from "color.js";
// import { LightenDarkenColor } from 'lighten-darken-color';

function ColorGradient({
  handleAccordioChange,
  expanded,
  image,
  color,
  handleColorComplete,
  preColor,
}) {
  //  color gradient

  const defaultColor = {
    h: 210.8955223880597,
    s: 72.43243243243244,
    v: 72.54901960784314,
    a: 1,
  };

  const [hsva, setHsva] = useState(defaultColor);

  const [wheelHsva, setWheelHsva] = useState(defaultColor);

  const [colorLogo, setColorLogo] = useState([]);

  const [lightShades, setLightShades] = useState([]);

  const [darkShades, setDarkShades] = useState([]);

  React.useEffect(() => {
    if (Object.keys(color || {}).length > 0) {
      setHsva(hexToHsva(color?.subline));
      setWheelHsva(hexToHsva(color?.highlight));
    }
  }, [color]);

  React.useEffect(() => {
    // prominent(image, { amount: 8, format: "hex" }).then((color) => {
    setColorLogo([241, 221, 63]); // [241, 221, 63]
    // });
  }, [image]);

  React.useEffect(() => {
    handleColorComplete({
      headline: darkShades[5],
      subline: hsvaToHexa(hsva),
      highlight: lightShades[8],
    });
  }, [hsva, lightShades, darkShades]);

  const CustomPointer = (prefixCls, left) => {
    return (
      <span
        style={{
          height: "10px",
          width: "190px",
          position: "absolute",
          left: -2,
          top: -2,
        }}
      >
        <span
          style={{
            height: "13px",
            width: "13px",
            backgroundColor: "#151515",
            borderRadius: "50%",
            display: "inline-block",
            position: "absolute",
            left: left,

            border: "2px solid #FFFFFF",
          }}
        />
      </span>
    );
  };

  // shades color
  useEffect(() => {
    const generateSwatchesFromHue = (term, showShades, showTints) => {
      const colorBuffer = [];

      const color = new Values(hsvaToHexa(hsva));

      color[term]().forEach((c) => colorBuffer.push(c.hexString()));

      return colorBuffer;
    };

    const generateDarkShades = generateSwatchesFromHue("shades", true, false);
    const generateLightShades = generateSwatchesFromHue("tints", false, true);

    setLightShades(generateLightShades);
    setDarkShades(generateDarkShades);
  }, [hsva]);

  return (
    <Accordion
      variant="elevation"
      elevation={0}
      expanded={expanded === `panel1_4`}
      onChange={handleAccordioChange(`panel1_4`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography>Color gradient</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "felx", flexDirection: "column" }}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => {
            setHsva(defaultColor);
            setWheelHsva(defaultColor);
          }}
        >
          Reset to system default
        </Typography>

        <Wheel
          style={{ margin: "10px auto 5px auto" }}
          color={wheelHsva}
          // onChange={(color) => {
          //   setHsva({ ...hsva, ...color.hsva });
          //   setWheelHsva({ ...hsva, ...color.hsva });
          // }}
          direction="anticlockwise"
        />

        <ShadeSlider
          style={{ margin: "10px auto 5px auto" }}
          pointer={({ prefixCls, left }) => CustomPointer(prefixCls, left)}
          radius="4px"
          width="200px"
          height="10px"
          hsva={hsva}
          onChange={(newShade) => {
            setHsva({
              ...hsva,
              v: newShade.v,
              s: newShade.s,
            });
          }}
        />

        <Alpha
          style={{ margin: "10px auto 5px auto" }}
          radius="4px"
          width="200px"
          height="10px"
          pointer={({ prefixCls, left }) => CustomPointer(prefixCls, left)}
          hsva={hsva}
          onChange={(newShade) => {
            setHsva({ ...newShade });
          }}
        />

        <EditableInput
          labelStyle={{ color: "red" }}
          value={hsvaToHexa(hsva)}
          placement="top"
          style={{
            width: "100%",
            alignItems: "flex-start",
            margin: "10px auto 5px auto",
          }}
          onChange={(e) => {
            setHsva(hexToHsva(e.target.value));
            // setWheelHsva(hexToHsva(e.target.value));
          }}
          inputStyle={{
            height: "40px",
            borderRadius: "5px",
            padding: "16px",
          }}
        />

        <List dense={false}>
          {[
            { title: "Headline colour", color: darkShades[5] },
            { title: "Subline colour", color: hsvaToHexa(hsva) },
            { title: "Highlight color", color: lightShades[8] },
          ].map((shades, index) => (
            <ListItem key={index} disableGutters>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    type="body2"
                    style={{
                      color: "#242424",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    {shades.title}
                  </Typography>
                }
              />

              <ListItemSecondaryAction>
                <Swatch
                  colors={[shades.color]}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <hr style={{ border: "1px solid #A1BFDF", height: "0px" }} />

        <List disablePadding dense={false}>
          <ListItem disableGutters>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  type="body2"
                  style={{
                    color: "#242424",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  Logo color swatches
                </Typography>
              }
              secondary={
                colorLogo.length > 0 ? (
                  <Swatch
                    colors={colorLogo}
                    style={{
                      margin: "10px auto 5px auto",
                    }}
                    onChange={(hsvColor) => {
                      setHsva(hsvColor);
                      // setWheelHsva(hsvColor);
                    }}
                  />
                ) : (
                  <Typography
                    type="body3"
                    style={{
                      color: "#242424",
                      fontSize: "13px",
                      fontWeight: 400,
                      marginTop: "2px",
                    }}
                  >
                    Currently no logo swatch available
                  </Typography>
                )
              }
            />
          </ListItem>
        </List>

        <List disablePadding dense={false}>
          <ListItem disableGutters>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  type="body2"
                  style={{
                    color: "#242424",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  Previously Selected colour swatches
                </Typography>
              }
              secondary={
                preColor.length > 0 ? (
                  <Swatch
                    colors={preColor}
                    style={{
                      margin: "10px auto 5px auto",
                    }}
                    onChange={(hsvColor) => {
                      setHsva(hsvColor);
                      // setWheelHsva(hsvColor);
                    }}
                  />
                ) : (
                  <Typography
                    type="body3"
                    style={{
                      color: "#242424",
                      fontSize: "13px",
                      fontWeight: 400,
                      marginTop: "2px",
                    }}
                  >
                    Currently no previous swatch available
                  </Typography>
                )
              }
            />
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

export default ColorGradient;
