import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";

import score0Large from "../../../../../images/score0Large.jpg";
import beginerLarge from "../../../../../images/beginerLarge.png";
import intermediateLarge from "../../../../../images/intermideateLarge.png";
import expertLarge from "../../../../../images/expertLarge.png";
import chatToolTip from "../../../../../images/chatToolTip.png";
import Heading from "components/UI/phase-3/heading/Heading";

const EsgScoreLeftStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    // height:'100%'
  },
  mainHeadder: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0px 8px 0px",
  },
  Typography: {
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "10px",
  },
  Typography2: {
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "4px",
  },
  iconCont: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  headerIcon: {
    marginRight: "20px",
  },
  Table: {
    outline: "0.5px solid lightGrey",
    borderRadius: "5px",
    boxShadow: "1px 1px 1px",
    height: "100%",
  },
  TableContainer: {
    padding: "3px",
  },
  TableCell: {
    padding: "5px",
    border: "none",
  },
  hedaderCell: { color: "grey", fontSize: "x-small" },
  topicCell: { padding: "4px 4px" },
  lableCell: {
    // width: '193px',
    // height: '20px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    // lineHeight: "20px",
  },
  scoreCell: {
    textAlign: "right",
    // width: '32px',
    // height: '28px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "28px",
  },
  SuggestionBtn: {
    textTransform: "none",
    textAlign: "center",
    width: "100%",
    fontFamily: "Poppins",
    margin: "5% 0px 5px 1px",
    padding: "5px",
  },
  contributorGrid: {
    padding: "0px 15px 0px 10px",
  },
  contributorsBtn: {
    backgroundColor: "white",
    color: "green",
    border: "0.5px solid green",
    borderRadius: "5px",
    textTransform: "none",
    textAlign: "center",
    width: "100%",
    fontFamily: "Poppins",
    margin: "5% 0px 5px 1px",
  },
  topicStyle: {
    width: "40px",
    height: "40px",
    marginTop: "5px",
    position: "relative",
    top: "5px",
  },
  levelImg: {
    width: "70px",
    // height: "135px",
  },
  ".MuiLinearProgress-root": {
    color: "green",
  },
  LinearProgress: {
    // color:'success.main',
    margin: "-5px 25px 25px 25px",
  },
  scoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "xx-large",
  },
  maxScoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
  },
  sharesurvey: {
    color: "#222",
    fontSize: "12px",
    backgroundColor: "#BFE2CD",
    padding: " 7px 10px ",
    borderRadius: "100px",
  },
  describe: {
    color: "grey",
    fontSize: "x-small",
    marginTop: "11px",
  },
  scoreImage: {
    width: "185px",
    // height: "190px",
  },
  mainGrid: {
    textAlign: "center",
  },
  discribe: {
    fontSize: "small",
  },
  slider: {
    ".MuiSlider-root": {
      color: "#20b2aa",
    },
    ".MuiSlider-root:hover": {
      color: "#2e8b57",
    },
  },
  levelTypo: {
    // marginLeft: "5%",
    textAlign: "center",
  },
  progressIndicator1: {
    position: "relative",
    left: "33%",
    top: "12px",
    backgroundColor: "rgb(0 128 0)",
    zIndex: 1,
  },
  progressIndicator2: {
    position: "relative",
    left: "66.7%",
    top: "12px",
    backgroundColor: "rgb(0 128 0)",
    zIndex: 1,
  },
  [theme.breakpoints.down("lg")]: {
    SuggestionBtn: {
      margin: "5% 0px 5px 1px",
    },
    discribe: {
      fontSize: "small",
      textWrap: "no-wrap",
      wordWrap: "no-wrap",
    },
  },
  [theme.breakpoints.down("md")]: {
    SuggestionBtn: {
      margin: "5% 0px 5px 1px",
      padding: "5px",
    },
    contributorsBtn: {
      margin: "5% 0px 5px 1px",
      paddingLeft: "5px",
    },
    discribe: {
      textAlign: "left",
      fontSize: "small",
      textWrap: "no-wrap",
      wordWrap: "no-wrap",
    },
    sharesurvey: {
      fontWeight: 500,
    },
  },
  leftTop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
}));

const Progress = withStyles({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "green",
    },
  },
})(LinearProgress);

function EsgScoreLeft() {
  const classes = EsgScoreLeftStyles();

  const score = 50;
  return (
    <Box
      style={{
        flex: 1,
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
      }}
    >
      <Grid item sm={12} className={classes.mainGrid}>
        <Box className={classes.leftTop}>
          <Box>
            {score === 0 && (
              <img
                src={score0Large}
                alt="score0Large"
                className={classes.scoreImage}
              />
            )}
            {score > 0 && score < 33.3 && (
              <img
                src={beginerLarge}
                alt="beginerLarge"
                className={classes.scoreImage}
              />
            )}
            {score >= 33.3 && score < 66.6 && (
              <img
                src={intermediateLarge}
                alt="intermediateLarge"
                className={classes.scoreImage}
              />
            )}
            {score >= 66.6 && score <= 100 && (
              <img
                className={classes.scoreImage}
                src={expertLarge}
                alt="expertLarge"
              />
            )}
          </Box>
          <Box>
            <Heading text={"You ESG score is"} size={"22px"} marginBottom={0} />
            <Typography className={classes.maxScoreTypo}>
              <span className={classes.scoreTypo}>{score}</span> / 100{" "}
            </Typography>
            {score === 0 && (
              <Typography className={classes.sharesurvey}>
                Share Survey To identify your Score
              </Typography>
            )}
            {score > 0 && score < 33.3 && (
              <Typography className={classes.sharesurvey}>
                Share Survey To identify your Score
              </Typography>
            )}
            {score >= 33.3 && score < 66.6 && (
              <Typography className={classes.sharesurvey}>
                Intermediate, Good progress.
              </Typography>
            )}
            {score >= 66.6 && (
              <Typography className={classes.sharesurvey}>
                Advanced, Great !
              </Typography>
            )}
          </Box>
        </Box>

        {/* <Typography className={classes.discribe}>
          Your Survey Data is Generated using the contributions from your
          stakeholders
        </Typography> */}
      </Grid>

      <Grid>
        {/* <div style={{ marginRight: '10%' }}>
            <IndicatorTool
              style={{
                // zIndex: -1500 + '!important',
                position: 'relative',
                left: `${  score <= 4
                  ? 0
                  : score <= 30
                  ? score - 4.5
                  : score <= 70
                  ? score - 7
                  : score <= 95
                  ? score - 10
                  : 85}%`,
              }}
              open={true}
              placement="top-start"
              title="You're Here"
              arrow
            >
              <div></div>
            </IndicatorTool>
          </div> */}
        <div
          style={{
            height: 46,
            width: 112,
            display: "flex",
            alignItems: "center",
            position: "relative",
            left: `${
              score <= 6
                ? 0
                : score <= 30
                  ? score - 5.5
                  : score <= 60
                    ? score - 6
                    : score <= 70
                      ? score - 7.2
                      : score <= 90
                        ? score - 7.8
                        : score <= 95
                          ? score - 11
                          : 85
            }%`,
            marginTop: 10,
          }}
        >
          <img
            style={{ height: "85%", width: "85%" }}
            src={chatToolTip}
            alt=""
          />
        </div>
        <div className={classes.LinearProgress}>
          <span className={classes.progressIndicator1}>'</span>
          <span className={classes.progressIndicator2}>'</span>
          <Progress
            // style={{ zIndex: 0 + '!important',}}
            color="primary"
            variant="determinate"
            value={score}
          />
        </div>
      </Grid>

      <Grid item sm={12} container style={{ justifyContent: "space-evenly" }}>
        <Grid item>
          <Typography className={classes.levelTypo}>Beginner</Typography>
          <img
            className={classes.levelImg}
            src={beginerLarge}
            alt="beginerLarge"
          />
        </Grid>
        <Grid item>
          <Typography className={classes.levelTypo}>Intermediate</Typography>
          <img
            className={classes.levelImg}
            src={intermediateLarge}
            alt="intermediateLarge"
          />
        </Grid>
        <Grid item>
          <Typography className={classes.levelTypo}>Expert</Typography>
          <img
            className={classes.levelImg}
            src={expertLarge}
            alt="expertLarge"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default EsgScoreLeft;
