import React from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material";
import Chip from "@mui/material/Chip";
const chipTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: "#7467EF" },
      secondary: { main: "#E55C79" },
    },
    overrides: {
      MuiChip: {
        colorPrimary: {
          "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#7467EF",
          },
        },
        colorSecondary: {
          "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#E55C79",
          },
        },
        outlinedPrimary: {
          color: "#000000",
          backgroundColor: "rgba(50, 160, 95, 0.2)",
          borderRadius: "5%",
          border: "0px",
          "&:hover": {
            color: "#000000",
            backgroundColor: "rgba(50, 160, 95, 0.2)",
          },
        },
        outlinedSecondary: {
          color: "#000000",
          backgroundColor: "rgba(255, 194, 80, 0.2)",
          borderRadius: "5%",
          border: "0px",
          "&:hover": {
            color: "#000000",
            backgroundColor: "rgba(255, 194, 80, 0.2)",
          },
        },
        root: {
          color: "#FFFFFF",
          backgroundColor: "#F4B63F",
          "&:hover": {
            color: "#FFFFFF",
            backgroundColor: "#F4B63F",
          },
        },
        outlined: {
          color: "#000000",
          backgroundColor: "rgba(0, 0, 155, 0.2)",
          borderRadius: "5%",
          border: "0px",
          "&:hover": {
            color: "#000000",
            backgroundColor: "rgba(0, 0, 155, 0.2)",
          },
        },
      },
    },
  })
);
export default class CustomChip extends React.PureComponent {
  render() {
    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={chipTheme}>
            <Chip {...this.props} />
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
}
