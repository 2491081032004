import { Typography, Box } from "@mui/material";
function NoListItem({ message = "pass message to display" }) {
  return [1, 2, 3, 4, 5].map((item) => (
    <Box key={item} sx={{ padding: "10px 0px" }}>
      <Box
        sx={{
          width: "100%",
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#DADBE655",
          borderRadius: 10,
        }}
      >
        {item === 3 && <Typography>{message}</Typography>}
      </Box>
    </Box>
  ));
}

export default NoListItem;
