import { Widgets } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import Heading from "components/UI/phase-3/heading/Heading";

const riskAreas = [
  {
    category: "Labor Standards And Human Rights",
    description:
      "Poor labor standards and human rights violations can result in reputational damage, legal liabilities, and loss of investor confidence.",
  },
  {
    category: "Risk Management",
    description:
      "Potential for business disruptions and financial losses, suggestions for enterprise risk management frameworks.",
  },
  {
    category: "Community Relations",
    description:
      "Best practices in employee education and career development, potential for enhanced employee retention and productivity.",
  },
  {
    category: "Regulatory Compliance",
    description:
      "Poor compliance with environmental regulations may result in fines, legal penalties, and reputational damage.",
  },
  {
    category: "Greenhouse Gas Emissions",
    description:
      "High emissions contribute to climate change, which poses a significant threat to the environment and human well-being.",
  },
];

const strongAreas = [
  {
    category: "Board Composition And Diversity",
    description:
      "A diverse and effective board can provide oversight and strategic guidance, improving corporate performance and reducing risk.",
  },
  {
    category: "Corporate Governance Policies And Procedures",
    description:
      "Implementing strong corporate governance policies and procedures can reduce risk, increase transparency, and enhance investor confidence.",
  },
  {
    category: "Ethical Business Practices",
    description:
      "Maintaining ethical business practices can improve reputation, reduce risk, and enhance long-term value creation.",
  },
  {
    category: "Transparency And Disclosure",
    description:
      "Transparency and disclosure can enhance investor confidence, improve reputation, and promote accountability.",
  },
];

const improvementAreas = [
  {
    category: "Supply Chain Sustainability",
    description:
      "Ensuring supply chain sustainability can help reduce environmental impact, improve efficiency, and build trust with stakeholders.",
  },
  {
    category: "Waste Reduction And Recycling",
    description:
      "Implementing waste reduction and recycling programs can reduce waste generation, conserve resources, and reduce costs.",
  },
  {
    category: "Employee Training And Development",
    description:
      "Providing employee training and education programs can help improve workplace safety and reduce the risk of accidents and injuries.",
  },
];

function Suggestions() {
  return (
    <Box>
      <Box>
        <Heading text="Risk Area's" />
        <Typography variant="body2">
          These are the area of risks & identification of emerging ESG risks and
          potential regulatory changes. With impact percentage total of
        </Typography>

        <Grid
          container
          style={{
            backgroundColor: "#F9F9F9",
            marginTop: "10px",
            gap: "25px",
            padding: "20px",
          }}
        >
          {riskAreas?.map((item) => (
            <Grid item style={{ width: "30%" }}>
              <Heading text={item?.category} marginBottom="10px" />
              <Typography variant="body2">{item?.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box style={{ marginTop: "20px" }}>
        <Heading text="Strong Area's" />
        <Typography variant="body2">
          These are the area of Strong & identification of emerging ESG risks
          and potential regulatory changes. With impact percentage total of
        </Typography>

        <Grid
          container
          style={{
            backgroundColor: "#F9F9F9",
            marginTop: "10px",
            gap: "25px",
            padding: "20px",
          }}
        >
          {strongAreas?.map((item) => (
            <Grid item style={{ width: "30%" }}>
              <Heading text={item?.category} marginBottom="10px" />
              <Typography variant="body2">{item?.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box style={{ marginTop: "20px" }}>
        <Heading text="Improvement Area's" />
        <Typography variant="body2">
          These are the area of risk & identification of emerging ESG risks and
          potential regulatory changes. With impact percentage total of
        </Typography>

        <Grid
          container
          style={{
            backgroundColor: "#F9F9F9",
            marginTop: "10px",
            gap: "25px",
            padding: "20px",
          }}
        >
          {improvementAreas?.map((item) => (
            <Grid item style={{ width: "30%" }}>
              <Heading text={item?.category} marginBottom="10px" />
              <Typography variant="body2">{item?.description}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Suggestions;
