var data = [
  {
    title: "COLLECTION OF INFORMATION",
    desc: [
      `a. User(s) privacy is important to BillionLives and BillionLives have taken steps to ensure that BillionLives do not collect more information from User than is necessary for BillionLives to provide User(s) with BillionLives’ services and to protect User(s) account.`,
      `b. Information including, but not limited to, User name, address, phone number, fax number, email address, gender, date and/or year of birth and user preferences ("Registration Information") may be collected at the time of User registration on the Platform.`,
      `c.	In connection with any communication or transaction and payment services or any other services that you may avail using the Platform, information, including but not limited to, bank account numbers, billing and delivery information, credit/debit card numbers and expiration dates and tracking information from cheques or money orders ("Account Information") may be collected, among other things, to facilitate the sale and purchase as well as the settlement of purchase price of the products or services transacted on or procured through the Platform.`,
      `d.	BillionLives record and retain details of Users’ activities on the Platform. Information relating to communication or transactions including, but not limited to, the types and specifications reporting standards and any information disclosed in any communication forum provided by us and/or other affiliated companies of BillionLives (“Activities Information”) may be collected as and when the communication and / or transactions are conducted through the Platform.`,
      `e.	BillionLives record and retain records of Users’ browsing or buying activities on Platform including but not limited to IP addresses, browsing patterns and User behavioural patterns. In addition, we gather statistical information about the Platform and visitors to the Platform including, but not limited to, IP addresses, browser software, operating system, software and hardware attributes, pages viewed, number of sessions and unique visitors (together "Browsing Information").`,
      `f.	Registration Information, Account Information, Activities Information, and Browsing Information are collectively referred to as User Data.`,
      `g.	It is mandatory for Users of the Platform to provide certain categories of User Data (as specified at the time of collection). In the event that Users do not provide any or sufficient User Data marked as mandatory, BillionLives may not be able to complete the registration process or provide such Users with BillionLives’ products or services.`,
    ],
  },
  {
    title: "USE OF USER DATA",
    desc: [
      `If you provide any User Data to BillionLives, you are deemed to have authorized BillionLives to collect, retain and use that User Data for the following purposes:`,
      `a.	Verification of User’s identity;`,
      `b.	Processing User’s registration as a user, providing User with a log-in ID for the Platform and maintaining and managing User’s registration;`,
      `c.	Providing User with customer service and responding to User(s) queries, feedback, claims or disputes;`,
      `d.	To facilitate communication between Users on the Platform and / or processing Users transactions on the Platform;`,
      `e.	Performing research or statistical analysis in order to improve the content and layout of the Platform, to improve BillionLives’ product offerings and services and for marketing and promotional purposes;`,
      `f.	Subject to applicable law, BillionLives (including our affiliated companies and their designated Service Providers may use User’s name, phone number, residential address, email address, fax number and other data ("Marketing Data") to provide notices, surveys, product alerts, communications and other marketing materials to User(s) relating to products and services offered by BillionLives or BillionLives’ affiliated companies;`,
      `g.	If User voluntarily submit any User information or other information to the Platform for publication on the Platform through the publishing tools, then Users are deemed to have given consent to the publication of such information on the Platform; and`,
      `h.	Making such disclosures as may be required for any of the above purposes or as required by law, regulations and guidelines or in respect of any investigations, claims or potential claims brought on or against us or against third parties.`,
    ],
  },
  {
    title: "DISCLOSURE OF USER DATA",
    desc: [
      `a.	User further agrees that BillionLives may disclose and transfer User Data to third party service providers (including but not limited to data entry, database management, promotions, products and services alerts, delivery services, payment extension services, authentication and verification services and logistics services) ("Service Providers"). These Service Providers are under a duty of confidentiality to BillionLives and are only permitted to use User Data in connection with the purposes specified in clause 2 herein above.`,
      `b.	User(s) agree that BillionLives may disclose and transfer User Data to BillionLives’ affiliated companies and/or their designated Service Providers.`,
      `c.	When necessary BillionLives may also disclose and transfer User Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations.`,
      `d.	Any User Data supplied by User will be retained by BillionLives and will be accessible by our employees, any Service Providers engaged by BillionLives and third parties referred to in clause 3 herein, for or in relation to any of the purposes stated in Clause 2 herein above.`,
      `e.	All voluntary information uploaded by you on the Platform (including without limitation information about your products, images, remarks, feedbacks etc. ) may be made publicly available on the Platform and therefore accessible by any internet user. Any voluntary information that User disclose to BillionLives becomes public information and User relinquishes any proprietary rights (including but not limited to the rights of confidentiality and copyright) in such information. User should exercise caution when deciding to include personal or proprietary information in the voluntary information that User submits to BillionLives or uploads on the Platform.`,
      `f.	BillionLives may share User Data with third parties, including without limitation, rating agencies, regulators or vendors to enable such third parties to offer their products or services to such Users. While BillionLives shall endeavour to have in place internal procedures to keep User Data secure from intruders, there is no guarantee that such measures/procedures can eliminate all of the risks of theft, loss or misuse.`,
      `g.	BillionLives may establish relationships with other parties and websites to offer User the benefit of products and services which BillionLives does not offer. BillionLives may offer you access to these other parties and/or their websites. This Privacy Policy does not apply to the products and services enabled or facilitated by such third parties. The privacy policies of those other parties may differ from BillionLives, and BillionLives has no control over the information that User may submit to those third parties. User should read the relevant privacy policy for those third parties before responding to and availing any offers, products or services advertised or provided by those third parties.`,
    ],
  },
  {
    title: "RIGHT TO UPDATE USER DATA",
    desc: [
      "Under the applicable laws, User have the right of access to personal information held by BillionLives and to request updating / correcting the information.",
    ],
  },
  {
    title: "COOKIES",
    desc: [
      `BillionLives uses "cookies" to store specific information about User and track User(s) visits to the Sites. A "cookie" is a small amount of data that is sent to User’s browser and stored on User’s device. If User does not deactivate or erase the cookie, each time User uses the same device to access the Platform, our services will be notified of User visit to the Platform and in turn BillionLives may have knowledge of User visit and the pattern of User’s usage.`,
      `Generally, BillionLives use cookies to identify User and enable BillionLives to`,
      `i) access User’s Registration Information or Account Information so User do not have to re-enter it;`,
      `ii) gather statistical information about usage by Users;`,
      `iii) research visiting patterns and help target advertisements based on User interests;`,
      `iv) assist BillionLives’ partners to track User visits to the Platform and process orders; and`,
      `v) track progress and participation on the Platform.`,
      `User can determine if and how a cookie will be accepted by configuring the browser which is installed in User’s device. If User choose, User can change those configurations. If User reject all cookies by choosing the cookie-disabling function, User may be required to re-enter information on the Platform more often and certain features of the Platform may be unavailable.`,
    ],
  },
  {
    title: "MINORS",
    desc: [
      "The Platform and its contents are not targeted to minors (those under the age of 18). However, BillionLives have no way of distinguishing the age of individuals who access our Platform. If a minor has provided BillionLives with personal information without parental or guardian consent, the parent or guardian should contact BillionLives’ Legal Department at the address set out in clause 9 below to remove the information.",
    ],
  },
  {
    title: "SECURITY MEASURES",
    desc: [
      "BillionLives employs commercially reasonable security methods to prevent unauthorized access to the Platform, to maintain data accuracy and to ensure the correct use of the information BillionLives hold. No data transmission over the internet or any wireless network can be guaranteed to be perfectly secure. As a result, while BillionLives tries to protect the information BillionLives hold, BillionLives cannot guarantee the security of any information the User transmits to BillionLives and Users do so at their own risk.",
    ],
  },
  {
    title: "CHANGES TO THIS PRIVACY POLICY",
    desc: [
      "Any changes to this Privacy Policy will be communicated by us posting an amended and restated Privacy Policy on the Platform. Once posted on the Platform the new Privacy Policy will be effective immediately. Your continued use of the Platform shall be deemed to be your acceptance to the provisions of the Privacy Policy. User agree that any information BillionLives hold about User (as described in this Privacy Policy and whether or not collected prior to or after the new Privacy Policy became effective) will be governed by the latest version of the Privacy Policy.",
    ],
  },
];

export default data;
