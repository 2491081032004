import React from "react";
import Button from "@mui/material/Button";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";

const greenTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: { main: "#3374B9" },
      secondary: { main: "#47BB76" },
      action: {
        disabledBackground: "#C0D4E9",
      },
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: "#ffffff",
        },
        containedPrimary: {
          color: "#ffffff",
        },
        textPrimary: {
          "&:hover": {
            backgroundColor: "#ffffff",
          },
        },
        textSecondary: {
          "&:hover": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
  })
);
export default class CustomButton extends React.PureComponent {
  render() {
    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={greenTheme}>
            <Button {...this.props} />
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
}
