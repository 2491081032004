import { Box, IconButton, Button, Stack, Pagination } from "@mui/material";
import { getAssetsList } from "actions/phase-3/orgDetails/orgDetailsAction";

import DynamicTableFull from "components/UI/phase-3/dynamicTable/DynamicTableFull";
import SearchTextField from "components/UI/textfield/searchTextField";
import AssetsDrawer from "components/widgets/phase-3/OrgDetailsProfile/AssetsComps/AssetsDrawer";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEffect, useState } from "react";

import { FiUpload, FiDownload, FiPlus } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useAssetsAPIs } from "./APIs/AssetsApis";

const assetsListHead = [
  { name: "Asset Name" },
  { name: "Asset ID" },
  { name: "Entity/Unit Type" },
  { name: "Entity/Unit Name" },
  { name: "Handled By" },
  { name: "View" },
];

const tableRowOrder = [
  "asset_name",
  "asset_id",
  "unit.unit_id || entity.entity_id",
  "unit.unit_name || entity.entity_name",
  "created_by.name",
];

const customAction = (row) => (
  <IconButton
    aria-label="CustomAction"
    size="small"
    sx={{ color: "primary.main" }}
  >
    <IoIosArrowForward />
  </IconButton>
);

const Assets = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const {
    // states & data
    assets_list,
    fetchAssets,
  } = useAssetsAPIs(keyword, limit, page);

  return (
    <Box position={"relative"} height={"65vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={2} mb={2} justifyContent="flex-end">
        <SearchTextField searchClick={handleSearch} sx={{ minWidth: "30em" }} />
        <Button variant="text" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button variant="text" startIcon={<FiUpload />}>
          Upload Sheet
        </Button>
        <Button
          variant="contained"
          startIcon={<FiPlus />}
          size="small"
          onClick={toggleDrawer}
        >
          Add Assets
        </Button>
      </Stack>

      {/* Entry List Table */}
      <DynamicTableFull
        tableHead={assetsListHead}
        tableData={assets_list?.assets_list}
        tableRowOrder={tableRowOrder}
        renderAction={customAction}
      />

      <Box position={"absolute"} right={2} bottom={0}>
        <Pagination
          count={Math.ceil(assets_list?.total_count / limit)}
          variant="outlined"
          page={page}
          onChange={handlePageChange}
        />
      </Box>

      <AssetsDrawer
        open={openDrawer}
        onClose={toggleDrawer}
        fetchAssets={fetchAssets}
      />
    </Box>
  );
};

export default Assets;
