// !------------- A children can be passed (no other things are needed) -------------!

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Typography, Box } from "@mui/material";

import { IoIosArrowForward } from "react-icons/io";

export const ActiveCrumbStyle = (isActive) => {
  return {
    color: isActive ? "#15314E" : "",
    fontWeight: isActive ? 600 : 400,
    fontSize: "16px",
    lineHeight: "24px",
    cursor: isActive ? "default" : "pointer",
  };
};

const LayoutHeader = ({ children, mainCrumb }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const startIndex = 1; // Skip the first segment

  const handleClick = (index) => {
    const routeTo = `/${pathnames.slice(0, index + startIndex + 1).join("/")}`;
    navigate(routeTo);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 20px",
      }}
    >
      {/* Breadcrumbs */}
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<IoIosArrowForward style={{ color: "#A1BFDF" }} />}
      >
        <Typography
          color="inherit"
          style={{
            ...ActiveCrumbStyle(pathnames.length === 1),
            textTransform: "capitalize",
          }}
          onClick={() => {
            if (pathnames.length !== 1) navigate(`/${pathnames[0]}`);
          }}
        >
          {mainCrumb || "Surveys"}
        </Typography>
        {pathnames.slice(startIndex).map((name, index) => {
          const isLast = index + 1 === pathnames.length - 1;

          return (
            <div key={index}>
              <Typography
                color={"inherit"}
                style={{
                  ...ActiveCrumbStyle(isLast),
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  if (!isLast) handleClick(index);
                }}
              >
                {name.replace(/_/g, " ")}
              </Typography>
            </div>
          );
        })}
      </Breadcrumbs>

      {/* other options */}
      <Box>{children}</Box>
    </Box>
  );
};

export default LayoutHeader;
