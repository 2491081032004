import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import OtpInput from "react-otp-input";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import CustomTextfield from "../textfield/textfield";
import { Navigate, useNavigate } from "react-router-dom";
import {
  emailValidation,
  passwordValidation,
} from "../../../actions/regexValidation/regexValidation";
import CustomButton from "../button/button";
import freeTrial from "../../../images/freetrail.png";
import tickBox from "../../../images/tickbox.png";
import signupCardUseStyles from "./signupCardStyles";
import freeTrialFeatures from "../../../constants/signupCardConst/signupCardConst";
import clsx from "clsx";
import TextField from "@mui/material/TextField";

function SignupCard(mainProps) {
  const { signupCard, token, signupStepNo } = mainProps.props.login;
  const {
    setSignupCard,
    setShowOnboarding,
    setSideMessage,
    signupWithEmail,
    verifyOtp,
    resendOtp,
    forgetPassword,
    login_with_email,
    setSignupStepNo,
  } = mainProps.props;

  const classes = signupCardUseStyles();
  const initialFormDetails = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const [redirect] = useState(false);
  const [formDetails, setFormDetails] = useState({ ...initialFormDetails });
  const [nameError, setNameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  // const [value, setValue] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [code, setCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [disableSendAgain, setDisableSendAgain] = useState(false);
  const [redirectToDetails, setRedirectToDetails] = useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);

  const [isForgetPasswordDialog, setForgetPasswordDialog] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    const { firstName, lastName, email, password } = formDetails;
    let name = firstName + " " + lastName;
    signupWithEmail(name, email, password);
  };

  const onFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      if (value === "") {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
    if (name === "lastName") {
      if (value === "") {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }
    if (name === "email") {
      setEmailError(emailValidation(value));
    }
    if (name === "password") {
      setPasswordError(passwordValidation(value));
    }
    if (name === "confirmPassword") {
    }
    setFormDetails((formDetails) => ({
      ...formDetails,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    setTimeout(() => {
      setSignupCard(true);
    }, 700);
  }, [setSignupCard]);

  useEffect(() => {
    if (signupStepNo === 1) {
      setSideMessage("otp");
    } else if (signupStepNo === 2) {
      setSideMessage("plan");
    }
  }, [signupStepNo]);
  //to check and redirect the user to otp page
  const localSignupProcessNo = JSON.parse(
    localStorage.getItem("signup_process_no")
  );

  useEffect(() => {
    if (localSignupProcessNo === 1) {
      setSignupStepNo(1);
    } else if (localSignupProcessNo === 2) {
      setSignupStepNo(2);
    }
  }, [localSignupProcessNo]);

  useEffect(() => {
    setOtpEmail(formDetails?.email);
  }, [formDetails?.email]);

  // if (redirect) {
  //   return <Redirect to="/tnc"></Redirect>;
  // }

  const handleOTPEmail = (e) => {
    const value = e.target.value;
    setEmailError(emailValidation(value));
    setOtpEmail(value);
  };

  if (redirectToDetails) {
    return <Navigate to="/basicDetails" />;
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleOtpChange = (code) => setCode(code);

  if (disableSendAgain) {
    setTimeout(() => {
      setDisableSendAgain(false);
    }, 1000 * 60);
  }

  const forgetPasswordDialog = () => {
    return (
      <Dialog
        open={isForgetPasswordDialog}
        onClose={() => setForgetPasswordDialog(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle>
          Reset Password
          <Typography variant="body2">
            Please enter your email address to reset your password. We will send
            a reset link to your email address.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CustomTextfield
            variant="outlined"
            placeholder="Enter your Email"
            fullWidth
            onKeyPress={(e) =>
              e.key === "Enter" &&
              forgetPassword(email, window.location.hostname)
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              forgetPassword(email, window.location.hostname);
              setForgetPasswordDialog(false);
              setEmail("");
            }}
            disabled={!emailValidation(email)}
          >
            reset password
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const isDisabled =
    formDetails.firstName !== "" &&
    formDetails.lastName !== "" &&
    formDetails.email !== "" &&
    emailError &&
    passwordError &&
    formDetails.password !== "" &&
    confirmPassword !== "" &&
    checked === true &&
    formDetails.password === confirmPassword
      ? false
      : true;
  return (
    <>
      {isForgetPasswordDialog && forgetPasswordDialog()}

      <Slide
        direction="down"
        in={signupCard}
        mountOnEnter
        unmountOnExit
        timeout={700}
      >
        <Card variant="outlined">
          <CardContent className={classes.cardcontent}>
            <Box className={classes.cardBox}>
              <List className={classes.cardList} component="nav">
                {(signupStepNo === 0 || signupStepNo === 1) && (
                  <>
                    <ListItem
                      button
                      selected={selectedIndex === 0}
                      onClick={(event) => handleListItemClick(event, 0)}
                      classes={{
                        root: classes.rootTab,
                        selected: classes.selected,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            className={classes.listTypo}
                            style={{ fontWeight: selectedIndex === 0 && 600 }}
                          >
                            Signup
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 1}
                      onClick={(event) => handleListItemClick(event, 1)}
                      classes={{
                        root: classes.rootTab,
                        selected: classes.selected,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            className={classes.listTypo1}
                            style={{ fontWeight: selectedIndex === 1 && 600 }}
                          >
                            Login
                          </Typography>
                        }
                      />
                    </ListItem>
                  </>
                )}
                {signupStepNo === 2 && (
                  <ListItem
                    button
                    selected
                    onClick={(event) => handleListItemClick(event, 1)}
                    classes={{
                      root: classes.rootTab,
                      selected: classes.selected,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.listTypo2}>
                          Plan Selection
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </Box>
            <Box paddingX="16px" paddingTop="80px" paddingBottom="18px">
              {signupStepNo === 0 && selectedIndex === 0 && (
                <>
                  <Typography className={classes.labelText}>
                    First name
                  </Typography>
                  <CustomTextfield
                    type="text"
                    name="firstName"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formDetails.firstName}
                    onChange={onFormChange}
                    className={classes.costumTextField}
                  />

                  <Typography className={classes.labelText}>
                    Last name
                  </Typography>
                  <CustomTextfield
                    type="text"
                    variant="outlined"
                    name="lastName"
                    size="small"
                    fullWidth
                    value={formDetails.lastName}
                    onChange={onFormChange}
                    className={classes.costumTextField}
                  />
                  <Typography className={classes.labelText}>Email</Typography>
                  <CustomTextfield
                    id="email"
                    name="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="email"
                    value={formDetails.email}
                    error={!emailError}
                    helperText={
                      !emailError ? "Please enter a valid Email." : ""
                    }
                    onChange={onFormChange}
                    className={classes.costumTextField}
                  />
                  <Typography className={classes.labelText}>
                    Password
                  </Typography>
                  <CustomTextfield
                    id="password"
                    name="password"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    error={!passwordError}
                    helperText={
                      !passwordError ? "Please enter a valid password." : ""
                    }
                    value={formDetails.password}
                    onChange={onFormChange}
                    className={classes.costumTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          name="passSvg"
                          position="end"
                          className={classes.inputStyle}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography className={classes.labelText}>
                    Confirm Password
                  </Typography>
                  <CustomTextfield
                    id="confirmpassword"
                    name="confirmpassword"
                    variant="outlined"
                    size="small"
                    error={
                      confirmPassword !== "" &&
                      confirmPassword !== formDetails.password
                    }
                    helperText={
                      confirmPassword !== formDetails.password
                        ? "Password and confirm password should match."
                        : ""
                    }
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type={showConfirmPassword ? "text" : "password"}
                    className={classes.costumTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          name="cnfPassSvg"
                          position="end"
                          className={classes.inputStyle}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setSignupStepNo(1)}
                  >
                    Already sent? Verify OTP
                  </Button>

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingTop="62px"
                  >
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        name="signupCheckbox"
                        color="primary"
                        disableRipple
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                      <a href="/tnc" target="_blank" rel="noopener noreferrer">
                        <Typography className={classes.checkboxLabelText}>
                          Agree to the terms and Conditions
                        </Typography>
                      </a>
                    </Box>
                    <CustomButton
                      name="signupBtn"
                      variant="contained"
                      color="primary"
                      size="small"
                      disableElevation
                      className={classes.signupBtn}
                      disabled={isDisabled}
                      onClick={() => {
                        handleSubmit();
                        setSideMessage("otp");
                      }}
                    >
                      Signup
                    </CustomButton>
                  </Box>
                </>
              )}
              {signupStepNo === 1 && selectedIndex === 0 && (
                <>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    gap={2}
                  >
                    {/* Title */}
                    <Typography className={classes.codeTitle}>
                      Enter the 4 digit code sent to
                    </Typography>

                    {/* TextField */}
                    <TextField
                      id="otpEmail"
                      label="OTP Email"
                      value={otpEmail}
                      onChange={handleOTPEmail}
                      error={!otpEmail || !emailError}
                      helperText={
                        !emailError ? "Please enter a valid Email." : ""
                      }
                    />

                    {/* OTP Input */}
                    <OtpInput
                      value={code}
                      onChange={handleOtpChange}
                      numInputs={4}
                      separator={<span className={classes.otpSeperator}></span>}
                      renderInput={(props, index) => (
                        <input
                          key={index}
                          {...props}
                          disabled={!otpEmail || !emailError}
                        />
                      )}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        color: "#000",
                        width: "56px",
                        height: "56px",
                        fontSize: "16px",
                        fontWeight: "400",
                        caretColor: "blue",
                        borderRadius: "10px",
                        border: "1px solid",
                        borderColor:
                          !otpEmail || !emailError ? "#CFD3DB" : "black",
                        marginRight: 5,
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none",
                      }}
                    />

                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        <Typography variant="body1">Didn't receive</Typography>
                        <Button variant="text">Send again</Button>
                      </Box>
                      <Button
                        variant={"text"}
                        onClick={() => setSignupStepNo(0)}
                      >
                        Go back
                      </Button>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    paddingTop="150px"
                  >
                    <CustomButton
                      name="continueBtn"
                      color="primary"
                      variant="contained"
                      size="small"
                      className={clsx(classes.signupBtn, classes.costumBtn)}
                      onClick={() => {
                        setSideMessage("plan");
                        verifyOtp(otpEmail, code);
                      }}
                      disabled={!otpEmail || !emailError}
                    >
                      Continue
                    </CustomButton>
                  </Box>
                </>
              )}
              {signupStepNo === 2 && selectedIndex === 0 && (
                <Box paddingLeft="16px" paddingRight="16px">
                  <Box display="flex" alignItems="center">
                    <img src={freeTrial} alt="" />
                    <Typography className={classes.freetrial}>
                      Free Trial
                    </Typography>
                  </Box>
                  <Box paddingTop="64px">
                    {freeTrialFeatures.map((feature) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          paddingBottom="40px"
                        >
                          <img src={tickBox} alt="" />
                          <Box paddingLeft="24px">
                            <Typography className={classes.featureTitle}>
                              {feature.title}
                            </Typography>
                            <Typography className={classes.featureSubTitle}>
                              {feature.subTitle}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <CustomButton
                      name="trialBtn"
                      color="primary"
                      variant="contained"
                      size="small"
                      className={clsx(classes.signupBtn, classes.costumBtn)}
                      onClick={() => {
                        setSignupCard(false);
                        setShowOnboarding(true);
                        setSideMessage("onboarding");
                        setRedirectToDetails(true);
                      }}
                    >
                      Start 15 day trial
                    </CustomButton>
                  </Box>
                </Box>
              )}
              {selectedIndex === 1 && (
                <Box>
                  <Typography className={classes.labelText} gutterBottom>
                    Enter Mail ID
                  </Typography>
                  <CustomTextfield
                    id="email"
                    name="email"
                    type="email"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.costumTextField}
                    error={!emailValidation(email)}
                    helperText={
                      !emailValidation(email)
                        ? "Please enter a valid Email."
                        : ""
                    }
                    value={email}
                    onKeyPress={(e) =>
                      e.key === "Enter" && login_with_email(email, password)
                    }
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Typography className={classes.labelText}>
                    Password
                  </Typography>
                  <CustomTextfield
                    id="password"
                    name="password"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    error={!passwordError}
                    helperText={
                      !passwordError ? "Please enter a valid password." : ""
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={classes.costumTextField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputStyle}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <VisibilityIcon fontSize="small" color="disabled" />
                          ) : (
                            <VisibilityOffIcon
                              fontSize="small"
                              color="disabled"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box>
                    <Button
                      color="primary"
                      onClick={() => setForgetPasswordDialog(true)}
                    >
                      Forget password?
                    </Button>
                  </Box>
                  <Box className={classes.btnBox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agreement"
                          color="primary"
                          disableRipple
                          id="agreement"
                          checked={keepMeSignedIn}
                          onChange={() => setKeepMeSignedIn(!keepMeSignedIn)}
                        />
                      }
                      label="Keep me signed in"
                      className={classes.labelCheckbox}
                    />

                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        disabled={
                          email === "" ||
                          password === "" ||
                          !emailValidation(email)
                        }
                        type="submit"
                        onClick={() => login_with_email(email, password)}
                      >
                        Login
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Slide>
    </>
  );
}
export default React.memo(SignupCard);
