import { Box } from "@mui/material";
import RightWaveBG from "images/phase-3/AuthLeftRight/RightWaveBG.svg";

const AuthRightWrap = ({ children }) => {
  return (
    <Box position={"relative"} height={"100%"} width={"100%"}>
      {children}

      <Box
        component={"img"}
        position={"absolute"}
        bottom={0}
        left={0}
        zIndex={-1}
        width={"100%"}
        src={RightWaveBG}
        alt={"waveBG"}
        sx={{ height: { xs: "8em", md: "10em" } }}
      />
    </Box>
  );
};
export default AuthRightWrap;
