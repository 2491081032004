import Tree from "react-d3-tree";
import { Box } from "@mui/material";

// components
import RenderNode from "components/widgets/phase-3/OrgDetailsProfile/OrgStructureComps/RenderNode";
import StructureSearch from "components/widgets/phase-3/OrgDetailsProfile/OrgStructureComps/StructureSearch";

// icons
import greydotsBG from "images/phase-3/orgstructure/greydotsBG.png";
import NodeDrawer from "components/widgets/phase-3/OrgDetailsProfile/OrgStructureComps/NodeDrawer";
import { useState } from "react";

const orgData = {
  name: "Aerofusion Dynamics Global Headquarters",
  location: "Chennai, Tamil Nadu",
  type: "Entity",
  units: 9,
  assets: 12,
  suppliers: 16,
  stakeholders: [
    {
      _id: "65fdsf5df5s5dfa",
      name: "Stakeholder1",
      avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
    },
    {
      _id: "65fdsf5df5s5dfb",
      name: "Stakeholder2",
      avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
    },
    {
      _id: "65fdsf5df5s5dfc",
      name: "Stakeholder3",
      avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
    },
  ],
  children: [
    {
      name: "Bengaluru Corporate Office",
      location: "Bengaluru, Karnataka",
      type: "Entity",
      assets: 5,
      suppliers: 2,
      stakeholders: [
        {
          _id: "65fdsf5df5s5dfa",
          name: "Stakeholder1",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
        },
        {
          _id: "65fdsf5df5s5dfb",
          name: "Stakeholder2",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
        },
        {
          _id: "65fdsf5df5s5dfc",
          name: "Stakeholder3",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
        },
      ],
      children: [
        {
          name: "Bengaluru Corporate Office",
          location: "Bengaluru, Karnataka",
          type: "Unit",
          assets: 5,
          suppliers: 2,
          stakeholders: [
            {
              _id: "65fdsf5df5s5dfa",
              name: "Stakeholder1",
              avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
            },
            {
              _id: "65fdsf5df5s5dfb",
              name: "Stakeholder2",
              avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
            },
            {
              _id: "65fdsf5df5s5dfc",
              name: "Stakeholder3",
              avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
            },
          ],
        },
        {
          name: "Chennai Regional Office",
          location: "Chennai, Tamil Nadu",
          type: "Unit",
          assets: 21,
          suppliers: 16,
          stakeholders: [
            {
              _id: "65fdsf5df5s5dfa",
              name: "Stakeholder1",
              avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
            },
            {
              _id: "65fdsf5df5s5dfb",
              name: "Stakeholder2",
              avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
            },
            {
              _id: "65fdsf5df5s5dfc",
              name: "Stakeholder3",
              avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
            },
          ],
        },
      ],
    },
    {
      name: "Chennai Regional Office",
      location: "Chennai, Tamil Nadu",
      type: "Entity",
      assets: 21,
      suppliers: 16,
      stakeholders: [
        {
          _id: "65fdsf5df5s5dfa",
          name: "Stakeholder1",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
        },
        {
          _id: "65fdsf5df5s5dfb",
          name: "Stakeholder2",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
        },
        {
          _id: "65fdsf5df5s5dfc",
          name: "Stakeholder3",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
        },
      ],
    },
    {
      name: "Hyderabad Research Office",
      location: "Hyderabad, Andhra",
      type: "Entity",
      assets: 9,
      suppliers: 20,
      stakeholders: [
        {
          _id: "65fdsf5df5s5dfa",
          name: "Stakeholder1",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
        },
        {
          _id: "65fdsf5df5s5dfb",
          name: "Stakeholder2",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
        },
        {
          _id: "65fdsf5df5s5dfc",
          name: "Stakeholder3",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
        },
      ],
    },
    {
      name: "Pune Design Office",
      location: "Pune, Maharashtra",
      type: "Unit",
      assets: 19,
      suppliers: 3,
      stakeholders: [
        {
          _id: "65fdsf5df5s5dfa",
          name: "Stakeholder1",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
        },
        {
          _id: "65fdsf5df5s5dfb",
          name: "Stakeholder2",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
        },
        {
          _id: "65fdsf5df5s5dfc",
          name: "Stakeholder3",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
        },
      ],
    },
    {
      name: "Mumbai Sales Office",
      location: "Mumbai, Maharashtra",
      type: "Unit",
      assets: 15,
      suppliers: 6,
      stakeholders: [
        {
          _id: "65fdsf5df5s5dfa",
          name: "Stakeholder1",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
        },
        {
          _id: "65fdsf5df5s5dfb",
          name: "Stakeholder2",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
        },
        {
          _id: "65fdsf5df5s5dfc",
          name: "Stakeholder3",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
        },
      ],
    },
    {
      name: "Delhi Administrative Office",
      location: "Delhi, NCT",
      type: "Unit",
      assets: 22,
      suppliers: 23,
      stakeholders: [
        {
          _id: "65fdsf5df5s5dfa",
          name: "Stakeholder1",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName1" },
        },
        {
          _id: "65fdsf5df5s5dfb",
          name: "Stakeholder2",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName2" },
        },
        {
          _id: "65fdsf5df5s5dfc",
          name: "Stakeholder3",
          avatar: { url: "https://i.pravatar.cc/300", name: "randomName3" },
        },
      ],
    },
  ],
};

const OrgStructure = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  return (
    <Box
      width={"100%"}
      height={"70vh"}
      position={"relative"}
      sx={{
        background: `url(${greydotsBG})`,
        bgcolor: "background.paper",
        backgroundSize: "70em",
      }}
    >
      <StructureSearch orgData={orgData} />

      <Tree
        data={orgData}
        orientation="vertical"
        pathFunc="step"
        renderCustomNodeElement={(rd3tProps) => (
          <RenderNode {...rd3tProps} onNodeClick={toggleDrawer} />
        )}
        nodeSize={{ x: 500, y: 500 }} // Adjusted node size
        translate={{ x: window.innerWidth / 2, y: 100 }}
        zoom={0.7}
      />

      <NodeDrawer open={openDrawer} onClose={toggleDrawer} />
    </Box>
  );
};

export default OrgStructure;
