import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleDismiss = () => {
    this.setState({ hasError: false });
    // console.log(window.location)
    window.location = "/";
    // this.props.history.push('/')
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <div style={styles.messageBox}>
            <h1>Something went wrong.</h1>
            <button onClick={this.handleDismiss} style={styles.button}>
              Dismiss and Reload
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#e0f7fa", // Light blue background
    color: "#00796b", // Darker blue-green text
    fontFamily: "Arial, sans-serif",
  },
  messageBox: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#ffffff", // White background for the message box
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow for subtle depth
  },
  button: {
    marginTop: "10px",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#00796b", // Darker blue-green for the button
    color: "#ffffff", // White text on the button
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.3s", // Smooth transition on hover
  },
};

export default ErrorBoundary;
