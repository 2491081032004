import React from "react";
import { Box, Tabs, Tab, Stack, Button } from "@mui/material";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import {
  basicDataFirstTab,
  basicDataSecondTab,
  esgProfileTab,
  esgRisk,
} from "components/widgets/phase-3/supplier/left/leftSectionData";
import {
  companySetup,
  dataSetup,
  esgDiagnosis,
  esgRiskRight,
} from "components/widgets/phase-3/supplier/right/rightData";
import { useNavigate } from "react-router-dom";
import SupplierLeft from "components/widgets/phase-3/supplier/left/SupplierLeft";
import useTabChanges from "customHooks/useTabChanges";

// components

// Array of tab details
const tabs = [
  {
    label: "Company Setup",
    leftComponent: basicDataFirstTab,
    rightComponent: companySetup,
  },
  {
    label: "Data Setup",
    leftComponent: basicDataSecondTab,
    rightComponent: dataSetup,
  },
  {
    label: "ESG Diagnosis",
    leftComponent: esgProfileTab,
    rightComponent: esgDiagnosis,
  },
  { label: "ESG Risk", leftComponent: esgRisk, rightComponent: esgRiskRight },
];

function SupplierTabs() {
  const navigate = useNavigate();
  const {
    selectedTab,
    selectedSubTab,
    handleChange,
    handleSubTabChange,
    handlePreviousTabChanges,
    handleTabChanges,
  } = useTabChanges(tabs);

  return (
    <Box>
      {/* main tabs */}
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="supplier tabs"
        sx={{ marginBottom: "15px" }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      {/* main body */}
      <MainLayoutSection
        leftWidth="30%"
        left={
          <LeftSectionLayout>
            <SupplierLeft
              component={tabs[selectedTab]?.leftComponent}
              selectedSubTab={selectedSubTab}
              handleSubTabChange={handleSubTabChange}
            />
          </LeftSectionLayout>
        }
        rightWidth="65%"
        right={
          <RightSectionLayout>
            {tabs[selectedTab]?.rightComponent[selectedSubTab]}
          </RightSectionLayout>
        }
      />
      {/* buttons */}
      <Stack direction={"row"} justifyContent={"flex-end"} gap={2} mt={2}>
        <Button
          variant="text"
          disabled={!selectedTab && !selectedSubTab}
          onClick={handlePreviousTabChanges}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={selectedTab > 3 ? navigate("/supplier") : handleTabChanges}
        >
          {selectedTab === 3 ? "Back to Listing Page" : "Proceed"}
        </Button>
      </Stack>
    </Box>
  );
}

export default SupplierTabs;
