import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CustomTextfield from "../../../components/UI/textfield/textfield";
import { emailValidation } from "../../../actions/regexValidation/regexValidation";

export default function LoginForgetPasswordDialog({
  forgetPassword,
  forgetPasswordDialog,
  setForgetPasswordDialog,
}) {
  const [email, setEmail] = useState("");

  return (
    <Dialog
      open={forgetPasswordDialog}
      onClose={() => setForgetPasswordDialog(false)}
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle>
        Reset Password
        <Typography variant="body2">
          Please enter your email address to reset your password. We will send a
          reset link to your email address.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CustomTextfield
          variant="outlined"
          placeholder="Enter your Email"
          fullWidth
          onKeyPress={(e) =>
            e.key === "Enter" && forgetPassword(email, window.location.hostname)
          }
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            forgetPassword(email, window.location.hostname);
            setForgetPasswordDialog(false);
            setEmail("");
          }}
          disabled={!emailValidation(email)}
        >
          reset password
        </Button>
      </DialogActions>
    </Dialog>
  );
}
