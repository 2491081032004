import { useMemo } from "react";
import { Box, Button, Grid } from "@mui/material";
import Datepicker from "components/UI/phase-3/datePicker/Datepicker";
import DynamicDrawer from "components/UI/phase-3/Drawer/DynamicDrawer";
import Heading from "components/UI/phase-3/heading/Heading";
import InputField from "components/UI/phase-3/inputField/InputField";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import NoListItem from "components/widgets/phase-3/MaterialityMapping/selectToppic/left/component/NoListItem";

import { usePaginationSearch } from "customHooks/usePaginationSearch";
import useFormValidation from "customHooks/useFormValidation";
import { useEsgStrategyPlanningTopic } from "../../APIs/HandleTopicApi";
import DefineAgainstLists from "./DefineAgainstLists";
function DatPolicies() {
  const { openDrawer, toggleDrawer } = usePaginationSearch();

  const initialState = useMemo(
    () => ({
      title: "",
      policy_overview: "",
      validity: "",
    }),
    []
  );

  const validationRules = useMemo(
    () => ({
      title: { required: true },
      policy_overview: { required: true },
      validity: { required: true },
    }),
    []
  );

  const { formData, errors, handleChange, validationErrors, setFormData } =
    useFormValidation(initialState, validationRules);

  const {
    handleUpdateEsgActivity,
    handleAddEsgActivity,
    esgPolicies,
    handleDeleteEsgActivity,
  } = useEsgStrategyPlanningTopic(false, null, "POLICY");

  const handleSubmit = async () => {
    const response = formData?.policy_id
      ? await handleUpdateEsgActivity(formData)
      : await handleAddEsgActivity(formData);
    if (response?.status || false) {
      toggleDrawer();
      setFormData(initialState);
    }
  };
  return (
    <>
      {/* Drawer */}
      <DynamicDrawer
        hasDrawerOpen={openDrawer}
        handleClose={() => {
          toggleDrawer();
          setFormData(initialState);
        }}
        heading={formData?.policy_id ? "Update Policy" : "Add Policy"}
        buttonText={formData?.policy_id ? "Update Policy" : "Add Policy"}
        disableSubmit={Object.keys(validationErrors).length > 0}
        submitClick={handleSubmit}
      >
        <InputField
          value={formData?.title}
          error={errors.title}
          name={"title"}
          handleChange={handleChange}
          heading={"Policy Name"}
          label={"Enter here"}
          marginBottom={"6px"}
          marginTop={5}
        />

        <InputField
          value={formData?.policy_overview}
          error={errors.policy_overview}
          name={"policy_overview"}
          handleChange={handleChange}
          heading={"Policy Overview"}
          label={"Enter here"}
          marginBottom={"6px"}
          rows={6}
        />

        <Datepicker
          value={formData?.validity}
          error={errors.validity}
          name={"validity"}
          handleChange={handleChange}
          heading={"Validity of the policy"}
          label={"Select Dates"}
          marginBottom={"6px"}
        />
      </DynamicDrawer>
      {/* Heading */}
      <Heading text="Policies Details" marginBottom="0">
        <Button
          variant={"outlined"}
          color={"secondary"}
          startIcon={<AddOutlinedIcon />}
          size={"small"}
          onClick={toggleDrawer}
        >
          Add Policy
        </Button>
      </Heading>
      {/* main content */}
      <>
        <DefineAgainstLists
          list={esgPolicies}
          handleUpdate={setFormData}
          openDrawer={toggleDrawer}
          handleDelete={handleDeleteEsgActivity}
        />
      </>

      {/* no data compoenent */}

      {esgPolicies?.length === 0 && <NoListItem message="No Policy Added" />}
    </>
  );
}

export default DatPolicies;
