import { Stack, Typography } from "@mui/material";

const SignupLoginHead = ({ head, desc }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h1" fontWeight={"600"}>
        {head}
      </Typography>
      <Typography variant="body1" color={"action.active"}>
        {desc}
      </Typography>
    </Stack>
  );
};
export default SignupLoginHead;
