import { useEffect } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";

const MainGoalsForm = ({ setHasChanges, validationData, handleFormError }) => {
  const { formData, handleChange, hasErrors } = validationData;

  const disableSubmit =
    !formData.goals || formData.goals.length < 1 || hasErrors;

  const handleDataChange = (e) => {
    const { name, checked } = e.target;
    const updatedGoals = checked
      ? [...formData.goals, name]
      : formData.goals.filter((goal) => goal !== name);

    handleChange({
      target: {
        name: "goals",
        value: updatedGoals,
      },
    });

    setHasChanges(true);
  };

  useEffect(() => {
    handleFormError(disableSubmit);
  }, [disableSubmit, handleFormError]);

  const goalsList = [
    "Understand and improve our ESG practices",
    "Find out which ESG issues matter most to us",
    "Check and improve our supplier’s ESG efforts",
    "Create a clear plan for our ESG goals",
    "Track and reduce our carbon emissions",
    "Make sure we meet all ESG rules and standards",
  ];

  return (
    <Box display={"flex"} flexWrap={"wrap"} gap={2}>
      {goalsList.map((goal) => (
        <FormControlLabel
          key={goal}
          control={
            <Checkbox
              checked={formData.goals?.includes(goal)}
              onChange={handleDataChange}
              name={goal}
            />
          }
          label={goal}
        />
      ))}
    </Box>
  );
};

export default MainGoalsForm;
