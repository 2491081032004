import { useEffect } from "react";
import { Box, Stack, TextField, Typography, Autocomplete } from "@mui/material";
import requiredField from "components/UI/phase-3/inputField/requiredShow";
import { useDispatch, useSelector } from "react-redux";
import {
  getIndustryArray,
  getSectorArray,
} from "actions/phase-3/common/commonAction";
import { getParentEntity } from "actions/phase-3/orgDetails/orgDetailsAction";

const CompanyInfoForm = ({
  setHasChanges,
  validationData,
  handleFormError,
}) => {
  const dispatch = useDispatch();
  const { parent_id } = useSelector((state) => state.user);
  const { sector, industry } = useSelector((state) => state.common);

  const { formData, errors, handleChange, setFormData, hasErrors } =
    validationData;

  const disableSubmit =
    !formData.company_name ||
    !formData.sector_id ||
    !formData.industry_id ||
    !formData.isin ||
    hasErrors;

  const handleFormData = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleDataChange = (e) => {
    handleChange(e);
    setHasChanges(true);
  };

  const handleSelectSector = (sectorId) => {
    handleFormData("sector_id", sectorId || null);
    if (sectorId) {
      dispatch(getIndustryArray(sectorId));
    } else {
      handleFormData("industry_id", null); // Clear industry if sector is deselected
    }
    setHasChanges(true);
  };

  useEffect(() => {
    handleFormError(disableSubmit);
  }, [disableSubmit, handleFormError]);

  useEffect(() => {
    if (!sector) {
      dispatch(getSectorArray());
    }
    if (parent_id) {
      dispatch(getParentEntity(parent_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2}>
      {/* Company Name */}
      <Box>
        <Typography variant="body1" color="action.disabled" gutterBottom>
          Company Name{" "}
          {requiredField(
            Boolean(errors.company_name) || !formData.company_name
          )}
        </Typography>
        <TextField
          name="company_name"
          placeholder="Company Name"
          value={formData.company_name}
          onChange={handleDataChange}
          error={Boolean(errors.company_name)}
          helperText={errors.company_name}
          required
          fullWidth
        />
      </Box>

      {/* Sector */}
      <Box>
        <Typography variant="body1" color="action.disabled" gutterBottom>
          Sector{" "}
          {requiredField(Boolean(errors.sector_id) || !formData.sector_id)}
        </Typography>
        <Autocomplete
          options={sector || []}
          getOptionLabel={(option) => option.Sector}
          value={
            sector?.find((item) => item._id === formData.sector_id) || null
          } // Find the selected sector by _id
          onChange={(event, newValue) =>
            handleSelectSector(newValue?._id || null)
          } // Set the sector_id in formData
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Sector"
              error={Boolean(errors.sector_id)}
              helperText={errors.sector_id}
              required
            />
          )}
          fullWidth
        />
      </Box>

      {/* Industry */}
      <Box>
        <Typography variant="body1" color="action.disabled" gutterBottom>
          Industry{" "}
          {requiredField(Boolean(errors.industry_id) || !formData.industry_id)}
        </Typography>
        <Autocomplete
          options={industry || []}
          getOptionLabel={(option) => option.Industry}
          value={
            industry?.find((item) => item._id === formData.industry_id) || null
          } // Find the selected industry by _id
          onChange={(event, newValue) =>
            handleFormData("industry_id", newValue?._id || null)
          } // Set the industry_id in formData
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Industry"
              error={Boolean(errors.industry_id)}
              helperText={errors.industry_id}
              required
            />
          )}
          fullWidth
          disabled={!formData.sector_id} // Disable if sector is not selected
        />
      </Box>

      {/* ISIN */}
      <Box>
        <Typography variant="body1" color="action.disabled" gutterBottom>
          ISIN {requiredField(Boolean(errors.isin) || !formData.isin)}
        </Typography>
        <TextField
          name="isin"
          placeholder="Enter ISIN"
          value={formData.isin}
          onChange={handleChange}
          error={Boolean(errors.isin)}
          helperText={errors.isin}
          required
          fullWidth
        />
      </Box>
    </Stack>
  );
};

export default CompanyInfoForm;
