import { set_loader, unset_loader } from "../../actions/loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import encrypt from "../shared/sharedActions";
import {
  SET_ASSESSMENT_NAME,
  SET_YEAR_OF_ASSESSMENT,
  SET_SUSTAINIBILITY_TEAM_MESSAGE,
  SET_ESG_GOAL,
  SET_ABOUT_REPORT,
  SET_DOCUMENT,
  SET_DEFAULT,
  SET_COMPANY_NAME,
  SET_COMPANY_LOGO,
  SET_COMPANY_LOGO_URI,
  SET_SECTORS,
  SET_COMPANY_DESCRIPTION,
  SET_NUMBER_OF_EMPLOYEE,
  SET_COMPANY_EMAIL,
  SET_COMPANY_WEBSITE,
  SET_COMPANY_ADDRESS,
  SET_COMPANY_LANDMARK,
  SET_COMPANY_PINCODE,
  SET_COMPANY_STATE,
  SET_COMPANY_COUNTRY,
  SET_BRANCH_ADDRESS_BASIC,
  SET_BRANCH_LANDMARK,
  SET_BRANCH_PINCODE,
  SET_BRANCH_STATE,
  SET_BRANCH_COUNTRY,
  SET_BRANCH_ADDRESS_ARRAY,
  UNSET_BRANCH_ADDRESS,
  SET_ASSESSMENT_DETAILS,
  SET_MASTER_SECTOR,
  SET_FRAMEWORKS,
  SET_FRAMEWORK_ID,
  SET_TEMPLATE_TYPE,
  SET_BASIC_INFO_RIGHT_TAB,
  SET_BASIC_INFO_TAB,
  SET_REPORTING_REGION,
  SET_ALL_COUNTRIES,
  SET_NEW_YEAR_OF_ASSESSMENT,
} from "../../constants/basicinfoConst/basicinfoConst";
import { viewFilteredFrameworks } from "../frameworks/frameworkAction";
import { diff } from "deep-object-diff";
import axios from "utils/axios/axios";
export const setAssessmentName = (payload) => ({
  type: SET_ASSESSMENT_NAME,
  payload: payload,
});

export const setYearOfAssessment = (payload) => ({
  type: SET_YEAR_OF_ASSESSMENT,
  payload: payload,
});
export const setNewYearOfAssessment = (payload) => ({
  type: SET_NEW_YEAR_OF_ASSESSMENT,
  payload: payload,
});

export const setSustainibilityTeamMessage = (payload) => ({
  type: SET_SUSTAINIBILITY_TEAM_MESSAGE,
  payload: payload,
});

export const setEsgGoal = (payload) => ({
  type: SET_ESG_GOAL,
  payload: payload,
});

export const setAboutReport = (payload) => ({
  type: SET_ABOUT_REPORT,
  payload: payload,
});

export const setBasicInfoRightTab = (payload) => ({
  type: SET_BASIC_INFO_RIGHT_TAB,
  payload: payload,
});

export const setBasicInfoTab = (payload) => ({
  type: SET_BASIC_INFO_TAB,
  payload: payload,
});

export const setDocument = (payload) => ({
  type: SET_DOCUMENT,
  payload: payload,
});

export const setDefault = (payload) => ({
  type: SET_DEFAULT,
  payload: payload,
});

export const setCompanyName = (payload) => ({
  type: SET_COMPANY_NAME,
  payload: payload,
});

export const setCompanyLogo = (payload) => ({
  type: SET_COMPANY_LOGO,
  payload: payload,
});

export const setCompanyLogoURI = (payload) => ({
  type: SET_COMPANY_LOGO_URI,
  payload: payload,
});

export const setSectors = (payload) => ({
  type: SET_SECTORS,
  payload: payload,
});

export const setCompanyDescription = (payload) => ({
  type: SET_COMPANY_DESCRIPTION,
  payload: payload,
});

export const setNumberOfEmployee = (payload) => ({
  type: SET_NUMBER_OF_EMPLOYEE,
  payload: payload,
});

export const setCompanyEmail = (payload) => ({
  type: SET_COMPANY_EMAIL,
  payload: payload,
});

export const setCompanyWebsite = (payload) => ({
  type: SET_COMPANY_WEBSITE,
  payload: payload,
});

export const setCompanyAddress = (payload) => ({
  type: SET_COMPANY_ADDRESS,
  payload: payload,
});

export const setCompanyLandmark = (payload) => ({
  type: SET_COMPANY_LANDMARK,
  payload: payload,
});

export const setCompanyPincode = (payload) => ({
  type: SET_COMPANY_PINCODE,
  payload: payload,
});

export const setCompanyState = (payload) => ({
  type: SET_COMPANY_STATE,
  payload: payload,
});

export const setCompanyCountry = (payload) => ({
  type: SET_COMPANY_COUNTRY,
  payload: payload,
});

export const setBranchAddress = (payload) => {
  return {
    type: SET_BRANCH_ADDRESS_BASIC,
    payload: payload,
  };
};

export const setBranchLandmark = (payload) => ({
  type: SET_BRANCH_LANDMARK,
  payload: payload,
});

export const setBranchPincode = (payload) => ({
  type: SET_BRANCH_PINCODE,
  payload: payload,
});

export const setBranchState = (payload) => ({
  type: SET_BRANCH_STATE,
  payload: payload,
});

export const setBranchCountry = (payload) => ({
  type: SET_BRANCH_COUNTRY,
  payload: payload,
});

export const setBranchAddressArray = (payload) => ({
  type: SET_BRANCH_ADDRESS_ARRAY,
  payload: payload,
});

export const unsetBranchAddress = (payload) => ({
  type: UNSET_BRANCH_ADDRESS,
  payload: payload,
});

export const setAssessmentDetails = (payload) => ({
  type: SET_ASSESSMENT_DETAILS,
  payload: payload,
});

export const setFrameworks = (payload) => ({
  type: SET_FRAMEWORKS,
  payload: payload,
});

export const setFrameworkId = (payload) => ({
  type: SET_FRAMEWORK_ID,
  payload: payload,
});

export const setTemplateType = (payload) => ({
  type: SET_TEMPLATE_TYPE,
  payload: payload,
});

export const setReportingRegion = (payload) => ({
  type: SET_REPORTING_REGION,
  payload,
});

export const setMasterSector = (payload) => {
  payload.map((sector, index) => {
    return sector.industry_data.map((industry, index2) => {
      return (payload[index].industry_data[index2].isSelected = false);
    });
  });

  return {
    type: SET_MASTER_SECTOR,
    payload: payload,
  };
};

export const getAssessmentDetails = (token, assessment_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      organisation_id: localStorage.getItem("current_organisation"),
    });

    return fetch(UNIVERSAL.BASEURL + "/assessments/get_assessment_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.result) {
          // dispatch(getAllCountries(token));
          dispatch(setAssessmentDetails(responseJson.result));
          responseJson.result.country_id &&
            dispatch(
              viewFilteredFrameworks(
                token,
                responseJson.result.country_id,
                localStorage.getItem("current_organisation")
              )
            );
          dispatch(
            getSectorsByFramework(token, responseJson.result?.framework_id)
          );
          // dispatch(setMasterSector(responseJson.result.sector_data));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
export const getSectorsByFramework = (token, framework_id) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      framework_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/assessments/get_sector_industry", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setMasterSector(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const getFrameworkDetails = (token) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/framework/view_frameworks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setFrameworks(responseJson.result));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const updateBasicAssessmentDetails = (
  token,
  assessment_id,
  basicInfo,
  isSave
) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      assessment_id,
      framework_id: basicInfo.frameworkId,
      assessment_name: basicInfo.assessmentName,
      year_of_assessment: basicInfo.yearOfAssessment,
      new_year_of_assessment: basicInfo.newYearOfAssessment || "",
      reporting_geography: basicInfo.reportingRegion,
      esg_goal: basicInfo.esgGoal,
      about_report: basicInfo.aboutReport,
      sustainability_team_message: basicInfo.sustainibilityTeamMessage,
    });

    const checkDiff = Object.keys(
      diff(
        {
          framework_id: basicInfo.frameworkId,
          assessment_name: basicInfo.assessmentName,
          year_of_assessment: basicInfo.yearOfAssessment,
          new_year_of_assessment: basicInfo.newYearOfAssessment,
          reporting_geography: basicInfo.reportingRegion,
          esg_goal: basicInfo.esgGoal,
          about_report: basicInfo.aboutReport,
          sustainability_team_message: basicInfo.sustainibilityTeamMessage,
        },
        {
          framework_id: basicInfo.objectDiff.frameworkId,
          assessment_name: basicInfo.objectDiff.assessmentName,
          year_of_assessment: basicInfo.objectDiff.yearOfAssessment,
          new_year_of_assessment: basicInfo.objectDiff.newYearOfAssessment,
          reporting_geography: basicInfo.objectDiff.reportingRegion,
          esg_goal: basicInfo.objectDiff.esgGoal,
          about_report: basicInfo.objectDiff.aboutReport,
          sustainability_team_message:
            basicInfo.objectDiff.sustainibilityTeamMessage,
        }
      )
    ).length;

    if (checkDiff === 0) {
      dispatch(unset_loader());
    } else {
      return fetch(
        UNIVERSAL.BASEURL + "/assessments/update_assessment_details",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: data }),
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          } else {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          dispatch(unset_loader());
        });
    }
  };
};

export const updateBasicCompanyDetails = (token, assessment_id, basicInfo) => {
  return (dispatch) => {
    dispatch(set_loader());

    var sectorData = [],
      sectorData2 = [];

    basicInfo.masterSectors.map((sector, index) => {
      sectorData.push({
        sector: sector._id,
        industry: [],
      });

      sector.industry_data.map((industry, index2) => {
        if (industry.isSelected) {
          sectorData[index].industry.push(industry._id);
        }
        return true;
      });
      return true;
    });

    sectorData.map((sector, index) => {
      if (sector.industry.length > 0) {
        sectorData2.push(sector);
      }
      return true;
    });

    // const checkDiff = Object.keys(
    // 	diff(
    // 		{
    // 			comapny_name: basicInfo.companyName,
    // 			company_operation: basicInfo.companyDescription,
    // 			employee_count: basicInfo.numberOfEmployees,
    // 			email: basicInfo.companyEmail,
    // 			website: basicInfo.companyWebsite,
    // 			head_office: {
    // 				companyAddress: basicInfo.companyAddress,
    // 				companyLandmark: basicInfo.companyLandmark,
    // 				companyPincode: basicInfo.companyPincode,
    // 				companyState: basicInfo.companyState,
    // 				companyCountry: basicInfo.companyCountry,
    // 			},
    // 			branch_office: basicInfo.branchAddressArray,
    // 			sector: basicInfo.sectors,
    // 			framework_id: basicInfo.frameworkId,
    // 			organisation_id: localStorage.getItem("current_organisation"),
    // 			logo: basicInfo.companyLogo,
    // 		},
    // 		{
    // 			comapny_name: basicInfo.objectDiff.companyName,
    // 			company_operation: basicInfo.objectDiff.companyDescription,
    // 			employee_count: basicInfo.objectDiff.numberOfEmployees,
    // 			email: basicInfo.objectDiff.companyEmail,
    // 			website: basicInfo.objectDiff.companyWebsite,
    // 			head_office: {
    // 				companyAddress: basicInfo.objectDiff.companyAddress,
    // 				companyLandmark: basicInfo.objectDiff.companyLandmark,
    // 				companyPincode: basicInfo.objectDiff.companyPincode,
    // 				companyState: basicInfo.objectDiff.companyState,
    // 				companyCountry: basicInfo.objectDiff.companyCountry,
    // 			},
    // 			branch_office: basicInfo.objectDiff.branchAddressArray,
    // 			sector: sectorData2,
    // 			framework_id: basicInfo.objectDiff.frameworkId,
    // 			organisation_id: localStorage.getItem("current_organisation"),
    // 			logo: "",
    // 		}
    // 	)
    // ).length;

    var formData = new FormData();

    var data = encrypt({
      "user-token": token,
      assessment_id: assessment_id,
      comapny_name: basicInfo.companyName,
      company_operation: basicInfo.companyDescription,
      employee_count: basicInfo.numberOfEmployees,
      email: basicInfo.companyEmail,
      website: basicInfo.companyWebsite,
      head_office: {
        companyAddress: basicInfo.companyAddress,
        companyLandmark: basicInfo.companyLandmark,
        companyPincode: basicInfo.companyPincode,
        companyState: basicInfo.companyState,
        companyCountry: basicInfo.companyCountry,
      },
      branch_office: basicInfo.branchAddressArray,
      sector: sectorData2,
      framework_id: basicInfo.frameworkId,
      organisation_id: localStorage.getItem("current_organisation"),
    });

    formData.append("data", data);
    formData.append("logo", basicInfo.companyLogo);

    // if (checkDiff === 0) {
    // 	dispatch(unset_loader());
    // } else {
    return fetch(UNIVERSAL.BASEURL + "/assessments/update_company_details", {
      method: "POST",

      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCompanyLogo(""));
          dispatch(setSectors(sectorData2));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
// };
export const setAllCountries = (payload) => ({
  type: SET_ALL_COUNTRIES,
  payload,
});

export const getAllCountries = (token) => {
  const encData = encrypt({
    "user-token": token,
  });
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/frameworks/get_all_countries");
      console.log(data, !data?.status);

      if (!data?.status) {
        throw Error(data?.message);
      }

      dispatch(set_snack_bar(data.status, data.message));
      dispatch(setAllCountries(data.result));
    } catch (error) {
      console.log(error);
      dispatch(set_snack_bar(false, error.message));
    } finally {
      dispatch(unset_loader());
    }

    // return fetch(UNIVERSAL.BASEURL + "/frameworks/get_all_countries", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ data: data }),
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     if (responseJson.status) {
    //       dispatch(set_snack_bar(responseJson.status, responseJson.message));
    //       dispatch(setAllCountries(responseJson.result));
    //     } else {
    //       dispatch(set_snack_bar(responseJson.status, responseJson.message));
    //     }
    //     dispatch(unset_loader());
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
};

// ----------------- set preferred framework -----------------
export const setFrameworkPreference = (assessment_id, framework_preference) => {
  return (dispatch, getState) => {
    const { token } = getState().login;

    const data = encrypt({
      "user-token": token,
      assessment_id,
      framework_preference,
    });
    return fetch(UNIVERSAL.BASEURL + "/framework/set_framework_preference", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
