import CompanyDetails from "components/widgets/phase-3/supplier/right/companySetup/CompanyDetails";
import StakeholderList from "./stakeHolderList/StakeHolderList";
import ApiConnections from "./apiConnection/ApiConnection";
import EsgKpi from "./esgkpi/EsgKpi";
import EsgRiskRating from "./riskRating/EsgRiskRating";
import EsgProfile from "./esgProfile/EsgProfile";
import Controversies from "./Controversies/Controversies";
import Documents from "./Documents/DocumentsMain";
import SurveyQuestions from "./Survey/SurveyQuestion";
import { NoDataComponent } from "../../esgMaturity/shareSurvey/NoData";

const companySetup = {
  0: <CompanyDetails />,
  1: <StakeholderList />,
};

const dataSetup = {
  0: <ApiConnections />,
  1: <Documents />,
  2: <SurveyQuestions />,
};

const esgDiagnosis = {
  0: <EsgProfile />,
  1: <EsgKpi />,
  2: <Controversies />,
  3: <NoDataComponent mainTitle={"No Response"} />,
};

const esgRiskRight = {
  0: <EsgRiskRating />,
};

export { companySetup, dataSetup, esgDiagnosis, esgRiskRight };
