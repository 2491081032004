import { Box, IconButton, Button, Stack, Pagination } from "@mui/material";

import SearchTextField from "components/UI/textfield/searchTextField";
import EntryDrawer from "components/widgets/phase-3/OrgDetailsProfile/EntryComps/EntryDrawer";

import { FiUpload, FiDownload, FiPlus } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import DynamicTableFull from "components/UI/phase-3/dynamicTable/DynamicTableFull";

// redux
import { useEntityListAPIs } from "./APIs/EntryListApis";
import { usePaginationSearch } from "customHooks/usePaginationSearch";

const entityListHead = [
  { name: "Entity Name" },
  { name: "ISIN No." },
  { name: "Location" },
  { name: "Entity Type" },
  { name: "Handling Stakeholder" },
  { name: "View" },
];

const tableRowOrder = [
  "entity_name",
  "isin",
  "location.city_state",
  "entity_type",
  "created_by.name",
];

const customAction = (row) => (
  <IconButton
    aria-label="CustomAction"
    size="small"
    sx={{ color: "primary.main" }}
  >
    <IoIosArrowForward />
  </IconButton>
);

const EntryList = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const {
    // states & data
    entity_list,
    fetchEntityList,
  } = useEntityListAPIs(keyword, limit, page);

  return (
    <Box position={"relative"} height={"65vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={2} mb={2} justifyContent="flex-end">
        <SearchTextField searchClick={handleSearch} sx={{ minWidth: "30em" }} />
        <Button variant="text" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button variant="text" startIcon={<FiUpload />}>
          Upload Sheet
        </Button>
        <Button
          variant="contained"
          startIcon={<FiPlus />}
          onClick={toggleDrawer}
        >
          Add Entry
        </Button>
      </Stack>

      {/* Entry List Table */}
      <DynamicTableFull
        tableHead={entityListHead}
        tableData={entity_list?.entity_list}
        tableRowOrder={tableRowOrder}
        renderAction={customAction}
      />

      <Box position={"absolute"} right={2} bottom={0}>
        <Pagination
          count={Math.ceil(entity_list?.total_count / limit)}
          variant="outlined"
          page={page}
          onChange={handlePageChange}
        />
      </Box>

      {/* Drawer */}
      <EntryDrawer
        open={openDrawer}
        onClose={toggleDrawer}
        fetchEntityList={fetchEntityList}
      />
    </Box>
  );
};

export default EntryList;
