import makeStyles from "@mui/styles/makeStyles";

const userSettingStyles = makeStyles((theme) => ({
  actionButton: {
    visibility: "hidden",
  },
  container: {
    "&:hover": {
      "& $actionButton": {
        visibility: "visible",
      },
    },
  },
  userContent: {
    padding: 90,
    paddingTop: 50,
    // paddingBottom: 10,
    height: "90vh",

    [theme.breakpoints.down("md")]: {
      padding: 40,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 8,
    },
  },
  Typography: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  Typography1: { margin: "50px 10px 50px 10px" },
  Typography2: { fontWeight: "600", fontSize: 26 },
  Typography3: { fontWeight: "600", fontSize: 22 },
  CustomButton: { paddingLeft: 50, paddingRight: 50 },
  grid: { marginLeft: 30 },
  grid1: { borderBottom: "1px solid #DADBE6", paddingBottom: 5 },
  grid2: { marginTop: 15 },
  grid3: { paddingLeft: 75 },
  grid4: { paddingRight: 75 },
  grid5: { marginTop: 20 },
  grid6: { display: "flex", justifyContent: "flex-end" },
  grid7: { paddingRight: 10 },
  grid8: { display: "flex", alignItems: "center" },
  grid9: { marginTop: 50 },
  CustomTextfield: { width: "100%" },
  Avatar: { width: 50, height: 50 },
  Card1: { border: "none" },
}));

export default userSettingStyles;
