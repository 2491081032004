import { useEffect, useRef, useState } from "react";
// icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import {
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    fontSize: 12,
    marginBottom: 6,
    backgroundColor: theme.palette.background.paper,
    "& .Mui-selected": {
      backgroundColor: "#EBF1F8", // Change this to the desired color
      borderRadius: "8px",
      marginBottom: "5px",
      marginTop: "5px",
    },
    "& .Mui-selected:hover": {
      backgroundColor: "#EBF1F8", // Change this to the desired color on selected hover
      borderRadius: "8px",
    },
    "& :hover": {
      backgroundColor: "#EBF1F8", // Change this to the desired color on selected hover
      borderRadius: "8px",
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    "& .Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
  selected: {
    backgroundColor: "red", // Change this to the desired color
  },
}));

const sectionData = [
  {
    _id: "663a2a2498facc12099dc53e",
    default: false,
    order: 1,
    title: "Section 1",
  },
  {
    _id: "663a2a2498facc12099dc53f",
    default: false,
    order: 1,
    title: "Section 2",
  },
  {
    _id: "663a2a2498facc12099dc53g",
    default: false,
    order: 1,
    title: "Section 3",
  },
  {
    _id: "663a2a2498facc12099dc53h",
    default: false,
    order: 1,
    title: "Section 4",
  },
];

const SurveyQuestionsLeft = ({ selectedSection, setSelectedSection }) => {
  const classes = useStyles();
  const [editPopper, setEditPopper] = useState(null);
  const [OpenDrawer, setOpenDrawer] = useState(false);
  const [sectionValue, setSectionValue] = useState({});
  const [editName, setEditName] = useState(null);
  const [title, setTitle] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (!editName && inputRef.current) {
      // Focus on the input element when showInput is true
      inputRef.current.focus();
    }
  }, [editName]);

  // ---------- Inner Functions ----------
  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };

  function handleClick(e) {
    setEditPopper(e.currentTarget);
  }

  function handleEditName(id) {
    setEditName(id);
    setEditPopper(null);
  }

  function handleCloseEdit() {
    setEditPopper(null);
  }

  const handleDrawer = (value) => {
    setOpenDrawer(value);
    setSectionValue({});
    setEditPopper(null);
  };

  // -------------------------------------

  return (
    <Grid
      item
      container
      md={2}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "scroll",
        position: "relative",
      }}
    >
      {/* List Mapping */}
      <List
        disablePadding
        classes={{
          root: classes.root,
        }}
        component="nav"
        aria-label="main mailbox folders"
        style={{ position: "relative" }}
      >
        {sectionData?.map((section) => (
          <ListItem
            key={section?._id}
            button
            selected={section?._id === selectedSection?._id}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
            onClick={() => handleSelectSection(section)}
          >
            {editName === section?._id ? (
              <FormControlLabel
                control={
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-multiline-static"
                    label="Rename"
                    defaultValue={section?.title}
                    variant="outlined"
                    // onKeyDown={handleKeyPress}
                    // onChange={(e) => setTitle(e.target.value)}
                    // onBlur={() => setEditName(null)}
                    // ref={inputRef}
                  />
                }
                // label="Make Section Mandatory"
              />
            ) : (
              <ListItemText
                primary={
                  <Typography
                    component="div"
                    variant="body1"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // width: "85%",
                      wordWrap: "break-word",
                    }}
                  >
                    {section?.title}

                    {!section?.default &&
                      selectedSection?._id === section?._id && (
                        <IconButton
                          edge="end"
                          aria-label="more"
                          disableRipple
                          // disabled={selectedSection?._id !== section?._id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClick(e);
                          }}
                        >
                          <MoreVertOutlinedIcon style={{ fontSize: "16px" }} />
                        </IconButton>
                      )}
                  </Typography>
                }
                primaryTypographyProps={{
                  style: { width: "100px", whiteSpace: "pre-wrap" },
                }}
              />
            )}
          </ListItem>
        ))}

        {/* Add Section Button */}
        {/* {selectedSection && !selectedSection?.default && ( */}
        {/* {!selectedSurveyData?.default && (
          <CustomButton
            style={{
              textTransform: "none",
              marginRight: "auto",
              marginLeft: "auto",
              width: "100%",
              borderRadius: "8px",
            }}
            size="small"
            color="primary"
            variant="text"
            onClick={handleCreateSection}
          >
            <AddIcon fontSize={"10px"} />
            <Typography>Add Section</Typography>
          </CustomButton>
        )} */}

        {/* {editPopper && (
          <EditPopperMenu
            anchorEl={editPopper}
            handleClose={handleCloseEdit}
            handleClick={handleDrawer}
            handleEditName={(id) => handleEditName(id)}
            handleRemoveSection={handleRemoveSection}
            sectionId={selectedSection?._id}
          />
        )} */}
        {/* {OpenDrawer && (
          <LeftSectionDrawer
            OpenDrawer={OpenDrawer}
            handleDrawer={handleDrawer}
            setSectionValue={setSectionValue}
            sectionValue={sectionValue}
            selectedSection={selectedSection}
            surveyId={selectedSurveyData?._id}
          />
        )} */}
      </List>
    </Grid>
  );
};
export default SurveyQuestionsLeft;
