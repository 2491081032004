import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_ASSIGNED_STAKEHOLDERS,
  SET_MATURITY_SURVEY,
  SET_STAKEHOLDERS,
  SET_MATURITY_SURVEY_ID,
} from "constants/phase-3/esgMaturity/esgMaturityConst";

// ----------------- Payloads -----------------
export function setAssignedStakeholders(payload) {
  return {
    type: SET_ASSIGNED_STAKEHOLDERS,
    payload: payload,
  };
}

export function setStakeholders(payload) {
  return {
    type: SET_STAKEHOLDERS,
    payload: payload,
  };
}

export function setMaturitySurvey(payload) {
  return {
    type: SET_MATURITY_SURVEY,
    payload: payload,
  };
}

export function setMaturitySurveyID(payload) {
  return {
    type: SET_MATURITY_SURVEY_ID,
    payload: payload,
  };
}

// ----------------- Actions -----------------
export function getAssignedStakeholder(entity_id) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/survey/get_assigned_stakeholder",
      bodyData: {
        entity_id,
      },
      callback: (data) => {
        dispatch(setAssignedStakeholders(data?.result));
      },
    });
  };
}

export function getStakeholders(parent_id, entity_id, unit_id, keyword) {
  return async (dispatch) => {
    let body = {};

    if (parent_id === entity_id) {
      body["parent_id"] = parent_id;
    } else if (entity_id) {
      body["entity_id"] = entity_id;
    } else {
      body["unit_id"] = unit_id;
    }

    apiBolerplate({
      dispatch,
      url: "/survey/get_stakeholders",
      bodyData: {
        ...body,
        keyword,
      },
      callback: (data) => {
        dispatch(setStakeholders(data?.result?.stakeholder_list));
      },
    });
  };
}

export function assignMaturitySurvey(
  stakeholder_ids,
  survey_id,
  parent_id,
  entity_id,
  fetchAssignedStakeholders,
  toggleDrawer
) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/assign_stakeholder/assign_maturity_survey",
      bodyData: { stakeholder_ids, survey_id, parent_id, entity_id },
      callback: (data) => {
        // final actions
        if (Boolean(fetchAssignedStakeholders)) {
          fetchAssignedStakeholders();
        }
        if (Boolean(toggleDrawer)) {
          toggleDrawer();
        }
      },
    });
  };
}

export function getMaturitySurvey() {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/maturity_matrix/get_survey_questions",
      method: "get",
      callback: (data) => {
        // final actions
        const questions = data?.result;
        dispatch(setMaturitySurvey(questions));
        dispatch(
          setMaturitySurveyID({
            name: questions[0]?.name,
            survey_id: questions[0]?.survey_id,
          })
        );
      },
    });
  };
}
