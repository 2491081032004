import { useNavigate } from "react-router-dom";

import Heading from "components/UI/phase-3/heading/Heading";
import { Box, Button, Chip, TableCell, TableRow } from "@mui/material";
import PlusOneOutlined from "@mui/icons-material/Add";
import { NoDataComponent } from "components/widgets/phase-3/esgMaturity/shareSurvey/NoData";

function SupplierListing() {
  const navigate = useNavigate();
  return (
    <>
      <Heading text="">
        <Button
          variant="contained"
          size="medium"
          startIcon={<PlusOneOutlined />}
          onClick={() => navigate("/supplier/New_Assessment")}
        >
          Create Supplier
        </Button>
      </Heading>
      <NoDataComponent mainTitle={"No Supplier Created"} />
    </>
  );
}

export default SupplierListing;
