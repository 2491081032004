import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const stakeholdersList = [
  {
    name: "Satyam Parmar",
    role: "Software applications engineer",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Nidhi Jain",
    role: "Database Report writer",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Abdul VishaQ",
    role: "Network architect",
    surveyStatus: "Survey Expired",
    employeeType: "Internal",
  },
  {
    name: "Rashmi Kadam",
    role: "Cloud system engineer",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Naveen Roy",
    role: "Data modeling analyst",
    surveyStatus: "Inprogress",
    employeeType: "Internal",
  },
  {
    name: "Rutvi Pujara",
    role: "Front-end developer",
    surveyStatus: "Survey Expired",
    employeeType: "External",
  },
  {
    name: "Aaron Louis",
    role: "Project manager",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Abinash",
    role: "Database administrator",
    surveyStatus: "Completed",
    employeeType: "Internal",
  },
  {
    name: "Manjusha Praveen",
    role: "Data warehouse programming",
    surveyStatus: "Inprogress",
    employeeType: "Internal",
  },
  {
    name: "Krishanu Mahanta",
    role: "Application security",
    surveyStatus: "Completed",
    employeeType: "External",
  },
  {
    name: "Purnema",
    role: "Program manager",
    surveyStatus: "Completed",
    employeeType: "External",
  },
  {
    name: "Nilam Ghatage",
    role: "Network administrator",
    surveyStatus: "Inprogress",
    employeeType: "External",
  },
];

const useStyle = makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
  },
  tableHeaderTitle: {
    color: "rgb(21, 49, 78)",
  },
  tableHeaderDorpdown: {
    display: "flex",
    alignItems: "center",
    gridGap: 10,
  },
  table: {
    minWidth: "650px",
  },
  tableHead: {
    backgroundColor: "#EBF1F8",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  },
  severityStyle: {
    padding: "2px",
    textAlign: "center",
    borderRadius: 100,
  },
}));

export default function Stakeholders() {
  const classes = useStyle();

  function severity(value, type) {
    if (type === "text") {
      return value === "Completed"
        ? "#154328"
        : value === "In progress"
          ? "#8C7038"
          : "#676767";
    }

    if (type === "bg") {
      return value === "Completed"
        ? "#BFE2CD"
        : value === "Inprogress"
          ? "#FFEFD0"
          : "#E9E9E9";
    }
  }

  return (
    <Box sx={{ margin: "10px 0px" }}>
      <TableContainer component="paper">
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="left" width={200}></TableCell>
              <TableCell align="left" width={200}></TableCell>

              <TableCell align="left" width={200}>
                Survey Status
              </TableCell>

              <TableCell align="center">Employee Type</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {stakeholdersList?.map((data, index) => (
              <TableRow
                key={data?._id}
                className={classes.tableRow}
                onClick={() => console.log(data.id)}
              >
                <TableCell align="left">{data.name}</TableCell>
                <TableCell align="left">{data.role}</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>

                <TableCell align="center">
                  <Typography
                    variant="body2"
                    style={{
                      color: severity(data.surveyStatus, "text"),
                      backgroundColor: severity(data.surveyStatus, "bg"),
                    }}
                    className={classes.severityStyle}
                  >
                    {data.surveyStatus || "In Progress"}
                  </Typography>
                </TableCell>

                <TableCell align="center">{data.employeeType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
