import { Stack, Avatar, Typography } from "@mui/material";

function Messages() {
  return (
    <>
      <Stack direction={"row"} gap={3}>
        <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/300" />
        <Stack gap={1}>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Typography variant="h2">Shanya Natrajan</Typography>
            <Typography variant="subtitle2" color="#878787">
              Yesterday
            </Typography>
          </Stack>
          <Typography variant="body2">
            Hey avantika malik can you explain the last 3rd part of consumption
            based on the Pinned output
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}

export default Messages;
