import { Box, Tab, Tabs } from "@mui/material";
import SurveyBasicDetails from "components/widgets/phase-3/survey/surveyBasicDetails/SurveyBasicDetails";
import SurveyQuestions from "components/widgets/phase-3/survey/surveyQuestions/SurveyQuestions";
import SurveyResponses from "components/widgets/phase-3/survey/surveyResponses/SurveyResponses";
import SurveySettings from "components/widgets/phase-3/survey/surveySettings/SurveySettings";
import React, { useState } from "react";

const tabs = [
  { label: "Basic Details", component: <SurveyBasicDetails /> },
  { label: "Questions", component: <SurveyQuestions /> },
  { label: "Responses", component: <SurveyResponses /> },
  { label: "Settings", component: <SurveySettings /> },
];

function SurveyTabs() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="define-against-topic"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Box mt={1}>{tabs[selectedTab].component}</Box>
    </Box>
  );
}

export default SurveyTabs;
