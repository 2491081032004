import { Box } from "@mui/material";

function PinnedMessageMain() {
  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
      gap={2}
      //   p={2}
    ></Box>
  );
}

export default PinnedMessageMain;
