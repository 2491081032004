import {
  SET_TASK_MATURITY_QUES,
  SET_TASKS_LIST,
} from "constants/phase-3/task/maturity/maturityConst";

const initial_state = {
  tasks_list: [],
  task_maturity_ques: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_TASKS_LIST:
      return (state = {
        ...state,
        tasks_list: action.payload,
      });

    case SET_TASK_MATURITY_QUES:
      return (state = {
        ...state,
        task_maturity_ques: action.payload,
      });

    default:
      return state;
  }
}

// ------------------- Important Key Points -------------------
