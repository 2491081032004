import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { DialogContent, MenuItem, Select } from "@mui/material";
import { TextField } from "@mui/material";
import useDebounce from "../../../../../customHooks/useDebouncec";
import { CustomDataInput } from "./SelectChartType";
import CloseIcon from "@mui/icons-material/Close";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const [value, setValue] = React.useState({
    cutout: 0,
    rotation: 0,
    circumference: 360,
  });
  // console.log("🚀 ~ file: barChartCustomization.js:36 ~ SimpleDialog ~ value", value)

  const handleChange = (e) => {
    setValue((preValue) => ({
      ...preValue,
      [e.target.name]: Number(e.target.value),
    }));
  };

  useDebounce(
    () => {
      props.setPieChartCustomize(value);
    },
    2000,
    [value]
  );

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: 18 }}>
            {" "}
            Customize Pie Chart
          </Typography>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          padding: 25,
          justifyContent: "space-between",
        }}
      >
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Pie Cutout"
            variant="outlined"
            name="cutout"
            value={value.cutout}
            onChange={handleChange}
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Pie Rotation"
            variant="outlined"
            name="rotation"
            value={value.rotation}
            onChange={handleChange}
          />
        </form>

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Pie Circumference"
            variant="outlined"
            name="circumference"
            value={value.circumference}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function PieChartCustomization({ setPieChartCustomize }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Customize Pie Chart
      </Button> */}
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        input={<CustomDataInput />}
        value={"Edit Pie Chart"}
        defaultValue={"Edit Pie Chart"}
        style={{ width: "100%" }}
      >
        <MenuItem value="Edit Pie Chart" onClick={handleClickOpen}>
          Edit Pie Chart
        </MenuItem>
      </Select>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setPieChartCustomize={setPieChartCustomize}
      />
    </div>
  );
}
