import React from "react";
import * as PropTypes from "prop-types";
import MemberDropdown from "./MemberDropdown";
import RemoveButtonGroup from "./RemoveButtonGroup";
import MemberGroupTitle from "./MemberGroupTitle";
import { Typography } from "@mui/material";
// import PlusIcon from './plus-icon.svg';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
const MemberGroup = ({
  members,
  availableMembers,
  addMemberName,
  updateMethods,
  title,
  pivotConfig,
}) => {
  return (
    <div>
      {/* {console.log('members',members,availableMembers)} */}
      {/* <MemberGroupTitle title={title} /> */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* {members.length === 0 && ( */}
        <MemberDropdown
          style={{ width: "100%" }}
          disabled={members.length !== 0}
          type={"new"}
          onClick={(m) => {
            members.length === 0 && updateMethods.add(m);
          }}
          availableMembers={availableMembers}
        >
          <AddIcon style={{ cursor: "pointer" }} /> {addMemberName}
        </MemberDropdown>

        {/* )} */}
      </div>
      <div>
        {members.length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px 10px",
              backgroundColor: "#F9F9F9",
              borderRadius: "8px",
              width: "93%",
              marginLeft: "8px",
            }}
          >
            <Typography>{`Selected ${
              members[members.length - 1].title
            }`}</Typography>
            <RemoveCircleOutlineIcon
              style={{ cursor: "pointer" }}
              onClick={() => updateMethods.remove(members[0])}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px 10px",
              backgroundColor: "#F9F9F9",
              borderRadius: "8px",
              width: "93%",
              marginLeft: "8px",
            }}
          >
            {" "}
            <Typography
              style={{ marginLeft: "10px" }}
            >{`Selected ${title}`}</Typography>
          </div>
        )}

        {/* {members[members.length>0 && members.length-1].map((m) => (
        // <RemoveButtonGroup
        //   key={m.index || m.name}
        //   onRemoveClick={() => updateMethods.remove(m)}
        // >
        //   <MemberDropdown
        //     type="selected"
        //     availableMembers={availableMembers}
        //     onClick={(updateWith) => updateMethods.update(m, updateWith)}
        //   >
        //     {m.title}
        //   </MemberDropdown>
        // </RemoveButtonGroup>
      ))} */}
      </div>
    </div>
  );
};

MemberGroup.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
};

export default MemberGroup;
