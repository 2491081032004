import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CustomButton from "./../../UI/button/button";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    right: "1%",
    top: "25%",
  },
});

const DataHubSurveyDatePicker = (props) => {
  const classes = useStyles();

  const { handleDatePickerClose, handleDates, isDatePickerDialogOpen } = props;

  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={isDatePickerDialogOpen}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogContent>
          <Typography>
            <DateRange
              onChange={(item) => {
                setState([item.selection]);
                handleDates([item.selection]);
              }}
              ranges={state}
              months={2}
              direction="horizontal"
            />
          </Typography>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            variant="outlined"
            color="primary"
            onClick={() => {
              handleDatePickerClose(false);
            }}
          >
            Close
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            style={{ padding: "6px 20px" }}
            onClick={() => handleDatePickerClose(false)}
          >
            Set Period
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DataHubSurveyDatePicker);
