import { useEffect } from "react";
import { Box, Stack, TextField, Typography, Autocomplete } from "@mui/material";
import requiredField from "components/UI/phase-3/inputField/requiredShow";

const ESGRoleForm = ({ setHasChanges, validationData, handleFormError }) => {
  const { formData, errors, setFormData, hasErrors } = validationData;

  const disableSubmit = !formData.work || !formData.category || hasErrors;

  const works_list = [
    "Sustainability Strategy",
    "ESG Reporting and Compliance",
    "Risk Management and Mitigation",
    "Stakeholder Engagement",
    "Supply Chain Management",
    "Environmental Impact Assessment",
    "Corporate Governance",
    "Sustainable Finance",
  ];
  const category_list = [
    "Board of Directors",
    "Executive Team",
    "Employees",
    "Investors & Shareholders",
    "Suppliers & Vendors",
    "Consultants",
    "Customers",
    "Government Agencies",
  ];

  const handleFormData = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setHasChanges(true);
  };

  useEffect(() => {
    handleFormError(disableSubmit);
  }, [disableSubmit, handleFormError]);

  return (
    <Stack spacing={2}>
      {/* Work */}
      <Box>
        <Typography variant="body1" color="action.disabled" gutterBottom>
          What kind of work do you do?{" "}
          {requiredField(Boolean(errors.work) || !formData.work)}
        </Typography>
        <Autocomplete
          options={works_list}
          value={formData.work}
          onChange={(event, newValue) => handleFormData("work", newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.work)}
              helperText={errors.work}
              required
            />
          )}
          fullWidth
        />
      </Box>

      {/* Role */}
      <Box>
        <Typography variant="body1" color="action.disabled" gutterBottom>
          Select User Role in ESG{" "}
          {requiredField(Boolean(errors.category) || !formData.category)}
        </Typography>
        <Autocomplete
          options={category_list}
          value={formData.category}
          onChange={(event, newValue) => handleFormData("category", newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.category)}
              helperText={errors.category}
              required
            />
          )}
          fullWidth
        />
      </Box>
    </Stack>
  );
};

export default ESGRoleForm;
