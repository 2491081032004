import { Box } from "@mui/material";
import EsgScoreLeft from "./EsgScoreLeft";
import EsgScoreRight from "./EsgScoreRight";

function EsgScore() {
  return (
    <Box style={{ display: "flex", gap: "30px" }}>
      <EsgScoreLeft />
      <EsgScoreRight />
    </Box>
  );
}

export default EsgScore;
