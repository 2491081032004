import { getUnitsList } from "actions/phase-3/orgDetails/orgDetailsAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useUnitsAPIs = (
  keyword = "",
  limit = 5,
  page = 0,
  hideLoader = false,
  hideMsg = false
) => {
  const { units_list } = useSelector((state) => state.orgDetails);
  const { parent_id } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const fetchUnits = () => {
    dispatch(
      getUnitsList(
        parent_id,
        keyword,
        limit,
        (page - 1) * limit,
        hideLoader,
        hideMsg
      )
    );
  };

  useEffect(() => {
    fetchUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  return {
    // states & data
    units_list,
    // funcs
    fetchUnits,
  };
};
