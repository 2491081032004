import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

import InfoIcon from "@mui/icons-material/Info";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LoopIcon from "@mui/icons-material/Loop";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";
import Checkbox from "@mui/material/Checkbox";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import CustomTextfield from "../../UI/textfield/textfield";
import CustomButton from "./../../UI/button/button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import clsx from "clsx";
import { emailValidation } from "../../../actions/regexValidation/regexValidation";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import materialityAssessmentLeftStyles from "./materialityAssessmentLeftStyles";
import {
  Social,
  Human,
  Business,
  Environmental,
  Governance,
  RatingFive,
  RatingFour,
  RatingThree,
  RatingTwo,
  RatingOne,
} from "../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import {
  stakeHolderCategoryList,
  topicRelevanceData,
} from "../../../constants/materialityConst/materialityConst";
const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const theme = createTheme(
  adaptV4Theme({
    palette: {
      secondary: {
        main: "#47BB76",
      },
      // [theme.breakpoints.down('lg')]: {
      //   width: '83%',
      //   marginTop: '8px'
      // }
    },
    topicname: {
      fontFamily: ["poppins", "sans-serif"].join(","),
    },
    selectTopicText: {
      color: "#9FA1AF",
      fontSize: 13,
      padding: "10px 0px",
      fontFamily: ["poppins", "sans-serif"].join(","),
    },
    containerSelectTopicText: {
      marginBottom: 7,
    },
    emptyTopicBar: {
      width: "100%",
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#DADBE655",
      borderRadius: 10,
    },
    relavanceAvatar: {
      width: 35,
      height: 35,
      color: "#000",
      backgroundColor: "#fff",
      border: "1px solid #DADBE6",
      fontSize: 16,
      lineHeight: "24px",
      fontFamily: "Poppins",
    },
    stakholderTitle: {
      fontWeight: "600",
      marginLeft: 15,
      fontFamily: "Poppins",
      fontSize: 16,
      lineHeight: "24px",
      textTransform: "capitalize",
    },
  })
);

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <Box>{children}</Box>}</div>;
}

function checkVote(vote) {
  switch (vote) {
    case 1:
      return <RatingOne />;
    case 2:
      return <RatingTwo />;
    case 3:
      return <RatingThree />;
    case 4:
      return <RatingFour />;
    case 5:
      return <RatingFive />;
    default:
      return;
  }
}

export const TopicBar = (props) => {
  const classes = materialityAssessmentLeftStyles();

  const [currentIndex, setCurrentIndex] = useState("");
  const [isInfo, setIsInfo] = useState(false);
  const { topic, source, index, materialityAssessment, handleAddTopic } = props;
  // let typeOfTopic = materialityAssessment?.masterTopics.filter(
  //   (main) => main._id + "" === topic.parent_id + ""
  // );
  const typeOfTopic = [{ topic_name: "Social Capital", text: "first topic" }];
  return (
    <>
      <div
        className={classes.topicBarContainer}
        onMouseLeave={() => {
          setIsInfo(false);
        }}
        onMouseEnter={() => {
          setCurrentIndex(index);
        }}
        style={{ backgroundColor: source === "right" && "#EBF1F8" }}
      >
        <div className={classes.topicBarContents}>
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Social Capital" && (
              <Social style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Environment" && (
              <Environmental style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Leadership & Governance" && (
              <Governance style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Human Capital" && (
              <Human style={{ fontSize: 46 }} />
            )}
          {typeOfTopic &&
            typeOfTopic.length > 0 &&
            typeOfTopic[0].topic_name === "Business Model & Innovation" && (
              <Business style={{ fontSize: 46 }} />
            )}
          <div
            className={clsx(!isInfo && classes.topicContentDivider, {
              [classes.topicContentDividerInfo]: isInfo,
            })}
          ></div>
          <div style={{ maxWidth: 500 }}>
            <Typography className={classes.topicname}>{topic?.text}</Typography>
            {index === currentIndex && isInfo && (
              <>
                {topic?.topic_info !== "" ? (
                  <Typography className={classes.selectTopicText}>
                    Note: {topic?.topic_info}
                  </Typography>
                ) : (
                  <Typography className={classes.selectTopicText}>
                    Topic info not available
                  </Typography>
                )}
              </>
            )}
          </div>
        </div>
        <div className={classes.mainDiv}>
          {source !== "right" && (
            <>
              <div>
                <IconButton
                  name="expandTopic"
                  className={classes.iconContainer}
                  onClick={() => {
                    setIsInfo(!isInfo);
                  }}
                  size="large"
                >
                  <InfoIcon className={classes.iconColor} />
                </IconButton>
              </div>
              <div>
                <IconButton
                  name="addTopic"
                  className={clsx(classes.iconContainer, classes.iconColor)}
                  onClick={() => handleAddTopic(topic)}
                  size="large"
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
            </>
          )}
          {source === "right" && (
            <div>
              <IconButton
                name="addTopic"
                className={clsx(classes.iconContainer, classes.iconColor)}
                onClick={() => handleAddTopic(topic)}
                size="large"
              >
                <RemoveCircleRoundedIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const RelavanceBar = (props) => {
  const classes = materialityAssessmentLeftStyles();

  const { item } = props;
  return (
    <>
      <div
        className={classes.relavanceBarContainer}
        style={{ cursor: "default" }}
      >
        <div className={classes.topicBarContents}>
          <Avatar className={classes.relavanceAvatar}>
            {item?.holding_position}
          </Avatar>
          <div className={classes.topicContentDivider}></div>
          <Typography className={classes.Typography1}>
            {item?.topic_name}
          </Typography>
        </div>

        <div className={classes.mainDiv}>
          {item?.vote_data?.average > 0 && (
            <IconButton className={classes.iconColor} size="large">
              {checkVote(item.vote_data.average)}
            </IconButton>
          )}

          <IconButton
            name="removeTopics"
            className={classes.iconColor}
            size="large"
          >
            <RemoveCircleRoundedIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default function MaterialityAssessmentLeft(props) {
  const {
    materialityAssessment,
    generalSettings,
    login,
    assessment_id,
    upsertAssessmentTopic,
    addCustomTopic,
    getSelectedTopic,
    setTopicTab,
    getTopicData,
    addStakeholder,
    set_snack_bar,
    viewAllUsers,
    selectAllTopics,
    getTopics,
    editStakeholder,
    addUser,
    setTopicFilter,
    getMaterialityMapData,
    deleteAssessmentTopic,
    uploadUserSheet,
  } = props;

  const classes = materialityAssessmentLeftStyles();

  const [currentIndex, setCurrentIndex] = useState("");
  const [isInfo, setIsInfo] = useState(false);
  const [isCustomTopicDialogOpen, setIsCustomTopicDialogOpen] = useState(false);
  const [isTopicSelected, setIsTopicSelected] = useState(false);
  const [filteredTopic, setFilteredTopic] = useState("");
  const [customTopicId, setCustomTopicId] = useState("");
  const [customTopicName, setCustomTopicName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [designation, setDesignation] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [addNewUserDialogOpen, setAddNewUserDialogOpen] = useState(false);
  const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false);
  const [currentTopic, setCurrentTopic] = useState("");
  const [filter, setFilter] = useState(
    materialityAssessment?.topicFilter === "RECOMMENDED"
      ? "Recommended"
      : "All Topics"
  );

  const [analysisSort, setAnalysisSort] = useState("High-Low");
  const [currentTopicHoldingPosition, setCurrentTopicHoldingPosition] =
    useState("");

  const [checkedUser, setCheckedUSer] = useState([]);
  const [stakeholderContent, setStakeholderContent] = useState({
    subject: "",
    content: `At ${
      login.selectedOrganization && login.selectedOrganization.company_name
    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`,
  });

  const [limit, setLimit] = useState(10);

  // checkbox
  const [checkedState, setCheckedState] = useState(
    new Array(generalSettings.allUsers.length).fill(false)
  );

  // share dialog state
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  //to share survey button

  const [showSurveyBtn, setShowSurveyBtn] = useState(false);

  const handleChange = (e, position, userData) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    if (e.target.checked) {
      setCheckedUSer((preValue) => [...preValue, userData]);
    }
    if (!e.target.checked) {
      let remvoeSelectUser = checkedUser.filter((user) =>
        user._id === userData._id ? false : true
      );
      setCheckedUSer(remvoeSelectUser);
    }
    setCheckedState(updatedCheckedState);
  };

  const stakeHolderContentChange = (e) => {
    const { name, value, defaultValue } = e.target;
    setStakeholderContent((prev) => {
      return { ...prev, [name]: value };
    });
    if (stakeholderContent.content === "") {
      setStakeholderContent((prev) => {
        return { ...prev, [name]: defaultValue };
      });
    }
  };
  const step_no = 2;

  useEffect(() => {
    viewAllUsers(login.token, login.current_organisation, "");
    setTopicTab(0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkedUser.length === 0) {
      setShowSurveyBtn(true);
    } else {
      setShowSurveyBtn(false);
    }
  }, [checkedUser]);

  useEffect(() => {
    getTopics(
      login.token,
      assessment_id,
      limit,
      0,
      materialityAssessment.topicFilter
    );
  }, [limit, materialityAssessment.topicFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let filteredData = undefined;
    if (
      Array.isArray(materialityAssessment.topicData) &&
      materialityAssessment.topicData.length !== 0
    ) {
      filteredData = materialityAssessment.topicData.filter(
        (topic) =>
          !materialityAssessment.selectedTopicsData.find(
            (seleted) => seleted.topic_id === topic._id
          )
      );
      setFilteredTopic(filteredData);
    }
    if (
      Array.isArray(materialityAssessment.topicData) &&
      materialityAssessment.topicData.length === 0
    ) {
      setFilteredTopic([]);
    }
  }, [
    materialityAssessment.topicData,
    materialityAssessment.selectedTopicsData,
  ]);

  const RelavanceBar = (props) => {
    const { item } = props;
    return (
      <>
        <div
          className={classes.relavanceBarContainer}
          style={{ cursor: "default" }}
        >
          <div
            className={classes.topicBarContents}
            onClick={() => handleRelevanceClick(item)}
          >
            <Avatar className={classes.relavanceAvatar}>
              {item.holding_position}
            </Avatar>
            <div className={classes.topicContentDivider}></div>
            <Typography className={classes.Typography1}>
              {item.topic_name}
            </Typography>
          </div>

          <div className={classes.mainDiv}>
            {item.vote_data.average > 0 && (
              <IconButton className={classes.iconColor} size="large">
                {checkVote(item.vote_data.average)}
              </IconButton>
            )}

            <IconButton
              name="removeTopics"
              onClick={() =>
                deleteAssessmentTopic(
                  login.token,
                  item.assessment_topic_id,
                  assessment_id,
                  10,
                  0,
                  materialityAssessment.topicFilter,
                  item.topic_name
                )
              }
              className={classes.iconColor}
              size="large"
            >
              <RemoveCircleRoundedIcon />
            </IconButton>
          </div>
        </div>
      </>
    );
  };

  const RiskOpportunity = (props) => {
    const { text, userData } = props;

    return (
      userData && (
        <div className={clsx(classes.topicBarContents, classes.mainDiv1)}>
          {!userData.profile_img ? (
            <Avatar className={classes.relavanceAvatar}>
              {userData.user_name.charAt(0)}
            </Avatar>
          ) : (
            <Avatar
              className={classes.relavanceAvatar}
              src={userData.profile_img.url}
            ></Avatar>
          )}
          <div className={classes.riskOpprotunityInfoDivider}></div>
          <Typography className={classes.Typography2}>{text}</Typography>
        </div>
      )
    );
  };

  const Attachments = (props) => {
    const { fileName } = props;
    return (
      <>
        <Box width="70%" className={classes.box1}>
          <AttachFileIcon className={classes.AttachFileIcon} color="disabled" />
          <div className={classes.mainDiv2}>{fileName}</div>
          <input
            className={clsx(classes.inputCheck, classes.input)}
            type="file"
          />
        </Box>
      </>
    );
  };

  const handleCloseCustomTopicDialog = () => {
    setIsCustomTopicDialogOpen(false);
  };

  const customTopic = () => {
    return (
      <Dialog open={isCustomTopicDialogOpen}>
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={6}>
              <Typography className={classes.Typography3}>
                Custom topic
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mx={1}>
                <CustomTextfield
                  name="topicTitle"
                  label="Description Title"
                  variant="outlined"
                  size="small"
                  value={customTopicName}
                  onChange={(e) => setCustomTopicName(e.target.value)}
                  helperText="hint : location of operation, Diversity or Emission control Etc.."
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mx={1}>
                <Typography className={classes.Typography4}>
                  Category related to the topic
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xss={12}>
              {materialityAssessment.masterTopics.map((topic, index) => {
                return (
                  <React.Fragment key={index}>
                    {topic.topic_name === "Social Capital" && (
                      <Box m={1}>
                        <Chip
                          className={
                            topic._id !== customTopicId
                              ? classes.topicNameChipSocial
                              : classes.selectedTopicNameChipSocial
                          }
                          icon={
                            <FiberManualRecordIcon
                              className={classes.circleIcon}
                            />
                          }
                          label={topic.topic_name}
                          onClick={() => setCustomTopicId(topic._id)}
                        />
                      </Box>
                    )}
                    {topic.topic_name === "Human Capital" && (
                      <Box m={1}>
                        <Chip
                          className={
                            topic._id !== customTopicId
                              ? classes.topicNameChipHuman
                              : classes.selectedTopicNameChipHuman
                          }
                          icon={
                            <FiberManualRecordIcon
                              className={classes.circleIcon}
                            />
                          }
                          label={topic.topic_name}
                          onClick={() => setCustomTopicId(topic._id)}
                        />
                      </Box>
                    )}
                    {topic.topic_name === "Environment" && (
                      <Box m={1}>
                        <Chip
                          className={
                            topic._id !== customTopicId
                              ? classes.topicNameChipEnvironement
                              : classes.selectedTopicNameChipEnvironement
                          }
                          icon={
                            <FiberManualRecordIcon
                              className={classes.circleIcon}
                            />
                          }
                          label={topic.topic_name}
                          onClick={() => setCustomTopicId(topic._id)}
                        />
                      </Box>
                    )}
                    {topic.topic_name === "Leadership & Governance" && (
                      <Box m={1}>
                        <Chip
                          className={
                            topic._id !== customTopicId
                              ? classes.topicNameChipGovernance
                              : classes.selectedTopicNameChipGovernance
                          }
                          icon={
                            <FiberManualRecordIcon
                              className={classes.circleIcon}
                            />
                          }
                          label={topic.topic_name}
                          onClick={() => setCustomTopicId(topic._id)}
                        />
                      </Box>
                    )}
                    {topic.topic_name === "Business Model & Innovation" && (
                      <Box m={1}>
                        <Chip
                          className={
                            topic._id !== customTopicId
                              ? classes.topicNameChipBusiness
                              : classes.selectedTopicNameChipBusiness
                          }
                          icon={
                            <FiberManualRecordIcon
                              className={classes.circleIcon}
                            />
                          }
                          label={topic.topic_name}
                          onClick={() => setCustomTopicId(topic._id)}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid item xs={3} className={classes.grid1}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={handleCloseCustomTopicDialog}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5}>
              <CustomButton
                color="primary"
                variant="contained"
                disabled={customTopicName === "" || customTopicId === ""}
                onClick={() => {
                  addCustomTopic(
                    login.token,
                    assessment_id,
                    customTopicName,
                    customTopicId
                  );
                  handleCloseCustomTopicDialog();
                }}
              >
                Add Topic
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  const handleTabChange = (event, newValue) => {
    setTopicTab(newValue);
  };

  const handleCloseAddNewDialog = () => {
    setAddNewUserDialogOpen(false);
  };

  const handleCloseUpdateDialog = () => {
    setUpdateUserDialogOpen(false);
  };

  const handleAddTopic = (topic) => {
    upsertAssessmentTopic(
      login.token,
      assessment_id,
      topic._id,
      true,
      "",
      "",
      "",
      "",
      topic.text,
      {
        relevance: "",
        weightage: "",
        additional_params: {
          risk: "",
          opportunity: null,
        },
        file: "",
      },
      props.basicInfo?.frameworkId
    );
  };

  const handleRelevanceClick = (item) => {
    getTopicData(login.token, item.assessment_topic_id);
    setIsTopicSelected(true);
    setCurrentTopicHoldingPosition(item.holding_position);
    setCurrentTopic(item);
  };

  const checkTopicType = (topicType) => {
    if (topicType) {
      switch (topicType.topic_name) {
        case "Environment":
          return <Environmental style={{ fontSize: 46 }} />;
        case "Social Capital":
          return <Social style={{ fontSize: 46 }} />;
        case "Leadership & Governance":
          return <Governance style={{ fontSize: 46 }} />;
        case "Business Model & Innovation":
          return <Business style={{ fontSize: 46 }} />;
        case "Human Capital":
          return <Human style={{ fontSize: 46 }} />;

        default:
          break;
      }
    }
  };

  const currentUserId = "";

  const handleEditStakeholder = () => {
    editStakeholder(
      login.token,
      assessment_id,
      currentUserId,
      designation,
      organisation,
      category,
      type,
      step_no
    );
    setUpdateUserDialogOpen(false);
  };

  const handleAddStakeholder = () => {
    if (emailValidation(checkedUser[0].email)) {
      addStakeholder(
        login.token,
        assessment_id,
        login.current_organisation,
        checkedUser,
        stakeholderContent.subject,
        stakeholderContent.content,
        step_no
      );
    } else {
      set_snack_bar(true, "Please enter valid email...");
    }

    setCheckedUSer([]);
    setShareDialogOpen(false);
  };

  const handleFilterChange = (value) => {
    setFilter(value);
    value === "All Topics" && setTopicFilter("ALL_TOPICS");
    value === "Recommended" && setTopicFilter(value.toUpperCase());
  };

  const handleAddUser = () => {
    if (emailValidation(email)) {
      addUser(
        login.token,
        login.current_organisation,
        name,
        email,
        designation,
        organisation,
        category,
        type
      );
    } else {
      set_snack_bar(true, "Please enter valid email...");
    }

    setName("");
    setEmail("");
    setDesignation("");
    setType("");
    setOrganisation("");
    setCategory("");
  };

  const uploadHandler = (e) => {
    const file = e.target.files[0];
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an excel file");
    } else {
      uploadUserSheet(login.token, login.current_organisation, file);
      setAddNewUserDialogOpen(false);
    }
  };

  const addNewUserDialog = () => {
    return (
      <Dialog open={addNewUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Add New</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            className={classes.grid2}
          >
            <Grid item md={12} className={classes.grid3}>
              <a
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQKbeO_-PkJKIXrH3qHW4ePLL-xxKtRkB8VwfSRlkcSbjrfjiYY6VSxWjQjQOunfGSptRXHTOfqfUxW/pub?output=xlsx"
                download
              >
                <CustomButton
                  name="downloadTemp"
                  startIcon={<GetAppRoundedIcon />}
                  variant="outlined"
                  color="primary"
                  className={classes.CustomButton1}
                >
                  download template
                </CustomButton>
              </a>
              <>
                <CustomButton
                  name="uploadTemp"
                  startIcon={<PublishRoundedIcon />}
                  className={classes.CustomButton2}
                  variant="outlined"
                  color="primary"
                  component="label"
                >
                  upload
                  <input
                    id="excel-upload"
                    type="file"
                    hidden
                    cursor="pointer"
                    onChange={(e) => uploadHandler(e)}
                  />
                </CustomButton>
              </>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userName"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userRole"
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userEmail"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userOrg"
                label="Organization"
                variant="outlined"
                size="small"
                fullWidth
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                name="userCategory"
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                name="userPosition"
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                name="cancelBtn"
                color="primary"
                variant="outlined"
                onClick={handleCloseAddNewDialog}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                name="addNewBtn"
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                disabled={
                  name === "" ||
                  email === "" ||
                  category === "" ||
                  type === "" ||
                  designation === "" ||
                  organisation === ""
                }
                onClick={() => {
                  handleCloseAddNewDialog();
                  handleAddUser();
                }}
              >
                Add New User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  // update user dialog
  const updateUserDialog = () => {
    return (
      <Dialog open={updateUserDialogOpen} maxWidth="md">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>Edit User</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={classes.grid2}>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                label="Role"
                variant="outlined"
                size="small"
                fullWidth
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                label="Organization"
                variant="outlined"
                size="small"
                fullWidth
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12} md={6} className={classes.grid4}>
              <CustomTextfield
                label="Category"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {stakeHolderCategoryList.map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid5}>
              <CustomTextfield
                label="Type"
                variant="outlined"
                size="small"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["Internal", "External"].map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </CustomTextfield>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={handleCloseUpdateDialog}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={handleEditStakeholder}
                disabled={
                  email === "" ||
                  category === "" ||
                  type === "" ||
                  designation === "" ||
                  organisation === ""
                }
              >
                Update User
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  // share survey dialog
  const handleShareDialogOpen = () => {
    setShareDialogOpen(true);
  };

  const shareDialog = () => {
    return (
      <Dialog open={shareDialogOpen} fullWidth maxWidth="sm">
        <DialogTitle>
          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Grid item xs={3}>
              <Typography className={classes.Typography3}>
                Share List
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
            style={{}}
          >
            <Grid item md={12} className={classes.grid8}>
              {checkedUser.length > 0 ? (
                checkedUser.map((user, index) => (
                  <Chip
                    className={classes.sectorChip}
                    key={index}
                    label={user.email}
                  />
                ))
              ) : (
                <Chip className={classes.sectorChip} label="Non Selected" />
              )}
            </Grid>
            {true && (
              <>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    label="Email Subject"
                    name="subject"
                    fullWidth
                    size="small"
                    variant="outlined"
                    style={{ marginTop: 10 }}
                    onChange={stakeHolderContentChange}
                    helperText="Hint : survey ESG requires contribution"
                  />
                </Grid>
                <Grid item className={classes.grid8}>
                  <Typography className={classes.Typography5}>
                    Content
                  </Typography>
                </Grid>
                <Grid item className={classes.grid8}>
                  <CustomTextfield
                    name="content"
                    multiline
                    onChange={(e) => {
                      stakeHolderContentChange(e);
                    }}
                    className={classes.CustomTextfield}
                    defaultValue={`At ${
                      login.selectedOrganization &&
                      login.selectedOrganization.company_name
                    } we are proud to have you as one of our key stakeholder. As part of our sustainability reporting process, We are reaching out to gather your views as to the key sustainability topics we should report on.`}
                    fullWidth
                    variant="outlined"
                    inputProps={{ className: classes.shareContentText }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} className={classes.grid7}>
            <Grid item xs={3} md={6} className={classes.grid3}>
              <CustomButton
                color="primary"
                variant="outlined"
                onClick={() => setShareDialogOpen(false)}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={5} md={6} className={classes.grid6}>
              <CustomButton
                color="primary"
                variant="contained"
                className={classes.CustomButton3}
                onClick={() => {
                  handleAddStakeholder();
                  setCheckedState(
                    new Array(generalSettings.allUsers.length).fill(false)
                  );
                }}
              >
                Share
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {shareDialogOpen && shareDialog()}
      {isCustomTopicDialogOpen && customTopic()}
      {addNewUserDialogOpen && addNewUserDialog()}
      {updateUserDialogOpen && updateUserDialog()}
      <Card
        variant="elevation"
        id="drawer-container"
        elevation={1}
        className={classes.cardContainer}
        data-tut="reactour__materialLeft"
      >
        <CardContent className={classes.CardContent}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AppBar
                position="static"
                color="inherit"
                className={classes.AppBar}
              >
                <Tabs
                  value={materialityAssessment.topicTab}
                  onChange={handleTabChange}
                >
                  <Tab
                    name="selectTopic"
                    disableRipple
                    className={classes.tab}
                    label={
                      <span className={classes.tabLabel}>Select Topic</span>
                    }
                  />
                  <Tab
                    name="sktList"
                    disableRipple
                    className={classes.tab}
                    label={
                      <span className={classes.tabLabel}>Stakeholder List</span>
                    }
                  />
                  <Tab
                    name="analysis"
                    onClick={() => {
                      getSelectedTopic(login.token, assessment_id);
                      getMaterialityMapData(login.token, assessment_id);
                    }}
                    disableRipple
                    className={classes.tab}
                    label={<span className={classes.tabLabel}>Analysis</span>}
                  />
                </Tabs>
              </AppBar>
              <div className="scroll" style={{ height: "63vh" }}>
                <div index={materialityAssessment.topicTab}>
                  {/* Select topic tab panel */}
                  <TabPanel value={materialityAssessment.topicTab} index={0}>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid container item xs={12} sm={6} md={5} lg={7}>
                        <CustomTextfield
                          name="topicFilter"
                          variant="outlined"
                          size="small"
                          select
                          InputLabelProps={{
                            classes: {
                              root: classes.label,
                            },
                          }}
                          className={classes.search2}
                          SelectProps={{
                            classes: { icon: classes.icon },
                          }}
                          value={filter}
                          onChange={(e) => {
                            handleFilterChange(e.target.value);
                          }}
                        >
                          {["All Topics", "Recommended"].map((item) => (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </CustomTextfield>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        md={7}
                        lg={5}
                        className={classes.buttonsGrid}
                      >
                        <CustomButton
                          name="selectAll"
                          variant="contained"
                          color="secondary"
                          className={classes.CustomButton3}
                          size="small"
                          onClick={() =>
                            selectAllTopics(
                              login.token,
                              assessment_id,
                              materialityAssessment.topicFilter,
                              props.basicInfo?.frameworkId
                            )
                          }
                        >
                          Select All
                        </CustomButton>
                        <CustomButton
                          name="customTopic"
                          onClick={() => setIsCustomTopicDialogOpen(true)}
                          className={classes.CustomButton4}
                          variant="outlined"
                          color="primary"
                          size="small"
                        >
                          Custom Topic
                        </CustomButton>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className={classes.containerSelectTopicText}
                    >
                      <Grid item xs={12}>
                        <Typography className={classes.selectTopicText}>
                          The topics you see are recommended carefully based on
                          your industry standard. Go through each to add or
                          remove them accordingly. You can even add custom topic
                          if required.
                        </Typography>
                      </Grid>
                    </Grid>

                    {filteredTopic.length > 0 &&
                      filteredTopic.map((topic, index) => (
                        <div key={index} className={classes.grid9}>
                          <TopicBar topic={topic} index={index} />
                        </div>
                      ))}

                    {filteredTopic.length === 0 &&
                      [1, 2, 3, 4, 5].map((item) => (
                        <div key={item} className={classes.grid9}>
                          <div className={classes.emptyTopicBar}>
                            {item === 3 && (
                              <Typography className={classes.selectTopicText}>
                                No more topics
                              </Typography>
                            )}
                          </div>
                        </div>
                      ))}
                    {limit < materialityAssessment.totalTopicCount &&
                      filteredTopic.length > 0 && (
                        <Box className={classes.box2}>
                          <CustomButton
                            name="loadMore"
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() =>
                              setLimit((oldLimit) => oldLimit + 10)
                            }
                          >
                            Load More
                          </CustomButton>
                        </Box>
                      )}
                  </TabPanel>

                  {/* Stakeholder list tab panel */}
                  <TabPanel value={materialityAssessment.topicTab} index={1}>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      style={{ marginTop: 5 }}
                    >
                      <Grid container item xs={12} sm={6} md={6} lg={7}>
                        <Typography className={classes.stakholderTitle}>
                          Company Stakeholder
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} sm={6} md={6} lg={5}>
                        <CustomButton
                          name="addNewStk"
                          size="small"
                          variant="outlined"
                          color="primary"
                          className={classes.CustomButton5}
                          onClick={() => setAddNewUserDialogOpen(true)}
                        >
                          Add New
                        </CustomButton>
                        <CustomButton
                          name="shareSrvy"
                          size="medium"
                          variant="contained"
                          color="primary"
                          disabled={showSurveyBtn}
                          className={classes.CustomButton6}
                          onClick={() => handleShareDialogOpen()}
                        >
                          Share survey
                        </CustomButton>
                      </Grid>
                    </Grid>

                    <div className={classes.mainDiv3}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>
                                <Typography className={classes.tableHeaderText}>
                                  Name
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={classes.tableHeaderText}>
                                  Email ID
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={classes.tableHeaderText}>
                                  Designation
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {generalSettings.allUsers.length > 0 ? (
                              generalSettings.allUsers.map((user, id) => {
                                return (
                                  !materialityAssessment.assignedStakeholdersData.some(
                                    (assignedUser) =>
                                      assignedUser.user_data._id === user._id
                                  ) && (
                                    <TableRow
                                      key={id}
                                      className={classes.stakeholderTableRow}
                                    >
                                      <TableCell
                                        size="small"
                                        padding="checkbox"
                                        component="th"
                                        scope="row"
                                      >
                                        <Checkbox
                                          name={user.name.substring(0, 4)}
                                          size="small"
                                          color="primary"
                                          id={`${id}`}
                                          checked={checkedState[id]}
                                          onChange={(e) =>
                                            handleChange(e, id, user)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Typography
                                          className={classes.boldText}
                                          style={{ color: "#242424" }}
                                        >
                                          {user.name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          className={classes.tableHeaderText}
                                        >
                                          {user.email}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          className={classes.tableHeaderText}
                                        >
                                          {user.designation}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Typography>No user to add</Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </TabPanel>

                  {/* Analysis tab panel */}
                  <TabPanel value={materialityAssessment.topicTab} index={2}>
                    {!isTopicSelected && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={8} md={9}>
                            <CustomTextfield
                              name="ranking"
                              variant="outlined"
                              size="small"
                              select
                              InputLabelProps={{
                                classes: {
                                  root: classes.label,
                                },
                              }}
                              SelectProps={{
                                classes: { icon: classes.icon },
                              }}
                              value={analysisSort}
                              onChange={(e) => {
                                setAnalysisSort(e.target.value);
                              }}
                            >
                              <MenuItem value="High-Low">High - Low</MenuItem>
                              <MenuItem value="Low-High">Low - High</MenuItem>
                            </CustomTextfield>
                          </Grid>
                          <Grid item xs={4} md={3}>
                            <CustomButton
                              className={classes.refreshBtn}
                              onClick={() => {
                                getMaterialityMapData(
                                  login.token,
                                  assessment_id
                                );
                                getSelectedTopic(login.token, assessment_id);
                              }}
                              startIcon={<LoopIcon />}
                              variant="outlined"
                              color="primary"
                              size="small"
                            >
                              Refresh
                            </CustomButton>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={12}>
                            <Typography className={classes.selectTopicText}>
                              Create a list of stakeholder to whom the survey
                              list has to be sent and evaluated inorder to get
                              the perspective of the company details.
                            </Typography>
                          </Grid>
                        </Grid>

                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className={classes.selectTopicText}>
                            Ranking
                          </Typography>
                          <Typography className={classes.selectTopicText}>
                            Topic Name
                          </Typography>
                          <Typography className={classes.selectTopicText}>
                            Relavance
                          </Typography>
                        </Box>

                        {materialityAssessment.selectedTopicsData.length > 0 &&
                        analysisSort === "High-Low"
                          ? materialityAssessment.selectedTopicsData
                              .sort((a, b) => {
                                return a.holding_position - b.holding_position;
                              })
                              .map((item, index) => (
                                <div key={index} className={classes.subDiv2}>
                                  <RelavanceBar index={index + 1} item={item} />
                                </div>
                              ))
                          : materialityAssessment.selectedTopicsData
                              .sort((a, b) => {
                                return b.holding_position - a.holding_position;
                              })
                              .map((item, index) => (
                                <div key={index} className={classes.subDiv2}>
                                  <RelavanceBar index={index + 1} item={item} />
                                </div>
                              ))}

                        {materialityAssessment.selectedTopicsData.length ===
                          0 &&
                          [1, 2, 3, 4, 5].map((item) => (
                            <div key={item} className={classes.grid9}>
                              <div className={classes.emptyTopicBar}>
                                {item === 3 && (
                                  <Typography
                                    className={classes.selectTopicText}
                                  >
                                    Not enough data to generate report
                                  </Typography>
                                )}
                              </div>
                            </div>
                          ))}
                      </>
                    )}

                    {/* Selected Topic */}
                    {isTopicSelected && (
                      <>
                        {/* Header */}
                        <Card
                          onClick={() => setIsTopicSelected(false)}
                          variant="outlined"
                          className={classes.card}
                        >
                          <CardContent className={classes.CardContent2}>
                            <ArrowBackIcon />
                            <Typography className={classes.Typography6}>
                              {
                                materialityAssessment.selectedTopicDetails
                                  .topic_name
                              }
                            </Typography>
                            {checkTopicType(
                              materialityAssessment.masterTopics.find(
                                (topic) =>
                                  topic._id ===
                                  materialityAssessment.selectedTopicDetails
                                    .parent_id
                              )
                            )}
                          </CardContent>
                        </Card>

                        {/* Rating */}
                        <Card
                          variant="elevation"
                          elevation={3}
                          className={classes.card1}
                        >
                          <CardContent>
                            <div className={classes.subDiv3}>
                              <Typography className={classes.blueText}>
                                Rating
                              </Typography>
                              {materialityAssessment.selectedTopicDetails !==
                                "" &&
                                checkVote(
                                  materialityAssessment.selectedTopicDetails
                                    .vote_data.average
                                )}
                            </div>
                            <div className={classes.contentDivider}></div>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography className={classes.selectTopicText}>
                                  Participants
                                </Typography>
                                <Typography className={classes.blueText}>
                                  {currentTopic !== "" &&
                                    currentTopic.total_participants}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography className={classes.selectTopicText}>
                                  Responded
                                </Typography>
                                <Typography className={classes.blueText}>
                                  {currentTopic !== "" &&
                                    currentTopic.vote_data.count}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography className={classes.selectTopicText}>
                                  Holding position
                                </Typography>
                                <Typography className={classes.blueText}>
                                  {currentTopicHoldingPosition}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography className={classes.selectTopicText}>
                                  Impact
                                </Typography>
                                <Typography className={classes.blueText}>
                                  {
                                    materialityAssessment.selectedTopicDetails
                                      .impact
                                  }
                                </Typography>
                              </Grid>
                            </Grid>
                            <div className={classes.contentDivider}></div>
                            {topicRelevanceData.map((topic, index) => {
                              const { name } = topic;
                              let votePercent = undefined;
                              let voteCount = undefined;
                              if (
                                materialityAssessment.selectedTopicDetails
                                  .vote_data
                              ) {
                                if (index === 0) {
                                  votePercent = materialityAssessment
                                    .selectedTopicDetails.vote_data.five
                                    ? (materialityAssessment
                                        .selectedTopicDetails.vote_data.five /
                                        materialityAssessment
                                          .selectedTopicDetails.vote_data
                                          .count) *
                                      100
                                    : 0;
                                  voteCount = materialityAssessment
                                    .selectedTopicDetails.vote_data.five
                                    ? materialityAssessment.selectedTopicDetails
                                        .vote_data.five
                                    : 0;
                                }
                                if (index === 1) {
                                  votePercent = materialityAssessment
                                    .selectedTopicDetails.vote_data.four
                                    ? (materialityAssessment
                                        .selectedTopicDetails.vote_data.four /
                                        materialityAssessment
                                          .selectedTopicDetails.vote_data
                                          .count) *
                                      100
                                    : 0;
                                  voteCount = materialityAssessment
                                    .selectedTopicDetails.vote_data.four
                                    ? materialityAssessment.selectedTopicDetails
                                        .vote_data.four
                                    : 0;
                                }
                                if (index === 2) {
                                  votePercent = materialityAssessment
                                    .selectedTopicDetails.vote_data.three
                                    ? (materialityAssessment
                                        .selectedTopicDetails.vote_data.three /
                                        materialityAssessment
                                          .selectedTopicDetails.vote_data
                                          .count) *
                                      100
                                    : 0;
                                  voteCount = materialityAssessment
                                    .selectedTopicDetails.vote_data.three
                                    ? materialityAssessment.selectedTopicDetails
                                        .vote_data.three
                                    : 0;
                                }
                                if (index === 3) {
                                  votePercent = materialityAssessment
                                    .selectedTopicDetails.vote_data.two
                                    ? (materialityAssessment
                                        .selectedTopicDetails.vote_data.two /
                                        materialityAssessment
                                          .selectedTopicDetails.vote_data
                                          .count) *
                                      100
                                    : 0;
                                  voteCount = materialityAssessment
                                    .selectedTopicDetails.vote_data.two
                                    ? materialityAssessment.selectedTopicDetails
                                        .vote_data.two
                                    : 0;
                                }
                                if (index === 4) {
                                  votePercent = materialityAssessment
                                    .selectedTopicDetails.vote_data.one
                                    ? (materialityAssessment
                                        .selectedTopicDetails.vote_data.one /
                                        materialityAssessment
                                          .selectedTopicDetails.vote_data
                                          .count) *
                                      100
                                    : 0;
                                  voteCount = materialityAssessment
                                    .selectedTopicDetails.vote_data.one
                                    ? materialityAssessment.selectedTopicDetails
                                        .vote_data.one
                                    : 0;
                                }
                              }
                              return (
                                <Grid
                                  container
                                  key={index}
                                  className={classes.mainDiv4}
                                  alignItems="center"
                                >
                                  <div
                                    className={classes.mainDiv5}
                                    style={{
                                      width: `${votePercent}%`,
                                    }}
                                  ></div>
                                  <Grid
                                    item
                                    xs={10}
                                    className={classes.centerLeft}
                                  >
                                    <Typography className={classes.Typography7}>
                                      {name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.mainGrid1}
                                    xs={2}
                                  >
                                    <Typography className={classes.Typography8}>
                                      {votePercent}%
                                    </Typography>
                                    <Typography className={classes.Typography9}>
                                      {voteCount}
                                      {" votes"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </CardContent>
                        </Card>

                        {/* Risk */}
                        {materialityAssessment.selectedTopicDetails !== "" && (
                          <Card
                            variant="elevation"
                            elevation={3}
                            className={classes.card}
                          >
                            <CardContent>
                              <Typography className={classes.blueText}>
                                Risks to the topic
                              </Typography>
                              <div className={classes.contentDivider}></div>
                              {materialityAssessment.selectedTopicDetails &&
                                materialityAssessment.selectedTopicDetails
                                  .risk &&
                                materialityAssessment.selectedTopicDetails.risk
                                  .length > 0 &&
                                materialityAssessment.selectedTopicDetails.risk.map(
                                  (risk, index) => (
                                    <RiskOpportunity
                                      key={index}
                                      text={risk.risk}
                                      userData={risk.user_data}
                                    />
                                  )
                                )}
                            </CardContent>
                          </Card>
                        )}

                        {/* Opportunity */}
                        {materialityAssessment.selectedTopicDetails !== "" && (
                          <Card
                            variant="elevation"
                            elevation={3}
                            className={classes.card}
                          >
                            <CardContent>
                              <Typography className={classes.blueText}>
                                Opportunity to the topic
                              </Typography>
                              <div className={classes.contentDivider}></div>
                              {materialityAssessment.selectedTopicDetails &&
                                materialityAssessment.selectedTopicDetails
                                  .opportunity &&
                                materialityAssessment.selectedTopicDetails
                                  .opportunity.length > 0 &&
                                materialityAssessment.selectedTopicDetails.opportunity.map(
                                  (opportunity, index) => (
                                    <RiskOpportunity
                                      key={index}
                                      text={opportunity.opportunity}
                                      userData={opportunity.user_data}
                                    />
                                  )
                                )}
                            </CardContent>
                          </Card>
                        )}

                        {/* Attachments */}
                        {materialityAssessment.selectedTopicDetails !== "" && (
                          <Card
                            variant="elevation"
                            elevation={3}
                            className={classes.card}
                          >
                            <CardContent>
                              <Typography className={classes.blueText}>
                                Attachments to the topic
                              </Typography>
                              <div className={classes.contentDivider}></div>
                              {materialityAssessment.selectedTopicDetails &&
                                materialityAssessment.selectedTopicDetails
                                  .file &&
                                materialityAssessment.selectedTopicDetails.file
                                  .length > 0 &&
                                materialityAssessment.selectedTopicDetails.file.map(
                                  (file, index) => (
                                    <Attachments
                                      key={index}
                                      fileName={file.name}
                                    />
                                  )
                                )}
                            </CardContent>
                          </Card>
                        )}
                      </>
                    )}
                  </TabPanel>
                </div>
              </div>
            </ThemeProvider>
          </StyledEngineProvider>
        </CardContent>
      </Card>
    </>
  );
}
