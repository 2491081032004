import React from "react";
import { connect } from "react-redux";
import LoginController from "../../components/router/controller";
import {
  setLogin,
  getVersionNo,
  setIsSubscriptionActive,
  setToken,
} from "../../actions/login/loginActions";

export class Controller_con extends React.PureComponent {
  render() {
    return <LoginController {...this.props} />;
  }
}

export const mapStateToProps = (store) => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (
      type,
      email,
      user_token,
      profile_img,
      name,
      roles,
      signupProcessNo
    ) => {
      dispatch(
        setLogin(
          type,
          email,
          user_token,
          profile_img,
          name,
          roles,
          signupProcessNo
        )
      );
    },
    getVersionNo: () => {
      dispatch(getVersionNo());
    },
    setToken: (token) => {
      dispatch(setToken(token));
    },
    setIsSubscriptionActive: (payload) => {
      dispatch(setIsSubscriptionActive(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller_con);
