import { Drawer, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CreateTabs from "./CreateTabs";
import Builder from "./Builder";
import PreviewCharts from "./PreviewCharts";
import CustomButton from "../../../UI/button/button";
import { QueryBuilder } from "@cubejs-client/react";
import stateChangeHeuristics from "../../../UI/dataHubCalculatedCharts/component/exploreQBuilder/stateChangeHeuristics";
import { ISO_8601 } from "moment";

function CreateChartsDrawer({ onClose, open, builderProps, handleAddChart }) {
  const {
    vizState,
    cubejsApi,
    setVizState,
    chartExtra,
    borderColor,
    setBorderColor,
    backgroundColor,
    setBackGroundColor,
    setPivotConfiq,
    setLimit,
    barChartCustomize,
    chartX_AxisCustomize,
    chartY_AxisCustomize,
    lineChartCustomize,
    pieChartCustomize,
    setBarChartCustomize,
    setChartX_AxisCustomize,
    setChartY_AxisCustomize,
    setLineChartCustomize,
    setPieChartCustomize,
    setAddChart,
    chartTitle,
    setChartTitle,
    setChartDescription,
    chartDescription,
    initialBackgroundColor,
    initialBorderColor,
    axisLabels,
    setAxisLabels,
  } = builderProps;
  const [selectedTab, setSelectedTab] = useState(1);
  const [warning, setWarning] = useState(false);
  const CloseAlert = ({ updateQuery, updatePivotConfig, pivotConfig }) => {
    return (
      <div
        componet={Paper}
        style={{
          maxWidth: 350,
          position: "absolute",
          backgroundColor: "white",
          borderRadius: 8,
          right: 20,
          top: 20,
          zIndex: 1,
          padding: 10,
          boxShadow: "1px 1px 4px grey",
        }}
      >
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>
          Create Chart
        </Typography>
        <div style={{ padding: "35px 10px" }}>
          <Typography
            style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}
          >
            Closing this tab not store the changes. Are you sure you want to
            close this tab ?
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            position: "relative",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            paddingBottom: "5px",
          }}
        >
          <CustomButton
            onClick={() => {
              setWarning(false);
              updateQuery({
                measures: [],
                dimensions: [],
                order: [],
                limit: 10000,
              });
              setBackGroundColor(initialBackgroundColor);
              setBorderColor(initialBorderColor);
              setChartTitle("New Chart");
              setChartDescription("New Chart Description");
              setLimit(5000);
              setSelectedTab(1);
              setAxisLabels({
                x_labels: "",
                y_labels: "",
              });
              onClose();
            }}
            fullwidth
            style={{
              textTransform: "none",
              padding: "3px 18px",
              borderRadius: "8px",
              fontSize: 13,
              // marginRight: "10px",
              border: "none",
              width: "125px",
            }}
            color="primary"
            variant="outlined"
          >
            Close
          </CustomButton>
          <CustomButton
            onClick={() => setWarning(false)}
            style={{
              textTransform: "none",
              padding: "3px 18px",
              borderRadius: "8px",
              fontSize: 13,
              "&:hover": {
                boxShadow: "1px 1px 4px grey",
              },
            }}
            color="primary"
            variant="contained"
          >
            Continue Editing
          </CustomButton>
        </div>
      </div>
    );
  };
  return (
    <QueryBuilder
      // vizState={vizState}
      setVizState={setVizState}
      // query={{
      //   timeDimensions: [
      //     {
      //       dimension: "LineItems.createdAt",
      //       granularity: "quarter",
      //     },
      //   ],
      // }}
      cubejsApi={cubejsApi}
      wrapWithQueryRenderer={true}
      stateChangeHeuristics={stateChangeHeuristics}
      render={({
        measures,
        availableMeasures,
        updateMeasures,
        dimensions,
        availableDimensions,
        updateDimensions,
        segments,
        availableSegments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        availableTimeDimensions,
        updateTimeDimensions,
        isQueryPresent,
        chartType,
        updateChartType,
        validatedQuery,
        pivotConfig,
        updatePivotConfig,
        orderMembers,
        updateOrder,
        updateQuery,
        refresh,
        // cubejsApi,
        query,
        ...props
      }) => [
        <div>
          <Drawer
            anchor="right"
            fullWidth={true}
            // maxWidth={"xl"}

            onClose={() => setWarning(true)}
            open={open}
          >
            {warning && (
              <CloseAlert
                updateQuery={updateQuery}
                updatePivotConfig={updatePivotConfig}
                pivotConfig={pivotConfig}
              />
            )}
            <div
              style={{
                minWidth: "1200px",
                maxWidth: "1200px",
                padding: "10px 0px 10px 10px",
                position: "relative",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #EBF1F8",
                }}
              >
                <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                  Create charts
                </Typography>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    color: "#3374B9",
                    marginRight: 10,
                  }}
                  onClick={() => setWarning(true)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CreateTabs
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  chartTitle={chartTitle}
                  measures={measures}
                  dimensions={dimensions}
                  chartType={chartType}
                  orderMembers={orderMembers}
                  pivotConfig={pivotConfig}
                />
                <Builder
                  setChartTitle={setChartTitle}
                  chartTitle={chartTitle}
                  axisLabels={axisLabels}
                  setAxisLabels={setAxisLabels}
                  setChartDescription={setChartDescription}
                  chartDescription={chartDescription}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  measures={measures}
                  availableMeasures={availableMeasures}
                  updateMeasures={updateMeasures}
                  query={query}
                  //dimentions props
                  dimensions={dimensions}
                  availableDimensions={availableDimensions}
                  updateDimensions={updateDimensions}
                  //charts
                  chartType={chartType}
                  updateChartType={updateChartType}
                  //order
                  orderMembers={orderMembers}
                  updateOrder={updateOrder}
                  //limit
                  setLimit={setLimit}
                  setPivotConfiq={setPivotConfiq}
                  pivotConfig={pivotConfig}
                  updatePivotConfig={updatePivotConfig}
                  updateQuery={updateQuery}
                  //Color
                  backgroundColor={backgroundColor}
                  borderColor={borderColor}
                  setBorderColor={setBorderColor}
                  setBackGroundColor={setBackGroundColor}
                  //time dimension
                  timeDimensions={timeDimensions}
                  availableTimeDimensions={availableTimeDimensions}
                  updateTimeDimensions={updateTimeDimensions}
                />
                <PreviewCharts
                  vizState={vizState}
                  measures={measures}
                  dimensions={dimensions}
                  chartType={chartType}
                  orderMembers={orderMembers}
                  pivotConfig={pivotConfig}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  setAddChart={setAddChart}
                  isQueryPresent={isQueryPresent}
                  query={query}
                  timeDimensions={timeDimensions}
                  cubejsApi={cubejsApi}
                  backgroundColor={backgroundColor}
                  borderColor={borderColor}
                  barChartCustomize={barChartCustomize}
                  chartX_AxisCustomize={chartX_AxisCustomize}
                  chartY_AxisCustomize={chartY_AxisCustomize}
                  lineChartCustomize={lineChartCustomize}
                  pieChartCustomize={pieChartCustomize}
                  axisLabels={axisLabels}
                  {...props}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: "100%",
                  padding: "0px 10px 8px",
                }}
              >
                <CustomButton
                  onClick={() => {
                    selectedTab > 1 && setSelectedTab((prev) => prev - 1);
                  }}
                  style={{
                    textTransform: "none",
                    padding: "3px 18px",
                    borderRadius: "8px",
                    fontSize: 13,
                    marginRight: "10px",
                    border: "none",
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Previous
                </CustomButton>
                <CustomButton
                  onClick={() =>
                    setSelectedTab((prev) => {
                      if (prev === 9) {
                        handleAddChart();
                        updateQuery({
                          measures: [],
                          dimensions: [],
                          order: [],
                          limit: 10000,
                        });

                        setSelectedTab(1);
                      }
                      if (prev === 3 && timeDimensions?.length > 0) {
                        const currentTime = timeDimensions[0];

                        delete currentTime.dateRange;

                        updateTimeDimensions.update(currentTime, {
                          ...currentTime,
                          granularity: "quarter",
                        });
                      }
                      return prev + 1;
                    })
                  }
                  disabled={(() => {
                    if (!chartTitle && selectedTab === 1) return true;
                    else if (!measures.length > 0 && selectedTab === 2)
                      return true;
                    else if (!dimensions.length > 0 && selectedTab === 3)
                      return true;
                    else if (!chartType && selectedTab === 4) return true;
                    else if (
                      !measures.length > 0 &&
                      !dimensions.length > 0 &&
                      (selectedTab === 5 ||
                        selectedTab === 6 ||
                        selectedTab === 7 ||
                        selectedTab === 8)
                    )
                      return true;
                    return false;
                  })()}
                  style={{
                    textTransform: "none",
                    padding: "3px 18px",
                    borderRadius: "8px",
                    fontSize: 13,
                  }}
                  color="primary"
                  variant="contained"
                >
                  Save & Next
                </CustomButton>
              </div>
            </div>
          </Drawer>
        </div>,
      ]}
    />
  );
}

export default CreateChartsDrawer;
