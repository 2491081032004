import {
  REDIRECT_VALUE,
  SET_KEYWORD,
  SET_ASSESSMENT,
  SET_CHANGE_PASSWORD_DIALOG,
  SET_ACTIVITY_LOG,
  SET_CURRENT_STEP,
  SET_ORGANIZATION_DETAILS,
  SET_ASSESSMENT_STATUS,
  SET_ASSIGNED_ASSESSMENTS,
  SET_ASSIGNED_ASSESSMENT_STATUS,
  SET_NOTIFICATIONS,
  SET_ASSESSMENTS_TOTAL_COUNT,
  SET_TOTAL_NOTIFICATIONS,
  SET_ASSESSMENTS_PAGINATION_PAGE,
} from "../../constants/surveyListing/surveyListingConst";
import { LIMIT } from "../../constants/login/ActionTypes";

let initial_state = {
  redirect_value: false,
  assessment_id: "",
  assessments: [],
  assignedAssessments: [],
  limit: LIMIT,
  startingAfter: 0,
  keyword: "",
  changePasswordDialog: false,
  activityLog: [],
  currentStep: "",
  organisation_details: "",
  assessmentStatus: false,
  assignedAssessmentStatus: false,
  notifications: [],
  totalNotifications: 0,
  assessmentsTotalCount: 0,
  paginationPage: 1,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case REDIRECT_VALUE:
      return { ...state, redirect_value: true, assessment_id: action.payload };
    case SET_ASSESSMENT:
      return { ...state, assessments: action.payload };
    case SET_ASSIGNED_ASSESSMENTS:
      return { ...state, assignedAssessments: action.payload };
    case SET_KEYWORD:
      return { ...state, keyword: action.payload };
    case SET_CHANGE_PASSWORD_DIALOG:
      return { ...state, changePasswordDialog: action.payload };
    case SET_ACTIVITY_LOG:
      return { ...state, activityLog: action.payload };
    case SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    case "REDIRECT_VALUE1":
      return { ...state, redirect_value: false };
    case SET_ORGANIZATION_DETAILS:
      return { ...state, organisation_details: action.payload };
    case SET_ASSESSMENT_STATUS:
      return { ...state, assessmentStatus: action.payload };
    case SET_ASSIGNED_ASSESSMENT_STATUS:
      return { ...state, assignedAssessmentStatus: action.payload };
    case SET_NOTIFICATIONS:
      console.log("reducer", action.payload);
      action.payload.length > 0
        ? (state.notifications = [...state.notifications, ...action.payload])
        : (state.notifications = [...action.payload]);
      return { ...state };
    case SET_TOTAL_NOTIFICATIONS:
      return { ...state, totalNotifications: action.payload };
    case SET_ASSESSMENTS_TOTAL_COUNT:
      return { ...state, assessmentsTotalCount: action.payload };
    case SET_ASSESSMENTS_PAGINATION_PAGE:
      return { ...state, paginationPage: action.payload };
    default:
      return state;
  }
}
