import {
  Box,
  Button,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
  adaptV4Theme,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// icons
import DownloadWhiteSVG from "../../../../images/downloadWhite.svg";
import { fetchSelectedSurveyData } from "../../../../actions/datahubActions/datahubActions";
import { useState } from "react";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#3374b9",
      },
      secondary: {
        main: "#47BB76",
      },
    },
  })
);

const SelectedSurveyButtons = () => {
  const dispatch = useDispatch();
  const { selectedDashboardSurvey } = useSelector((state) => state.dataHub);
  const { token, selectedOrganization } = useSelector((state) => state.login);

  const [disableFetch, setDisableFetch] = useState(
    selectedDashboardSurvey?.process_in_progress
  );

  const formatDate = (date) => {
    const day = moment(date).format("Do");
    const monthYear = moment(date).format("MMMM YYYY");
    return `${day} ${monthYear}`;
  };

  const handleFetchData = () => {
    if (!disableFetch) {
      dispatch(
        fetchSelectedSurveyData(
          token,
          selectedOrganization?._id,
          selectedDashboardSurvey?.survey_id
        )
      );
      setDisableFetch(true);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ marginRight: 15, gap: 15 }}
          >
            {/* change format to this type: 23rd February 2024 */}
            <Typography variant="body1" style={{ textWrap: "nowrap" }}>
              Last Updated on{" "}
              {formatDate(selectedDashboardSurvey?.last_modified_on)}
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              startIcon={
                <img
                  src={DownloadWhiteSVG}
                  alt="downloadicon"
                  style={{ width: 20, height: 20 }}
                />
              }
              style={{
                color: "#F5FAFE",
                textTransform: "none",
                textWrap: "nowrap",
              }}
              onClick={handleFetchData}
              disabled={disableFetch}
            >
              Fetch Data
            </Button>
          </Box>

          {/* <Divider orientation="vertical" />

          <Box display={"flex"} gap={1}>
            <IconButton>
              <AddIcon style={{ color: "#3374B9" }} />
            </IconButton>
            <IconButton>
              <ShareOutlinedIcon style={{ color: "#3374B9" }} />
            </IconButton>
            <IconButton>
              <TrendingUpIcon style={{ color: "#3374B9" }} />
            </IconButton>
          </Box> */}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default SelectedSurveyButtons;
