import { JWT_SECRET } from "../../constants/login/ActionTypes";
const cryptoJS = require("crypto-js");
const encrypt = (object) => {
  //env secret key get in next.js client side.
  const jwt_secret = JWT_SECRET;

  //encrypt data using cryptoJS.
  const data = cryptoJS.AES.encrypt(
    JSON.stringify(object),
    jwt_secret
  ).toString();

  return data;
};

export default encrypt;
