import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Typography, Button } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { makeStyles } from "@mui/styles";

import EsgScore from "./EsgScore";
import SelfAssessmentSurvey from "./SelfAssessmentSurvey";
import { dummyesgScores, dummyInsights } from "../esgkpi/riskUtils";
import EsgInsights from "./EsgInsights";

export const appbarStyles = makeStyles((theme) => ({
  AppBar: { boxShadow: "none" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
}));
function EsgProfile(props) {
  // TABS DATA FROM REDUX
  // const { riskEvaluator } = props;

  const [profileTabNo, setProfileTabNo] = useState(0);
  const classes = appbarStyles();

  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            ESG Profile
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Button
            variant="text"
            color="primary"
            style={{ textTransform: "capitalize" }}
            // startIcon={<img src={ExportIcon} alt="share_icon" />}
          >
            Export
          </Button>
          <Button
            variant="text"
            color="primary"
            style={{ textTransform: "capitalize" }}
            // startIcon={<img src={ShareIcon} alt="share_icon" />}
          >
            Share
          </Button>
        </div>
      </div>

      <div>
        <TabContext value={profileTabNo}>
          <TabList
            onChange={(e, newVal) => {
              setProfileTabNo(newVal);
            }}
            // onChange={handleTabChange}
          >
            <Tab
              value={0}
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>ESG Score</span>}
            />
            <Tab
              value={1}
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Self Assessment </span>}
            />

            <Tab
              value={2}
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>ESG Insights</span>}
            />
          </TabList>

          <TabPanel value={0}>
            <div
              className="scroll"
              style={{ height: "55vh", marginTop: "10px", paddingBottom: 20 }}
            >
              <EsgScore scores={dummyesgScores} />
            </div>
          </TabPanel>
          <TabPanel value={1}>
            <SelfAssessmentSurvey {...props} data={dummyesgScores[0]} />
          </TabPanel>
          <TabPanel value={2}>
            <EsgInsights {...props} dummyInsights={dummyInsights} />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
}

export default EsgProfile;
