import blueIcon from "images/IconBlue.png";
import greenIcon from "images/IconGreen.png";
import purpuleIcon from "images/IconPurpule.png";
import yellowIcon from "images/IconYellow.png";
import orangeIcon from "images/IconOrange.png";
import securityIcon from "images/securityIcon.png";
import compilanceIcon from "images/compilanceIcon.png";

export const getColoredLogo = (topic) => {
  if (topic === "Environment") return greenIcon;
  if (topic === "Social") return purpuleIcon;
  if (topic === "Governance") return blueIcon;
  if (topic === "Business") return orangeIcon;
  if (topic === "Human Capital") return yellowIcon;
  if (topic === "Security") return securityIcon;
  if (topic === "Compliance") return compilanceIcon;
  else return null;
};

export const returnCategoryOfdataPoint = (data) => {
  let environment_categories = [
      "Energy",
      "Water",
      "Waste",
      "Analytic CO2",
      "Green revenues",
    ],
    social_categories = [
      "Career development and training",
      "Data privacy",
      "Diversity and Inclusion",
      "Health and Safety",
      "HumanRights",
      "Responsible Marketing",
    ],
    governance_categories = ["Compensation", "Structure"];

  if (environment_categories.includes(data)) return "Environment";
  if (social_categories.includes(data)) return "Social";
  if (governance_categories.includes(data)) return "Governance";
};

export const gptPeerAnalysisText = () => {
  let arr = [
    `“Good sustainability practices positively influenced the stock price performance of 80% of companies and lowered the cost of capital of 90% of companies. Solid ESG practices are found to have resulted in better operational performance in 88% of companies”`,
    `“Strong ESG initiatives have significantly boosted the stock price performance of 80% of companies, vividly demonstrating the concrete financial advantages derived from embracing sustainable practices within today's market environment.”`,
    `“The compelling link between effective ESG strategies and enhanced operational performance is vividly illustrated by the 88% of companies experiencing tangible improvements in their day-to-day business functions.”`,
    `“ESG is the compass guiding businesses towards a future where profitability intertwines with purpose, where investors increasingly recognize that sustainable practices are pivotal for mitigating risks and seizing opportunities.”`,
    `“Beyond compliance, ESG represents a commitment to ethics, transparency, and accountability, creating a framework that not only resonates with stakeholders but also fortifies a company's position in a world where sustainable practices are becoming non-negotiable benchmarks for success.”`,
    `“Companies embracing comprehensive ESG frameworks have witnessed a convergence of financial success and sustainable practices, with significant proportions reaping the rewards through heightened stock prices and lowered capital costs.”`,
  ];
  let i = Math.round(Math.random() * arr.length);
  return [arr[i]];
};
export const riskArea = [
  {
    topic: "Human Capital",
    heading: "Labor Standards and Human Rights",
    description:
      "Poor labor standards and human rights violations can result in reputational damage, legal liabilities, and loss of investor confidence.",
  },
  {
    topic: "Governance",
    heading: "Risk Management",
    description:
      "Potential for business disruptions and financial losses, suggestions for enterprise risk management frameworks",
  },
  {
    topic: "Human Capital",
    heading: "Community Relations",
    description:
      "Best practices in employee education and career development, potential for enhanced employee retention and productivity",
  },
  {
    topic: "Environment",
    heading: "Regulatory Compliance",
    description:
      "Poor compliance with environmental regulations may result in fines, legal penalties, and reputational damage",
  },
  {
    topic: "Environment",
    heading: "Greenhouse Gas Emissions",
    description:
      "High emissions contribute to climate change, which poses a significant threat to the environment and human well-being",
  },
];

export const strongAreas = [
  {
    topic: "Business",
    heading: "Board Composition and Diversity",
    description:
      "A diverse and effective board can provide oversight and strategic guidance, improving corporate performance and reducing risk.",
  },
  {
    topic: "Business",
    heading: "Corporate Governance Policies and Procedures",
    description:
      "Implementing strong corporate governance policies and procedures can reduce risk, increase transparency, and enhance investor confidence.",
  },
  {
    topic: "Business",
    heading: "Ethical Business Practices:",
    description:
      "Maintaining ethical business practices can improve reputation, reduce risk, and enhance long-term value creation",
  },
  {
    topic: "Business",
    heading: "Transparency and Disclosure",
    description:
      "Transparency and disclosure can enhance investor confidence, improve reputation, and promote accountability.",
  },
];

export const improvementAreas = [
  {
    topic: "Environment",
    heading: "Transparency and Disclosure",
    description:
      "Transparency and disclosure can enhance investor confidence, improve reputation, and promote accountability.",
  },
  {
    topic: "Environment",
    heading: "Waste Reduction and Recycling",
    description:
      "Implementing waste reduction and recycling programs can reduce waste generation, conserve resources, and reduce costs",
  },
  {
    topic: "Human Capital",
    heading: "Employee Training and Development",
    description:
      "Providing employee training and education programs can help improve workplace safety and reduce the risk of accidents and injuries",
  },
];

export const financialInsights = [
  {
    heading: "For the financial year",
    description:
      "Report shows strong financial health, with a high current ratio indicating a good ability to pay off short-term liabilities. However, the company's debt-to-equity ratio is relatively high, indicating a potential risk of overleveraging. The company has a good history of meeting its debt obligations, with timely payments and no defaults. Overall, the report suggests that ABC Company has a solid financial footing and is a low-risk investment or lending opportunity.",
  },
];
export const dummyControversies = {
  "Anti-competition controversy": 0,
  "Business ethics controversies": 1,
  "Intellectual property controversies": 0,
  "Critical countries controversies": 0,
  "Public health controversies": 0,
  "Tax fraud controversies": 0,
  "Child labour controversies": 0,
  "Human rights controversies": 0,
  "Management compensation controversies count": 0,
  "Consumer controversies": 1,
  "Customer health and safety controversies": 0,
  "Privacy controversies": 0,
  "Product access controversies": 0,
  "Responsible marketing controversies": 0,
  "Responsible R&D controversies": 0,
  "Environmental controversies": 0,
  "Accounting controversies count": 0,
  "Insider dealings controversies": 0,
  "Shareholder rights controversies": 0,
  "Diversity and opportunity controversies": 1,
  "Employee health and safety controversies": 0,
  "Wages or working conditions controversies": 0,
  Strikes: 0,
};
export const dummyArticles = [
  {
    _id: "1qT5oosB1t71qve3KydX",
    companyname: "Emirates NBD",
    domain: "khaleejtimes.com",
    seendate: "20221026T120000Z",
    sentiment_label: "Positive",
    title:
      "Dubai : Residents win $1 million , luxury vehicles in Duty Free promotion",
    url: "https://www.khaleejtimes.com/uae/dubai-residents-win-1-million-luxury-vehicles-in-duty-free-promotion",
  },
  {
    _id: "1aT5oosB1t71qve3KydX",
    companyname: "Emirates NBD",
    domain: "gulfnews.com",
    seendate: "20221026T101500Z",
    sentiment_label: "Positive",
    title: "Kazakh expat in UAE wins $1 million in Dubai Duty Free draw",
    url: "https://gulfnews.com/uae/kazakh-expat-in-uae-wins-1-million-in-dubai-duty-free-draw-1.91517231",
  },
  {
    _id: "36T5oosB1t71qve3wSdp",
    companyname: "Emirates NBD",
    domain: "khaleejtimes.com",
    seendate: "20221031T160000Z",
    sentiment_label: "Neutral",
    title: "Taaleem seeks to raise Dh750m via IPO",
    url: "https://www.khaleejtimes.com/business/taaleem-seeks-to-raise-dh750m-via-ipo",
  },
  {
    _id: "2KT5oosB1t71qve3bSci",
    companyname: "Emirates NBD",
    domain: "gulfnews.com",
    seendate: "20221027T053000Z",
    sentiment_label: "Negative",
    title:
      "Dubai uber - bank Emirates NBD hits Dh9b in nine - month 2022 profit",
    url: "https://gulfnews.com/business/banking/dubai-uber-bank-emirates-nbd-hits-dh9b-in-nine-month-2022-profit-1.1666846953010",
  },
  {
    _id: "waQJo4sB1t71qve32SjR",
    companyname: "Emirates NBD",
    domain: "zawya.com",
    seendate: "20230424T074500Z",
    sentiment_label: "Negative",
    title:
      "Dollar bonds of India Adani Ports rise as company starts debt buyback",
    url: "https://www.zawya.com/en/markets/fixed-income/dollar-bonds-of-indias-adani-ports-rise-as-company-starts-debt-buyback-qimshc47",
  },
];
export const dummyesgScores = {
  scoreObject: {
    environment: {
      score: 47.83425775613276,
      sum_of_weighted_score: 0.47834257756132753,
      final_weighted_score: 0.2152541599025974,
      average: {
        energy: 0.2975694444444445,
        water: 0.4738095238095238,
        waste: 0.49653679653679655,
        analytic: 0.4454545454545454,
        green: 0.5,
      },
      weighted_score: {
        energy: 0.07439236111111112,
        water: 0.11845238095238095,
        waste: 0.12413419913419914,
        analytic: 0.11136363636363635,
        green: 0.05,
      },
      data_points: {
        energy: 7.4392361111111125,
        water: 11.845238095238095,
        waste: 12.413419913419913,
        analytic: 11.136363636363635,
        green: 5,
      },
    },
    social: {
      score: 52.42647058823529,
      sum_of_weighted_score: 0.524264705882353,
      final_weighted_score: 0.23591911764705883,
      average: {
        career: 0.625,
        dataPrivacy: 0.5,
        diversity: 0.5,
        health: 0.5416666666666666,
        humanRights: 0.4754901960784313,
        product: 0.5,
      },
      weighted_score: {
        career: 0.125,
        dataPrivacy: 0.1,
        diversity: 0.1,
        health: 0.05416666666666667,
        humanRights: 0.09509803921568627,
        product: 0.05,
      },
      data_points: {
        career: 12.5,
        dataPrivacy: 10,
        diversity: 10,
        health: 5.416666666666667,
        humanRights: 9.509803921568627,
        product: 5,
      },
    },
    governance: {
      score: 18.692905242905244,
      sum_of_weighted_score: 0.08692905242905244,
      final_weighted_score: 0.008692905242905244,
      average: {
        compensation: 0.355978835978836,
        structure: 0.25665584415584414,
      },
      weighted_score: {
        compensation: 0.0355978835978836,
        structure: 0.05133116883116883,
      },
      data_points: {
        compensation: 3.55978835978836,
        structure: 5.133116883116883,
      },
    },
    business: {
      score: 28.692905242905244,
      sum_of_weighted_score: 0.08692905242905244,
      final_weighted_score: 0.008692905242905244,
      average: {
        compensation: 0.355978835978836,
        structure: 0.25665584415584414,
      },
      weighted_score: {
        compensation: 0.0355978835978836,
        structure: 0.05133116883116883,
      },
      data_points: {
        compensation: 3.55978835978836,
        structure: 5.133116883116883,
      },
    },
    human: {
      score: 68.692905242905244,
      sum_of_weighted_score: 0.08692905242905244,
      final_weighted_score: 0.008692905242905244,
      average: {
        compensation: 0.355978835978836,
        structure: 0.25665584415584414,
      },
      weighted_score: {
        compensation: 0.0355978835978836,
        structure: 0.05133116883116883,
      },
      data_points: {
        compensation: 3.55978835978836,
        structure: 5.133116883116883,
      },
    },
  },
  esgScore: 45.98661827925614,
  esgcScore: 5.555555555555555,
  finalEsg: 25.77108691740585,
  energy_kpis: {
    "Total electricity consumption in Gigajoules  from renewable sources": 0,
    "Total fuel consumption in Gigajoules  from renewable sources": 0,
    "Total Energy consumption  in Gigajoules  from renewable sources ": 0,
    "Total electricity consumption in Gigajoules  from non renewable sources": 57122.44,
    "Total fuel consumption in Gigajoules  fromnon renewable sources": 0,
    "Total Energy consumption  in Gigajoules  from non renewable sources ": 0,
  },
  water_kpis: {
    "wasteCount withdrawal by source (in kilolitres)  from surface water": 0,
    "Water withdrawal by source (in kilolitres)  from ground water": 0,
    "Water withdrawal by source (in kilolitres)  from third party water ": 0,
    "Amount of rainwater was utilized  (in kilolitres) ": 0,
    " Volume of water withdrawal  (in kilolitres)": 0,
    "Total volume of water consumption (in kilolitres)  ": 78400,
    " Water intensity per rupee of turnover (Water consumed / turnover) ": 0,
  },
};

export const dummyIndustries = [
  {
    _id: "6197687b956d954194412524",
    Sector: "Health Care",
    industry_data: [
      {
        _id: "6197687b956d95419441254d",
        Industry: "Managed Care",
        industry_code: "HC-MC",
      },
      {
        _id: "6197687b956d95419441254c",
        Industry: "Health Care Delivery",
        industry_code: "HC-DY",
      },
      {
        _id: "6197687b956d95419441254a",
        Industry: "Health Care Distributors",
        industry_code: "HC-DI",
      },
      {
        _id: "6197687b956d95419441254e",
        Industry: "Medical Equipment & Supplies",
        industry_code: "HC-MS",
      },
      {
        _id: "6197687b956d954194412549",
        Industry: "Biotechnology & Pharmaceuticals",
        industry_code: "HC-BP",
      },
      {
        _id: "6197687b956d95419441254b",
        Industry: "Drug Retailers",
        industry_code: "HC-DR",
      },
    ],
  },
  {
    _id: "6197687b956d954194412523",
    Sector: "Financials",
    industry_data: [
      {
        _id: "6197687b956d954194412547",
        Industry: "Insurance",
        industry_code: "FN-IN",
      },
      {
        _id: "6197687b956d954194412542",
        Industry: "Asset Management & Custody Activities",
        industry_code: "FN-AC",
      },
      {
        _id: "6197687b956d954194412545",
        Industry: "Security & Commodity Exchanges",
        industry_code: "FN-EX",
      },
      {
        _id: "6197687b956d954194412544",
        Industry: "Consumer Finance",
        industry_code: "FN-CF",
      },
      {
        _id: "6197687b956d954194412543",
        Industry: "Commercial Banks",
        industry_code: "FN-CB",
      },
      {
        _id: "6197687b956d954194412546",
        Industry: "Investment Banking & Brokerage",
        industry_code: "FN-IB",
      },
      {
        _id: "6197687b956d954194412548",
        Industry: "Mortgage Finance",
        industry_code: "FN-MF",
      },
    ],
  },
  {
    _id: "6197687b956d95419441252a",
    Sector: "Transportation",
    industry_data: [
      {
        _id: "6197687b956d954194412572",
        Industry: "Automobiles",
        industry_code: "TR-AU",
      },
      {
        _id: "6197687b956d954194412577",
        Industry: "Road Transportation",
        industry_code: "TR-RO",
      },
      {
        _id: "6197687b956d954194412575",
        Industry: "Marine Transportation",
        industry_code: "TR-MT",
      },
      {
        _id: "6197687b956d954194412570",
        Industry: "Airlines",
        industry_code: "TR-AL",
      },
      {
        _id: "6197687b956d954194412573",
        Industry: "Cruise Lines",
        industry_code: "TR-CL",
      },
      {
        _id: "6197687b956d954194412571",
        Industry: "Auto Parts",
        industry_code: "TR-AP",
      },
      {
        _id: "6197687b956d954194412574",
        Industry: "Car Rental & Leasing",
        industry_code: "TR-CR",
      },
      {
        _id: "6197687b956d95419441256f",
        Industry: "Air Freight & Logistics",
        industry_code: "TR-AF",
      },
      {
        _id: "6197687b956d954194412576",
        Industry: "Rail Transportation",
        industry_code: "TR-RA",
      },
    ],
  },
  {
    _id: "6197687b956d954194412527",
    Sector: "Resource Transformation",
    industry_data: [
      {
        _id: "6197687b956d954194412560",
        Industry: "Electrical & Electronic Equipment",
        industry_code: "RT-EE",
      },
      {
        _id: "6197687b956d95419441255d",
        Industry: "Aerospace & Defense",
        industry_code: "RT-AE",
      },
      {
        _id: "6197687b956d954194412561",
        Industry: "Industrial Machinery & Goods",
        industry_code: "RT-IG",
      },
      {
        _id: "6197687b956d95419441255e",
        Industry: "Chemicals",
        industry_code: "RT-CH",
      },
      {
        _id: "6197687b956d95419441255f",
        Industry: "Containers & Packaging",
        industry_code: "RT-CP",
      },
    ],
  },
  {
    _id: "6197687b956d954194412522",
    Sector: "Food & Beverage",
    industry_data: [
      {
        _id: "6197687b956d95419441253b",
        Industry: "Agricultural Products",
        industry_code: "FB-AG",
      },
      {
        _id: "6197687b956d95419441253c",
        Industry: "Food Retailers & Distributors",
        industry_code: "FB-FR",
      },
      {
        _id: "6197687b956d954194412541",
        Industry: "Tobacco",
        industry_code: "FB-TB",
      },
      {
        _id: "6197687b956d954194412540",
        Industry: "Restaurants",
        industry_code: "FB-RN",
      },
      {
        _id: "6197687b956d95419441253f",
        Industry: "Processed Foods",
        industry_code: "FB-PF",
      },
      {
        _id: "6197687b956d95419441253e",
        Industry: "Non-Alcoholic Beverages",
        industry_code: "FB-NB",
      },
      {
        _id: "6197687b956d95419441253d",
        Industry: "Meat, Poultry & Dairy",
        industry_code: "FB-MP",
      },
      {
        _id: "6197687b956d95419441253a",
        Industry: "Alcoholic Beverages",
        industry_code: "FB-AB",
      },
    ],
  },
  {
    _id: "6197687b956d954194412529",
    Sector: "Technology & Communications",
    industry_data: [
      {
        _id: "6197687b956d95419441256a",
        Industry: "Hardware",
        industry_code: "TC-HW",
      },
      {
        _id: "6197687b956d95419441256b",
        Industry: "Internet Media & Services",
        industry_code: "TC-IM",
      },
      {
        _id: "6197687b956d95419441256c",
        Industry: "Semiconductors",
        industry_code: "TC-SC",
      },
      {
        _id: "6197687b956d95419441256e",
        Industry: "Telecommunication Services",
        industry_code: "TC-TL",
      },
      {
        _id: "6197687b956d95419441256d",
        Industry: "Software & IT Services",
        industry_code: "TC-SI",
      },
      {
        _id: "6197687b956d954194412569",
        Industry:
          "Electronic Manufacturing Services & Original Design Manufacturing",
        industry_code: "TC-ES",
      },
    ],
  },
  {
    _id: "6197687b956d954194412520",
    Sector: "Consumer Goods",
    industry_data: [
      {
        _id: "6197687b956d95419441252c",
        Industry: "Appliance Manufacturing",
        industry_code: "CG-AM",
      },
      {
        _id: "6197687b956d95419441252b",
        Industry: "Apparel, Accessories & Footwear",
        industry_code: "CG-AA",
      },
      {
        _id: "6197687b956d954194412531",
        Industry: "Toys & Sporting Goods",
        industry_code: "CG-TS",
      },
      {
        _id: "6197687b956d95419441252f",
        Industry: "Household & Personal Products",
        industry_code: "CG-HP",
      },
      {
        _id: "6197687b956d954194412530",
        Industry: "Multiline and Specialty Retailers & Distributors",
        industry_code: "CG-MR",
      },
      {
        _id: "6197687b956d95419441252e",
        Industry: "E-Commerce",
        industry_code: "CG-EC",
      },
      {
        _id: "6197687b956d95419441252d",
        Industry: "Building Products & Furnishings",
        industry_code: "CG-BF",
      },
    ],
  },
  {
    _id: "6197687b956d954194412525",
    Sector: "Infrastructure",
    industry_data: [
      {
        _id: "6197687b956d954194412551",
        Industry: "Gas Utilities & Distributors",
        industry_code: "IF-GU",
      },
      {
        _id: "6197687b956d954194412553",
        Industry: "Real Estate",
        industry_code: "IF-RE",
      },
      {
        _id: "6197687b956d954194412552",
        Industry: "Home Builders",
        industry_code: "IF-HB",
      },
      {
        _id: "6197687b956d954194412555",
        Industry: "Waste Management",
        industry_code: "IF-WM",
      },
      {
        _id: "6197687b956d954194412554",
        Industry: "Real Estate Services",
        industry_code: "IF-RS",
      },
      {
        _id: "6197687b956d95419441254f",
        Industry: "Engineering & Construction Services",
        industry_code: "IF-EN",
      },
      {
        _id: "6197687b956d954194412550",
        Industry: "Electric Utilities & Power Generators",
        industry_code: "IF-EU",
      },
      {
        _id: "6197687b956d954194412556",
        Industry: "Water Utilities & Services",
        industry_code: "IF-WU",
      },
    ],
  },
  {
    _id: "6197687b956d954194412526",
    Sector: "Renewable Resources & Alternative Energy",
    industry_data: [
      {
        _id: "6197687b956d95419441255c",
        Industry: "Wind Technology & Project Developers",
        industry_code: "RR-WT",
      },
      {
        _id: "6197687b956d95419441255b",
        Industry: "Solar Technology & Project Developers",
        industry_code: "RR-ST",
      },
      {
        _id: "6197687b956d954194412558",
        Industry: "Fuel Cells & Industrial Batteries",
        industry_code: "RR-FC",
      },
      {
        _id: "6197687b956d954194412559",
        Industry: "Forestry Management",
        industry_code: "RR-FM",
      },
      {
        _id: "6197687b956d954194412557",
        Industry: "Biofuels",
        industry_code: "RR-BI",
      },
      {
        _id: "6197687b956d95419441255a",
        Industry: "Pulp & Paper Products",
        industry_code: "RR-PP",
      },
    ],
  },
  {
    _id: "6197687b956d954194412528",
    Sector: "Services",
    industry_data: [
      {
        _id: "6197687b956d954194412567",
        Industry: "Media & Entertainment",
        industry_code: "SV-ME",
      },
      {
        _id: "6197687b956d954194412565",
        Industry: "Hotels & Lodging",
        industry_code: "SV-HL",
      },
      {
        _id: "6197687b956d954194412563",
        Industry: "Casinos & Gaming",
        industry_code: "SV-CA",
      },
      {
        _id: "6197687b956d954194412564",
        Industry: "Education",
        industry_code: "SV-ED",
      },
      {
        _id: "6197687b956d954194412562",
        Industry: "Advertising & Marketing",
        industry_code: "SV-AD",
      },
      {
        _id: "6197687b956d954194412566",
        Industry: "Leisure Facilities",
        industry_code: "SV-LF",
      },
      {
        _id: "6197687b956d954194412568",
        Industry: "Professional & Commercial Services",
        industry_code: "SV-PS",
      },
    ],
  },
  {
    _id: "6197687b956d954194412521",
    Sector: "Extractives & Minerals Processing",
    industry_data: [
      {
        _id: "6197687b956d954194412538",
        Industry: "Oil & Gas – Refining & Marketing",
        industry_code: "EM-RM",
      },
      {
        _id: "6197687b956d954194412534",
        Industry: "Oil & Gas – Exploration & Production",
        industry_code: "EM-EP",
      },
      {
        _id: "6197687b956d954194412536",
        Industry: "Oil & Gas – Midstream",
        industry_code: "EM-MD",
      },
      {
        _id: "6197687b956d954194412532",
        Industry: "Construction Materials",
        industry_code: "EM-CM",
      },
      {
        _id: "6197687b956d954194412533",
        Industry: "Coal Operations",
        industry_code: "EM-CO",
      },
      {
        _id: "6197687b956d954194412535",
        Industry: "Iron & Steel Producers",
        industry_code: "EM-IS",
      },
      {
        _id: "6197687b956d954194412537",
        Industry: "Metals & Mining",
        industry_code: "EM-MM",
      },
      {
        _id: "6197687b956d954194412539",
        Industry: "Oil & Gas – Services",
        industry_code: "EM-SV",
      },
    ],
  },
];

export const settingsIndustries = [
  {
    _id: "6197687b956d954194412524",
    Sector: "Health Care",
    industry_data: [
      {
        _id: "6197687b956d95419441254d",
        Industry: "Managed Care",
        industry_code: "HC-MC",
      },
      {
        _id: "6197687b956d95419441254c",
        Industry: "Health Care Delivery",
        industry_code: "HC-DY",
      },
      {
        _id: "6197687b956d95419441254a",
        Industry: "Health Care Distributors",
        industry_code: "HC-DI",
      },
      {
        _id: "6197687b956d95419441254e",
        Industry: "Medical Equipment & Supplies",
        industry_code: "HC-MS",
      },
      {
        _id: "6197687b956d954194412549",
        Industry: "Biotechnology & Pharmaceuticals",
        industry_code: "HC-BP",
      },
      {
        _id: "6197687b956d95419441254b",
        Industry: "Drug Retailers",
        industry_code: "HC-DR",
      },
    ],
  },
  {
    _id: "6197687b956d954194412523",
    Sector: "Financials",
    industry_data: [
      {
        _id: "6197687b956d954194412547",
        Industry: "Insurance",
        industry_code: "FN-IN",
      },
      {
        _id: "6197687b956d954194412542",
        Industry: "Asset Management & Custody Activities",
        industry_code: "FN-AC",
      },
      {
        _id: "6197687b956d954194412545",
        Industry: "Security & Commodity Exchanges",
        industry_code: "FN-EX",
      },
      {
        _id: "6197687b956d954194412544",
        Industry: "Consumer Finance",
        industry_code: "FN-CF",
      },
      {
        _id: "6197687b956d954194412543",
        Industry: "Commercial Banks",
        industry_code: "FN-CB",
      },
      {
        _id: "6197687b956d954194412546",
        Industry: "Investment Banking & Brokerage",
        industry_code: "FN-IB",
      },
      {
        _id: "6197687b956d954194412548",
        Industry: "Mortgage Finance",
        industry_code: "FN-MF",
      },
    ],
  },
  {
    _id: "6197687b956d95419441252a",
    Sector: "Transportation",
    industry_data: [
      {
        _id: "6197687b956d954194412572",
        Industry: "Automobiles",
        industry_code: "TR-AU",
      },
      {
        _id: "6197687b956d954194412577",
        Industry: "Road Transportation",
        industry_code: "TR-RO",
      },
      {
        _id: "6197687b956d954194412575",
        Industry: "Marine Transportation",
        industry_code: "TR-MT",
      },
      {
        _id: "6197687b956d954194412570",
        Industry: "Airlines",
        industry_code: "TR-AL",
      },
      {
        _id: "6197687b956d954194412573",
        Industry: "Cruise Lines",
        industry_code: "TR-CL",
      },
      {
        _id: "6197687b956d954194412571",
        Industry: "Auto Parts",
        industry_code: "TR-AP",
      },
      {
        _id: "6197687b956d954194412574",
        Industry: "Car Rental & Leasing",
        industry_code: "TR-CR",
      },
      {
        _id: "6197687b956d95419441256f",
        Industry: "Air Freight & Logistics",
        industry_code: "TR-AF",
      },
      {
        _id: "6197687b956d954194412576",
        Industry: "Rail Transportation",
        industry_code: "TR-RA",
      },
    ],
  },
  {
    _id: "6197687b956d954194412527",
    Sector: "Resource Transformation",
    industry_data: [
      {
        _id: "6197687b956d954194412560",
        Industry: "Electrical & Electronic Equipment",
        industry_code: "RT-EE",
      },
      {
        _id: "6197687b956d95419441255d",
        Industry: "Aerospace & Defense",
        industry_code: "RT-AE",
      },
      {
        _id: "6197687b956d954194412561",
        Industry: "Industrial Machinery & Goods",
        industry_code: "RT-IG",
      },
      {
        _id: "6197687b956d95419441255e",
        Industry: "Chemicals",
        industry_code: "RT-CH",
      },
      {
        _id: "6197687b956d95419441255f",
        Industry: "Containers & Packaging",
        industry_code: "RT-CP",
      },
    ],
  },
  {
    _id: "6197687b956d954194412522",
    Sector: "Food & Beverage",
    industry_data: [
      {
        _id: "6197687b956d95419441253b",
        Industry: "Agricultural Products",
        industry_code: "FB-AG",
      },
      {
        _id: "6197687b956d95419441253c",
        Industry: "Food Retailers & Distributors",
        industry_code: "FB-FR",
      },
      {
        _id: "6197687b956d954194412541",
        Industry: "Tobacco",
        industry_code: "FB-TB",
      },
      {
        _id: "6197687b956d954194412540",
        Industry: "Restaurants",
        industry_code: "FB-RN",
      },
      {
        _id: "6197687b956d95419441253f",
        Industry: "Processed Foods",
        industry_code: "FB-PF",
      },
      {
        _id: "6197687b956d95419441253e",
        Industry: "Non-Alcoholic Beverages",
        industry_code: "FB-NB",
      },
      {
        _id: "6197687b956d95419441253d",
        Industry: "Meat, Poultry & Dairy",
        industry_code: "FB-MP",
      },
      {
        _id: "6197687b956d95419441253a",
        Industry: "Alcoholic Beverages",
        industry_code: "FB-AB",
      },
    ],
  },
  {
    _id: "6197687b956d954194412529",
    Sector: "Technology & Communications",
    industry_data: [
      {
        _id: "6197687b956d95419441256a",
        Industry: "Hardware",
        industry_code: "TC-HW",
      },
      {
        _id: "6197687b956d95419441256b",
        Industry: "Internet Media & Services",
        industry_code: "TC-IM",
      },
      {
        _id: "6197687b956d95419441256c",
        Industry: "Semiconductors",
        industry_code: "TC-SC",
      },
      {
        _id: "6197687b956d95419441256e",
        Industry: "Telecommunication Services",
        industry_code: "TC-TL",
      },
      {
        _id: "6197687b956d95419441256d",
        Industry: "Software & IT Services",
        industry_code: "TC-SI",
        isSelected: true,
      },
      {
        _id: "6197687b956d954194412569",
        Industry:
          "Electronic Manufacturing Services & Original Design Manufacturing",
        industry_code: "TC-ES",
      },
    ],
  },
  {
    _id: "6197687b956d954194412520",
    Sector: "Consumer Goods",
    industry_data: [
      {
        _id: "6197687b956d95419441252c",
        Industry: "Appliance Manufacturing",
        industry_code: "CG-AM",
      },
      {
        _id: "6197687b956d95419441252b",
        Industry: "Apparel, Accessories & Footwear",
        industry_code: "CG-AA",
      },
      {
        _id: "6197687b956d954194412531",
        Industry: "Toys & Sporting Goods",
        industry_code: "CG-TS",
      },
      {
        _id: "6197687b956d95419441252f",
        Industry: "Household & Personal Products",
        industry_code: "CG-HP",
      },
      {
        _id: "6197687b956d954194412530",
        Industry: "Multiline and Specialty Retailers & Distributors",
        industry_code: "CG-MR",
      },
      {
        _id: "6197687b956d95419441252e",
        Industry: "E-Commerce",
        industry_code: "CG-EC",
      },
      {
        _id: "6197687b956d95419441252d",
        Industry: "Building Products & Furnishings",
        industry_code: "CG-BF",
      },
    ],
  },
  {
    _id: "6197687b956d954194412525",
    Sector: "Infrastructure",
    industry_data: [
      {
        _id: "6197687b956d954194412551",
        Industry: "Gas Utilities & Distributors",
        industry_code: "IF-GU",
      },
      {
        _id: "6197687b956d954194412553",
        Industry: "Real Estate",
        industry_code: "IF-RE",
      },
      {
        _id: "6197687b956d954194412552",
        Industry: "Home Builders",
        industry_code: "IF-HB",
      },
      {
        _id: "6197687b956d954194412555",
        Industry: "Waste Management",
        industry_code: "IF-WM",
      },
      {
        _id: "6197687b956d954194412554",
        Industry: "Real Estate Services",
        industry_code: "IF-RS",
      },
      {
        _id: "6197687b956d95419441254f",
        Industry: "Engineering & Construction Services",
        industry_code: "IF-EN",
      },
      {
        _id: "6197687b956d954194412550",
        Industry: "Electric Utilities & Power Generators",
        industry_code: "IF-EU",
      },
      {
        _id: "6197687b956d954194412556",
        Industry: "Water Utilities & Services",
        industry_code: "IF-WU",
      },
    ],
  },
  {
    _id: "6197687b956d954194412526",
    Sector: "Renewable Resources & Alternative Energy",
    industry_data: [
      {
        _id: "6197687b956d95419441255c",
        Industry: "Wind Technology & Project Developers",
        industry_code: "RR-WT",
      },
      {
        _id: "6197687b956d95419441255b",
        Industry: "Solar Technology & Project Developers",
        industry_code: "RR-ST",
      },
      {
        _id: "6197687b956d954194412558",
        Industry: "Fuel Cells & Industrial Batteries",
        industry_code: "RR-FC",
      },
      {
        _id: "6197687b956d954194412559",
        Industry: "Forestry Management",
        industry_code: "RR-FM",
      },
      {
        _id: "6197687b956d954194412557",
        Industry: "Biofuels",
        industry_code: "RR-BI",
      },
      {
        _id: "6197687b956d95419441255a",
        Industry: "Pulp & Paper Products",
        industry_code: "RR-PP",
      },
    ],
  },
  {
    _id: "6197687b956d954194412528",
    Sector: "Services",
    industry_data: [
      {
        _id: "6197687b956d954194412567",
        Industry: "Media & Entertainment",
        industry_code: "SV-ME",
      },
      {
        _id: "6197687b956d954194412565",
        Industry: "Hotels & Lodging",
        industry_code: "SV-HL",
      },
      {
        _id: "6197687b956d954194412563",
        Industry: "Casinos & Gaming",
        industry_code: "SV-CA",
      },
      {
        _id: "6197687b956d954194412564",
        Industry: "Education",
        industry_code: "SV-ED",
      },
      {
        _id: "6197687b956d954194412562",
        Industry: "Advertising & Marketing",
        industry_code: "SV-AD",
      },
      {
        _id: "6197687b956d954194412566",
        Industry: "Leisure Facilities",
        industry_code: "SV-LF",
      },
      {
        _id: "6197687b956d954194412568",
        Industry: "Professional & Commercial Services",
        industry_code: "SV-PS",
      },
    ],
  },
  {
    _id: "6197687b956d954194412521",
    Sector: "Extractives & Minerals Processing",
    industry_data: [
      {
        _id: "6197687b956d954194412538",
        Industry: "Oil & Gas – Refining & Marketing",
        industry_code: "EM-RM",
      },
      {
        _id: "6197687b956d954194412534",
        Industry: "Oil & Gas – Exploration & Production",
        industry_code: "EM-EP",
      },
      {
        _id: "6197687b956d954194412536",
        Industry: "Oil & Gas – Midstream",
        industry_code: "EM-MD",
      },
      {
        _id: "6197687b956d954194412532",
        Industry: "Construction Materials",
        industry_code: "EM-CM",
      },
      {
        _id: "6197687b956d954194412533",
        Industry: "Coal Operations",
        industry_code: "EM-CO",
      },
      {
        _id: "6197687b956d954194412535",
        Industry: "Iron & Steel Producers",
        industry_code: "EM-IS",
      },
      {
        _id: "6197687b956d954194412537",
        Industry: "Metals & Mining",
        industry_code: "EM-MM",
      },
      {
        _id: "6197687b956d954194412539",
        Industry: "Oil & Gas – Services",
        industry_code: "EM-SV",
      },
    ],
  },
];

export const settingsUsers = [
  {
    _id: "1",
    name: "Saad Ansari",
    email: "vaibhaw@appstone.in",
  },
  {
    _id: "2",
    name: "Vaibhaw Mishra",
    email: "baibhav@appstone.in",
  },
  {
    _id: "3",
    name: "Baibhav Ray",
    email: "saad@appstone.in",
  },
];

export const vendorCountries = [
  {
    _id: "62f0bda085121dcc6fbc928c",
    country_code: "DM",
    country_name: "Dominica",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/dm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9258",
    country_code: "AM",
    country_name: "Armenia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/am.png",
  },
  {
    _id: "62f0bda085121dcc6fbc926f",
    country_code: "BR",
    country_name: "Brazil",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/br.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9257",
    country_code: "AL",
    country_name: "Albania",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/al.png",
  },
  {
    _id: "62f0bda085121dcc6fbc925d",
    country_code: "AT",
    country_name: "Austria",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/at.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9266",
    country_code: "BF",
    country_name: "Burkina Faso",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9253",
    country_code: "AE",
    country_name: "United Arab Emirates",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ae.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9278",
    country_code: "CD",
    country_name: "Congo, Democratic Republic of the",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cd.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9256",
    country_code: "AI",
    country_name: "Anguilla",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ai.png",
  },
  {
    _id: "62f0bda085121dcc6fbc925a",
    country_code: "AQ",
    country_name: "Antarctica",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/aq.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9259",
    country_code: "AO",
    country_name: "Angola",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ao.png",
  },
  {
    _id: "62f0bda085121dcc6fbc926b",
    country_code: "BL",
    country_name: "Saint Barthelemy",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bl.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9252",
    country_code: "AD",
    country_name: "Andorra",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ad.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9255",
    country_code: "AG",
    country_name: "Antigua and Barbuda",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ag.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9261",
    country_code: "AZ",
    country_name: "Azerbaijan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/az.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9254",
    country_code: "AF",
    country_name: "Afghanistan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/af.png",
  },
  {
    _id: "62f0bda085121dcc6fbc926e",
    country_code: "BO",
    country_name: "Bolivia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bo.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9279",
    country_code: "CF",
    country_name: "Central African Republic",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9282",
    country_code: "CR",
    country_name: "Costa Rica",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9288",
    country_code: "CZ",
    country_name: "Czech Republic",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b3",
    country_code: "HT",
    country_name: "Haiti",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ht.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b8",
    country_code: "IM",
    country_name: "Isle of Man",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/im.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c4",
    country_code: "KG",
    country_name: "Kyrgyzstan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/kg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c8",
    country_code: "KN",
    country_name: "Saint Kitts and Nevis",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/kn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d1",
    country_code: "LI",
    country_name: "Liechtenstein",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/li.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d2",
    country_code: "LK",
    country_name: "Sri Lanka",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/lk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e1",
    country_code: "ML",
    country_name: "Mali",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ml.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e3",
    country_code: "MN",
    country_name: "Mongolia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e6",
    country_code: "MQ",
    country_name: "Martinique",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mq.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9302",
    country_code: "PK",
    country_name: "Pakistan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9305",
    country_code: "PN",
    country_name: "Pitcairn",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9307",
    country_code: "PS",
    country_name: "Palestine, State of",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ps.png",
  },
  {
    _id: "62f0bda085121dcc6fbc930a",
    country_code: "PY",
    country_name: "Paraguay",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/py.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9320",
    country_code: "SS",
    country_name: "South Sudan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ss.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9340",
    country_code: "VN",
    country_name: "Vietnam",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/vn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9341",
    country_code: "VU",
    country_name: "Vanuatu",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/vu.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9283",
    country_code: "CU",
    country_name: "Cuba",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cu.png",
  },
  {
    _id: "62f0bda085121dcc6fbc928a",
    country_code: "DJ",
    country_name: "Djibouti",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/dj.png",
  },
  {
    _id: "62f0bda085121dcc6fbc929e",
    country_code: "GD",
    country_name: "Grenada",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gd.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a5",
    country_code: "GM",
    country_name: "Gambia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92af",
    country_code: "HK",
    country_name: "Hong Kong",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/hk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b5",
    country_code: "ID",
    country_name: "Indonesia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/id.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c6",
    country_code: "KI",
    country_name: "Kiribati",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ki.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92cc",
    country_code: "KY",
    country_name: "Cayman Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ky.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92df",
    country_code: "MH",
    country_name: "Marshall Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mh.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e4",
    country_code: "MO",
    country_name: "Macao",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mo.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f6",
    country_code: "NL",
    country_name: "Netherlands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/nl.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9304",
    country_code: "PM",
    country_name: "Saint Pierre and Miquelon",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9338",
    country_code: "US",
    country_name: "United States",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/us.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9339",
    country_code: "UY",
    country_name: "Uruguay",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/uy.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9345",
    country_code: "YE",
    country_name: "Yemen",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ye.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9267",
    country_code: "BG",
    country_name: "Bulgaria",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc925b",
    country_code: "AR",
    country_name: "Argentina",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ar.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92aa",
    country_code: "GS",
    country_name: "South Georgia and the South Sandwich Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gs.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9264",
    country_code: "BD",
    country_name: "Bangladesh",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bd.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b7",
    country_code: "IL",
    country_name: "Israel",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/il.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9268",
    country_code: "BH",
    country_name: "Bahrain",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bh.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d3",
    country_code: "LR",
    country_name: "Liberia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/lr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc927e",
    country_code: "CL",
    country_name: "Chile",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cl.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9289",
    country_code: "DE",
    country_name: "Germany",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/de.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f5",
    country_code: "NI",
    country_name: "Nicaragua",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ni.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9312",
    country_code: "SB",
    country_name: "Solomon Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sb.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b4",
    country_code: "HU",
    country_name: "Hungary",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/hu.png",
  },
  {
    _id: "62f0bda085121dcc6fbc927c",
    country_code: "CI",
    country_name: "Cote d'Ivoire",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ci.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9316",
    country_code: "SG",
    country_name: "Singapore",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92bd",
    country_code: "IS",
    country_name: "Iceland",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/is.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9284",
    country_code: "CV",
    country_name: "Cape Verde",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cv.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9324",
    country_code: "SY",
    country_name: "Syrian Arab Republic",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sy.png",
  },
  {
    _id: "62f0bda085121dcc6fbc928b",
    country_code: "DK",
    country_name: "Denmark",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/dk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d7",
    country_code: "LV",
    country_name: "Latvia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/lv.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e2",
    country_code: "MM",
    country_name: "Myanmar",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9325",
    country_code: "SZ",
    country_name: "Swaziland",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9291",
    country_code: "EG",
    country_name: "Egypt",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/eg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc929c",
    country_code: "GA",
    country_name: "Gabon",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ga.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e7",
    country_code: "MR",
    country_name: "Mauritania",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc933c",
    country_code: "VC",
    country_name: "Saint Vincent and the Grenadines",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/vc.png",
  },
  {
    _id: "62f0bda085121dcc6fbc929f",
    country_code: "GE",
    country_name: "Georgia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ge.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9348",
    country_code: "ZM",
    country_name: "Zambia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/zm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f0",
    country_code: "NA",
    country_name: "Namibia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/na.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9308",
    country_code: "PT",
    country_name: "Portugal",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pt.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ac",
    country_code: "GU",
    country_name: "Guam",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gu.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c3",
    country_code: "KE",
    country_name: "Kenya",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ke.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9315",
    country_code: "SE",
    country_name: "Sweden",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/se.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9318",
    country_code: "SI",
    country_name: "Slovenia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/si.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d0",
    country_code: "LC",
    country_name: "Saint Lucia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/lc.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ed",
    country_code: "MX",
    country_name: "Mexico",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mx.png",
  },
  {
    _id: "62f0bda085121dcc6fbc931e",
    country_code: "SO",
    country_name: "Somalia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/so.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9323",
    country_code: "SX",
    country_name: "Sint Maarten (Dutch part)",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sx.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ef",
    country_code: "MZ",
    country_name: "Mozambique",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92fa",
    country_code: "NU",
    country_name: "Niue",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/nu.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9327",
    country_code: "TD",
    country_name: "Chad",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/td.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9303",
    country_code: "PL",
    country_name: "Poland",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pl.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9309",
    country_code: "PW",
    country_name: "Palau",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc930d",
    country_code: "RO",
    country_name: "Romania",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ro.png",
  },
  {
    _id: "62f0bda085121dcc6fbc930f",
    country_code: "RU",
    country_name: "Russian Federation",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ru.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9322",
    country_code: "SV",
    country_name: "El Salvador",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sv.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9269",
    country_code: "BI",
    country_name: "Burundi",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bi.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9295",
    country_code: "ET",
    country_name: "Ethiopia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/et.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9297",
    country_code: "FJ",
    country_name: "Fiji",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/fj.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a3",
    country_code: "GI",
    country_name: "Gibraltar",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gi.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ae",
    country_code: "GY",
    country_name: "Guyana",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gy.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92bc",
    country_code: "IR",
    country_name: "Iran, Islamic Republic of",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ir.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92dc",
    country_code: "ME",
    country_name: "Montenegro",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/me.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f3",
    country_code: "NF",
    country_name: "Norfolk Island",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/nf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92fb",
    country_code: "NZ",
    country_name: "New Zealand",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/nz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9300",
    country_code: "PG",
    country_name: "Papua New Guinea",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9314",
    country_code: "SD",
    country_name: "Sudan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sd.png",
  },
  {
    _id: "62f0bda085121dcc6fbc931c",
    country_code: "SM",
    country_name: "San Marino",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9333",
    country_code: "TV",
    country_name: "Tuvalu",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tv.png",
  },
  {
    _id: "62f0bda085121dcc6fbc933f",
    country_code: "VI",
    country_name: "US Virgin Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/vi.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9342",
    country_code: "WF",
    country_name: "Wallis and Futuna",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/wf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9346",
    country_code: "YT",
    country_name: "Mayotte",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/yt.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c2",
    country_code: "JP",
    country_name: "Japan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/jp.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ee",
    country_code: "MY",
    country_name: "Malaysia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/my.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f1",
    country_code: "NC",
    country_name: "New Caledonia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/nc.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92fc",
    country_code: "OM",
    country_name: "Oman",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/om.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9306",
    country_code: "PR",
    country_name: "Puerto Rico",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc931d",
    country_code: "SN",
    country_name: "Senegal",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc931f",
    country_code: "SR",
    country_name: "Suriname",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9321",
    country_code: "ST",
    country_name: "Sao Tome and Principe",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/st.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9336",
    country_code: "UA",
    country_name: "Ukraine",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ua.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9260",
    country_code: "AX",
    country_name: "Alland Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ax.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9277",
    country_code: "CC",
    country_name: "Cocos (Keeling) Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cc.png",
  },
  {
    _id: "62f0bda085121dcc6fbc927f",
    country_code: "CM",
    country_name: "Cameroon",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9281",
    country_code: "CO",
    country_name: "Colombia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/co.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9285",
    country_code: "CW",
    country_name: "Curacao",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc929d",
    country_code: "GB",
    country_name: "United Kingdom",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gb.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b2",
    country_code: "HR",
    country_name: "Croatia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/hr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d9",
    country_code: "MA",
    country_name: "Morocco",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ma.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92dd",
    country_code: "MF",
    country_name: "Saint Martin (French part)",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc930b",
    country_code: "QA",
    country_name: "Qatar",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/qa.png",
  },
  {
    _id: "62f0bda085121dcc6fbc932f",
    country_code: "TN",
    country_name: "Tunisia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9330",
    country_code: "TO",
    country_name: "Tonga",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/to.png",
  },
  {
    _id: "62f0bda085121dcc6fbc925e",
    country_code: "AU",
    country_name: "Australia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/au.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9343",
    country_code: "WS",
    country_name: "Samoa",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ws.png",
  },
  {
    _id: "62f0bda085121dcc6fbc926d",
    country_code: "BN",
    country_name: "Brunei Darussalam",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9272",
    country_code: "BV",
    country_name: "Bouvet Island",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bv.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d5",
    country_code: "LT",
    country_name: "Lithuania",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/lt.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92da",
    country_code: "MC",
    country_name: "Monaco",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mc.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92db",
    country_code: "MD",
    country_name: "Moldova, Republic of",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/md.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e5",
    country_code: "MP",
    country_name: "Northern Mariana Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mp.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f4",
    country_code: "NG",
    country_name: "Nigeria",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ng.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9319",
    country_code: "SJ",
    country_name: "Svalbard and Jan Mayen",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sj.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9331",
    country_code: "TR",
    country_name: "Turkey",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc933d",
    country_code: "VE",
    country_name: "Venezuela",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ve.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9349",
    country_code: "ZW",
    country_name: "Zimbabwe",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/zw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9290",
    country_code: "EE",
    country_name: "Estonia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ee.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b9",
    country_code: "IN",
    country_name: "India",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/in.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92be",
    country_code: "IT",
    country_name: "Italy",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/it.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ca",
    country_code: "KR",
    country_name: "Korea, Republic of",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/kr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92cb",
    country_code: "KW",
    country_name: "Kuwait",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/kw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f9",
    country_code: "NR",
    country_name: "Nauru",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/nr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92fd",
    country_code: "PA",
    country_name: "Panama",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pa.png",
  },
  {
    _id: "62f0bda085121dcc6fbc930e",
    country_code: "RS",
    country_name: "Serbia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/rs.png",
  },
  {
    _id: "62f0bda085121dcc6fbc932c",
    country_code: "TK",
    country_name: "Tokelau",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc932e",
    country_code: "TM",
    country_name: "Turkmenistan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9263",
    country_code: "BB",
    country_name: "Barbados",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bb.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9270",
    country_code: "BS",
    country_name: "Bahamas",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bs.png",
  },
  {
    _id: "62f0bda085121dcc6fbc928d",
    country_code: "DO",
    country_name: "Dominican Republic",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/do.png",
  },
  {
    _id: "62f0bda085121dcc6fbc929b",
    country_code: "FR",
    country_name: "France",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/fr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a6",
    country_code: "GN",
    country_name: "Guinea",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92bf",
    country_code: "JE",
    country_name: "Jersey",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/je.png",
  },
  {
    _id: "62f0bda085121dcc6fbc931b",
    country_code: "SL",
    country_name: "Sierra Leone",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sl.png",
  },
  {
    _id: "62f0bda085121dcc6fbc932b",
    country_code: "TJ",
    country_name: "Tajikistan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tj.png",
  },
  {
    _id: "62f0bda085121dcc6fbc933b",
    country_code: "VA",
    country_name: "Holy See (Vatican City State)",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/va.png",
  },
  {
    _id: "62f0bda085121dcc6fbc933e",
    country_code: "VG",
    country_name: "British Virgin Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/vg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc926a",
    country_code: "BJ",
    country_name: "Benin",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bj.png",
  },
  {
    _id: "62f0bda085121dcc6fbc926c",
    country_code: "BM",
    country_name: "Bermuda",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9273",
    country_code: "BW",
    country_name: "Botswana",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9274",
    country_code: "BY",
    country_name: "Belarus",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/by.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a8",
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gq.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b1",
    country_code: "HN",
    country_name: "Honduras",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/hn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c0",
    country_code: "JM",
    country_name: "Jamaica",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/jm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c9",
    country_code: "KP",
    country_name: "Korea, Democratic People's Republic of",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/kp.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92cd",
    country_code: "KZ",
    country_name: "Kazakhstan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/kz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92fe",
    country_code: "PE",
    country_name: "Peru",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pe.png",
  },
  {
    _id: "62f0bda085121dcc6fbc931a",
    country_code: "SK",
    country_name: "Slovakia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9329",
    country_code: "TG",
    country_name: "Togo",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9265",
    country_code: "BE",
    country_name: "Belgium",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/be.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9271",
    country_code: "BT",
    country_name: "Bhutan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bt.png",
  },
  {
    _id: "62f0bda085121dcc6fbc927d",
    country_code: "CK",
    country_name: "Cook Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ck.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9286",
    country_code: "CX",
    country_name: "Christmas Island",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cx.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a0",
    country_code: "GF",
    country_name: "French Guiana",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a1",
    country_code: "GG",
    country_name: "Guernsey",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a2",
    country_code: "GH",
    country_name: "Ghana",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gh.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a7",
    country_code: "GP",
    country_name: "Guadeloupe",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gp.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e0",
    country_code: "MK",
    country_name: "Macedonia, the Former Yugoslav Republic of",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e8",
    country_code: "MS",
    country_name: "Montserrat",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ms.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92eb",
    country_code: "MV",
    country_name: "Maldives",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mv.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ff",
    country_code: "PF",
    country_name: "French Polynesia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/pf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc930c",
    country_code: "RE",
    country_name: "Reunion",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/re.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9313",
    country_code: "SC",
    country_name: "Seychelles",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sc.png",
  },
  {
    _id: "62f0bda085121dcc6fbc925c",
    country_code: "AS",
    country_name: "American Samoa",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/as.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9275",
    country_code: "BZ",
    country_name: "Belize",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/bz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc927b",
    country_code: "CH",
    country_name: "Switzerland",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ch.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9280",
    country_code: "CN",
    country_name: "China",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cn.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9293",
    country_code: "ER",
    country_name: "Eritrea",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/er.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9296",
    country_code: "FI",
    country_name: "Finland",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/fi.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ad",
    country_code: "GW",
    country_name: "Guinea-Bissau",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b0",
    country_code: "HM",
    country_name: "Heard Island and McDonald Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/hm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ba",
    country_code: "IO",
    country_name: "British Indian Ocean Territory",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/io.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92bb",
    country_code: "IQ",
    country_name: "Iraq",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/iq.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c7",
    country_code: "KM",
    country_name: "Comoros",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/km.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d4",
    country_code: "LS",
    country_name: "Lesotho",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ls.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92e9",
    country_code: "MT",
    country_name: "Malta",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mt.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f7",
    country_code: "NO",
    country_name: "Norway",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/no.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9326",
    country_code: "TC",
    country_name: "Turks and Caicos Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tc.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9332",
    country_code: "TT",
    country_name: "Trinidad and Tobago",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tt.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9347",
    country_code: "ZA",
    country_name: "South Africa",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/za.png",
  },
  {
    _id: "62f0bda085121dcc6fbc925f",
    country_code: "AW",
    country_name: "Aruba",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/aw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9276",
    country_code: "CA",
    country_name: "Canada",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ca.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9292",
    country_code: "EH",
    country_name: "Western Sahara",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/eh.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9298",
    country_code: "FK",
    country_name: "Falkland Islands (Malvinas)",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/fk.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9299",
    country_code: "FM",
    country_name: "Micronesia, Federated States of",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/fm.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a9",
    country_code: "GR",
    country_name: "Greece",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gr.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ab",
    country_code: "GT",
    country_name: "Guatemala",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gt.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c1",
    country_code: "JO",
    country_name: "Jordan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/jo.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92c5",
    country_code: "KH",
    country_name: "Cambodia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/kh.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ce",
    country_code: "LA",
    country_name: "Lao People's Democratic Republic",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/la.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92cf",
    country_code: "LB",
    country_name: "Lebanon",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/lb.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d8",
    country_code: "LY",
    country_name: "Libya",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ly.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ea",
    country_code: "MU",
    country_name: "Mauritius",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mu.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92ec",
    country_code: "MW",
    country_name: "Malawi",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9301",
    country_code: "PH",
    country_name: "Philippines",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ph.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9317",
    country_code: "SH",
    country_name: "Saint Helena",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sh.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9328",
    country_code: "TF",
    country_name: "French Southern Territories",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tf.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9334",
    country_code: "TW",
    country_name: "Taiwan, Province of China",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9262",
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ba.png",
  },
  {
    _id: "62f0bda085121dcc6fbc927a",
    country_code: "CG",
    country_name: "Congo, Republic of the",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9287",
    country_code: "CY",
    country_name: "Cyprus",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/cy.png",
  },
  {
    _id: "62f0bda085121dcc6fbc928e",
    country_code: "DZ",
    country_name: "Algeria",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/dz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc928f",
    country_code: "EC",
    country_name: "Ecuador",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ec.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9294",
    country_code: "ES",
    country_name: "Spain",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/es.png",
  },
  {
    _id: "62f0bda085121dcc6fbc929a",
    country_code: "FO",
    country_name: "Faroe Islands",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/fo.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92a4",
    country_code: "GL",
    country_name: "Greenland",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/gl.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92b6",
    country_code: "IE",
    country_name: "Ireland",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ie.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92d6",
    country_code: "LU",
    country_name: "Luxembourg",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/lu.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92de",
    country_code: "MG",
    country_name: "Madagascar",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/mg.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f2",
    country_code: "NE",
    country_name: "Niger",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ne.png",
  },
  {
    _id: "62f0bda085121dcc6fbc92f8",
    country_code: "NP",
    country_name: "Nepal",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/np.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9310",
    country_code: "RW",
    country_name: "Rwanda",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/rw.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9311",
    country_code: "SA",
    country_name: "Saudi Arabia",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/sa.png",
  },
  {
    _id: "62f0bda085121dcc6fbc932a",
    country_code: "TH",
    country_name: "Thailand",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/th.png",
  },
  {
    _id: "62f0bda085121dcc6fbc932d",
    country_code: "TL",
    country_name: "Timor-Leste",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tl.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9335",
    country_code: "TZ",
    country_name: "United Republic of Tanzania",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/tz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9337",
    country_code: "UG",
    country_name: "Uganda",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/ug.png",
  },
  {
    _id: "62f0bda085121dcc6fbc933a",
    country_code: "UZ",
    country_name: "Uzbekistan",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/uz.png",
  },
  {
    _id: "62f0bda085121dcc6fbc9344",
    country_code: "XK",
    country_name: "Kosovo",
    country_flag: "https://flags.fmcdn.net/data/flags/mini/xk.png",
  },
];

export const vendorOrgs = [
  {
    _id: "65a78e01e236992711b6f1be",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "Prolender",
    type: "Existing Vendor",
    website: "http://www.prolender.com/",
    logo: "https://shorturl.at/qsF13",
    userName: "Baibhav Ray",
    userDesignation: "Tech Lead",
    country: "Canada",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN001",
    year_of_assessment: "2023-2024",
    operation:
      "The simple, yet comprehensive solution to your mortgage banking needs.",
    no_of_employees: "0-50",
    status: "incomplete",
    address: [
      {
        landmark: "",
        state: "San Diego",
        companyAddress: "6050 Santo Road",
        landMark: "Suite 160",
        pinCode: "",
        country: "CA",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2024-01-17T08:21:21.401Z",
    modified_by: "63ee1726c317ed7d780980a4",
    modified_at: "2024-01-17T07:15:11.365Z",

    // stakeholders: [{name: ""}]
  },

  {
    _id: "65a78e01e236992711b6f1bf",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "AWS",
    type: "New Vendor",
    website: "https://aws.amazon.com/",
    logo: "https://shorturl.at/etASV",
    userName: "Saad Ansari",
    userDesignation: "Director",
    country: "United States",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN002",
    year_of_assessment: "2022-2024",
    operation:
      "Amazon Web Services offers reliable, scalable, and inexpensive cloud computing services.",
    no_of_employees: "Above 5000",
    status: "incomplete",
    address: [
      {
        landmark: "",
        state: "Seattle",
        companyAddress: "",
        landMark: "land mark",
        pinCode: "",
        country: "WA",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2023-06-18T07:15:11.365Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2023-06-18T07:15:11.365Z",
  },

  {
    _id: "65a78e01e236992711b6f1c0",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "Oracle",
    type: "Existing Vendor",
    website: "http://www.oracle.com",
    logo: "https://shorturl.at/BPQY1",
    userName: "Vaibhaw Mishra",
    userDesignation: "SDE",
    country: "United States",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN003",
    year_of_assessment: "2023-2024",
    operation:
      "Oracle offers a comprehensive and fully integrated stack of cloud applications and cloud platform services.",
    no_of_employees: "Above 5000",
    status: "complete",
    address: [
      {
        landmark: "",
        state: "Austin",
        companyAddress: "",
        landMark: "",
        pinCode: "",
        country: "Texas",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2024-01-18T07:15:11.365Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2024-01-18T07:15:11.365Z",
  },

  {
    _id: "65a78e01e236992711b6f1c1",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "S&P Global",
    type: "New Vendor",
    website: "http://www.spglobal.com",
    logo: "https://shorturl.at/fuC06",
    userName: "Arichwal Kumar",
    userDesignation: "SDE",
    country: "India",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN003",
    year_of_assessment: "2021-2022",
    operation:
      "We provide iconic and innovative index solutions backed by unparalleled expertise across the asset-class spectrum.",
    no_of_employees: "10001-5000",
    status: "incomplete",
    address: [
      {
        landmark: "",
        state: "",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2024-02-06T07:15:11.365Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2024-02-06T07:15:11.365Z",
  },

  {
    _id: "65a78e01e236992711b6f1c2",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "Dun & Bradstreet",
    type: "Existing Vendor",
    website: "https://www.dnb.co.in/",
    logo: "https://shorturl.at/jIRY2",
    userName: "Vipul Kumar",
    userDesignation: "ASD",
    country: "India",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN003",
    year_of_assessment: "2023-2024",
    operation:
      "Dun & Bradstreet, a leading global provider of business decisioning data and analytics",
    no_of_employees: "251-1000",
    status: "complete",
    address: [
      {
        landmark: "",
        state: "",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2022-12-18T07:15:11.365Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2022-12-18T07:15:11.365Z",
  },

  {
    _id: "65a78e01e236992711b6f1c3",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "Dell",
    type: "Existing Vendor",
    website: "https://www.dell.com/",
    logo: "https://shorturl.at/jlxR3",
    userName: "Aman Raj",
    userDesignation: "SDE",
    country: "India",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN003",
    year_of_assessment: "2023-2024",
    operation:
      "Dell provides technology solutions, services & support. Buy Laptops, Touch Screen PCs, Desktops, Servers, Storage, Monitors, Gaming & Accessories.",
    no_of_employees: "251-1000",
    status: "incomplete",
    address: [
      {
        landmark: "",
        state: "",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2024-01-02T07:15:11.365Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2024-01-02T07:15:11.365Z",
  },

  {
    _id: "65a78e01e236992711b6f1c4",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "Fortinet",
    type: "New Vendor",
    website: "https://www.fortinet.com/",
    logo: "https://shorturl.at/dCG16",
    userName: "Alok Kumar",
    userDesignation: "SDE",
    country: "United States",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN003",
    year_of_assessment: "2023-2024",
    operation: "Fortinet delivers cybersecurity everywhere you need it.",
    no_of_employees: "251-1000",
    status: "incomplete",
    address: [
      {
        landmark: "",
        state: "",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2023-05-18T07:15:11.365Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2023-05-18T07:15:11.365Z",
  },

  {
    _id: "65a78e01e236992711b6f1c5",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "Cisco",
    type: "Existing Vendor",
    website: "https://www.cisco.com/",
    logo: "https://shorturl.at/dipKW",
    userName: "Shambhavi Singh",
    userDesignation: "HR",
    country: "India",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN003",
    year_of_assessment: "2023-2024",
    operation:
      "Cisco delivers innovative software-defined networking, cloud, and security solutions to help transform your business, empowering an inclusive future.",
    no_of_employees: "Above 5000",
    status: "complete",
    address: [
      {
        landmark: "",
        state: "",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2023-02-19T08:16:12.466Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2023-02-19T08:16:12.466Z",
  },

  {
    _id: "65a78e01e236992711b6f1c6",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "Azure",
    type: "Existing Vendor",
    website: "https://azure.microsoft.com/en-in",
    logo: "https://shorturl.at/osD89",
    userName: "Akshat Mishra",
    userDesignation: "Director",
    country: "India",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN003",
    year_of_assessment: "2023-2024",
    operation:
      "Invent with purpose, realize cost savings, and make your organization more efficient with Microsoft Azure's open and flexible cloud computing platform.",
    no_of_employees: "251-1000",
    status: "incomplete",
    address: [
      {
        landmark: "",
        state: "",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2024-01-18T07:15:11.365Z",
    modified_by: "63e20b1959c56d85c5910042",
    modified_at: "2024-01-18T07:15:11.365Z",
  },
];

export const vendorDetails = [
  {
    _id: "65a78e01e236992711b6f1bd",
    bank_id: "63da5f8a6369492c38bedef1",
    name: "vendor updated",
    logo: null,
    country: "IN",
    sectors: [
      {
        sector: "6197687b956d954194412524",
        industry: ["6197687b956d95419441254d"],
      },
    ],
    isin: "ISIN001",
    year_of_assessment: "2023-2024",
    operation: "ops",
    no_of_employees: "251-1000",
    status: "complete",
    address: [
      {
        landmark: "",
        state: "Odisha",
        companyAddress: "12 fv",
        landMark: "land mark",
        pinCode: "560061",
        country: "India",
      },
    ],
    created_by: "63e20b1959c56d85c5910042",
    created_at: "2024-01-17T08:21:21.401Z",
    modified_by: "63ee1726c317ed7d780980a4",
    modified_at: "2024-01-18T07:15:11.365Z",
  },
];

export const vendorUsers = [
  // {
  //   _id: "65a8c8a58a4f1eb4ecc2c9a1",
  //   name: "Baibhav Ray",
  //   email: "vendor@gmail.com",
  //   role: "dev",
  //   category: "Employees",
  //   type: "",
  //   status: "Not Sent",
  // },
  { name: "saad", email: "saad@appstone.in", role: "dev", id: 0 },
  { name: "vaibhaw", email: "vaibhaw@appstone.in", role: "dev", id: 1 },
  { name: "bhaibhav", email: "bhaibhav@appstone.in", role: "dev", id: 2 },
  { name: "deepak", email: "deepak@appstone.in", role: "dev", id: 3 },
  { name: "john", email: "john@example.com", role: "manager", id: 4 },
  { name: "alice", email: "alice@example.com", role: "designer", id: 5 },
  { name: "bob", email: "bob@example.com", role: "dev", id: 6 },
  { name: "emma", email: "emma@example.com", role: "manager", id: 7 },
  { name: "michael", email: "michael@example.com", role: "dev", id: 8 },
  { name: "sara", email: "sara@example.com", role: "designer", id: 9 },
  { name: "peter", email: "peter@example.com", role: "dev", id: 10 },
  { name: "linda", email: "linda@example.com", role: "manager", id: 11 },
  { name: "tom", email: "tom@example.com", role: "dev", id: 12 },
  { name: "jane", email: "jane@example.com", role: "designer", id: 13 },
  { name: "mark", email: "mark@example.com", role: "dev", id: 14 },
];

export const surveySections = [
  {
    _id: "659fb3dff62b501a0bb1354d",
    title: "Company Profile",
    default: true,
    active: true,
    order: 1,
    last_published_by: "63e20b1959c56d85c5910042",
    last_published_on: "2024-02-01T09:15:26.192Z",
    survey_id: "65ba207c058e57a4a9a37b35",
    is_published: true,
    version: 1,
  },
  {
    _id: "65bb79576b299654c6e88c9a",
    title: "Information Security",
    default: true,
    active: true,
    order: 1,
    last_published_by: null,
    last_published_on: null,
    survey_id: "65ba207c058e57a4a9a37b35",
    is_published: true,
    version: 2,
  },
  {
    _id: "65bb8f5dabcf92c1432cd22e",
    title: "Data Privacy",
    active: true,
    order: 1,
    last_published_by: null,
    last_published_on: null,
    survey_id: "65ba207c058e57a4a9a37b35",
    default: true,
    is_published: true,
    version: 3,
  },
  {
    _id: "65bb96aed1fd1db04f2ded24",
    title: "Third Party/Subcontractors ",
    active: true,
    order: 1,
    last_published_by: null,
    last_published_on: null,
    survey_id: "65ba207c058e57a4a9a37b35",
    default: true,
    is_published: false,
    version: 4,
  },
  {
    _id: "659fb470f62b501a0bb1354e",
    title: "Business and Ethical Conduct",
    default: true,
    active: true,
    order: 2,
    last_published_by: "63e20b1959c56d85c5910042",
    last_published_on: "2024-02-01T09:15:26.192Z",
    survey_id: "65ba207c058e57a4a9a37b35",
    is_published: true,
    version: 1,
  },
  {
    _id: "65bb79876b299654c6e88c9b",
    title: "Environment",
    default: true,
    active: true,
    order: 2,
    last_published_by: null,
    last_published_on: null,
    survey_id: "65ba207c058e57a4a9a37b35",
    is_published: true,
    version: 2,
  },
  {
    _id: "65bb8f5dabcf92c1432cd257",
    title: "Labour Standards",
    active: true,
    order: 2,
    last_published_by: null,
    last_published_on: null,
    survey_id: "65ba207c058e57a4a9a37b35",
    default: true,
    is_published: true,
    version: 3,
  },
  {
    _id: "65bb96aed1fd1db04f2ded4d",
    title: "Human Rights",
    active: true,
    order: 2,
    last_published_by: null,
    last_published_on: null,
    survey_id: "65ba207c058e57a4a9a37b35",
    default: true,
    is_published: false,
    version: 4,
  },
  {
    _id: "65bb9662d1fd1db04f2ded21",
    title: "Compliance Compliance",
    default: false,
    active: true,
    order: 4,
    show: true,
    mandatory: false,
    is_published: true,
    version: 3,
  },
  {
    _id: "65bb9662d1fd1db04f2ded21",
    title: "Risk Management",
    default: false,
    active: true,
    order: 4,
    show: true,
    mandatory: false,
    is_published: true,
    version: 3,
  },
  {
    _id: "65bb9662d1fd1db04f2ded21",
    title: "Organizational Security",
    default: false,
    active: true,
    order: 4,
    show: true,
    mandatory: false,
    is_published: true,
    version: 3,
  },
  {
    _id: "65bb9662d1fd1db04f2ded21",
    title: "Physical and Environmental Security",
    default: false,
    active: true,
    order: 4,
    show: true,
    mandatory: false,
    is_published: true,
    version: 3,
  },
];

export const initialData = [
  {
    survey_name: "survey1",
    // survey_type: surveyType,
    survey_createdBy: "Saad",
    survey_dated: "Jan 20, 2022",
    survey_status: true,
    survey_description: "default section",
  },
  {
    survey_name: "survey2",
    // survey_type: surveyType,
    survey_createdBy: "baibhav",
    survey_dated: "feb 29, 2022",
    survey_status: true,
    survey_description: "custom section",
  },
];

export const initialData2 = {
  survey1: [
    { title: "Self Assessment Section" },
    { title: "Controversy Survey" },
  ],
  survey2: [
    { title: "Company Profile" },
    { title: "Information Security" },
    { title: "Data Privacy" },
    { title: "Third Party/Subcontractors" },
    { title: "Business and Ethical Conduct" },
    { title: "Environment" },
    { title: "Labour Standards" },
    { title: "Human Rights" },
    { title: "Compliance Compliance" },
    { title: "Risk Management" },
    { title: "Organizational Security" },
    { title: "Physical and Environmental Security" },
    { title: "Asset Management" },
    { title: "Asset Control" },

    { title: "Vulnerability and Threat Management" },
    { title: "Business Continuity Management" },
    { title: "Cloud Specific Requirements" },
  ],
};

export const dummyCompanyProfileQuestions = [
  {
    _id: "659fbea9f62b501a0bb13552",
    order: 14,
    question: "Please provide the full name for the company?",
    answers: [],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    edited_at: "2024-01-16T08:41:41.849Z",
    edited_by: "63e20b1959c56d85c5910042",
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13553",
    order: 13,
    question:
      "Please indicate the first, middle and surname of each of the company managing directors and individuals with 5% or more ownership of your company.",
    answers: [
      {
        option: "Rarely",
        weightage: 5,
      },
      {
        option: "Occasionally",
        weightage: 4,
      },
      {
        option: "Sometimes",
        weightage: 3,
      },
      {
        option: "Frequently",
        weightage: 2,
      },
      {
        option: "Almost always",
        weightage: 1,
      },
    ],
    weightage: 2,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question: "Where is compnay located ?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question: "Will the goods and services be contracted from an entire EU ?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question: "Primary Industry Area ",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question:
      "Please Provide the contect details and job title of the person completing the questionaire",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question:
      "Please indicate the first, middle and surname of each of the company managing directors and individuals with 5% or more ownership of your company ",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question: "What is the Ownership type of your company?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Textfield",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question: "Number of Employees",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Number",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question:
      "What is the approximate annual turnover (in USD) of your company last fiscal year?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Currency",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question:
      "Would your service access to any software applications(e.g., via a network connection) ? providing clear details about the nature and purpose of access is crutial.Please be aware that a 'Yes' answer may imply your willingness to undergo a further security assessment for your services. ",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Currency",
    active: true,
    version: 1,
    category: "Workforce",
  },
];
export const dummyInformationSecurityQuestions = [
  {
    _id: "659fbea9f62b501a0bb13552",
    order: 14,
    question: `"Would your service require access to  any software applications (e.g., via a network connection) ?Providing clear details about the nature and purpose of access is crucial. Please be aware that a ""yes"" answer may imply your willingness to undergo a further security assessment for your service.`,
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    edited_at: "2024-01-16T08:41:41.849Z",
    edited_by: "63e20b1959c56d85c5910042",
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13552",
    order: 14,
    question: `"Will your tools or applications require remote access to our software applications  (e.g., via VPN)? Please describe the specific access needs and protocols involved.
    if 'Yes' you maybe required to complete an additional Security Assessment).
    If ""No"" or ""Not Applicable,"" please provide an explanation. "`,
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    edited_at: "2024-01-16T08:41:41.849Z",
    edited_by: "63e20b1959c56d85c5910042",
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13552",
    order: 14,
    question: `"Will your tools or applications have access to or control over any of our software application's data (including client data)? 
    This may include the ability to view, modify, or manage the data. Please provide details of data access requirements and roles (controller/processor). If yes, be aware that your answers will be reviewed by our senior data privacy officer and may trigger a further data privacy assessment due to the sensitivity of the data involved.`,
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    edited_at: "2024-01-16T08:41:41.849Z",
    edited_by: "63e20b1959c56d85c5910042",
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13552",
    order: 14,
    question: `"In delivering services or products to your clients, do you utilize any third-party vendors or subcontractors to perform any part of the work? 
    Please provide details on the scope of their involvement and the types of services they undertake. We're particularly interested in any subvendors who may interact with our critical software or systems."`,
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    edited_at: "2024-01-16T08:41:41.849Z",
    edited_by: "63e20b1959c56d85c5910042",
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13552",
    order: 14,
    question: `Does your company have a formal policy with related processes and procedures in place regarding business conduct?`,
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    edited_at: "2024-01-16T08:41:41.849Z",
    edited_by: "63e20b1959c56d85c5910042",
    version: 1,
    category: "Community",
  },
];

export const surveyDummyQuestions = [
  {
    _id: "659fbea9f62b501a0bb13552",
    order: 14,
    question:
      "How concerned are you about the number of controversies linked to public health or industrial accidents that harm the health and safety of third parties (non-employees and non-customers)?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    edited_at: "2024-01-16T08:41:41.849Z",
    edited_by: "63e20b1959c56d85c5910042",
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13553",
    order: 13,
    question:
      "How often do you come across controversies related to patents and intellectual property infringements?",
    answers: [
      {
        option: "Rarely",
        weightage: 5,
      },
      {
        option: "Occasionally",
        weightage: 4,
      },
      {
        option: "Sometimes",
        weightage: 3,
      },
      {
        option: "Frequently",
        weightage: 2,
      },
      {
        option: "Almost always",
        weightage: 1,
      },
    ],
    weightage: 2,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13554",
    order: 7,
    question:
      "How concerned are you about the number of controversies linked to the company’s relations with employees or relating to wages or wage disputes?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb13555",
    order: 11,
    question:
      "How strongly do you agree or disagree with the following statement: The number of controversies related to insider dealings and share price manipulations has increased in recent years?",
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 4,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Shareholders",
  },
  {
    _id: "659fbea9f62b501a0bb13556",
    order: 15,
    question:
      "How would you rate your level of concern regarding controversies linked to aggressive or non-transparent accounting issues at our company?",
    answers: [
      {
        option: "Not concerned at all",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Shareholders",
  },
  {
    _id: "659fbea9f62b501a0bb13557",
    order: 23,
    question:
      "To what extent do you agree or disagree with the following statement: The company's marketing practices towards vulnerable consumers are unethical.",
    answers: [
      {
        option: "Strongly disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly agree",
        weightage: 1,
      },
    ],
    weightage: 6,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Product responsibility",
  },
  {
    _id: "659fbea9f62b501a0bb13558",
    order: 5,
    question:
      "How concerned are you about the number of controversies linked to the use of child labour issues in our company?",
    answers: [
      {
        option: "Not concerned at all",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 7,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Human rights",
  },
  {
    _id: "659fbea9f62b501a0bb13559",
    order: 8,
    question:
      "On a scale of 1 to 5, how would you rate the frequency of strikes or industrial disputes that have led to lost working days in our company?",
    answers: [
      {
        option: "Never",
        weightage: 5,
      },
      {
        option: "Rarely",
        weightage: 4,
      },
      {
        option: "Sometimes",
        weightage: 3,
      },
      {
        option: "Often",
        weightage: 2,
      },
      {
        option: "Almost always",
        weightage: 1,
      },
    ],
    weightage: 8,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb1355a",
    order: 1,
    question:
      "How concerned are you about controversies linked to responsible R&D in our company?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 9,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Product responsibility",
  },
  {
    _id: "659fbea9f62b501a0bb1355b",
    order: 19,
    question:
      "How concerned are you about the number of controversies linked to customer health and safety at this company?",
    answers: [
      {
        option: "Not concerned at all",
        weightage: 5,
      },
      {
        option: "Mildly concerned",
        weightage: 4,
      },
      {
        option: "Somewhat concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 10,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Product responsibility",
  },
  {
    _id: "659fbea9f62b501a0bb1355c",
    order: 4,
    question:
      "To what extent do you agree or disagree with the following statement: Our company has faced a significant number of controversies related to the health and safety of our workforce.",
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 1,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Workforce",
  },
  {
    _id: "659fbea9f62b501a0bb1355d",
    order: 6,
    question:
      "How strongly do you agree or disagree with the statement: The number of controversies linked to high executive or board compensation has increased in recent years.",
    answers: [
      {
        option: "Strongly disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly agree",
        weightage: 1,
      },
    ],
    weightage: 2,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Management",
  },
  {
    _id: "659fbea9f62b501a0bb1355e",
    order: 22,
    question:
      "How strongly do you agree or disagree with the following statement: Our company has faced controversies related to consumer complaints or dissatisfaction with our products or services.",
    answers: [
      {
        option: "Strongly disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly agree",
        weightage: 1,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Product responsibility",
  },
  {
    _id: "659fbea9f62b501a0bb1355f",
    order: 18,
    question:
      "How many controversies have been linked to employees or customers in the past year?",
    answers: [
      {
        option: "None",
        weightage: 5,
      },
      {
        option: "A few",
        weightage: 4,
      },
      {
        option: "Some",
        weightage: 3,
      },
      {
        option: "Many",
        weightage: 2,
      },
      {
        option: "A lot",
        weightage: 1,
      },
    ],
    weightage: 4,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Product responsibility",
  },
  {
    _id: "659fbea9f62b501a0bb13560",
    order: 20,
    question:
      'How strongly do you agree with the statement: "The company should prioritize avoiding controversies in countries that do not respect human rights principles"?',
    answers: [
      {
        option: "Strongly agree",
        weightage: 5,
      },
      {
        option: "Agree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Disagree",
        weightage: 2,
      },
      {
        option: "Strongly disagree",
        weightage: 1,
      },
    ],
    weightage: 5,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13561",
    order: 2,
    question:
      "How concerned are you about the number of controversies related to the environmental impact of the company's operations on natural resources or local communities?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 6,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Resource use",
  },
  {
    _id: "659fbea9f62b501a0bb13562",
    order: 10,
    question:
      "On a scale of 1 to 5, how concerned are you about the number of controversies linked to tax fraud, parallel imports, or money laundering?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Moderately concerned",
        weightage: 3,
      },
      {
        option: "Greatly concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 7,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13563",
    order: 17,
    question:
      "How often do you believe that access to a product has been associated with controversies?",
    answers: [
      {
        option: "Never",
        weightage: 5,
      },
      {
        option: "Rarely",
        weightage: 4,
      },
      {
        option: "Sometimes",
        weightage: 3,
      },
      {
        option: "Often",
        weightage: 2,
      },
      {
        option: "Almost always",
        weightage: 1,
      },
    ],
    weightage: 8,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Product responsibility",
  },
  {
    _id: "659fbea9f62b501a0bb13564",
    order: 9,
    question:
      '"On a scale of 1 to 5, how strongly do you agree with the following statement: The number of controversies linked to business ethics in general, political contributions or bribery and corruption is increasing."',
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 9,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13565",
    order: 12,
    question:
      'How much do you agree with the following statement: "There have been a significant number of controversies related to anti-competitive behavior such as anti-trust, monopoly, price-fixing, and kickbacks in recent years.',
    answers: [
      {
        option: "Strongly Disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly Agree",
        weightage: 1,
      },
    ],
    weightage: 10,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Community",
  },
  {
    _id: "659fbea9f62b501a0bb13566",
    order: 16,
    question:
      "To what extent do you believe the company's recent controversies related to shareholder rights infringements have impacted its reputation?\n\n",
    answers: [
      {
        option: "Strongly disagree",
        weightage: 5,
      },
      {
        option: "Disagree",
        weightage: 4,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 2,
      },
      {
        option: "Strongly agree",
        weightage: 1,
      },
    ],
    weightage: 1,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Shareholders",
  },
  {
    _id: "659fbea9f62b501a0bb13567",
    order: 21,
    question:
      "How concerned are you about the number of controversies linked to human rights issues within the company?",
    answers: [
      {
        option: "Not at all concerned",
        weightage: 5,
      },
      {
        option: "Slightly concerned",
        weightage: 4,
      },
      {
        option: "Somewhat concerned",
        weightage: 3,
      },
      {
        option: "Very concerned",
        weightage: 2,
      },
      {
        option: "Extremely concerned",
        weightage: 1,
      },
    ],
    weightage: 2,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Human rights",
  },
  {
    _id: "659fbea9f62b501a0bb13568",
    order: 3,
    question:
      "How strongly do you agree or disagree with the following statement: Our company's practices regarding workforce diversity and opportunity (e.g., wages, promotion, discrimination and harassment) have been free of controversy.",
    answers: [
      {
        option: "Strongly disagree",
        weightage: 1,
      },
      {
        option: "Disagree",
        weightage: 2,
      },
      {
        option: "Neutral",
        weightage: 3,
      },
      {
        option: "Agree",
        weightage: 4,
      },
      {
        option: "Strongly agree",
        weightage: 5,
      },
    ],
    weightage: 3,
    section_id: "659fb470f62b501a0bb1354e",
    question_type: "Satisfaction",
    active: true,
    version: 1,
    category: "Workforce",
  },
];

export const dummyInsights = {
  _id: "6580265168863cd4aa829c66",
  isin: "AEDEEMBN00010",
  year: "2022-2023",
  improvement_areas_summary: [
    {
      _id: "65950dedfe5923fbba30e9f7",
      heading: "Labor Standards and Human Rights",
      content:
        " Poor labor standards and human rights violations can result in reputational damage, legal liabilities, and loss of investor confidence.",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9f8",
      heading: "Risk Management",
      content:
        "Potential for business disruptions and financial losses, suggestions for enterprise risk management frameworks",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9f9",
      heading: "Community Relations",
      content:
        "Best practices in employee education and career development, potential for enhanced employee retention and productivity",
      logo: "",
    },
  ],
  is_published: true,
  process_id: 111,
  esg_risk_areas: [
    {
      _id: "65950dedfe5923fbba30e9fa",
      heading: "Labor Standards and Human Rights",
      content:
        "Poor labor standards and human rights violations can result in reputational damage, legal liabilities, and loss of investor confidence.",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9fb",
      heading: "Risk Management",
      content:
        "Potential for business disruptions and financial losses, suggestions for enterprise risk management frameworks",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9fc",
      heading: "Regulatory Compliance",
      content:
        "Poor compliance with environmental regulations may result in fines, legal penalties, and reputational damage",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9fd",
      heading: "Greenhouse Gas Emissions",
      content:
        "High emissions contribute to climate change, which poses a significant threat to the environment and human well-being",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9fe",
      heading: "Community Relations",
      content:
        "Best practices in employee education and career development, potential for enhanced employee retention and productivity",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9ff",
      content:
        "These are the area of risks & identification of emerging ESG risks and potential regulatory changes. With impact percentage total of",
      logo: "",
      dontShowIcon: true,
    },
  ],
  strong_areas_summary: [
    {
      _id: "65950dedfe5923fbba30e9fa",
      heading: "Labor Standards and Human Rights",
      content:
        "Poor labor standards and human rights violations can result in reputational damage, legal liabilities, and loss of investor confidence.",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9fb",
      heading: "Risk Management",
      content:
        "Potential for business disruptions and financial losses, suggestions for enterprise risk management frameworks",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9fc",
      heading: "Regulatory Compliance",
      content:
        "Poor compliance with environmental regulations may result in fines, legal penalties, and reputational damage",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30e9fd",
      heading: "Greenhouse Gas Emissions",
      content:
        "High emissions contribute to climate change, which poses a significant threat to the environment and human well-being",
      logo: "",
    },
  ],
  weak_areas_summary: [
    {
      _id: "65950dedfe5923fbba30e9ff",
      heading: "Customer Service",
      content:
        "Emirates NBD has a strong reputation for customer service, with a dedicated team of representatives available to assist with any queries or concerns.,The bank offers a range of channels for customers to interact with the bank, including online banking, mobile banking, and a 24/7 call center.,Emirates NBD has implemented various initiatives to improve customer experience, such as the 'My Way' program, which allows customers to personalize their banking experience.",
      logo: "",
    },
    {
      _id: "65950dedfe5923fbba30ea00",
      heading: "Digital Banking",
      content:
        "Emirates NBD has a comprehensive digital banking platform, offering a range of online and mobile banking services.,The bank's digital channels allow customers to perform a variety of transactions, such as fund transfers, bill payments, and account management.,Emirates NBD has invested heavily in digital technologies.",
      logo: "",
    },
  ],
  is_proccess_completed: true,
  modified_by: "63e38ac7540b95b829a1206e",
  modified_on: "2023-12-22T08:39:01.919Z",
};
