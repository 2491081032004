import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { DialogContent, MenuItem, Select } from "@mui/material";
import { TextField } from "@mui/material";
import useDebounce from "../../../../../customHooks/useDebouncec";
import { CustomDataInput } from "./SelectChartType";
import CloseIcon from "@mui/icons-material/Close";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const [value, setValue] = React.useState({
    borderWidth: 10,
    barThickness: 100,
    borderRadius: 20,
  });
  // console.log(
  //   "🚀 ~ file: barChartCustomization.js:36 ~ SimpleDialog ~ value",
  //   value
  // );

  const handleChange = (e) => {
    setValue((preValue) => ({
      ...preValue,
      [e.target.name]: Number(e.target.value),
    }));
  };

  useDebounce(
    () => {
      props.setBarChartCustomize(value);
    },
    2000,
    [value]
  );

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ fontWeight: 600, fontSize: 18 }}>
            {" "}
            Customize Bar Chart
          </Typography>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          padding: "25px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Bar Thickness"
            variant="outlined"
            name="barThickness"
            value={value.barThickness}
            onChange={handleChange}
          />
        </form>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Bar Border Width"
            variant="outlined"
            name="borderWidth"
            value={value.borderWidth}
            onChange={handleChange}
          />
        </form>

        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            type="number"
            id="outlined-basic"
            label="Set Bar Border Radius"
            variant="outlined"
            name="borderRadius"
            value={value.borderRadius}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function BarChartCustomization({ setBarChartCustomize }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Customize Bar Chart
      </Button> */}

      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        input={<CustomDataInput />}
        value={"Edit Bar Chart"}
        defaultValue={"Edit Bar Chart"}
        style={{ width: "100%" }}
      >
        <MenuItem value="Edit Bar Chart" onClick={handleClickOpen}>
          Edit Bar Chart
        </MenuItem>
      </Select>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setBarChartCustomize={setBarChartCustomize}
      />
    </div>
    //    <div>
    //    <Button variant="outlined" color="primary" onClick={handleClickOpen}>
    //      Customize Bar Chart
    //    </Button>
    //    <SimpleDialog
    //      selectedValue={selectedValue}
    //      open={open}
    //      onClose={handleClose}
    //      setBarChartCustomize={setBarChartCustomize}
    //    />
    //  </div>
  );
}
