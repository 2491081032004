import React, { useState, useCallback } from "react";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { FormControlLabel } from "@mui/material";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import Autocomplete from "@mui/material/Autocomplete";
import MoreIcon from "@mui/icons-material/MoreVert";

import LinearProgress from "@mui/material/LinearProgress";

import ReportSkeleton from "components/widgets/skeletons/reportSkeleton";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FontSelector from "components/widgets/assesmentApproval/fontSelector";
import ColorGradient from "components/widgets/assesmentApproval/colorGradient";

import sustainabilityReportPDFStyles from "components/widgets/sustainabilityReport/sustainablityReportPDFStyles";

import addcover from "images/add_cover.png";
import share from "images/share.png";
import headerfooter from "images/headerfooter.png";
import viewcomments from "images/view_comments.png";
import tablecontent from "images/table_of_content.png";

const InnerAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const InnerAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const InnerAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CommentsAccordion = withStyles({
  root: {
    "&:before": {
      display: "none",
    },
  },
})(MuiAccordion);
const CommentsAccordionSummary = withStyles({
  root: {
    backgroundColor: "#DDE9F6",
    minHeight: "64px",
    borderRadius: "5px",
    borderTop: "none",
  },
})(MuiAccordionSummary);

const getUIConfiguration = (setupOption, classes, setSetupOption) => {
  const commonButtonProps = {
    variant: "contained",
    color: "secondary",
    className: classes.costunBtn2,
  };

  const configurations = {
    0: {
      title: "Setup Document",
      content: (
        <Box height={500} overflow="scroll">
          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              onClick={() => setSetupOption(1)}
              button
              data-tut="reactour__addCover"
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={addcover}
                  alt="cover"
                />
              </ListItemIcon>
              <ListItemText primary="Customise layout" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(2)}
              button
              data-tut="reactour__addHeaderFooter"
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={headerfooter}
                  alt="headerfooter"
                />
              </ListItemIcon>
              <ListItemText primary="Add Header/Footer" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(3)}
              button
              data-tut="reactour__viewComments"
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={viewcomments}
                  alt="comments"
                />
              </ListItemIcon>
              <ListItemText primary="View Comments" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(4)}
              button
              //   disabled={!hideLoader}
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={tablecontent}
                  alt="table of content"
                />
              </ListItemIcon>
              <ListItemText primary="Edit Table Of Content" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(5)}
              button
              data-tut="reactour__share"
            >
              <ListItemIcon>
                <img className={classes.setupIcocn} src={share} alt="Share" />
              </ListItemIcon>
              <ListItemText primary="Share" />
            </ListItem>
          </List>
        </Box>
      ),
    },
    1: {
      title: "Customise layout",
      button: <Button {...commonButtonProps}>Apply</Button>,
    },
    2: {
      title: "Add Header/Footer",
      button: <Button {...commonButtonProps}>Apply</Button>,
    },
    3: {
      title: "Add Comment",
    },
    4: {
      title: "Table Of Content",
      button: (
        <Button {...commonButtonProps} style={{ marginLeft: "auto" }}>
          Apply
        </Button>
      ),
    },
    5: {
      title: "Stakeholder List",
      button: (
        <Button {...commonButtonProps} className={classes.costunBtn3}>
          Share
        </Button>
      ),
      iconButton: (
        <IconButton
          aria-label="display more actions"
          edge="end"
          color="inherit"
          size="large"
        >
          <MoreIcon />
        </IconButton>
      ),
    },
  };

  return configurations[setupOption] || {};
};

function ReportRightMain() {
  const [setupOption, setSetupOption] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel, id) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAccordioChangeColor = useCallback(
    (panel, id) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  const classes = sustainabilityReportPDFStyles();

  const { title, button, content, iconButton } = getUIConfiguration(
    setupOption,
    classes,
    setSetupOption
  );

  return (
    <>
      <Stack alignItems={"center"} direction={"row"}>
        {setupOption !== 0 && (
          <ArrowBackIcon
            name="backBtn"
            className={classes.arrow}
            onClick={() => {
              setSetupOption(0);
              // setSearchText("");
            }}
          />
        )}
        {title && (
          <Typography variant="body1" className={classes.cardTitle}>
            {title}
          </Typography>
        )}
        {button}
        {iconButton}
      </Stack>
      {/* {setupOption === 0 && (
        <Box height={500} overflow="scroll">
          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              onClick={() => setSetupOption(1)}
              button
              data-tut="reactour__addCover"
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={addcover}
                  alt="cover"
                />
              </ListItemIcon>
              <ListItemText primary="Customise layout" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(2)}
              button
              data-tut="reactour__addHeaderFooter"
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={headerfooter}
                  alt="headerfooter"
                />
              </ListItemIcon>
              <ListItemText primary="Add Header/Footer" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(3)}
              button
              data-tut="reactour__viewComments"
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={viewcomments}
                  alt="comments"
                />
              </ListItemIcon>
              <ListItemText primary="View Comments" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(4)}
              button
              //   disabled={!hideLoader}
            >
              <ListItemIcon>
                <img
                  className={classes.setupIcocn}
                  src={tablecontent}
                  alt="table of content"
                />
              </ListItemIcon>
              <ListItemText primary="Edit Table Of Content" />
            </ListItem>
            <ListItem
              onClick={() => setSetupOption(5)}
              button
              data-tut="reactour__share"
            >
              <ListItemIcon>
                <img className={classes.setupIcocn} src={share} alt="Share" />
              </ListItemIcon>
              <ListItemText primary="Share" />
            </ListItem>
          </List>
        </Box>
      )} */}
      {content}
      {setupOption === 1 && (
        <Box height={500} overflow="scroll">
          <Accordion
            variant="elevation"
            elevation={0}
            expanded={expanded === `panel1`}
            onChange={handleAccordionChange(`panel1`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Front Cover</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accord}>
              <InnerAccordion className={classes.autoWidth}>
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Background</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  {/* {coverImageUrl === "" ? ( */}
                  <>
                    <input
                      name="frontBgImage"
                      id="cover-image"
                      type="file"
                      // onChange={(event) => handleUploadCoverImage(event)}
                      className={classes.input}
                    />
                    <label htmlFor="cover-image">
                      <div className={classes.addCoverImage}>
                        <AddCircleOutlineIcon
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </label>
                  </>
                  {/* ) : (
                    <div className={classes.coverImage}>
                      <img src={coverImageUrl} alt="coverimage" />
                      <IconButton
                        onClick={handleClearCoverImage}
                        className={classes.ClearImage}
                        size="large"
                      >
                        <CancelIcon
                          className={classes.cancell}
                          fontSize="small"
                        />
                      </IconButton>
                    </div> */}
                  {/* )} */}
                </InnerAccordionDetails>
              </InnerAccordion>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Add text</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  <Box className={classes.autoWidth}>
                    <Box my={2}>
                      <TextField
                        name="frontHeadlineTxt"
                        label="Headline Text"
                        // value={coverHeadline}
                        // onChange={(e) => setCoverHeadline(e.target.value)}
                        variant="outlined"
                        fullWidth
                        multiline
                      />
                    </Box>
                    <Box my={2}>
                      <TextField
                        name="frontSublineTxt"
                        label="Subline Text"
                        // value={coverSubline}
                        // onChange={(e) => setCoverSubline(e.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Box my={2}>
                      <TextField
                        name="frontRegularTxt"
                        label="Regular Text"
                        // value={coverRregularText}
                        // onChange={(e) => setCoverRregularText(e.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </InnerAccordionDetails>
              </InnerAccordion>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Add logo</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  {/* {coverLogoUrl === "" ? ( */}
                  <>
                    <input
                      name="frontLogoImage"
                      id="cover-logo"
                      type="file"
                      // onChange={(event) => handleUploadCoverLogoImage(event)}
                      className={classes.input}
                    />
                    <label htmlFor="cover-logo">
                      <div className={classes.addCoverImage}>
                        <AddCircleOutlineIcon
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </label>
                  </>
                  {/* ) : (
                    <div className={classes.coverImage}>
                      <img src={coverLogoUrl} alt="coverlogo" />
                      <IconButton
                        onClick={handleClearCoverLogoImage}
                        className={classes.ClearImage}
                        size="large"
                      >
                        <CancelIcon
                          className={classes.cancell}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  )} */}
                </InnerAccordionDetails>
              </InnerAccordion>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="elevation"
            elevation={0}
            expanded={expanded === `panel2`}
            onChange={handleAccordionChange(`panel2`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Back Cover</Typography>
            </AccordionSummary>
            <AccordionDetails
              className={classes.accord}
              style={{ display: "felx", flexDirection: "column" }}
            >
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Background</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  {/* {backCoverImageUrl === "" ? ( */}
                  <>
                    <input
                      name="backBgImage"
                      id="back-cover-image"
                      type="file"
                      // onChange={(event) => handleUploadBackCoverImage(event)}
                      className={classes.input}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="back-cover-image">
                      <div className={classes.addCoverImage}>
                        <AddCircleOutlineIcon
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </label>
                  </>
                  {/* ) : (
                    <div className={classes.coverImage}>
                      <img src={backCoverImageUrl} alt="coverimage" />
                      <IconButton
                        onClick={handleClearBackCoverImage}
                        className={classes.ClearImage}
                        size="large"
                      >
                        <CancelIcon
                          className={classes.cancell}
                          style={{ color: "white" }}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  )} */}
                </InnerAccordionDetails>
              </InnerAccordion>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Add text</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  <Box className={classes.autoWidth}>
                    <Box my={2}>
                      <TextField
                        name="backHeadlineTxt"
                        label="Headline Text"
                        // value={coverBackHeadline}
                        // onChange={(e) => setCoverBackHeadline(e.target.value)}
                        variant="outlined"
                        fullWidth
                        multiline
                      />
                    </Box>
                    <Box my={2}>
                      <TextField
                        name="backSublineTxt"
                        label="Subline Text"
                        // value={coverBackSubline}
                        // onChange={(e) => setCoverBackSubline(e.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                    <Box my={2}>
                      <TextField
                        name="backRegularTxt"
                        label="Regular Text"
                        // value={coverBackRegular}
                        // onChange={(e) => setCoverBackRegular(e.target.value)}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </InnerAccordionDetails>
              </InnerAccordion>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Add logo</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  {/* {backCoverLogoUrl === "" ? ( */}
                  <>
                    <input
                      name="backLogoImage"
                      id="back-cover-logo"
                      type="file"
                      // onChange={(event) =>
                      // //   handleUploadBackCoverLogoImage(event)
                      // }
                      className={classes.input}
                    />
                    <label htmlFor="back-cover-logo">
                      <div className={classes.addCoverImage}>
                        <AddCircleOutlineIcon
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </label>
                  </>
                  {/* ) : (
                    <div className={classes.coverImage}>
                      <img src={backCoverLogoUrl} alt="coverlogo" />
                      <IconButton
                        onClick={handleClearBackCoverLogoImage}
                        className={classes.ClearImage}
                        size="large"
                      >
                        <CancelIcon
                          className={classes.cancell}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  )} */}
                </InnerAccordionDetails>
              </InnerAccordion>
            </AccordionDetails>
          </Accordion>
          <FontSelector
            handleAccordioChange={handleAccordioChangeColor}
            expanded={expanded}
            // font={sustainabilityReport.font}
            // handleFontComplete={handleFontComplete}
          />
          <ColorGradient
            handleAccordioChange={handleAccordioChangeColor}
            expanded={expanded}
            image={""}
            // color={sustainabilityReport.color}
            handleColorComplete={() => {}}
            preColor={[]}
          />
        </Box>
      )}
      {setupOption === 2 && (
        <Box height={520} overflow="scroll">
          <Accordion
            variant="elevation"
            elevation={0}
            expanded={expanded === `panel3`}
            onChange={handleAccordionChange(`panel3`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Header</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accord}>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Add text</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  <Box className={classes.autoWidth}>
                    <Box my={2}>
                      <TextField
                        name="addHeaderTxt"
                        label="Header Text"
                        // value={headerText}
                        // onChange={(e) => setHeaderText(e.target.value)}
                        variant="outlined"
                        fullWidth
                        multiline
                      />
                    </Box>
                  </Box>
                </InnerAccordionDetails>
              </InnerAccordion>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Add logo</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  {/* {headerLogoUrl === "" ? ( */}
                  <>
                    <input
                      name="headerLogo"
                      id="header-logo"
                      type="file"
                      // onChange={(event) => handleUploadHeaderLogoImage(event)}
                      className={classes.input}
                    />
                    <label htmlFor="header-logo">
                      <div className={classes.addCoverImage}>
                        <AddCircleOutlineIcon
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </label>
                  </>
                  {/* ) : (
                    <div className={classes.coverImage}>
                      <img src={headerLogoUrl} alt="coverlogo" />
                      <IconButton
                        onClick={handleClearHeaderLogoImage}
                        className={classes.ClearImage}
                        size="large"
                      >
                        <CancelIcon
                          className={classes.cancell}
                          fontSize="small"
                        />
                      </IconButton>
                    </div>
                  )} */}
                </InnerAccordionDetails>
              </InnerAccordion>
            </AccordionDetails>
          </Accordion>
          <Accordion
            variant="elevation"
            elevation={0}
            expanded={expanded === `panel4`}
            onChange={handleAccordionChange(`panel4`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Footer</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accord}>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Add text</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  <Box className={classes.autoWidth}>
                    <Box my={2}>
                      <TextField
                        name="addFooterTxt"
                        label="Footer Text"
                        // value={footerText}
                        // onChange={(e) => setFooterText(e.target.value)}
                        variant="outlined"
                        fullWidth
                        multiline
                      />
                    </Box>
                  </Box>
                </InnerAccordionDetails>
              </InnerAccordion>
              <InnerAccordion
                className={classes.autoWidth}
                variant="elevation"
                elevation={0}
              >
                <InnerAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Page Number</Typography>
                </InnerAccordionSummary>
                <InnerAccordionDetails>
                  <Box className={classes.autoWidth}>
                    <Box my={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="pgNumCheckbox"
                            color="secondary"
                            disableRipple
                            id="agreement"
                            checked={true}
                            // onChange={() =>
                            //   setIsPageNumberVisible(!isPageNumberVisible)
                            // }
                          />
                        }
                        label="Show page number"
                      />
                    </Box>
                  </Box>
                  <Box className={classes.autoWidth}>
                    <Box my={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="pgNumCheckbox"
                            color="secondary"
                            disableRipple
                            id="agreement"
                            checked={true}
                            // onChange={() =>
                            //   setIsPNumberDefaultPosition(
                            //     !isPNumberDefaultPosition
                            //   )
                            // }
                          />
                        }
                        label="Default position (Left)"
                      />
                    </Box>
                  </Box>
                </InnerAccordionDetails>
              </InnerAccordion>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      {setupOption === 3 && (
        <>
          <TextField
            name="cmtSearch"
            label="Search By Table Of Content"
            fullWidth
            size="small"
            variant="outlined"
            onChange={(e) => {
              //   setSearchText(e.target.value);
            }}
            className={classes.TextField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.search} fontSize="large" />
                </InputAdornment>
              ),
            }}
          />
          <Box height={460} overflow="scroll">
            {/* {filteredComments.length > 0 ? (
              commentsDisplay(filteredComments)
            ) : searchText.length > 0 ? ( */}
            <Typography className={classes.typo4}>No Data Found</Typography>
            {/* ) : searchText === "" ? (
              commentsDisplay(allComment)
            ) : (
              commentsDisplay(filteredComments)
            )} */}
          </Box>
        </>
      )}
      {setupOption === 4 && (
        <Box className={classes.box3}>
          <Grid container className={classes.grid7}>
            <Grid item xs={8} className={classes.tocTitle}>
              Title
            </Grid>
            <Grid item xs={4} className={classes.tocTitle}>
              Pg No
            </Grid>
          </Grid>
          <Box height={420} overflow="scroll">
            {/* {localSustainablityReport.map((toc, i) => {
              return (
                <>
                  <Box display="flex">
                    <Grid
                      container
                      key={toc.toc_id + i}
                      className={classes.tocContentGrid}
                    >
                      <Grid item xs={8}>
                        {toc.visible &&
                        showTocInput &&
                        (idToShowInput === toc.toc_id ||
                          idToShowInput === toc.assessment_topic_id) ? (
                          <TextField
                            name={toc.name.substring(0, 4)}
                            autoFocus
                            variant="outlined"
                            value={editTocObj.name}
                            InputProps={{
                              disableUnderline: true,
                              className: classes.inputBox,
                            }}
                            className={classes.tocInput}
                            fullWidth
                            onChange={(e) => {
                              handleEditToc(e, i);
                              setTocEyeDisabler(true);
                            }}
                            disabled={!toc.visible}
                            size="small"
                            inputRef={textFieldRef}
                            onBlur={() => {
                              api(toc);
                              setShowTocInput(false);
                            }}
                          ></TextField>
                        ) : (
                          <Typography
                            variant="body1"
                            className={classes.tocContent}
                            id={
                              toc.toc_id === ""
                                ? toc.assessment_topic_id
                                : toc.toc_id
                            }
                            onClick={(e) => {
                              if (toc.visible) {
                                handleShowInputToc(e, toc);
                              }
                            }}
                          >
                            {toc.name}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="body1"
                          className={classes.typo5}
                          align="center"
                        >
                          {toc.visible && toc.page_no && toc.page_no.pageNo}
                        </Typography>
                      </Grid>
                    </Grid>
                    {true && (
                      <Box key={toc.toc_id + i}>
                        <IconButton size="large">
                          {toc.visible ? (
                            <VisibilityOutlinedIcon
                              name={toc.name.substring(0, 4)}
                              style={{ color: "#9FA1AF" }}
                              onClick={() => {
                                toc.assessment_topic_id !== "" &&
                                  setEditTocAssessmentId(
                                    toc.assessment_topic_id
                                  );
                                handleTocVisibility(
                                  toc.name,
                                  toc.toc_id === "" ? "" : toc.toc_id,
                                  false,
                                  toc.order,
                                  toc.assessment_topic_id === ""
                                    ? ""
                                    : toc.assessment_topic_id
                                );
                              }}
                            />
                          ) : (
                            <VisibilityOffOutlinedIcon
                              name={toc.name.substring(0, 4)}
                              style={{ color: "#9FA1AF" }}
                              onClick={() => {
                                toc.assessment_topic_id !== "" &&
                                  setEditTocAssessmentId(
                                    toc.assessment_topic_id
                                  );
                                handleTocVisibility(
                                  toc.name,
                                  toc.toc_id === "" ? "" : toc.toc_id,
                                  true,
                                  toc.order,
                                  toc.assessment_topic_id === ""
                                    ? ""
                                    : toc.assessment_topic_id
                                );
                              }}
                            />
                          )}
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </>
              );
            })} */}
          </Box>
        </Box>
      )}
      {setupOption === 5 && (
        <Box height={520} overflow="scroll">
          {[].length > 0
            ? [].map((user, index) => (
                <div className={classes.demo} key={index}>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar1}>
                          {user.user_data.name.substring(0, 2)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.user_data.name}
                        secondary={user.user_data.email}
                      />
                    </ListItem>
                  </List>
                </div>
              ))
            : [].length > 0 && (
                <>
                  <CommentsAccordion variant="elevation" elevation={0}>
                    <CommentsAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ marginTop: 10 }}
                    >
                      <Box className={classes.box1}>
                        <Typography>Approvers</Typography>
                        <Box
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                            width: 32,
                            height: 32,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 16,
                            lineHeight: 24,
                          }}
                        >
                          {/* {approversArr.length}5 */}5
                        </Box>
                      </Box>
                    </CommentsAccordionSummary>

                    {[].map((user, index) => (
                      <div
                        style={{ wordBreak: "break-word" }}
                        className={classes.demo}
                        key={index}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "8px",
                            overflow: "scroll",
                          }}
                          id={user.stakeholder_id}
                          //   onMouseEnter={(e) => {
                          //     setShowRevomeUserIcon(true);
                          //     setUserId(e.target.id);
                          //   }}
                          //   onMouseLeave={(e) => {
                          //     setShowRevomeUserIcon(false);
                          //   }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexGrow: "4",
                            }}
                          >
                            {user.profile_img ? (
                              <Avatar
                                alt={user.name}
                                src={user.profile_img.url}
                              />
                            ) : (
                              <Avatar
                                style={{
                                  backgroundColor: " #DDE9F6",
                                }}
                              >
                                {user.name.substring(0, 2)}
                              </Avatar>
                            )}
                            <Box>
                              <Typography style={{ fontSize: 16 }}>
                                {user.name}
                              </Typography>
                              <Typography style={{ fontSize: 14 }}>
                                {user.email}
                              </Typography>
                            </Box>
                          </Box>
                          <Box flexGrow="4">
                            <Select
                              variant="outlined"
                              displayEmpty
                              size="small"
                              select
                              //   value={approver}
                              name={user.stakeholder_id}
                              //   onChange={(e) => {
                              //     e.target.name === user.stakeholder_id &&
                              //       handleApproverChange(e);
                              //   }}
                            >
                              {["To Approve", "To comment"].map((item) => (
                                <MenuItem value={item} key={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box flexGrow="2">
                            {
                              <IconButton
                                name="rmvAproover"
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  // setRemoveUserEmail(user.email);
                                  // setShowDeleteUserDailogue(true);
                                }}
                                style={{ cursor: "pointer" }}
                                size="large"
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            }
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </CommentsAccordion>
                  <CommentsAccordion variant="elevation">
                    <CommentsAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{ marginTop: 10 }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Reviewers</Typography>
                        <Box
                          style={{
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                            width: 32,
                            height: 32,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 16,
                            lineHeight: 24,
                          }}
                        >
                          {/* {reviewersArr.length} */}10
                        </Box>
                      </Box>
                    </CommentsAccordionSummary>

                    {[].map((user, index) => (
                      <div className={classes.div1} key={index}>
                        <Box
                          className={classes.box4}
                          id={user.stakeholder_id}
                          onMouseEnter={(e) => {
                            // setShowRevomeUserIcon(true);
                            // setUserId(e.target.id);
                          }}
                          onMouseLeave={(e) => {
                            // setShowRevomeUserIcon(false);
                          }}
                        >
                          <Box className={classes.box5}>
                            {user.profile_img ? (
                              <Avatar
                                alt={user.name}
                                src={user.profile_img.url}
                              />
                            ) : (
                              <Avatar className={classes.avatar1}>
                                {user.name.substring(0, 2)}
                              </Avatar>
                            )}
                            <Box>
                              <Typography className={classes.typo6}>
                                {user.name}
                              </Typography>
                              <Typography className={classes.typo6}>
                                {user.email}
                              </Typography>
                            </Box>
                          </Box>
                          <Box flexGrow="4">
                            <Select
                              variant="outlined"
                              displayEmpty
                              size="small"
                              select
                              //   value={reviewer}
                              name={user.stakeholder_id}
                              //   onChange={(e) => handleReviewerChange(e)}
                            >
                              {["To Comment", "To Approve"].map((item) => (
                                <MenuItem value={item} key={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box flexGrow="2">
                            <IconButton
                              name="rmvReviewers"
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                // setRemoveUserEmail(user.email);
                                // setShowDeleteUserDailogue(true);
                              }}
                              style={{ cursor: "pointer" }}
                              size="large"
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </CommentsAccordion>
                </>
              )}
        </Box>
      )}
    </>
  );
}

export default ReportRightMain;
