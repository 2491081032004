import { useState, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import MainLayoutStepper from "components/layout/stepperLayout/mainLayout";
import MainLayoutSection from "components/layout/twoSectionLayout/MainLayoutSection";
import LeftSectionLayout from "components/layout/twoSectionLayout/LeftSectionLayout";
import RightSectionLayout from "components/layout/twoSectionLayout/RightSectionLayout";
import BasicDetails from "components/widgets/phase-3/MaterialityMapping/materialityAssessmentBasicDetails/BasicDetails";
import MaterialityMappingTopicLeft from "components/widgets/phase-3/MaterialityMapping/selectToppic/left/materialityMappingTopicLeft";
import MaterialityMappingTopicRight from "components/widgets/phase-3/MaterialityMapping/selectToppic/right/MaterialityMappingTopicRight";
import ShareSurveyMain from "components/widgets/phase-3/esgMaturity/shareSurvey/ShareSurveyMain";
import MaturityQuestionMain from "components/widgets/phase-3/esgMaturity/maturityQuestion/MaturityQuestionMain";
import MaterialityAnalysisMain from "components/widgets/phase-3/MaterialityMapping/analysis/MaterialityAnalysisMain";
import { useMaterialityBasicDetail } from "components/widgets/phase-3/MaterialityMapping/APIs/HandleBasicDetailApi";
import MaterialityQuestionPreview from "components/widgets/phase-3/MaterialityMapping/assign/left/MaterialityQuestionPreview";
// import { useSelector } from "react-redux";

const dummyQuestions = [
  {
    topic: "Environmental Sustainability and Compliance",
    questions: [
      {
        question:
          "How frequently does your company conduct audits to ensure compliance with environmental regulations?",
        weightage: "4 weightage",
        option: [
          { option: "Never", value: "1" },
          { option: "Rarely", value: "2" },
          { option: "Occasionally", value: "3" },
          { option: "Regularly", value: "4" },
        ],
      },
      {
        question:
          "What strategies does your company have in place to reduce greenhouse gas emissions?",
        weightage: "5 weightage",
        option: [
          { option: "No approach in place", value: "1" },
          { option: "Considering plans", value: "2" },
          { option: "Initiated reduction measures", value: "3" },
          {
            option: "Implemented comprehensive reduction strategy",
            value: "4",
          },
        ],
      },
      {
        question:
          "To what extent does your company utilize renewable energy sources?",
        weightage: "4 weightage",
        option: [
          { option: "Not at all", value: "1" },
          { option: "Less than 25%", value: "2" },
          { option: "25% to 50%", value: "3" },
          { option: "More than 50%", value: "4" },
        ],
      },
      {
        question:
          "How does your company manage and minimize waste generated from operations?",
        weightage: "3 weightage",
        option: [
          { option: "No management plan", value: "1" },
          { option: "Basic waste segregation", value: "2" },
          { option: "Advanced recycling program", value: "3" },
          { option: "Zero waste initiative", value: "4" },
        ],
      },
      {
        question:
          "What initiatives has your company implemented to enhance employee health and safety?",
        weightage: "5 weightage",
        option: [
          { option: "No measures in place", value: "1" },
          { option: "Basic health and safety guidelines", value: "2" },
          { option: "Regular health and safety training", value: "3" },
          { option: "Comprehensive health and safety program", value: "4" },
        ],
      },
    ],
  },
  {
    topic: "Social Responsibility and Community Engagement",
    questions: [
      {
        question: "How does your company engage with local communities?",
        weightage: "3 weightage",
        option: [
          { option: "No engagement", value: "1" },
          { option: "Occasional community initiatives", value: "2" },
          { option: "Regular community programs", value: "3" },
          { option: "Strategic community partnerships", value: "4" },
        ],
      },
      {
        question:
          "How does your company address diversity and inclusion in the workplace?",
        weightage: "5 weightage",
        option: [
          { option: "No policy in place", value: "1" },
          { option: "Basic diversity policy", value: "2" },
          { option: "Active diversity and inclusion programs", value: "3" },
          {
            option: "Comprehensive diversity and inclusion strategy",
            value: "4",
          },
        ],
      },
      {
        question:
          "What measures does your company take to ensure fair labor practices in its supply chain?",
        weightage: "4 weightage",
        option: [
          { option: "No measures in place", value: "1" },
          { option: "Code of conduct for suppliers", value: "2" },
          { option: "Regular supplier audits", value: "3" },
          { option: "Partnerships to improve labor practices", value: "4" },
        ],
      },
      {
        question: "How does your company promote ethical business practices?",
        weightage: "5 weightage",
        option: [
          { option: "No ethical guidelines", value: "1" },
          { option: "Basic ethical guidelines", value: "2" },
          { option: "Regular ethics training", value: "3" },
          { option: "Comprehensive ethical business practices", value: "4" },
        ],
      },
      {
        question:
          "To what extent does your company involve employees in community service activities?",
        weightage: "4 weightage",
        option: [
          { option: "Not at all", value: "1" },
          { option: "Occasionally", value: "2" },
          { option: "Frequently", value: "3" },
          { option: "Always", value: "4" },
        ],
      },
    ],
  },
  {
    topic: "Innovation and Technology Integration",
    questions: [
      {
        question:
          "How does your company support innovation in environmental and social practices?",
        weightage: "5 weightage",
        option: [
          { option: "No support for innovation", value: "1" },
          { option: "Occasional support for innovative projects", value: "2" },
          { option: "Regular support for innovation", value: "3" },
          { option: "Dedicated innovation programs", value: "4" },
        ],
      },
      {
        question:
          "What is your company’s approach to integrating new technologies into its operations?",
        weightage: "4 weightage",
        option: [
          { option: "No integration", value: "1" },
          { option: "Ad hoc technology adoption", value: "2" },
          { option: "Planned technology integration", value: "3" },
          { option: "Strategic technology implementation", value: "4" },
        ],
      },
      {
        question:
          "How does your company measure the effectiveness of its technology investments?",
        weightage: "4 weightage",
        option: [
          { option: "No measurement", value: "1" },
          { option: "Basic metrics", value: "2" },
          { option: "Regular evaluations", value: "3" },
          { option: "Comprehensive impact assessment", value: "4" },
        ],
      },
      {
        question:
          "To what extent does your company invest in research and development?",
        weightage: "5 weightage",
        option: [
          { option: "Minimal investment", value: "1" },
          { option: "Occasional investment", value: "2" },
          { option: "Regular investment", value: "3" },
          { option: "Significant investment", value: "4" },
        ],
      },
      {
        question:
          "How does your company leverage technology to improve operational efficiency?",
        weightage: "3 weightage",
        option: [
          { option: "No technology use", value: "1" },
          { option: "Basic technology use", value: "2" },
          { option: "Advanced technology integration", value: "3" },
          { option: "Full-scale technology optimization", value: "4" },
        ],
      },
    ],
  },
];

function MaterialityMappingSteps() {
  const { handleMaterialityStep, disableButton } = useMaterialityBasicDetail();

  const navigate = useNavigate();

  const stepContentMapping = useMemo(
    () => ({
      0: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <BasicDetails />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
      1: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <MaterialityMappingTopicLeft />
            </LeftSectionLayout>
          }
          right={
            <RightSectionLayout>
              <MaterialityMappingTopicRight />
            </RightSectionLayout>
          }
        />
      ),
      2: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <MaterialityQuestionPreview
                heading="Materiality survey preview"
                source="materiality"
              />
            </LeftSectionLayout>
          }
          right={
            <RightSectionLayout>
              <ShareSurveyMain source={"materiality_mapping"} />
            </RightSectionLayout>
          }
        />
      ),
      3: () => (
        <MainLayoutSection
          left={
            <LeftSectionLayout>
              <MaterialityAnalysisMain />
            </LeftSectionLayout>
          }
          rightHidden={true}
          leftWidth="100%"
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Add any dependencies that can change the content
  );

  return (
    <MainLayoutStepper
      steps={[
        "Basic Details",
        "Select Topics",
        "Assign Stakeholders",
        "Analysis",
      ]}
      mainCrumb={""}
      heightAdjust="150px"
      finalStepText={"Proceed to materiality Listing"}
      handleFinalStep={() => navigate("/materiality_mapping")}
      handleStepChangeApi={handleMaterialityStep}
      handleDisableButton={disableButton}
      stepContentMapping={stepContentMapping}
    />
  );
}

export default MaterialityMappingSteps;
