import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Business,
  Environmental,
  Governance,
  Human,
  Social,
} from "../../../../../utils/MaterialityAssessmentUtils/materialityAssessmentUtils";
import { useState } from "react";

const EsgScoreLeftStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    // height:'100%'
  },
  mainHeadder: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0px 8px 0px",
  },
  Typography: {
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "10px",
  },
  Typography2: {
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "4px",
  },
  iconCont: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  headerIcon: {
    marginRight: "20px",
  },
  Table: {
    outline: "0.5px solid rgba(0,0,0,0.05)",
    borderRadius: "5px",
    // boxShadow: "1px 1px 1px",
    height: "100%",
  },
  TableContainer: {
    padding: "3px",
  },
  TableCell: {
    padding: "5px",
    border: "none",
  },
  hedaderCell: { color: "grey", fontSize: "x-small" },
  topicCell: { padding: "4px 4px" },
  lableCell: {
    // width: '193px',
    // height: '20px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    // lineHeight: "20px",
  },
  scoreCell: {
    textAlign: "right",
    // width: '32px',
    // height: '28px',
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "28px",
  },
  SuggestionBtn: {
    textTransform: "none",
    textAlign: "center",
    width: "100%",
    fontFamily: "Poppins",
    margin: "5% 0px 5px 1px",
    padding: "5px",
  },
  contributorGrid: {
    padding: "0px 15px 0px 10px",
  },
  contributorsBtn: {
    backgroundColor: "white",
    color: "green",
    border: "0.5px solid green",
    borderRadius: "5px",
    textTransform: "none",
    textAlign: "center",
    width: "100%",
    fontFamily: "Poppins",
    margin: "5% 0px 5px 1px",
  },
  topicStyle: {
    width: "40px",
    height: "40px",
    marginTop: "5px",
    position: "relative",
    top: "5px",
  },
  levelImg: {
    width: "140px",
    height: "135px",
  },
  ".MuiLinearProgress-root": {
    color: "green",
  },
  LinearProgress: {
    // color:'success.main',
    margin: "-5px 25px 25px 25px",
  },
  scoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "xx-large",
  },
  maxScoreTypo: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
  },
  sharesurvey: {
    color: "#222",
    fontSize: "12px",
    backgroundColor: "#BFE2CD",
    padding: "10px",
    borderRadius: "100px",
  },
  describe: {
    color: "grey",
    fontSize: "x-small",
    marginTop: "11px",
  },
  scoreImage: {
    width: "200px",
    height: "190px",
  },
  mainGrid: {
    textAlign: "center",
  },
  discribe: {
    fontSize: "small",
  },
  slider: {
    ".MuiSlider-root": {
      color: "#20b2aa",
    },
    ".MuiSlider-root:hover": {
      color: "#2e8b57",
    },
  },
  levelTypo: {
    // marginLeft: "5%",
    textAlign: "center",
  },
  progressIndicator1: {
    position: "relative",
    left: "33%",
    top: "12px",
    backgroundColor: "rgb(0 128 0)",
    zIndex: 1,
  },
  progressIndicator2: {
    position: "relative",
    left: "66.7%",
    top: "12px",
    backgroundColor: "rgb(0 128 0)",
    zIndex: 1,
  },
  [theme.breakpoints.down("lg")]: {
    SuggestionBtn: {
      margin: "5% 0px 5px 1px",
    },
    discribe: {
      fontSize: "small",
      textWrap: "no-wrap",
      wordWrap: "no-wrap",
    },
  },
  [theme.breakpoints.down("md")]: {
    SuggestionBtn: {
      margin: "5% 0px 5px 1px",
      padding: "5px",
    },
    contributorsBtn: {
      margin: "5% 0px 5px 1px",
      paddingLeft: "5px",
    },
    discribe: {
      textAlign: "left",
      fontSize: "small",
      textWrap: "no-wrap",
      wordWrap: "no-wrap",
    },
    sharesurvey: {
      fontWeight: 500,
    },
  },
  leftTop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
}));

const currentMaturityData = {
  category_matrix_value: {
    Community: 52,
    "Human Rights": 42,
    Management: 42,
    "Product Responsibility": 63,
    "Resource Use": 36,
    Shareholder: 29,
    Workforce: 53,
  },
};

function EsgScoreRight() {
  const classes = EsgScoreLeftStyles();

  const [scoreList, setScoreList] = useState([
    {
      component: <Environmental className={classes.topicStyle} />,
      lable: "Community",
      score: currentMaturityData.category_matrix_value["Community"]
        ? currentMaturityData.category_matrix_value["Community"]
        : 0,
    },
    {
      component: <Social className={classes.topicStyle} />,
      lable: "Human Rights",
      score: currentMaturityData.category_matrix_value["Human Rights"]
        ? currentMaturityData.category_matrix_value["Human Rights"]
        : 0,
    },
    {
      component: <Business className={classes.topicStyle} />,
      lable: "Management",
      score: currentMaturityData.category_matrix_value["Management"]
        ? currentMaturityData.category_matrix_value["Management"]
        : 0,
    },
    {
      component: <Governance className={classes.topicStyle} />,
      lable: "Product Responsibility",
      score: currentMaturityData.category_matrix_value["Product Responsibility"]
        ? currentMaturityData.category_matrix_value["Product Responsibility"]
        : 0,
    },
    {
      component: <Human className={classes.topicStyle} />,
      lable: "Resource Use",
      score: currentMaturityData.category_matrix_value["Resource Use"]
        ? currentMaturityData.category_matrix_value["Resource Use"]
        : 0,
    },
    {
      component: <Human className={classes.topicStyle} />,
      lable: "Shareholder",
      score: currentMaturityData.category_matrix_value["Shareholder"]
        ? currentMaturityData.category_matrix_value["Shareholder"]
        : 0,
    },
    {
      component: <Human className={classes.topicStyle} />,
      lable: "Workforce",
      score: currentMaturityData.category_matrix_value["Workforce"]
        ? currentMaturityData.category_matrix_value["Workforce"]
        : 0,
    },
  ]);
  return (
    <Box style={{ flex: 1 }}>
      <Grid item sm={12}>
        <TableContainer className={classes.TableContainer}>
          <Table className={classes.Table}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={clsx(classes.TableCell, classes.hedaderCell)}
                >
                  Topic
                </TableCell>
                <TableCell
                  className={clsx(classes.TableCell, classes.hedaderCell)}
                >
                  Lable
                </TableCell>
                <TableCell
                  className={clsx(classes.TableCell, classes.hedaderCell)}
                  align="right"
                >
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            {scoreList.map((topic) => {
              return (
                <TableBody key={topic.lable}>
                  <TableRow>
                    <TableCell
                      className={clsx(classes.TableCell, classes.topicCell)}
                    >
                      {topic.component}
                    </TableCell>
                    <TableCell
                      className={clsx(classes.TableCell, classes.lableCell)}
                    >
                      {topic.lable}
                    </TableCell>
                    <TableCell
                      className={clsx(classes.TableCell, classes.scoreCell)}
                    >
                      {topic.score}
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}

export default EsgScoreRight;
