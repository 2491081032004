import { IconButton } from "@mui/material";

import DynamicTableFull from "components/UI/phase-3/dynamicTable/DynamicTableFull";
import { IoIosArrowForward } from "react-icons/io";

const tableHead = [
  { name: "Unit Name" },
  { name: "Unit Id" },
  { name: "Unit Type" },
  { name: "Location" },
  { name: "Handled By" },
  { name: "View" },
];

const tableData = [
  {
    _id: "66ac5f35c695763e87bb8d62",
    name: "Bengaluru Corporate Office",
    unit_id: "O-001",
    type: "Office Unit",
    location: "Bengaluru",
    handled_by: "Varun Sharma",
  },
  {
    _id: "66ac5f4060ecfc479f338eeb",
    name: "Chennai Regional Office",
    unit_id: "O-002",
    type: "Office Unit",
    location: "Chennai",
    handled_by: "Suresh Iyengar",
  },
  {
    _id: "66ac5f43b168585dd10061e5",
    name: "Hyderabad Research Office",
    unit_id: "O-003",
    type: "Office Unit",
    location: "Hyderabad",
    handled_by: "Parent Entity",
  },
  {
    _id: "66ac5f477d8a08cb8790cb5e",
    name: "Pune Design Office",
    unit_id: "O-004",
    type: "Office Unit",
    location: "Pune",
    handled_by: "Vikram Kumar",
  },
  {
    _id: "66ac5f4f9e04c20c819159ec",
    name: "Mumbai Sales Office",
    unit_id: "O-005",
    type: "Office Unit",
    location: "Mumbai",
    handled_by: "Karthik Naidu",
  },
  {
    _id: "66ac5f55ee0b8478a8c2ee8f",
    name: "Delhi Administrative Office",
    unit_id: "O-006",
    type: "Office Unit",
    location: "Delhi",
    handled_by: "Aarti Balan",
  },
];

const tableRowOrder = ["name", "unit_id", "type", "location", "handled_by"];

const customAction = (row) => (
  <IconButton
    aria-label="CustomAction"
    size="small"
    sx={{ color: "primary.main" }}
  >
    <IoIosArrowForward />
  </IconButton>
);

const UnitsTab = () => {
  return (
    <DynamicTableFull
      tableHead={tableHead}
      tableData={tableData}
      tableRowOrder={tableRowOrder}
      renderAction={customAction}
    />
  );
};
export default UnitsTab;
