import {
  SET_ESG_STRATEGY_PLANNING_ASSESSMENT_LIST,
  SET_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS,
  SET_ORIGINAL_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS,
  SET_ESG_SELECTED_TOPIC,
  SET_ESG_ACTIVITY,
  SET_ESG_KPIS,
  SET_ESG_POLICIES,
  SET_ESG_RISKS,
} from "constants/phase-3/esgStrategyPlanning/esgStrategyPlanningConst";

const initial_state = {
  esgStrategyPlanningAssessmentList: [],

  totalEsgStrategyPlanningAssessments: null,

  esgStrategyPlanningAssessmentDetail: {},
  originalEsgStrategyPlanningAssessmentDetail: {},
  esgSelectedTopic: null,
  esgActivity: [],
  esgKpi: [],
  esgPolicies: [],
  esgRisks: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ESG_STRATEGY_PLANNING_ASSESSMENT_LIST:
      return (state = {
        ...state,
        esgStrategyPlanningAssessmentList: action.payload?.result,
        totalEsgStrategyPlanningAssessments: action.payload?.total,
      });

    case SET_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        esgStrategyPlanningAssessmentDetail: action.payload,
      });

    case SET_ORIGINAL_ESG_STRATEGY_PLANNING_ASSESSMENT_DETAILS:
      return (state = {
        ...state,
        originalEsgStrategyPlanningAssessmentDetail: action.payload,
      });

    case SET_ESG_SELECTED_TOPIC:
      return (state = {
        ...state,
        esgSelectedTopic: action.payload,
      });
    case SET_ESG_ACTIVITY:
      return (state = {
        ...state,
        esgActivity: action.payload,
      });

    case SET_ESG_KPIS:
      return (state = {
        ...state,
        esgKpi: action.payload,
      });

    case SET_ESG_POLICIES:
      return (state = {
        ...state,
        esgPolicies: action.payload,
      });

    case SET_ESG_RISKS:
      return (state = {
        ...state,
        esgRisks: action.payload,
      });

    default:
      return state;
  }
}
