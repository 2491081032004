import { Box, Stack, Typography } from "@mui/material";
import ProfileBasicDetails from "components/widgets/phase-3/OrgDetailsProfile/ProfileBasicDetails";
import ProfileContactDetails from "components/widgets/phase-3/OrgDetailsProfile/ProfileContactDetails";

const Profile = () => {
  const profileData = {
    company_image: {
      url: "https://s3-alpha-sig.figma.com/img/738e/ea8f/372d7f523df1037f08b3406c9ee0e9aa?Expires=1723420800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dKTfLs-G5URK3sOiUPf32v2BhRTmdr6nPNfW4gniadONl9P0CaTIQ0amBhBOkUpOI~~IaMrH54lrFNcTDceJJKKa4xdQYYRr-5x2rk8jeBa4aNC2YPB~5TNV~h3GYBnFoUk4pMBmvvtEOLQEChMbqjiQlNsVMTHw~P5Jfe~2~tq3BmnItjA4Tpun--KnUW~I2tTAxZlq7M8UoZ7Prk9lZjs0VPnXbXM89VYaUxpDqnAEhrcdcwl9riV0CTG9YgDd80t5ymkvwNIS0d6fsItG7-KJD3rLqh~e2by7IMkFGsu2uBXF1o46f~OuqmaVmrpKNropIOsu9F1Hui0xjYtwQQ__",
      name: "companyAvatar",
      type: "JPEG",
    },
    company_name: "Aerofusion Dynamics",
    industry_list: [
      { _id: "6584151sds121ds1d1s", name: "Manufacturing" },
      { _id: "6584151sds121ds1d1a", name: "Cyber Security" },
      { _id: "6584151sds121ds1d1b", name: "Transportion" },
    ],
    sector_list: [
      { _id: "68844df15df55df51da", name: "IT Sector" },
      { _id: "68844df15df55df51db", name: "Security" },
      { _id: "68844df15df55df51dc", name: "Transport" },
    ],
    industry: { _id: "6584151sds121ds1d1s", name: "Manufacturing" },
    sector: { _id: "68844df15df55df51da", name: "IT Sector" },

    company_id: "65578",

    contact_details: {
      apartment_name: "Wayne Towers",
      street_name: "gowtham",
      city_state: "Chennai, Tamil Nadu",
      pin_code: 600034,
    },
  };

  return (
    <Box>
      {/* Heading and Details */}
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: "primary.dark" }}>
          Profile
        </Typography>
        <Typography variant="body2">
          This profile section provides a comprehensive overview of Aerofusion
          Dynamics, highlighting its key details, locations, services, and
          commitment to sustainability.
        </Typography>
      </Stack>

      {/* ProfileBasicDetails */}
      <ProfileBasicDetails
        industry_list={profileData?.industry_list}
        sector_list={profileData?.sector_list}
        profileData={profileData}
      />

      {/* ProfileContactDetails */}
      <ProfileContactDetails contact_details={profileData?.contact_details} />
    </Box>
  );
};
export default Profile;
