import apiBolerplate from "utils/_helpers/apiBolerplate";
import {
  SET_INDUSTRY_ARRAY,
  SET_SECTOR_ARRAY,
} from "constants/phase-3/common/commonConst";

// ----------------- Payloads -----------------
export function setSectorArray(payload) {
  return {
    type: SET_SECTOR_ARRAY,
    payload: payload,
  };
}

export function setIndustryArray(payload) {
  return {
    type: SET_INDUSTRY_ARRAY,
    payload: payload,
  };
}

// ----------------- Actions -----------------

export function getSectorArray() {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/sector",
      method: "get",
      callback: (data) => {
        // final actions
        dispatch(setSectorArray(data?.result));
      },
    });
  };
}

export function getIndustryArray(sector_id) {
  return async (dispatch) => {
    apiBolerplate({
      dispatch,
      url: "/sector/industry",
      bodyData: { sector_id },
      hideLoader: true,
      hideMsg: true,
      callback: (data) => {
        // final actions
        dispatch(setIndustryArray(data?.result));
      },
    });
  };
}
