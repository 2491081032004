import makeStyles from "@mui/styles/makeStyles";

const companyDetailsFormStyles = makeStyles((theme) => ({
  image: {
    width: 200,
    height: 200,
    borderRadius: "50%",
    overflow: "hidden",
  },
  fileUpload: {
    cursor: "pointer",
  },
  blueText: {
    color: "#3374B9",
    fontWeight: "600",
    fontSize: 14,
  },
  sectorChip: {
    justifyContent: "flex-start",
    color: "#77787B",
    backgroundColor: "#F5F6F8",
    borderRadius: "5%",
    border: "0px",
    margin: "4px 8px 4px 0px",
    "&&:hover": {
      "& $removeChipIcon": {
        display: "inline",
      },
    },
    "&&:focus": {
      color: "#77787B",
      backgroundColor: "#F5F6F8",
    },
  },
  selectedSectorTitle: {
    color: "#77787B",
    fontSize: 14,
  },
  removeChipIcon: {
    display: "none",
  },
  IconColor: { color: "#000" },
  Avatar: { width: 200, height: 200 },
  grid: { borderBottom: "1px solid #DADBE6" },
  grid1: { width: 900 },
  grid2: { marginTop: 15, marginBottom: 15 },
  grid3: { padding: 0, paddingLeft: 30 },
  div: { marginTop: 15 },
  div1: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  div2: { marginBottom: 8 },
  div3: { display: "flex", alignItems: "center" },
  div4: { marginBottom: 8 },
  Typography: {
    paddingBottom: 5,
    borderBottom: "3px solid green",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  AccordionDetails: { display: "flex", flexDirection: "column" },
  Card: { minHeight: 350, width: "95%", marginTop: 15 },
  Card1: { border: "none" },
  Card2: { border: "none", height: "10vh" },
  CardContent: { textAlign: "right", paddingRight: 100 },
  typo: { fontWeight: "600", fontSize: 26 },
  typo1: { fontWeight: "600" },
  CustomButton: { marginRight: "30px" },
  CustomButton1: { textTransform: "none" },
  CustomButton2: { paddingRight: 30, paddingLeft: 30 },
}));
export default companyDetailsFormStyles;
