import DonutChart from "react-donut-chart";
import "./donut.css";

function PercentageDoughnutChart({
  percentage,
  color,
  emptyColor,
  footPrint,
  is_premium = true,
  customSize,
}) {
  return (
    <div
      style={{ width: "100%", filter: is_premium ? "blur(0px)" : "blur(5px)" }}
    >
      {
        <div className="main">
          <DonutChart
            // outerRadius={0.5}
            // innerRadius={0.9}
            // plugins={[circle]}
            className="donutchart-innertext-value"
            height={customSize || footPrint ? 150 : 200}
            width={customSize || footPrint ? 150 : 200}
            legend={false}
            emptyOffset={0.01}
            emptyColor={emptyColor}
            formatValues={(values, total) => `${percentage}/100`}
            colors={[color]}
            data={[
              {
                label: "",
                value: percentage,
              },
              {
                label: "",
                value: 100 - percentage,
                isEmpty: true,
              },
            ]}
          />
        </div>
      }
    </div>
  );
}

export default PercentageDoughnutChart;
